import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import classNames from "classnames";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchHomepageComponent,
  selectPopulatedHomepage,
} from "../../../../redux/slices/utilsSlice";
import { staggerContainer } from "../../components/utils/motion";
import { TitleText, TypingText } from "../utils/CustomText";
import FeatureCycleCard from "./FeaturedCycleCard";
import styles from "./featuredCyclesMOD.module.scss";

function FeaturedCycles(props) {
  const { data, index } = props;
  const dispatch = useDispatch();
  const pop = useSelector(selectPopulatedHomepage);
  const items = pop[index];

  async function fetchData() {
    if (items !== undefined || items?.length > 0) return; //nie fetchujemy jeśli już jest
    const result = await dispatch(
      fetchHomepageComponent({
        url: `/events/cycles?page=1&limit=${
          data.count ? parseInt(data.count) * 3 : 3
        }`,
        index,
      })
    );
  }

  const MAX_VISIBILITY = 3;
  const itemsLength = items?.length;
  const [active, setActive] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (itemsLength >= 5) {
      setActive(2);
    }
  }, [items]);

  const loading = items === null || items === undefined;

  if (loading) {
    return <div></div>;
  }

  return (
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={classNames(
        styles.maxContainer,
        styles.featuredEventsSection,
        styles.sectionMargin
      )}
    >
      <TypingText title="| Znajdź coś dla siebie" />
      <TitleText title="Wyróżnione cykle" />
      <div className={styles.carousel}>
        {active > 0 && (
          <button
            className={styles.navLeft}
            onClick={() => setActive((i) => i - 1)}
          >
            <ChevronLeftIcon sx={{ fontSize: 72 }} />
          </button>
        )}
        {itemsLength >= 5
          ? items?.map((cycle, i) => (
              <div
                key={i}
                className={styles.cardContainer}
                style={{
                  "--active": i === active ? 1 : 0,
                  "--offset": (active - i) / 3,
                  "--direction": Math.sign(active - i),
                  "--abs-offset": Math.abs(active - i) / 3,
                  pointerEvents: active === i ? "auto" : "none",
                  opacity: Math.abs(active - i) >= MAX_VISIBILITY ? "0" : "1",
                  display:
                    Math.abs(active - i) > MAX_VISIBILITY ? "none" : "block",
                }}
              >
                <FeatureCycleCard
                  key={cycle.id}
                  index={i}
                  active={active}
                  handleClick={setActive}
                  thumbnail={cycle?.product?.thumbnail}
                  {...cycle}
                />
              </div>
            ))
          : items?.map((cycle, i) => (
              <div
                key={i}
                className={styles.cardContainer}
                style={{
                  "--active": i === active ? 1 : 0,
                  "--offset": (active - i) / 3,
                  "--direction": Math.sign(active - i),
                  "--abs-offset": Math.abs(active - i) / 3,
                  pointerEvents: active === i ? "auto" : "none",
                  opacity: Math.abs(active - i) >= items.length + 1 ? "0" : "1",
                  display:
                    Math.abs(active - i) > items.length + 1 ? "none" : "block",
                }}
              >
                <FeatureCycleCard
                  key={cycle.id}
                  index={i}
                  active={active}
                  handleClick={setActive}
                  {...cycle}
                />
              </div>
            ))}
        {active < itemsLength - 1 && (
          <button
            className={styles.navRight}
            onClick={() => setActive((i) => i + 1)}
          >
            <ChevronRightIcon sx={{ fontSize: 72 }} />
          </button>
        )}
      </div>
    </motion.div>
  );
}

export default connect()(FeaturedCycles);
