import React from "react";
import styles from "./Attendance.module.scss";
import cx from "classnames";
import moment from "moment";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";

function AttendeeCard(props) {
  const handleReservationRedirect = () => {
    props.history?.push(
      `/users/${props.originalUserId}?tab=Cykle+zajęć&reservationID=${props.id}`

    )
  }
  return (
    <div className={styles.attendeeCardWrapper}>
      <div
        className={styles.attendeeCard}
      >
        <div onClick={handleReservationRedirect} className={styles.name}>{props.name}</div>
        {props.status ? (
          <div  onClick={() => {
            props.toggleUserAttendanceById(props.id);
          }} className={cx([styles.state, styles.present])}>OBECNY</div>
        ) : (
          <div  onClick={() => {
            props.toggleUserAttendanceById(props.id);
          }} className={cx([styles.state, styles.absent])}>NIEOBECNY</div>
        )}
      </div>

      {props.details && (
        <div className={styles.details}>
          <div className={styles.part}>
            <div className={styles.icon}>
              {" "}
              <PersonIcon sx={20} />{" "}
            </div>
            <div className={styles.content}>
              {props.details.checkedBy.fullName}
            </div>
          </div>

          <div className={styles.part}>
            <div className={styles.icon}>
              {" "}
              <AccessTimeIcon sx={20} />{" "}
            </div>
            <div className={styles.content}>
              {moment(props.details.timestamp).format("DD-MM-YYYY HH:mm")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AttendeeCard;
