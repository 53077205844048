import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Resources.module.scss";
import {
  fetchResources,
  selectResources,
} from "../../redux/slices/resourcesSlice";
import ResourceCard from "./ResourceCard";
import Loading from "../../components/universal/Loading";
import Empty from "../../components/universal/Empty";

function Resources(props) {
  const dispatch = useDispatch();
  const { resources, loading } = useSelector(selectResources);

  useEffect(() => {
    dispatch(fetchResources());
  }, []);

  return (
    <div className={styles.resourcesContainer}>
      <div className={styles.resourcesWrapper}>
        {loading ? <Loading/> : resources.length > 0 ? resources.map((resource) => (
          <ResourceCard resource={resource} />
        )) : <Empty text="Nie znaleziono laboratoriów"/>
        }
      </div>
    </div>
  );
}

export default Resources;
