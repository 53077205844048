import classNames from "classnames";
import React from "react";

import moment from "moment";
import styles from "./messages.module.scss";

import VisibilityIcon from "@mui/icons-material/Visibility";

function MessageCard(props) {
  const { data } = props;
  let UserIcon;

  const construnctClassnames = () => {
    let classes = [styles.cardContent];
    if (props.isSelected) classes.push(styles.selectedCardContent);
    if (!data.seen) classes.push(styles.unseenCardContent);

    return classNames(classes);
  };

  return (
    <div className={styles.messageCard}>
      <div className={construnctClassnames()}>
        <div className={styles.subjectSection}>
          <p
            className={
              data.seen
                ? styles.subject
                : classNames(styles.unseenSubject, styles.subject)
            }
          >
            {data.subject}
          </p>
        </div>

        <div
          className={
            data.seen
              ? styles.adresses
              : classNames(styles.unseenAdresses, styles.adresses)
          }
        >
          <div className={styles.senderSection}>
            <p className={styles.sender}>
              od:{" "}
              <span className={styles.senderInfo}>
                {data.sender
                  ? `${data.sender.fullName} (${data.senderMail})`
                  : data.senderMail}{" "}
              </span>
            </p>
          </div>

          {props.isSelected && (
            <div className={styles.icon}>
              <VisibilityIcon sx={{ fontSize: 30 }} />
            </div>
          )}

          <div className={styles.recipientSection}>
            <p className={styles.recipient}>
              do:{" "}
              <span className={styles.senderInfo}>
                {data.recipient
                  ? `${data.recipient.fullName} (${data.recipientMail})`
                  : data.recipientMail}{" "}
              </span>
            </p>
          </div>
        </div>

        <div className={styles.dateSection}>
          <p className={styles.sentAt}>
            {moment(data.sentAt).locale("pl").format("DD-MM-YYYY, HH:mm")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default MessageCard;
