import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AddPostButton from "./components/addPostButton/AddPostButton";

import {
  logout,
  selectUser,
  setTicketModal,
  setTicketVerifiedModal,
} from "../../redux/slices/userSlice";
import PendingCycles from "./components/pendingCycles/PendingCycles";

import OwnFeedback from "./components/feedback/OwnFeedback";
import MyEvents from "./components/myEvents/MyEvents";

import Users from "./components/users/Users";

import Communication from "./components/communication/Communication";
import Resources from "./components/resources/Resources";
// import Displays from "./components/displays/Displays";
import AddCycleButton from "./components/addCycleButton/addCycleButton";
import MyCycles from "./components/myCycles/MyCycles";
import PromoCodesNew from "./components/promoCodes/PromoCodesNew";

import { Helmet } from "react-helmet";
import { setSelectedFeedbackEvent } from "../../redux/slices/feedbackSlice";
import {
  setAddResourceModalVisible,
  setResourceViewModalID,
} from "../../redux/slices/resourcesSlice";
import PanelButton from "../../components/panelButton/PanelButton";
import styles from "./Panel.module.scss";
import LogoutButton from "./components/logoutButton/LogoutButton";
import ImageModal from "../../components/imageUploader/ImageModal";
import HourlyAssessmentButton from "./components/hourlyAssessmentButton/HourlyAssessmentButton";
import Logs from "../logs/Logs";
import LogsButton from "./components/logsButton/LogsButton";
import OrdersButton from "./components/ordersButton/OrdersButton";
import ManageHomePage from "./components/manageHomepage/ManageHomepage";
import Displays from "./components/displays/Displays";
import ManageCustomPages from "./components/manageCustomPages/ManageCustomPages";
import TicketButton from "./components/TicketButton/TicketButton";
import { socket } from "../../API/socketService";
import TicketVerified from "./components/TicketVerified/TicketVerified";
import ExternalCalendar from "./components/externalCalendar/ExternalCalendar";
import FastAddReservation from "./components/fastAddReservation/FastAddReservation";
import AddProductButton from "./components/addProductButton/AddProductButton";
import QuickUserSearch from "./components/QuickUserSearch/QuickUserSearch";
import ProductsButton from "./components/products/Products";
import ResetMyPassword from "./components/ResetMyPassword/ResetMyPassword";

function Panel(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const userData = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();

  const CHECK_FOR_INVOKES = () => {
    const searchParams = new URLSearchParams(history.location.search);

    if (searchParams.get("setEventForEvaluation")) {
      dispatch(
        setSelectedFeedbackEvent(searchParams.get("setEventForEvaluation"))
      );
    }
    if (searchParams.get("setResourceView")) {
      dispatch(setResourceViewModalID(searchParams.get("setResourceView")));
    }
    if (searchParams.get("addResourceView")) {
      dispatch(setAddResourceModalVisible());
    }

    history.replace({
      search: "",
    });
  };

  useEffect(() => {
    CHECK_FOR_INVOKES();
    socket.on("verifyTicket", (data) => {
      dispatch(setTicketModal(false));
      dispatch(setTicketVerifiedModal(true));
    });
  }, []);

  return (
    <div className={styles.panelWrapper}>
      <Helmet>
        <title>Panel - Stolica eXperymentu</title>
      </Helmet>
      <MyEvents showVisibilityToggle />
      <div className={styles.panelWidgets}>
        <div className={styles.panelButtons}>

          {(userData.scope.includes("users") || userData.type === "admin") && (
            <QuickUserSearch history={props.history} />
          )}

          {(userData.scope.includes("users") || userData.type === "admin") && (
            <FastAddReservation />
          )}

          {((userData.scope.includes("teacher") && userData.type === "mod") ||
            userData.type === "admin") && <Communication />}
          {(userData.scope.includes("cycles") || userData.type === "admin") && (
            <AddProductButton />
          )}
          {(userData.scope.includes("cycles") || userData.type === "admin") && (
            <AddCycleButton />
          )}

          {userData.type === "admin" && <Resources />}

          {userData.type === "user" && <TicketVerified />}
          {userData.type === "user" && <TicketButton />}
          {userData.type === "user" && <OrdersButton />}
          {(userData.type === "admin" || userData.scope.includes("users")) && (
            <Users />
          )}

          {(userData.type === "admin" ||
            userData.scope.includes("promoCodes")) && <PromoCodesNew />}
          {(userData.type === "admin" || userData.scope.includes("posts")) && (
            <AddPostButton />
          )}

          {(userData.type === "admin" || userData.scope.includes("logs")) && (
            <LogsButton />
          )}

          {(userData.type === "admin" ||
            userData.scope.includes("hourlyAssessment")) && (
              <HourlyAssessmentButton />
            )}
          {(userData.type === "admin" ||
            userData.scope.includes("manageHome")) && <ManageHomePage />}

          {(userData.type === "admin" ||
            userData.scope.includes("customPages")) && <ManageCustomPages />}

          {(userData.type === "admin" ||
            userData.scope.includes("displays")) && <Displays />}

          {(userData.scope.includes("cycles") || userData.type === "admin") && (
            <PendingCycles />
          )}

          {(userData.scope.includes("cycles") || userData.type === "admin") && (
            <ProductsButton />
          )}

          <ExternalCalendar />
          <ResetMyPassword />

          <LogoutButton />
        </div>

        <div className={styles.panelSemiPanels}>
          {userData.type === "user" && <OwnFeedback />}

          <MyCycles type={userData.type} />


        </div>
      </div>
    </div>
  );
}

export default connect()(Panel);
