import React from "react"
import { connect } from "react-redux"
import styles from "./components.module.scss"
import { motion } from "framer-motion"
import classNames from "classnames"
import { TypingText } from "./utils/CustomText"
import { fadeIn, staggerContainer } from "./utils/motion"

function Stats(props) {
  const { data } = props

  return (
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={styles.statsContainer}
    >
      {data.map((stat) => (
        <motion.div variants={stat.anim} key={stat.id} className={styles.stat}>
          <div className={styles.number}>{stat.value}</div>
          <div className={styles.title}>{stat.title}</div>
        </motion.div>
      ))}
    </motion.div>
  )
}

export default connect()(Stats)
