import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import styles from "./styles.module.scss";

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import classnames from "classnames";

function CustomCheckbox(props) {
  const { fontSize = "16px" } = props;
  const { textAlign = "center" } = props;

  const realWidth = parseInt(props.width) - 9;
  const realHeight = parseInt(props.height) - 4;

  // const checkboxWrapperStyles = useStyles(props);

  return (
    <div className={styles.checkboxWrapper} style={props.style}>
      <div
        onClick={props.onClick}
        style={{ height: props.size, width: props.size }}
        className={
          props.checked
            ? classnames([styles.checkbox, styles.activebox])
            : styles.checkbox
        }
      >
        <div className={styles.filler}>
          {props.checked ? <DoneIcon /> : <CloseIcon />}
        </div>
      </div>

      {props.label && (
        <div className={styles.labelContainer}>
          <div className={styles.label}>{props.label}</div>
        </div>
      )}
    </div>
  );
}

export default CustomCheckbox;
