import React, { useCallback } from "react";

import { createReactEditorJS } from "react-editor-js";
import Undo from "editorjs-undo";

import { i18n } from "./internalization";
import {
  EDITOR_JS_TEXT_ONLY,
  EDITOR_JS_TOOLS,
  EDITOR_JS_TOOLS_NO_FILES,
} from "./tools";

const ReactEditorJS = createReactEditorJS();

function TextEditor(props) {
  const handleInitialize = useCallback((instance) => {
    props.editorCore.current = instance;
  }, []);

  const config = {
    debounceTimer: 1000,
  };

  const handleReady = () => {
    const editor = props.editorCore.current._editorJS;
    /// UNDO TEMPORARILY DISABLED, AS IT COUSE SIGNIFICANT PERFORMANCE DROPS
    // const undo = new Undo({ editor, config });
    // if (props.defaultValue) undo.initialize(props.defaultValue);
  };

  return (
    <>
      {props.onChange ? (
        <ReactEditorJS
          i18n={i18n}
          holder={props.holder ? props.holder : undefined}
          tools={
            props.textOnly
              ? EDITOR_JS_TEXT_ONLY
              : props.useFiles
              ? EDITOR_JS_TOOLS
              : EDITOR_JS_TOOLS_NO_FILES
          }
          onInitialize={handleInitialize}
          onReady={handleReady}
          onChange={props.onChange ? props.onChange : null}
          readOnly={props.readOnly}
          defaultValue={props.defaultValue ? props.defaultValue : null}
          logLevel={"ERROR"}
        />
      ) : (
        <ReactEditorJS
          i18n={i18n}
          holder={props.holder && props.holder}
          tools={
            props.textOnly
              ? EDITOR_JS_TEXT_ONLY
              : props.useFiles
              ? EDITOR_JS_TOOLS
              : EDITOR_JS_TOOLS_NO_FILES
          }
          onInitialize={handleInitialize}
          onReady={handleReady}
          readOnly={props.readOnly}
          defaultValue={props.defaultValue ? props.defaultValue : null}
        />
      )}
    </>
  );
}

export default TextEditor;
