import React from "react";
import PanelButton from "../../../../components/panelButton/PanelButton";

import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { logout } from ".././../../../redux/slices/userSlice";

function LogoutButton(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <PanelButton
    label={"Wyloguj się"}
    icon={<LogoutIcon style={{ fontSize: 40 }} />}
    width={100}
    height={100}
    onClick={() => {
      dispatch(logout());
      history.push("/");
    }}
  />
  );
}

export default LogoutButton;
