import React from "react";

import styles from "./Floater.module.scss";

function Floater(props) {
  return (
    <div
      className={styles.floater}
      style={{
        left: props.left,
        right: props.right,
        top: props.top,
        bottom: props.bottom,
      }}
    >
      {props.text}
    </div>
  );
}

export default Floater;
