import React, { useState, useRef } from "react";

import { useDispatch, connect, useSelector } from "react-redux";

import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import plLocale from "@fullcalendar/core/locales/pl";
import { selectUser } from "../../redux/slices/userSlice";

import {
  selectSingleCycle,
  setSingleCycleEventToShow,
} from "../../redux/slices/eventsSlice";
import EventsList from "../calendarComponents/EventsList";
import EventModal from "../calendarComponents/EventModal";

import styles from "./calendar.module.scss";
import Loading from "../universal/Loading";
import { useEffect } from "react";

function ClassicCalendar(props) {
  const { cycle, singleCycleLoading, eventToShow } =
    useSelector(selectSingleCycle);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const parentLoading = props.loading;
  const [previewModalEvent, setPreviewModalEvent] = useState(null); ///dla modala

  const handleOpenPreviewModalEvent = (event) => {
    let eventFull = { ...event };

    if (typeof eventFull.cycle !== "object") eventFull.cycle = cycle;
    setPreviewModalEvent(eventFull);
  };

  useEffect(() => {
    if (eventToShow) handleOpenPreviewModalEvent(eventToShow);
  }, [eventToShow]);

  const handleClosePreviewModalEvent = () => {
    setPreviewModalEvent(null);
    dispatch(setSingleCycleEventToShow(null));
  };

  const calendarCore = useRef();

  const handleChangeDisplay = (el) => {
    let calendarApi = calendarCore.current.getApi();
    calendarApi.changeView("dayGridMonth", el.start);

    setTimeout(() => {
      let htmlEvent = document.getElementById(el.id);
      let parent = htmlEvent.parentElement.parentElement;
      parent.classList.add(styles.highlightedEvent);

      setTimeout(() => {
        parent.classList.remove(styles.highlightedEvent);
      }, 4000);
    }, 150);
  };

  return (
    <>
      <EventModal
        event={previewModalEvent}
        closeModal={handleClosePreviewModalEvent}
        singleCycle
      />

      <div className={styles.calendarAndListHolder}>
        <div className={styles.calendarHolder}>
          {parentLoading && (
            <div className={styles.loading}>
              {" "}
              <Loading />{" "}
            </div>
          )}
          <FullCalendar
            style={{ width: "100%" }}
            height={"auto"}
            ref={calendarCore}
            eventContent={(eventInfo) => {
              return (
                <div className="fc-event-main-frame">
                  <div className="fc-event-time">{eventInfo.timeText}</div>
                  <div className="fc-event-title-container">
                    <div
                      id={eventInfo.event.id}
                      className="fc-event-title fc-sticky"
                    >
                      {eventInfo.event.title}
                    </div>
                  </div>
                </div>
              );
            }}
            locale={plLocale}
            events={props.events}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            displayEventEnd={true}
            eventClick={(eventClick) => {
              handleOpenPreviewModalEvent({
                ...eventClick.event._def.extendedProps,
                title: eventClick.event.title,
                start: eventClick.event.start,
                end: eventClick.event.end,
              });
            }}
            eventDisplay="block"
            eventTimeFormat={{
              hour: "numeric",

              minute: "2-digit",
              meridiem: false,
            }}
          />
        </div>
        <div className={styles.listsContainer}>
          {props.inprogressEvents?.length > 0 && (
            <EventsList
              headerTitle="W trakcie"
              singleCycle={props.singleCycle}
              events={props.inprogressEvents}
              userType={user.type}
              userId={user._id}
              strList
              handleChangeDisplay={handleChangeDisplay}
              canAddEvents={props.canAddEvents}
              handleClick={(el) => {
                handleOpenPreviewModalEvent(el);
              }}
            />
          )}
          <EventsList
            singleCycle={props.singleCycle}
            upcoming={Boolean(props.upcomingEvents)}
            userType={user.type}
            events={props.upcomingEvents ? props.upcomingEvents : props.events}
            handleClick={(el) => {
              handleOpenPreviewModalEvent(el);
            }}
            handleChangeDisplay={handleChangeDisplay}
          />{" "}
        </div>
      </div>
    </>
  );
}

export default connect()(ClassicCalendar);
