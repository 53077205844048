import React from "react";

import { connect, useSelector } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";
import Loading from "../components/universal/Loading";

import { selectType } from "../redux/slices/userSlice";
import { emitInfo } from "./ToastEmmiter";

const PublicRoute = ({
  component,
  refreshToken,
  noMods,
  redirectPath,
  ...rest
}) => {
  let ComponentToRender = component;

  const data = useSelector(selectType);

  const USER_TYPE_CONDITION = noMods
    ? data.type == "user" || !data.authorized
    : true;

  const NOMODS_ROUTE = (
    <Route
      {...rest}
      render={(props) => (
        <>
          {emitInfo("Przeniesiono na podstronę dla superuserów")}
          <Redirect
            to={{ pathname: redirectPath, state: { from: props.location } }}
          />
        </>
      )}
    />
  );

  return refreshToken === true ? (
    <Route
      {...rest}
      render={(props) =>
        data.authorizing ? (
          <Loading />
        ) : !USER_TYPE_CONDITION ? (
          NOMODS_ROUTE
        ) : (
          <ComponentToRender {...props} />
        )
      }
    />
  ) : (
    <Route
      {...rest}
      render={(props) =>
        !USER_TYPE_CONDITION ? NOMODS_ROUTE : <ComponentToRender {...props} />
      }
    />
  );
};

export default withRouter(connect()(PublicRoute));
