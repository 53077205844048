import { Typography } from "@mui/material";
import React from "react";
import styles from "./homeNewsCardMOD.module.scss";
import moment from "moment";
import EdjsDisplay from "../../../../components/edjsDisplay/EdjsDisplay";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import Box from "@mui/material/Box";
import { fadeIn } from "../utils/motion";
import { motion } from "framer-motion";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

const DATE_FORMAT = "DD MMMM YYYY";

function HomeNewsCard(props) {
  const { data, index } = props;
  const history = useHistory();

  return (
    <motion.div
      variants={fadeIn("up", "spring", index * 0.4, 1)}
      className={styles.newsCard}
      onClick={() => {
        history.push("/aktualnosci/" + (data.slug ? data.slug : data._id));
      }}
      {...props}
    >
      <div className={styles.header}>
        <img
          src={data.thumbnail}
          className={styles.thumbnail}
          alt="Miniatura"
        />
        <div className={styles.date}>
          {moment(data.publicatedAt).locale("pl").format(DATE_FORMAT)}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.meta}>{data.title}</div>
        <div className={styles.desc}>{data.shortDesc}</div>
        <div className={styles.buttons}>
          <div className={styles.readMore}>Czytaj dalej</div>
        </div>
      </div>
    </motion.div>
  );
}

export default HomeNewsCard;
