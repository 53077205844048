import { useDispatch } from "react-redux";
import styles from "./QuickUserSearch.module.scss";
import {
  getParticipantReservations,
  selectParticipantsReservations,
  setSingleUser,
} from "../../../../redux/slices/manageUsersSlice";
import AutoCompleteSearch from "../../../../components/autoCompleteSearch/AutoCompleteSearch";
import Modal from "../../../../components/modal/Modal";
import { useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../../../components/universal/Loading";
import UserCycleReservationCard from "../../../users/components/CycleReservations/UserCycleReservationCard";

function QuickUserSearch(props) {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const dispatch = useDispatch();
  const { data, loading } = useSelector(selectParticipantsReservations);

  const hadnleSetSingleUser = (el) => {
    let pushlink = "/users/" + el._id + "?tab=Cykle+zajęć";
    if (el.participant) pushlink += "&participant=" + el.participant.fullName;
    props.history.push(pushlink);
    // setSelectedParticipant(el);
    // dispatch(getParticipantReservations(el.fullName));
    // setIsVisible(true);
  };

  const setInvisible = () => {
    setIsVisible(false);
  };

  return (
    <>
      <div className={styles.quickSearchBarContainer}>
        <AutoCompleteSearch
          searchUrl="/users/searchPeople"
          limit={15}
          setSingle={hadnleSetSingleUser}
          placeholder="Wyszukaj użytkownika"
          sx={{ width: 500 }}
          className={styles.searchBar}
          renderPhone
          renderEmail
          minLength={0}
        />
      </div>

      <Modal
        visible={isVisible}
        closeModal={setInvisible}
        title={
          "Zarządzaj rezerwacjami uczestnika" + selectedParticipant?.fullName
        }
        isDarkTitle={false}
        isPadding={true}
      >
        <div className={styles.modalContent}>
          {loading ? (
            <Loading />
          ) : (
            <>
              {data.map((el) => (
                <UserCycleReservationCard data={el} key={el._id} />
              ))}
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default QuickUserSearch;
