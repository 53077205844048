import { Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import styles from "./CycleReservations.module.scss";
import { reservationStatuses } from "./UserCycleReservations";
import { useSelector, connect } from "react-redux";
import { selectMaxLengths } from "../../../../redux/slices/utilsSlice";
import a from "color-alpha";
import { Tooltip } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const DATE_FORMAT = "dddd, DD-MM-YYYY HH:mm";

function UserCycleReservationCard(props) {
  const { data, additionalProps, onClick, ...rest } = props;
  const { cycleReservationStates } = useSelector(selectMaxLengths);

  const getStatusText = (status) => {
    return cycleReservationStates[status].text;
  };

  const getStatusColor = (status) => {
    return cycleReservationStates[status]?.color;
  };
  return (
    <div onClick={onClick} className={styles.userCycleReservationCard}>
      <div className={styles.cardContent}>
        <div className={styles.titleSection}>
          <Typography className={styles.title}>{data.cycle?.name}</Typography>
          <div className={styles.dates}>
            {data.cycle?.events?.map((e,i)=><>
            <span>{moment(e.start).format('dddd DD MMMM YYYY HH:mm')}</span>
            {i != data.cycle?.events?.length-1 && <span>{` | `}</span>}
            </>
            )}
          </div>
          <Typography className={styles.participant}>
            {data.participant?.fullName} {data.participant?.age && `(${data.participant.age} lat)`} {data.participant?.supervisor && ` - ${data.participant.supervisor}`} {data.participant?.phone && ` - ${data.participant.phone}`}
          </Typography>
        </div>

        <div className={styles.content}>
          <div
            style={{
              color: getStatusColor(data.status),
              backgroundColor: a(getStatusColor(data.status), 0.1),
            }}
            className={styles.statusSection}
          >
            {getStatusText(data.status)}
          </div>

          <div className={styles.timestampsSection}>
            <Tooltip title="Utworzono" placement="top">
              <div className={styles.timestamp}>
                <div className={styles.icon}>
                  <AddIcon sx={{ fontSize: 35 }} />
                </div>
                <div className={styles.text}>
                  {moment(data.createdAt).locale("pl").format(DATE_FORMAT)}
                  {data?.createdBy?.fullName &&
                    ` przez ${data.createdBy.fullName}`}
                </div>
              </div>
            </Tooltip>
            <Tooltip title="Zmodyfikowano">
              <div className={styles.timestamp}>
                <div className={styles.icon}>
                  <EditIcon />{" "}
                </div>
                <div className={styles.text}>
                  {moment(data.updatedAt).locale("pl").format(DATE_FORMAT)}

                  {data?.editedBy?.fullName &&
                    ` przez ${data.editedBy.fullName}`}
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect()(UserCycleReservationCard);
