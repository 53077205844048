import React from "react";

import styles from "./cycles.module.scss";

function PendingCycleCard(props) {
  const { data } = props;
  const date = new Date(data.createdAt);

  //console.log("pending", data);
  return (
    <div
      // onClick={() => .}
      {...props}
      className={styles.pendingCycleCard}
    >
      <div className={styles.name}>{data.name}</div>
      <div className={styles.building}>
        utworzony {date.toLocaleDateString("pl-PL")} przez{" "}
        {data.createdBy?.fullName}
      </div>
    </div>
  );
}

export default PendingCycleCard;
