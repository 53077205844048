import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../API/APIService";
import { toast } from "react-toastify";

import { history } from "../../index.jsx";

export const addReservationItemType = createAsyncThunk(
  "news/add",
  async (data) => {
    try {
      const response = await API.post("/reservations/types", data);
      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error);
      throw error;
    }
  }
);

export const setPage = createAsyncThunk(
  "reservations/setPage",
  async (obj, thunkApi) => {
    try {
      const { page } = obj;
      const response = await API.get(`/reservations/types/?page=${page}`);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const fetchItemType = createAsyncThunk(
  "reservations/fetchItemType",
  async (id) => {
    try {
      const response = await API.get(`/reservations/types?id=${id}`);

      return response.data;
    } catch (error) {
      toast.error(error.response.data.error);
      console.log(error);
      throw error;
    }
  }
);

export const updateItemType = createAsyncThunk(
  "reservations/updateItemType",
  async (obj, thunkApi) => {
    try {
      const response = await API.patch("/reservations/types", obj);
      toast.success("Pomyślnie edytowano dokument");
      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error);
      throw error;
    }
  }
);

export const deleteItemType = createAsyncThunk(
  "news/delete",
  async (id, thunkApi) => {
    try {
      const response = await API.delete(`/news/?_id=${id}`);
      toast.success("Pomyślnie usunięto dokument");
      history.push("/news");
      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error);
      throw error;
    }
  }
);

export const fetchOccupancy = createAsyncThunk(
  "reservations/fetchOccupancy",
  async (obj, thunkApi) => {
    try {
      console.log("requesting occ");

      const response = await API.get(
        `/reservations/occupancy/?typeId=${obj.typeId}&month=${obj.month}&year=${obj.year}`
      );

      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getLongestAvailable = createAsyncThunk(
  "reservations/getLongestAvailable",
  async (obj, thunkApi) => {
    try {
      console.log("requesting longestav");

      const response = await API.get(
        `/reservations/longestAvailable/?typeId=${
          obj.typeId
        }&date=${obj.startDate.format("YYYY-MM-DD")}`
      );

      obj.handleNext();

      return response.data;
    } catch (error) {
      toast.error("Nie znaleziono przedmiotu dla wybranej daty");
      console.log(error);
      throw error;
    }
  }
);

export const bookItem = createAsyncThunk(
  "resevations/book",
  async (obj, thunkApi) => {
    try {
      console.log("requesting booook");
      const { handleNext, ...rest } = obj;

      const response = await API.post(`/reservations/book`, rest);
      handleNext();

      console.log(response.data);

      return response.data;
    } catch (error) {
      toast.error("Nie udało się znaleźć pasującego przedmiotu!");
      console.log(error);
      throw error;
    }
  }
);

export const reservationSlice = createSlice({
  name: "reservations",
  initialState: {
    itemTypes: [],
    itemType: {},
    page: 1,
    totalPages: 0,
    loading: false,
    occupancy: [],
    occupancyLoading: false,
    longestAv: undefined,
    longestAvLoading: undefined,
    order: {},
  },
  reducers: {
    setItemType: (state, action) => {
      state.itemType = action.payload;
    },
    clearLongestAv: (state, action) => {
      state.longestAv = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setPage.fulfilled, (state, action) => {
      state.itemTypes = action.payload.docs;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
    });

    builder.addCase(fetchItemType.fulfilled, (state, action) => {
      state.itemType = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchItemType.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchItemType.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fetchOccupancy.fulfilled, (state, action) => {
      state.occupancy = action.payload.occupancy;
      state.occupancyLoading = false;
    });

    builder.addCase(fetchOccupancy.pending, (state, action) => {
      state.occupancyLoading = true;
      state.occupancy = [];
    });

    builder.addCase(fetchOccupancy.rejected, (state, action) => {
      state.occupancyLoading = false;
    });

    builder.addCase(getLongestAvailable.fulfilled, (state, action) => {
      state.longestAv = action.payload.longestAvailable;
      state.longestAvLoading = false;
    });

    builder.addCase(getLongestAvailable.pending, (state, action) => {
      state.longestAvLoading = true;
      state.longestAv = undefined;
    });

    builder.addCase(getLongestAvailable.rejected, (state, action) => {
      state.longestAvLoading = false;
    });

    builder.addCase(bookItem.fulfilled, (state, action) => {
      state.order = action.payload;
      state.bookLoading = false;
    });

    builder.addCase(bookItem.pending, (state, action) => {
      state.bookLoading = true;
      state.order = {};
    });

    builder.addCase(bookItem.rejected, (state, action) => {
      state.bookLoading = false;
    });
  },
});

const { actions, reducer } = reservationSlice;

// Action creators are generated for each case reducer function
export const { setItemType, clearLongestAv } = actions;

export const selectItemTypes = (state) => {
  return state.reservations.itemTypes;
};
export const selectPages = (state) => {
  return {
    page: state.reservations.page,
    pageCount: state.reservations.totalPages,
  };
};

export const selectItemType = (state) => {
  return {
    itemType: state.reservations.itemType,
    loading: state.reservations.loading,
  };
};

export const selectOccupancy = (state) => {
  return {
    array: state.reservations.occupancy,
    loading: state.reservations.occupancyLoading,
  };
};

export const selectLongestAv = (state) => {
  return {
    longestAvailable: state.reservations.longestAv,
    loading: state.reservations.longestAvLoading,
  };
};

export const selectOrder = (state) => {
  return {
    order: state.reservations.order,
    bookLoading: state.reservations.bookLoading,
  };
};

export default reducer;
