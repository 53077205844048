import { useState, useEffect } from "react";

export function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let scrollCont = document.getElementById("SXWrapper");
    let lastScrollY = scrollCont.scrollTop;
    // function to run on scroll
    const updateScrollDirection = () => {
      const scrollY = scrollCont.scrollTop;

      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    scrollCont.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      scrollCont.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]); // run when scroll direction changes

  return scrollDirection;
}
