import React, { useState } from "react";
import { connect } from "react-redux";
import styles from "./components.module.scss";
import EdjsDisplay from "../../../components/edjsDisplay/EdjsDisplay";
import Loading from "../../../components/universal/Loading";
import NotFound from "../../../components/universal/NotFound";
import { motion } from "framer-motion";
import { fadeIn, staggerContainer } from "./utils/motion";
function VariableSection(props) {
  const { data } = props;

  return (

      <div className={styles.variableSection}>
        <div className={styles.mainContainer}>
            <EdjsDisplay data={data?.content} />
        </div>
      </div>
  );
}

export default connect()(VariableSection);
