import React from "react";
import { connect, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination/Pagination";
import {
  getCycleFeedbackSelectedEvent,
  selectAverageRatingForEvent,
  selectFeedbacksByEventData,
  selectFeedbacksByEventLoading,
  selectFeedbacksByEventPage,
  setCycleFeedbackSelectedEvent,
  setFeedbacksByEventPage,
} from "../../../redux/slices/feedbackSlice";

import LightbulbIcon from "@mui/icons-material/Lightbulb";
import styles from "./EventFeedbacks.module.scss"
import FeedbackCard from "./FeedbackCard";

function EventFeedbacks(props) {
  const currentAverageRating = useSelector(selectAverageRatingForEvent);
  
  return (
    <div className={styles.eventFeedbacksContainer}>

    <div className={styles.header}>
      Oceny
    </div>

      {currentAverageRating && (
        <div className={styles.averageRatingContainer}>
          <span className={styles.txt}>Średnia ocena wydarzenia</span>
          <div className={styles.rating}>
            <div className={styles.icon}>
              <LightbulbIcon sx={{ fontSize: 30 }} />
            </div>
            <span className={styles.value}>{currentAverageRating}</span>
          </div>
        </div>
      )}

      <Pagination
        dataSelector={selectFeedbacksByEventData}
        pagesSelector={selectFeedbacksByEventPage}
        loadingSelector={selectFeedbacksByEventLoading}
        setPage={setFeedbacksByEventPage}
        ElementCard={FeedbackCard}
        emptyText={"Brak odzewu od uczestników"}
        emptyHint={
          "Tutaj pojawi się odzew pozostawiony przez uczestników tego wydarzenia"
        }
        customDispatchProps={{ id: props.eventID }}
        dataContainerClassName={styles.feedbackCardsContainer}
      />
    </div>
  );
}

export default connect()(EventFeedbacks);
