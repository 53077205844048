import { io } from "socket.io-client";

export let socket;

async function setupConnection(id) {
  if (socket?.connected) {
    console.log("Socket already connected");
    return;
  }

  let backend = "";
  //  if (process.env.NODE_ENV === "development") backend = "http://localhost:2069";
  if (process.env.NODE_ENV === "development") backend = "http://localhost";
  else backend = process.env.REACT_APP_SERVER_HOST; /// MUSI BYĆ PEŁEN PATH

  socket = io(backend, {
    path:
      process.env.NODE_ENV === "development"
        ? "/sockets/user/socket.io"
        : "/sockets/user/socket.io",
    rejectUnauthorized: false,
  });

  socket.on("connect", () => {
    console.log("SOCKET: connected");

    socket.emit("handshake", id);
  });

  socket.on("connect_error", (err) => {
    console.log(`connect_error due to ${err.message}`);
  });
}

async function closeConnection() {
  if (!socket?.connected) {
    console.log("Socket not connected");
    return;
  }

  try {
    socket.close();
  } catch (error) {
    console.log(error);
  }

  return "Closed";
}

export default { setupConnection, closeConnection };
