import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  selectNewPromoCodes,
  setNewPromoCodes,
} from "../../../../redux/slices/promoCodesSlice";
import PromoCodeCard from "./PromoCodeCard";

import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import styles from "./promoCodes.module.scss";

import CopyAllIcon from "@mui/icons-material/CopyAll";

function CodesNotification(props) {
  const dispatch = useDispatch();
  const { promoCodes, loading } = useSelector(selectNewPromoCodes);

  const handleCopyNewCodes = async () => {
    let promocodes = [];

    promoCodes.map((el) => {
      promocodes.push(el.code);
    });

    navigator.clipboard.writeText(promocodes.join(", "));
    dispatch(setNewPromoCodes([]));
  };

  return (
    <>
      <div className={styles.codesNotifications}>
        <div className={styles.header}>Nowe kody</div>
        <div className={styles.codesNotificationsContent}>
          {promoCodes.length > 0 ? (
            <>
              {promoCodes.map((el) => (
                <PromoCodeCard data={el} isSmall={true} />
              ))}
            </>
          ) : (
            <>
              <div className={styles.noNewCodes}>
                <Typography>Brak nowych kodów do wyświetlenia</Typography>
              </div>
            </>
          )}
        </div>

        <LoadingButton
          variant="outlined"
          onClick={handleCopyNewCodes}
          endIcon={<CopyAllIcon />}
          disabled={promoCodes.length === 0}
        >
          Skopiuj nowe kody
        </LoadingButton>
      </div>
    </>
  );
}

export default connect()(CodesNotification);
