import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../API/APIService";
import { toast } from "react-toastify";
import Cookie from "js-cookie";
import { history } from "../../index.jsx";
import { emmitError, emmitSuccess } from "../../utils/ToastEmmiter";

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    open: false,
    notifications: [],
  },
  reducers: {
    markNotificationSeen: (state, action) => {},
    setNotifications: (state, action) => {
      state.notifications = action.payload.map((el) => {
        return { seen: false, ...el };
      });
    },
    openNotifications: (state, action) => {
      state.open = true;
    },
    closeNotifications: (state, action) => {
      state.open = false;
    },
  },
});

const { actions, reducer } = notificationsSlice;

export const {
  markNotificationSeen,
  setNotifications,
  openNotifications,
  closeNotifications,
} = actions;

export const selectUnseenNotificationsCount = (state) =>
  state.notifications.notifications.filter((el) => el.seen === false).length;

export const selectNotifications = (state) => {
  return {
    notifications: state.notifications.notifications,
    open: state.notifications.open,
  };
};

export default reducer;
