import React from "react";
import Lottie from "lottie-react";
import * as animDef from "./loadingData.json";
import styles from "./styles.module.scss";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

function NotFound(props) {
  return (
    <div className={styles.notFound} {...props}>
      <div className={styles.notFoundContanier}>
        <div className={styles.icon}>
          <QuestionMarkIcon sx={{fontSize: 30}}/>
        </div>
        <div className={styles.txt}>
        {props.text ? props.text : "Nie znaleziono"}
        </div>
      </div>
    </div>
  );
}

export default NotFound;
