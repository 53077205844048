import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../components/modal/Modal";
import PanelButton from "../../../../components/panelButton/PanelButton";
import styles from "./promoCodes.module.scss";

import Pagination from "../../../../components/Pagination/Pagination";

import {
  selectPages,
  selectPagesLoading,
  selectPromoCodes,
  setPage,
  setSinglePromoCode,
} from "../../../../redux/slices/promoCodesSlice";
import PromoCodeCard from "./PromoCodeCard";
// import PromoCodeModal from "../../../../components/PromoCodeModal/PromoCodeModal";
import AddPromoCodes from "./AddPromoCodes";
import CodesNotifications from "./CodesNotifications";

function PromoCodesModal(props) {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);

  const setVisible = () => {
    setIsVisible(true);
  };

  const setInvisible = () => {
    setIsVisible(false);
  };

  return (
    <>
      <PanelButton
        label={"Kody promocyjne"}
        icon={<LocalOfferIcon style={{ fontSize: 40 }} />}
        onClick={setVisible}
        width={100}
        height={100}
      />
      <Modal
        visible={isVisible}
        closeModal={setInvisible}
        title={"Zarządzaj kodami promocyjnymi"}
        isDarkTitle={false}
        isPadding={true}
      >
        <div className={styles.managePromoContainer}>
          <div className={styles.topBarContainer}>
            <AddPromoCodes />

            <CodesNotifications />
          </div>

          <Pagination
            key="PROMOCODES"
            dataSelector={selectPromoCodes}
            pagesSelector={selectPages}
            loadingSelector={selectPagesLoading}
            setSingle={setSinglePromoCode}
            customRedirectHandler={() => {}}
            setPage={setPage}
            ElementCard={PromoCodeCard}
            dataContainerClassName={styles.paginationContainer}
            emptyHint={"Tutaj pojawią się kody promocyjne"}
            emptyText={"Brak kodów promocyjnych"}
          />
        </div>
      </Modal>
    </>
  );
}

export default connect()(PromoCodesModal);
