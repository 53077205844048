import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import styles from "./CycleFeedback.module.scss";

import LightbulbIcon from "@mui/icons-material/Lightbulb";
import Pagination from "../../../../components/Pagination/Pagination";
import Empty from "../../../../components/universal/Empty";
import {
  selectSingleCycleLateEvents,
  selectSingleCycleOWNLateEvents,
} from "../../../../redux/slices/eventsSlice";
import {
  getCycleFeedbackSelectedEvent,
  selectAverageRatingForEvent,
  selectFeedbacksByEventData,
  selectFeedbacksByEventLoading,
  selectFeedbacksByEventPage,
  setCycleFeedbackSelectedEvent,
  setFeedbacksByEventPage,
} from "../../../../redux/slices/feedbackSlice";
import { selectUser } from "../../../../redux/slices/userSlice";
import EventCard from "./EventCard";
import FeedbackCard from "./FeedbackCard";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";

import Checkbox from "@mui/material/Checkbox";
import { OutlinedInput } from "@mui/material";
import { useHistory } from "react-router-dom";

function CycleFeedback(props) {
  const selectedEvent = useSelector(getCycleFeedbackSelectedEvent);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [availableTeachers, setAvailableTeachers] = useState({});
  const [selectedTeachers, setSelectedTeachers] = useState([]);

  const handleChangeSelectedTeachers = (event) => {
    let toSet =
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value;

    toSet = toSet.filter((e) => Object.keys(availableTeachers).includes(e));

    setSelectedTeachers(toSet);
  };

  const IS_ONLY_TEACHER =
    !user.scope.includes("cycles") && user.type !== "admin";
  const lateEvents = useSelector(
    !IS_ONLY_TEACHER
      ? selectSingleCycleLateEvents
      : (state) => selectSingleCycleOWNLateEvents(state, user._id)
  );

  const currentAverageRating = useSelector(selectAverageRatingForEvent);

  const handleSelectEvent = (id) => {
    dispatch(setCycleFeedbackSelectedEvent(id));
    dispatch(setFeedbacksByEventPage({ page: 1, id }));
  };

  useEffect(() => {
    let uri = new URLSearchParams(history.location.search);
    if (uri.get("teacherToShow")) {
      setSelectedTeachers([uri.get("teacherToShow")]);
    }
  }, []);

  useEffect(() => {
    if (!IS_ONLY_TEACHER && lateEvents.length > 0) {
      let teachers = new Map();
      lateEvents.forEach((event) => {
        if (!teachers.get(event.teacher._id)) {
          teachers.set(event.teacher._id, event.teacher.fullName);
        }
      });
      setAvailableTeachers(Object.fromEntries(teachers));
    }
  }, [lateEvents]);

  return (
    <div className={styles.cycleFeedbackContainer}>
      <div className={styles.header}>

      <div className={styles.headerText}>
          {IS_ONLY_TEACHER
            ? "Odzew dotyczący Twoich wydarzeń"
            : "Odzew uczestników"}{" "} 
            </div>

            

        {!IS_ONLY_TEACHER && (
          <div className={styles.manualPickerContainer}>
          <FormControl className={styles.teacherSelection}>
            <InputLabel id="demo-multiple-checkbox-label">
              Wybrany nauczyciel
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedTeachers}
              onChange={handleChangeSelectedTeachers}
              input={<OutlinedInput label="Wybrany nauczyciel" />}
              renderValue={(selected) => {
                if (
                  selected.length === 1 &&
                  !Object.keys(availableTeachers).includes(selected[0])
                ) {
                  return "Nierozpoznany nauczyciel";
                }
                return selected
                  .map((el) => availableTeachers[el])
                  .join(", ");
              }}
            //MenuProps={MenuProps}
            >
              {Object.entries(availableTeachers).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  <Checkbox
                    checked={selectedTeachers.indexOf(key) > -1}
                  />
                  <ListItemText primary={value} />
                </MenuItem>
              ))}
            </Select>
          </FormControl></div>
        )} 

       
      </div>
      <div className={styles.columnsContainer}>
        <div className={styles.eventsColumn}>
          {lateEvents.length > 0 ? (
            <>
              {/* Jeśli jest adminem to ma mieć opcje filtrowania */}


              {lateEvents
                .filter((e) => {
                  if (selectedTeachers.length === 0) {
                    return true;
                  }
                  return selectedTeachers.includes(e.teacher._id);
                })
                .map((event) => (
                  <EventCard
                    key={event._id}
                    event={event}
                    onClick={handleSelectEvent}
                  />
                ))}
            </>
          ) : (
            <Empty
              text="Brak wydarzeń z ocenami"
              hint={
                IS_ONLY_TEACHER
                  ? "Tutaj pojawią się prowadzone przez Ciebie w przeszłości wydarzenia"
                  : "Tutaj pojawią się wydarzenia, które już się odbyły"
              }
            />
          )}
        </div>
        <div className={styles.reviewsColumn}>
          {!selectedEvent ? (
            <Empty
              text="Wybierz wydarzenie"
              hint="aby zobaczyć odzew uczestników"
            />
          ) : (
            <>
              {currentAverageRating && (
                <div className={styles.averageRatingContainer}>
                  <span className={styles.txt}>Średnia ocena wydarzenia</span>
                  <div className={styles.rating}>
                    <div className={styles.icon}>
                      <LightbulbIcon sx={{ fontSize: 30 }} />
                    </div>
                    <span className={styles.value}>{currentAverageRating}</span>
                  </div>
                </div>
              )}

              <Pagination
                dataSelector={selectFeedbacksByEventData}
                pagesSelector={selectFeedbacksByEventPage}
                loadingSelector={selectFeedbacksByEventLoading}
                setPage={setFeedbacksByEventPage}
                ElementCard={FeedbackCard}
                emptyText={"Brak odzewu od uczestników"}
                emptyHint={
                  "Tutaj pojawi się odzew pozostawiony przez uczestników tego wydarzenia"
                }
                customDispatchProps={{ id: selectedEvent }}
                dataContainerClassName={styles.feedbackCardsContainer}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect()(CycleFeedback);
