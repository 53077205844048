import PreviewIcon from "@mui/icons-material/Preview";
import React, { useState } from "react";
import ImageModal from "./ImageModal";
import styles from "./imageUploader.module.scss";

function Preview(props) {
  const [visible, setVisibility] = useState(false);
  const makeVisible = () => setVisibility(true);
  const makeInvisible = () => setVisibility(false)

  return (
    <div
      className={styles.preview}
    >
      <PreviewIcon
        sx={{ fontSize: 30 }}
        onClick={makeVisible}
        className={
          props.image != "" ? styles.previewActive : styles.previewInactive
        }
      />
        <ImageModal image={props.image} visible={visible} closeImageModal={makeInvisible}/>
        </div>
  );
}

export default Preview;
