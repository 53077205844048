import styles from "./ProductCycles.module.scss";
import velix4 from "../../../assets/velix/VELIX 4.png";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

function Tutorial(props) {
  useEffect(() => {
    const cookie = Cookies.get("tutorial");
    if (cookie == "never") {
      return;
    }
    setVisible(true);
  }, []);
  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    setVisible(false);
  };

  const setNeverVisible = () => {
    Cookies.set("tutorial", "never");
    setVisible(false);
  };

  return (
    visible && (
      <div className={styles.tutorialWrapper}>
        <div className={styles.tutorialContent}>
          <img src={velix4} />

          <div>
            Aby dodać zajęcia do koszyka musisz kliknąć w odpowiadający im kartonik.
          </div>

          <div>
            Aby usunąć wybrane zajęcia z koszyka, należy na nie kliknąć
            ponownie.
          </div>

          <div className={styles.buttons}>
            <Button variant="outlined" onClick={handleClose}>
              ZAMKNIJ
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={setNeverVisible}
            >
              NIE POKAZUJ PONOWNIE
            </Button>
          </div>
        </div>
      </div>
    )
  );
}

export default Tutorial;
