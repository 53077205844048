import React, { useRef, useState } from "react";

import InputLabel from "@mui/material/InputLabel";

import FormControl from "@mui/material/FormControl";

import TextField from "@mui/material/TextField";
import Modal from "../modal/Modal";

import styles from "./calendarComponents.module.scss";

import OutlinedInput from "@mui/material/OutlinedInput";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";

import LoadingButton from "@mui/lab/LoadingButton";

import ExpandableTextEditor from "../expandableTextEditor/expandableTextEditor";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { emmitError } from "../../utils/ToastEmmiter";
import { useDispatch, useSelector, connect } from "react-redux";
import { selectMaxLengths } from "../../redux/slices/utilsSlice";
import { setEventToEditData } from "../../redux/slices/eventsSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EditEventData(props) {
  // console.log("PROPSY WT");
  // console.log(props);
  const [modalNewEventTitle, setModalNewEventTitle] = React.useState(
    props.event.title
  );

  const maxLengths = useSelector(selectMaxLengths);

  const [preMailHours, setPreMailHours] = React.useState(
    props.event.extendedProps.preMail?.hours || 24
  );
  const [postMailHours, setPostMailHours] = React.useState(
    props.event.extendedProps.postMail?.hours || 24
  );

  const [teacher, setTeacher] = React.useState(
    props.event.extendedProps.teacher
      ? [
          props.event.extendedProps.teacher.id ||
            props.event.extendedProps.teacher._id ||
            props.event.extendedProps.teacher,
        ]
      : [props.teachers[0]._id]
  );

  const [additionalTeachers, setAdditionalTeachers] = React.useState(
    props.event.extendedProps.additionalTeachers
      ? props.event.extendedProps.additionalTeachers.map((teacher) => {
          return teacher.id || teacher._id || teacher;
        })
      : []
  );

  const [building, setBuilding] = React.useState(
    props.event._def.resourceIds.length === 2
      ? [props.event._def.resourceIds[1]]
      : [props.buildings[0]._id]
  );

  const dispatch = useDispatch();
  ///here everything saves
  const handleCloseModal = async () => {
    const eventDescription = await editorCoreMain.current.save();
    let preMail = undefined;
    let postMail = undefined;
    try {
      preMail = await editorCoreMain1.current.save();
    } catch (error) {
      //console.log("savingPreventd");
    }

    try {
      postMail = await editorCoreMain2.current.save();
    } catch (error) {
      //console.log("savingPreventd");
    }

    props.event.setProp("title", modalNewEventTitle);
    props.event.setExtendedProp("description", eventDescription);
    props.event.setExtendedProp("preMail", {
      content: preMail || props.event.extendedProps.preMail.content,
      hours: preMailHours,
      sent: props.event.extendedProps.preMail?.sent || false,
    });
    props.event.setExtendedProp("postMail", {
      content: postMail || props.event.extendedProps.postMail.content,
      hours: postMailHours,
      sent: props.event.extendedProps.postMail?.sent || false,
    });

    props.event.setResources([teacher[0], building[0]]);
    props.event.setExtendedProp("teacher", teacher[0]);
    props.event.setExtendedProp("additionalTeachers", additionalTeachers);
    // console.log(props.event)
    dispatch(setEventToEditData({ event: {}, modal: false }));
  };

  const editorCoreMain = useRef();
  const editorCoreMain1 = useRef();
  const editorCoreMain2 = useRef();

  const handleChangeTeacher = (event) => {
    const {
      target: { value },
    } = event;

    setTeacher(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeAdditionalTeachers = (event) => {
    const {
      target: { value },
    } = event;


    setAdditionalTeachers(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeBuilding = (event) => {
    const {
      target: { value },
    } = event;

    setBuilding(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [startDate, setStartDate] = useState(moment(props.event.start));
  const [endDate, setEndDate] = useState(moment(props.event.end));

  const handleStartDateChange = (value) => {
    if(!endDate) return emmitError("Wybierz datę zakończenia wydarzenia")
    if (endDate.isBefore(value))
      return emmitError(
        "Początek wydarzenia nie może być odbywać się przed jego końcem"
      );
    if(endDate.isSame(value)) return emmitError("Wydarzenie nie może trwać 0 minut");
    setStartDate(value);
    props.event.setStart(value.toISOString());
  };

  const handleEndDateChange = (value) => {
    if(!value) return emmitError("Wybierz datę zakończenia wydarzenia")
    if (value.isBefore(startDate))
      return emmitError(
        "Początek wydarzenia nie może być odbywać się przed jego końcem"
      );
    if(value.isSame(startDate)) return emmitError("Wydarzenie nie może trwać 0 minut");
    setEndDate(value);
    props.event.setEnd(value.toISOString());
  };

  const getGodzinyFormat = (value) => {
    value = parseInt(value);
    if (value <= 0) return "godzin";
    if (value === 1) return "godzinę";
    if (value === 2 || value === 3 || value === 4) return "godziny";
    if (value >= 5) return "godzin";
  };

  return (
    <>
      <Modal
        visible={props.open}
        closeModal={handleCloseModal}
        height={900}
        title={"Edytuj szczegóły wydarzenia"}
      >
        <div className={styles.editEventDataContainer}>
          <div className={styles.editable}>
            <TextField
              fullWidth
              inputProps={{ maxlength: maxLengths.name }}
              label="Nazwa wydarzenia"
              value={modalNewEventTitle}
              //defaultValue={props.event.title}
              onChange={(e) => setModalNewEventTitle(e.target.value)}
            />
          </div>

          <div className={styles.editable}>
            <FormControl>
              <InputLabel id="demo-simple-select-disabled-label">
                Nauczyciel główny
              </InputLabel>
              <Select
                labelId="demo-simple-select-disabled-label"
                id="demo-multiple-name"
                value={teacher}
                onChange={handleChangeTeacher}
                fullWidth
                input={<OutlinedInput label="Nauczyciel główny" />}
                MenuProps={MenuProps}
              >
                {props.teachers.map((el) => (
                  <MenuItem key={el._id} value={el._id}>
                    {el.fullName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="demo-simple-select-disabled-label">
                Nauczyciele dodatkowi
              </InputLabel>
              <Select
                labelId="demo-simple-select-disabled-label"
                id="demo-multiple-name-test"
                value={additionalTeachers}
                onChange={handleChangeAdditionalTeachers}
                fullWidth
                multiple
                input={<OutlinedInput label="Nauczyciele dodatkowi" />}
                MenuProps={MenuProps}
              >
                {props.teachers
                  .filter((el) => {
                    if (teacher.length === 0) return true;
                    return teacher[0]._id !== el._id;
                  })
                  .map((el) => (
                    <MenuItem key={el._id} value={el._id}>
                      {el.fullName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="demo-simple-select-disabled-label">
                Miejsce
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-disabled-label"
                id="demo"
                value={building}
                onChange={handleChangeBuilding}
                input={<OutlinedInput label="Miejsce" />}
                MenuProps={MenuProps}
              >
                {props.buildings.map((el) => (
                  <MenuItem key={el._id} value={el._id}>
                    {el.building} - {el.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className={styles.editable}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                label="Początek"
                value={startDate}
                ampm={false}
                onChange={handleStartDateChange}
                renderInput={(params) => {
                  return <TextField {...params} ampm={false} />;
                }}
              />
              <DateTimePicker
                label="Koniec"
                value={endDate}
                ampm={false}
                onChange={handleEndDateChange}
                renderInput={(params) => <TextField {...params} ampm={false} />}
              />
            </LocalizationProvider>
          </div>
          <ExpandableTextEditor
            title={"Opis wydarzenia"}
            defaultValue={props.event.extendedProps.description}
            expanded={true}
            ref={editorCoreMain}
            holder={"description"}
          />

          <div className={styles.hoursPicker}>
            {props.event.extendedProps.preMail?.sent ? (
              `Wysłano poniższą wiadomość na ${
                props.event.extendedProps.preMail?.hours
              } ${getGodzinyFormat(
                props.event.extendedProps.preMail?.hours
              )} przed spotkaniem`
            ) : (
              <>
                {" "}
                <p> Wyślij poniższą wiadomość na </p>
                <TextField
                  className={styles.input}
                  value={preMailHours}
                  id="capacity"
                  type="number"
                  onChange={(e) => {
                    let value = parseInt(e.target.value);
                    if (value > 0 && value < 100) setPreMailHours(value);
                  }}
                />
                <p> {getGodzinyFormat(preMailHours)} przed wydarzeniem</p>{" "}
              </>
            )}
          </div>

          <ExpandableTextEditor
            title={`Email - przed spotkaniem ${
              props.event.extendedProps.preMail?.sent ? "- wysłany" : ""
            }`}
            defaultValue={props.event.extendedProps.preMail?.content}
            expanded={false}
            readOnly={false}
            ref={editorCoreMain1}
            holder={"preMail"}
          />
          <div className={styles.hoursPicker}>
            {props.event.extendedProps.postMail?.sent ? (
              `Wysłano poniższą wiadomość na ${
                props.event.extendedProps.postMail?.hours
              } ${getGodzinyFormat(
                props.event.extendedProps.postMail?.hours
              )} po spotkaniu`
            ) : (
              <>
                {" "}
                <p> Wyślij poniższą wiadomość na </p>
                <TextField
                  className={styles.input}
                  value={postMailHours}
                  id="capacity"
                  type="number"
                  onChange={(e) => {
                    let value = parseInt(e.target.value);
                    if (value > 0 && value < 100) setPostMailHours(value);
                  }}
                />
                <p> {getGodzinyFormat(postMailHours)} po wydarzeniu</p>{" "}
              </>
            )}
          </div>

          <ExpandableTextEditor
            title={`Email - po spotkaniu ${
              props.event.extendedProps.postMail?.sent ? "- wysłany" : ""
            }`}
            defaultValue={props.event.extendedProps.postMail?.content}
            expanded={false}
            readOnly={false}
            ref={editorCoreMain2}
            holder={"afterMail"}
          />

          <div className={styles.buttons}>
            <LoadingButton
              variant="outlined"
              color="secondary"
              onClick={() => {
                props.event.remove();
                dispatch(setEventToEditData({ event: {}, modal: false }));
              }}
            >
              USUŃ WYDARZENIE
            </LoadingButton>

            <LoadingButton variant="outlined" onClick={handleCloseModal}>
              POTWIERDŹ
            </LoadingButton>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default connect()(EditEventData);
