import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../API/APIService";
import { toast } from "react-toastify";
import Cookie from "js-cookie";
import { history } from "../../index.jsx";
import { emitInfo, emmitError, emmitSuccess } from "../../utils/ToastEmmiter";

export const fetchOrderByID = createAsyncThunk(
  "/orders/fetchByID",
  async (id, { rejectWithValue }) => {
    let response = null;
    await API.get(`/orders/?id=${id}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const setPageOrdersByUser = createAsyncThunk(
  "/orders/setPageOrdersByUser",
  async ({ page, user }, { rejectWithValue }) => {
    let response = null;
    await API.get(`/orders/byUser?user=${user}&page=${page}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const cancelPendingOrder = createAsyncThunk(
  "/orders/cancelPendingOrder",
  async (orderID, { rejectWithValue }) => {
    let response = null;
    await API.delete(`/orders/?id=${orderID}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const downloadPDFInvoice = createAsyncThunk(
  "/orders/downloadInvoice",
  async ({ orderId, invoiceId }, { rejectWithValue }) => {
    let response = null;
    await API.get(
      `/orders/downloadInvoice?orderId=${orderId}&invoiceId=${invoiceId}`, {responseType: 'blob'}
    )
      .then((r) => {
        console.log(r);
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    singleOrder: undefined,
    singleOrderLoading: false,
    orders: [],
    ordersPage: 1,
    ordersTotalPages: 1,
    ordersLoading: false,
    cancelPendingOrderLoading: false,
    invoiceDownloadLoading: false
  },
  reducers: {
    setSingleOrder: (state, action) => {
      state.singleOrder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrderByID.pending, (state, action) => {
      state.singleOrderLoading = true;
    });

    builder.addCase(fetchOrderByID.rejected, (state, action) => {
      state.singleOrderLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(fetchOrderByID.fulfilled, (state, action) => {
      state.singleOrder = action.payload;
      state.singleOrderLoading = false;
    });

    builder.addCase(cancelPendingOrder.pending, (state, action) => {
      state.cancelPendingOrderLoading = true;
    });

    builder.addCase(cancelPendingOrder.rejected, (state, action) => {
      state.cancelPendingOrderLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(cancelPendingOrder.fulfilled, (state, action) => {
      state.cancelPendingOrderLoading = false;
      emmitSuccess("Pomyślnie anulowano zamówienie");
      emitInfo(
        "Kod promocyjny użyty w anulowanym zamówieniu nie może być wykorzystany drugi raz."
      );
    });

    builder.addCase(setPageOrdersByUser.pending, (state, action) => {
      state.ordersLoading = true;
    });

    builder.addCase(setPageOrdersByUser.rejected, (state, action) => {
      state.ordersLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(setPageOrdersByUser.fulfilled, (state, action) => {
      state.orders = action.payload.docs;
      state.ordersPage = action.payload.page;
      state.ordersTotalPages = action.payload.totalPages;
      state.ordersLoading = false;
    });

    builder.addCase(downloadPDFInvoice.fulfilled, (state,action) => {
      state.invoiceDownloadLoading = false
      
      console.log(action.payload)

      // var file = new Blob([action.payload], {type: 'application/pdf'});
      // var fileURL = URL.createObjectURL(file);
      // window.open(fileURL) - responseType: arraybuffer

      var fileURL = URL.createObjectURL(action.payload);
      window.open(fileURL)

      
      emmitSuccess("Pomyślnie pobrano fakturę")
    }) 

    builder.addCase(downloadPDFInvoice.pending, (state,action) => {
      state.invoiceDownloadLoading = true
    }) 

    builder.addCase(downloadPDFInvoice.rejected, (state,action) => {
      state.invoiceDownloadLoading = false
      emmitError(action.payload.data.error);
    }) 

  },
});

const { actions, reducer } = ordersSlice;

export const { setSingleOrder } = actions;

export const selectSingleOrder = (state) => {
  return state.orders.singleOrder;
};

export const selectSingleOrderLoading = (state) => {
  return state.orders.singleOrderLoading;
};

export const selectOrders = (state) => {
  return state.orders.orders;
};

export const selectOrdersPagination = (state) => {
  return {
    page: state.orders.ordersPage,
    pageCount: state.orders.ordersTotalPages,
  };
};

export const selectOrdersLoading = (state) => {
  return state.orders.ordersLoading;
};

export const selectSingleOrderCancelLoading = (state) => {
  return state.orders.cancelPendingOrderLoading;
};

export const selectInvoiceDownloadLoading = state => {
  return state.orders.invoiceDownloadLoading
}

export default reducer;
