import TvIcon from "@mui/icons-material/Tv";
import React from "react";

import PanelButton from "../../../../components/panelButton/PanelButton";

import GroupIcon from "@mui/icons-material/Group";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import InventoryIcon from '@mui/icons-material/Inventory';
function ProductsButton(props) {
  const history = useHistory();
  return (
    <div>
      <PanelButton
        onClick={() => history.push("/products")}
        label={"Zarządzaj produktami"}
        icon={<InventoryIcon style={{ fontSize: 40 }} />}
        width={100}
        height={100}
      />
    </div>
  );
}

export default ProductsButton;
