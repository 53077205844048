import React, { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import APIService from "../../API/APIService";
import EdjsDisplay from "../../components/edjsDisplay/EdjsDisplay";
import Loading from "../../components/universal/Loading";
import NotFound from "../../components/universal/NotFound";
import {
  selectNews,
  selectNewsLoading,
  selectPages,
  setPage,
  setSingleNews,
} from "../../redux/slices/newsSlice";
import styles from "./customPage.module.scss";

const description =
  "W Stolicy eXperymentu nieprzerwanie coś się dzieje. Zaglądaj tu a z pewnością będziesz na bieżąco! Aktualności, ciekawostki, zdjęcia z kolejnych etapów budowy i wiele innych";

function CustomPage(props) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await APIService.get(
          "/pages/custom/" + props.match.params?.slug
        );
        setData(res.data);
        setLoading(false);
        //console.log("CUSTOM PAGE")
        //console.log(data)
      } catch (error) {
        setLoading(false);
      }

      //console.log("CUSTOM PAGE")
      //console.log(data)
    };

    if (props.match.params?.slug) {
      setLoading(true);
      fetchData();
      //console.log("CUSTOM PAGE")
      //console.log(data)
    }
  }, [props.location.pathname]);

  if (!props.match.params?.slug) {
    return <NotFound />;
  }

  if (loading) return <Loading />;
  if (!loading && Object.keys(data).length === 0) return <NotFound />;

  return (
    <>
      <Helmet>
        <title>{data?.title} - Stolica eXperymentu</title>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
      </Helmet>
      <div className={styles.mainContainer}>
        <EdjsDisplay data={data?.content} />
      </div>
    </>
  );
}

export default connect()(CustomPage);
