import React, { useEffect, useState } from "react";
import Modal from "../../../../components/modal/Modal";
import { connect, useDispatch, useSelector } from "react-redux";
import { selectTicketVerifiedModal, setTicketVerifiedModal } from "../../../../redux/slices/userSlice";
import styles from "./TicketVerified.module.scss"
import Lottie from "react-lottie";
import * as vierifiedAnimation from "./verifiedAnim.json";
function TicketVerified(props) {

    const defaultOptions = {
        loop: false,
        autoplay: true,
      
        rendererSettings: {
          preserveAspectRatio: "xMidYMid meet",
        },
      };

    const ticketVerifiedVisible = useSelector(selectTicketVerifiedModal)
    const dispatch = useDispatch();
    const closeModal = () => dispatch(setTicketVerifiedModal(false))
    
    return (
        <Modal
        visible={ticketVerifiedVisible}
        closeModal={closeModal}
        height={400}
        isDarkTitle={true}
        title="Bilet zweryfikowany!"
        fullBody
        >
            <div className={styles.ticketVerifiedContainer}>
                <div className={styles.lottieContainer}>
                    <Lottie
                    isClickToPauseDisabled={false}
                    options={{
                        ...defaultOptions,
                        animationData: vierifiedAnimation
                    }}
                    />

                   
                </div>
                <div className={styles.messageContainer}>
                    <div>Twój bilet został zweryfikowany pomyślnie!</div> 
                    <div className={styles.welcome}>Zapraszamy!</div>
                </div>
            </div>
        </Modal>
    )
}


export default connect()(TicketVerified)