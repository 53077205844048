import * as React from "react";
import { motion } from "framer-motion";
import styles from "./navbar.module.scss";
const Path = (props) => (
  <motion.path
    fill="#ffffff"
    strokeWidth="3"
    stroke="white"
    strokeLinecap="round"
    {...props}
  />
);

export const MenuToggle = ({ toggle, animate }) => (
  <button className={styles.mobileToggle} onClick={toggle}>
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        animate={animate}
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
      />
      <Path
        animate={animate}
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        animate={animate}
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" },
        }}
      />
    </svg>
  </button>
);
