import * as React from "react";

import { Link } from "react-router-dom";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";

import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { login, selectType } from "../../../redux/slices/userSlice";
import styles from "../authCommon.module.scss";

import { useForm } from "react-hook-form";
import FormTextField from "../../../components/formMuiComponents/FormTextField";

import { yupResolver } from "@hookform/resolvers/yup";
import ForgotPassword from "./components/forgotPassword";
import ResendVerMail from "./components/resendVerMail";

import LoginIcon from "@mui/icons-material/Login";
import LoadingButton from "@mui/lab/LoadingButton";
import { Helmet } from "react-helmet";
import { emmitError, emmitSuccess } from "../../../utils/ToastEmmiter";

const loginSchema = Yup.object().shape({
  password: Yup.string().required("Hasło wymagane"),
  email: Yup.string()
    .email("Niepoprawny adres email")
    .required("Email wymagany!"),
});

function SignIn(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { authorizing } = useSelector(selectType);

  const { formState, watch, handleSubmit, reset, control } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = async (values) => {
    const result = await dispatch(
      login({
        email: values.email,
        password: values.password,
      })
    );
    if (result.meta.requestStatus === "fulfilled") {
      // history.push(props.location?.state?.from?.pathname || "/");
      history.push("/cycles");
    }
  };

  React.useEffect(() => {
    if (props.location.hash === "#successVerify")
      emmitSuccess("Pomyślnie zweryfikowano adres email");
    if (props.location.hash === "#errorVerify")
      emmitError("Błąd weryfikacji adresu email");
  }, []);

  return (
    <div className={styles.formWrapper}>
      <Helmet>
        <title>Logowanie - Stolica eXperymentu</title>
      </Helmet>

      <div className={styles.formHolder}>
        <div className={styles.formHeader}>
          <LockOutlinedIcon />
          <Typography component="h1" variant="h5">
            Zaloguj się
          </Typography>
        </div>

        <div className={styles.formBody}>
          <FormTextField
            id="email"
            className={styles.formInput}
            autoComplete="email"
            name="email"
            control={control}
            label="Adres email"
            autoFocus
          />
          <FormTextField
            className={styles.formInput}
            autoComplete="current-password"
            name="password"
            control={control}
            label="Hasło"
            type="password"
            id="password"
            enterClick={handleSubmit(onSubmit)}
          />

          <LoadingButton
            className={styles.formButton}
            variant="outlined"
            type="submit"
            endIcon={<LoginIcon />}
            fullWidth
            onClick={handleSubmit(onSubmit)}
            loading={authorizing}
          >
            Zaloguj się
          </LoadingButton>

          <Link className={styles.hrefLink} to="/register">
            {"Nie masz konta? Zarejestruj się"}
          </Link>
        </div>

        <div className={styles.formFooter}>
          <ResendVerMail />
          <ForgotPassword />
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  login,
};

export default connect(null, mapDispatchToProps)(SignIn);
