import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../API/APIService";

import { emmitError, emmitSuccess } from "../../utils/ToastEmmiter";

export const loadMessages = createAsyncThunk(
  "messages/loadMessages",
  async (obj, { rejectWithValue, getState }) => {
    const { limit } = obj;
    let query = "?count=" + limit + "&";
    const messages = getState().messages.messages;

    if (messages.length > 0) {
      query = query + "lastId=" + messages[messages.length - 1]._id + "&";
    }

    //
    let response = null;

    await API.get(`/messages/${query}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return { data: response.data, limit };
  }
);

export const fetchSingleMessage = createAsyncThunk(
  "messages/fetchSignleMessage",
  async (id, { rejectWithValue, getState }) => {
    let query = "?_id=" + id + "&";

    let response = null;

    await API.get(`/messages/single/${query}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const markSeen = createAsyncThunk(
  "messages/markSeen",
  async (id, { rejectWithValue, getState }) => {
    let response = null;
    await API.post(`/messages/markSeen`, { _id: id })
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return id;
  }
);

const initialState = {
  messages: [],
  singleMessage: {},
  hasNextPage: true,
  loading: false,
  singleLoading: false,
  unseenCount: 0,
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState: initialState,
  reducers: {
    setSingleMessage: (state, action) => {
      state.singleMessage = action.payload;
    },
    addUnseen: (state, action) => {
      state.unseenCount = state.unseenCount + 1;
    },
    subtractUnseen: (state, action) => {
      state.unseenCount = Math.max(state.unseenCount - 1, 0);
    },

    setUnseen: (state, action) => {
      state.unseenCount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadMessages.fulfilled, (state, action) => {
      if (action.payload.data.length < action.payload.limit) {
        state.hasNextPage = false;
      } else {
      }
      state.messages = [...state.messages, ...action.payload.data];
      state.loading = false;
    });
    builder.addCase(loadMessages.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loadMessages.rejected, (state, action) => {
      state.loading = false;

      emmitError(action.payload.data.error);
    });

    builder.addCase(fetchSingleMessage.fulfilled, (state, action) => {
      state.singleMessage = action.payload;
      state.singleLoading = false;
    });
    builder.addCase(fetchSingleMessage.pending, (state, action) => {
      state.singleLoading = true;
    });
    builder.addCase(fetchSingleMessage.rejected, (state, action) => {
      state.singleLoading = false;

      emmitError(action.payload.data.error);
    });

    builder.addCase(markSeen.fulfilled, (state, action) => {
      const id = action.payload;
      if (state.singleMessage._id === id) {
        state.singleMessage.seen = true;
        state.singleMessage.seenAt = new Date();
      }

      const allegedIndex = state.messages.findIndex((el) => el._id === id);
      if (allegedIndex !== -1) {
        state.messages[allegedIndex].seen = true;
        state.messages[allegedIndex].seenAt = new Date();
      }

      state.unseenCount = Math.max(state.unseenCount - 1, 0);
    });
    // builder.addCase(markSeen.pending, (state, action) => {
    //   state.singleLoading = true;
    // });
    // builder.addCase(markSeen.rejected, (state, action) => {
    //   state.singleLoading = false;

    //   emmitError(action.payload.data.error);
    // });
  },
});

const { actions, reducer } = messagesSlice;

export const { setSingleMessage, addUnseen, subtractUnseen, setUnseen } =
  actions;

export const selectMessages = (state) => {
  return {
    data: state.messages.messages,
    hasNextPage: state.messages.hasNextPage,
    loading: state.messages.loading,
  };
};

export const selectSingleMessage = (state) => {
  return {
    single: state.messages.singleMessage,
    singleLoading: state.messages.singleLoading,
  };
};

export const selectUnseenCount = (state) => {
  return state.messages.unseenCount;
};

export default reducer;
