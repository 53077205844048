import { configureStore } from "@reduxjs/toolkit";
import userReducer, { me } from "./slices/userSlice";
import newsReducer from "./slices/newsSlice";
import reservationReducer from "./slices/reservationSlice";
import eventsReducer from "./slices/eventsSlice";
import promoCodesReducer from "./slices/promoCodesSlice";
import Cookies from "js-cookie";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import cartReducer from "./slices/cartSlice";
import resourcesReducer from "./slices/resourcesSlice";
import displaysReducer from "./slices/displaysSlice";
import feedbackReducer from "./slices/feedbackSlice";
import messagesReducer from "./slices/messagesSlice";
import logsReducer from "./slices/logsSlice";
import utilsReducer, { fetchMaxLengths } from "./slices/utilsSlice";
import ordersReducer from "./slices/ordersSlice";
import manageUsersReducer from "./slices/manageUsersSlice";
import notificationsReducer from "./slices/notificationsSlice";
import customPagesReducer from "./slices/customPagesSlice";

const persistConfig = {
  key: "root",
  storage,
};

const persistedCardReducer = persistReducer(persistConfig, cartReducer);

const store = configureStore({
  reducer: {
    user: userReducer,
    news: newsReducer,
    reservations: reservationReducer,
    events: eventsReducer,
    cart: persistedCardReducer,
    promoCodes: promoCodesReducer,
    resources: resourcesReducer,
    displays: displaysReducer,
    feedback: feedbackReducer,
    messages: messagesReducer,
    logs: logsReducer,
    utils: utilsReducer,
    orders: ordersReducer,
    manageUsers: manageUsersReducer,
    notifications: notificationsReducer,
    customPages: customPagesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

const persistor = persistStore(store);

export { store, persistor };

store.dispatch(fetchMaxLengths());

// czyli jesli ktos sie nei wylogował to mozemy mu sprobowac odswiezyc sesyje
if (Cookies.get("AccessToken")) {
  store.dispatch(me());
}
