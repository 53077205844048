import { Typography } from "@mui/material";
import React from "react";
import styles from "./notifications.module.scss";
import moment from "moment";
import EdjsDisplay from "../../components/edjsDisplay/EdjsDisplay";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import Box from "@mui/material/Box";

const DATE_FORMAT = "DD MMMM YYYY";

function PendingFeedback(props) {
  const { data } = props;
  return (
    <div className={styles.pendingFeedbackCard} {...props}>
      <div className={styles.header}>
        <img
          src={data.thumbnail}
          className={styles.thumbnail}
          alt="Miniatura"
        />
        <div className={styles.date}>
          {moment(data.createdAt).locale("pl").format(DATE_FORMAT)}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.meta}>{data.title}</div>
        <div className={styles.desc}>{data.shortDesc}</div>
        <div className={styles.buttons}>
          <div className={styles.readMore}>Czytaj dalej</div>
        </div>
      </div>
    </div>
  );
}

export default PendingFeedback;
