import styles from "../checkout.module.scss"
import LoadingButton from "@mui/lab/LoadingButton";
import { Radio } from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";
import "react-multi-email/style.css";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ApartmentIcon from "@mui/icons-material/Apartment";
import TagIcon from "@mui/icons-material/Tag";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import BusinessIcon from "@mui/icons-material/Business";
import classNames from "classnames";



function FiscalData(props) {
    const { noBorder, maxLengths, setTaxType, toggleFiscalCompanyManual, setFiscalTaxNo, taxType, fiscalTaxNo, fiscalCompanyManual, companyFiscalDataLoading, verifyFiscalTaxNo, fiscalCompanyName, setFiscalCompanyName, companyFiscalData } = props
    return (
        <div className={noBorder ? classNames(styles.fiscalInfo, styles.noBorder) : styles.fiscalInfo}>
            <div className={styles.header}>
                <ReceiptIcon sx={{ fontSize: "36px", marginRight: "10px" }} />
                <div className={styles.content}>Dane fiskalne</div>
            </div>

            <div className={styles.content}>
                <div className={styles.fiscalTypeSection}>
                    <div className={styles.radioButton}>
                        <Radio
                            checked={taxType === "individual"}
                            onChange={() => setTaxType("individual")}
                        />
                        <span
                            className={styles.radioLabel}
                            onClick={() => setTaxType("individual")}
                        >
                            Osoba fizyczna
                        </span>
                    </div>
                    <div
                        className={classNames(
                            styles.radioButton,
                            styles.radioButtonMargin
                        )}
                    >
                        <Radio
                            style={{ alignSelf: "start" }}
                            checked={taxType === "company"}
                            onChange={() => setTaxType("company")}
                        />
                        <span
                            className={styles.radioLabel}
                            onClick={(e) => setTaxType("company")}
                        >
                            Firma
                        </span>
                    </div>
                </div>
                {taxType == "company" &&
                    <div className={styles.fiscalInputSection}>
                        <div className={styles.fiscalCompany}>
                            <div className={styles.fiscalDataWrapper}>
                                <TagIcon sx={{ fontSize: "36px", marginRight: "10px" }} />
                                <TextField
                                    className={styles.fiscalDataInput}
                                    style={{ minWidth: 250, marginRight: "10px" }}
                                    value={fiscalTaxNo}
                                    id="fiscalTaxNo"
                                    key="fiscalTaxNo"
                                    onChange={(e) => {
                                        setFiscalTaxNo(e.target.value);
                                    }}
                                    inputProps={{ maxlength: 10 }}
                                    label="NIP"
                                />

                                {!fiscalCompanyManual && (
                                    <LoadingButton
                                        variant="outlined"
                                        onClick={verifyFiscalTaxNo}
                                        loading={companyFiscalDataLoading}
                                    >
                                        ZWERYFIKUJ
                                    </LoadingButton>
                                )}
                            </div>

                            {fiscalCompanyManual && (
                                <div className={styles.fiscalDataWrapper}>
                                    <BusinessIcon
                                        sx={{ fontSize: "36px", marginRight: "10px" }}
                                    />
                                    <TextField
                                        className={styles.fiscalDataInput}
                                        style={{ minWidth: 250 }}
                                        value={fiscalCompanyName}
                                        id="fiscalCompanyName"
                                        key="fiscalCompanyName"
                                        onChange={(e) => {
                                            setFiscalCompanyName(e.target.value);
                                        }}
                                        label="Nazwa firmy"
                                        inputProps={{ maxlength: maxLengths.name * 2 }}
                                    />
                                </div>
                            )}

                            {companyFiscalData && companyFiscalData.buyer_company && (
                                <div className={styles.checkFiscalDataContainer}>
                                    <div className={styles.dataBox}>
                                        {JSON.stringify(companyFiscalData)}
                                    </div>
                                </div>
                            )}

                            {!fiscalCompanyManual ? (
                                <div className={styles.disclaimerContainer}>
                                    <div className={styles.txt}>
                                        {" "}
                                        Dane do faktury zostaną wprowadzone automatycznie na
                                        podstawie podanego NIPu zgodnie z informacjami
                                        dostępnymi w rejestrze CEDIG.{" "}
                                    </div>

                                    <LoadingButton
                                        variant="outlined"
                                        onClick={toggleFiscalCompanyManual}
                                    >
                                        WPROWADŹ RĘCZNIE
                                    </LoadingButton>
                                </div>
                            ) : (
                                <LoadingButton
                                    variant="outlined"
                                    onClick={toggleFiscalCompanyManual}
                                >
                                    WYPEŁNIJ AUTOMATYCZNIE
                                </LoadingButton>
                            )}
                        </div>

                    </div>}
            </div>
        </div>
    )
}


export default FiscalData


//FISCAL INDIVIDUAL
{/* <div className={styles.fiscalIndividual}>
                    <div className={styles.fiscalDataWrapper}>
                      <PersonIcon
                        sx={{ fontSize: "36px", marginRight: "10px" }}
                      />
                      <TextField
                        className={styles.fiscalDataInput}
                        style={{ minWidth: 250 }}
                        value={fiscalFullname}
                        id="fiscalFullname"
                        key="fiscalFullname"
                        onChange={(e) => {
                          setFiscalFullname(e.target.value);
                        }}
                        label="Imie i nazwisko"
                        inputProps={{ maxlength: maxLengths.name * 2 }}
                      />
                    </div>

                    <div className={styles.fiscalDataWrapper}>
                      <AlternateEmailIcon
                        sx={{ fontSize: "36px", marginRight: "10px" }}
                      />
                      <TextField
                        className={styles.fiscalDataInput}
                        style={{ minWidth: 250 }}
                        value={fiscalEmail}
                        id="fiscalEmail"
                        key="fiscalEmail"
                        inputProps={{ maxlength: maxLengths.email }}
                        onChange={(e) => {
                          setFiscalEmail(e.target.value);
                        }}
                        label="Adres e-mail"
                      />
                    </div>

                    <div className={styles.fiscalDataWrapper}>
                      <LocationOnIcon
                        sx={{ fontSize: "36px", marginRight: "10px" }}
                      />
                      <TextField
                        className={styles.fiscalDataInput}
                        style={{ minWidth: 250 }}
                        value={fiscalCity}
                        id="fiscalCity"
                        key="fiscalCity"
                        inputProps={{ maxlength: maxLengths.name }}
                        onChange={(e) => {
                          setFiscalCity(e.target.value);
                        }}
                        label="Miasto"
                      />
                    </div>
                  </div> */}
