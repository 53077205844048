import React, { useRef, useState } from "react";
import Modal from "../../../../components/modal/Modal";

import { connect, useDispatch, useSelector } from "react-redux";
import {
  closeResourceViewModal,
  deleteResource,
  selectLoadings,
  selectResourceByID,
  selectResourceViewModalID,
  updateResource,
} from "../../../../redux/slices/resourcesSlice";

import styles from "./Resources.module.scss";

import LoadingButton from "@mui/lab/LoadingButton";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { Button, TextField } from "@mui/material";
import ImageUploader from "../../../../components/imageUploader/ImageUploader";

import InputAdornment from "@mui/material/InputAdornment";
import { selectMaxLengths } from "../../../../redux/slices/utilsSlice";
import { getFullPath } from "../../../../svc/svc";

function ResourceView(props) {
  const dispatch = useDispatch();
  const loadings = useSelector(selectLoadings);
  const maxLengths = useSelector(selectMaxLengths);
  const selectedResource = useSelector(selectResourceViewModalID);
  const resource = useSelector((state) =>
    selectResourceByID(state, selectedResource)
  );

  const r_descInput = useRef(null);
  const [name, setName] = useState(resource ? resource.name : null);

  const [building, setBuilding] = useState(resource ? resource.building : null);
  const [capacity, setCapacity] = useState(resource ? resource.capacity : null);
  const [thumbnail, setThumbnail] = useState(
    resource ? resource.thumbnail : null
  );

  const handleDelete = async () => {
    const result = await dispatch(deleteResource(resource._id));
    if (result.meta.requestStatus == "fulfilled") {
      props.closeModal();
    }
  };

  const RESOURCE_EVENTS_LINK = getFullPath("/api/events/resourceEvents/") + resource._id

  const handleUpdate = () => {
    dispatch(
      updateResource({
        _id: resource._id,
        name,
        description: r_descInput.current.value,
        building,
        capacity,
        thumbnail,
      })
    );
  };

  const handleCapacityChange = (e) => {
    const value = e.target.value;
    if (value >= 0 && value <= 100) setCapacity(value);
  };

  const handleCloseModal = () => {
    dispatch(closeResourceViewModal());
  };

  return (
    <Modal
      visible={resource}
      closeModal={handleCloseModal}
      title={"Edytuj zasób"}
      height={620}
    >
      {resource && (
        <div className={styles.resourceView}>
          <div className={styles.editable}>
            <div className={styles.part}>
              <TextField
                className={styles.input}
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="outlined-basic"
                label="Nazwa"
                variant="outlined"
              />

              <FormControl className={styles.input}>
                <InputLabel id="label">Budynek</InputLabel>
                <Select
                  value={building}
                  label="Budynek"
                  id="selectView"
                  onChange={(e) => {
                    setBuilding(e.target.value);
                  }}
                >
                  {maxLengths.buildings.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                className={styles.input}
                value={capacity}
                id="capacity"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">os.</InputAdornment>
                  ),
                }}
                onChange={handleCapacityChange}
                label="Pojemność sali"
              />

              <TextField
                className={styles.field}
                inputRef={r_descInput}
                defaultValue={resource.description}
                id="outlined-multiline-static"
                label="Opis"
                multiline
                inputProps={{ maxlength: maxLengths.description }}
                rows={4}
                maxRows={4}
                fullWidth
              />
            </div>

            <div className={styles.part}>
              <ImageUploader setImage={setThumbnail} />
              {thumbnail &&
              <div style={{backgroundImage: `url(${thumbnail})`}} className={styles.imagePreview}>
                 
              </div>  }
            </div>
          </div>

          <div className={styles.icsBox}>
            <div className={styles.headerSection}>
            <h3>Wydarzenia powiązane z tym zaosbem</h3>
            <Button variant="outlined" color="primary" onClick={
              () => {
                navigator.clipboard.writeText(RESOURCE_EVENTS_LINK)
              }
            }>
              Skopiuj link
            </Button>
            </div>
            <a href={RESOURCE_EVENTS_LINK}>
              {RESOURCE_EVENTS_LINK}
            </a>
          </div>

          <div className={styles.buttons}>
            <LoadingButton
              variant="outlined"
              loading={loadings.delete}
              onClick={handleDelete}
              color="secondary"
            >
              {" "}
              Usuń{" "}
            </LoadingButton>

            <LoadingButton
              variant="outlined"
              loading={loadings.update}
              onClick={handleUpdate}
            >
              {" "}
              Zaktualizuj{" "}
            </LoadingButton>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default connect()(ResourceView);
