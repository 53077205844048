import React, { useRef, useState } from "react";
import Modal from "../../../../components/modal/Modal";

import { connect, useDispatch, useSelector } from "react-redux";
import {
  addResource,
  selectAddResourceModalVisibility,
  selectLoadings,
  setAddResourceModalInvisible,
} from "../../../../redux/slices/resourcesSlice";

import styles from "./Resources.module.scss";

import LoadingButton from "@mui/lab/LoadingButton";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { TextField } from "@mui/material";
import ImageUploader from "../../../../components/imageUploader/ImageUploader";

import InputAdornment from "@mui/material/InputAdornment";

import { selectMaxLengths } from "../../../../redux/slices/utilsSlice";

function ResourceAdd(props) {
  const dispatch = useDispatch();
  const loadings = useSelector(selectLoadings);

  const isModalVisible = useSelector(selectAddResourceModalVisibility);

  const handleModalClose = () => {
    dispatch(setAddResourceModalInvisible());
  };

  const maxLengths = useSelector(selectMaxLengths);

  const r_descInput = useRef(null);
  const [name, setName] = useState("");

  const [building, setBuilding] = useState("Stolica eXperymentu");
  const [capacity, setCapacity] = useState("");
  const [thumbnail, setImage] = useState("");

  const handleAddResource = async () => {
    const result = await dispatch(
      addResource({
        name,
        building,
        description: r_descInput.current.value,
        thumbnail,
        capacity,
      })
    );

    if (result.meta.requestStatus === "fulfilled") {
      resetValues();
      handleModalClose();
    }
  };

  const resetValues = () => {
    setName("");
    setBuilding("Stolica eXperymentu");
    setCapacity("");
    setImage("");
  };

  const handleCapacityChange = (e) => {
    const value = e.target.value;
    if (value >= 0 && value <= maxLengths.capacity * 10) setCapacity(value);
  };

  return (
    <Modal
      visible={isModalVisible}
      closeModal={handleModalClose}
      onClose={resetValues}
      title={"Dodaj zasób"}
      height={520}
    >
      <div className={styles.resourceView}>
        <div className={styles.editable}>
          <div className={styles.part}>
            <TextField
              className={styles.input}
              inputProps={{ maxlength: maxLengths.name }}
              onChange={(e) => setName(e.target.value)}
              id="nameADD"
              label="Nazwa"
              variant="outlined"
            />

            <FormControl className={styles.input}>
              <InputLabel id="label">Budynek</InputLabel>
              <Select
                value={building}
                label="Budynek"
                id="selectADD"
                onChange={(e) => {
                  setBuilding(e.target.value);
                }}
              >
                {maxLengths?.buildings?.map((b) => (
                  <MenuItem key={b} value={b}>
                    {b}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              className={styles.input}
              value={capacity}
              id="capacityADD"
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">os.</InputAdornment>
                ),
              }}
              onChange={handleCapacityChange}
              label="Pojemność sali"
            />

            <TextField
              className={styles.field}
              inputRef={r_descInput}
              id="outlined-multiline-static"
              label="Opis"
              multiline
              inputProps={{ maxlength: maxLengths.description }}
              rows={4}
              maxRows={4}
              fullWidth
            />
          </div>

          <div className={styles.part}>
            <ImageUploader
              setImage={(e) => {
                setImage(e);
              }}
            />

            <div
              className={styles.imagePreview}
              style={thumbnail ? { backgroundImage: `url(${thumbnail})` } : {}}
            ></div>
          </div>
        </div>

        <div className={styles.buttonsRight}>
          <LoadingButton
            variant="outlined"
            loading={loadings.add}
            onClick={handleAddResource}
          >
            {" "}
            Dodaj{" "}
          </LoadingButton>
        </div>
      </div>
    </Modal>
  );
}

export default connect()(ResourceAdd);
