import classnames from "classnames";
import moment from "moment";
import React from "react";
import styles from "./CycleFeedback.module.scss";

import { connect, useSelector } from "react-redux";
import { getCycleFeedbackSelectedEvent } from "../../../../redux/slices/feedbackSlice";
import TeacherInfoPopover from "../../../../components/teacherInfoPopover/TeacherInfoPopover";

function EventCard(props) {
  const event = props.event;
  const DATE_FORMAT = "DD MMMM YYYY hh:mm";
  const handleOnClick = () => {
    props.onClick(event._id);
  };

  const selectedEvent = useSelector(getCycleFeedbackSelectedEvent);

  const selected = selectedEvent === event._id;

  return (
    <div
      className={
        selected
          ? classnames([styles.eventCard, styles.eventCardActive])
          : styles.eventCard
      }
      onClick={!selected ? handleOnClick : undefined}
    >
      <div className={styles.name}>{event.title}</div>

      <div className={styles.start}>
        {moment(event.start).locale("pl").format(DATE_FORMAT)}
      </div>

      <div className={styles.teacher}>
        {/* <p className={styles.teacherAdornment}>Prowadzący:</p> */}
        <TeacherInfoPopover teacher={event.teacher}>
          <p className={styles.teacherName}>{event.teacher.fullName}</p>
        </TeacherInfoPopover>
      </div>
    </div>
  );
}

export default connect()(EventCard);
