import React, { useContext, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import styles from "./navbar.module.scss";

import classNames from "classnames";
import { matchPath, withRouter } from "react-router";
import { Link } from "react-router-dom";
import stolicaLogo from "../../assets/stolica-white.png";
import { selectCartItems } from "../../redux/slices/cartSlice";
import { logout, selectType } from "../../redux/slices/userSlice";

import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useWindowWidth } from "@react-hook/window-size";
import {
  motion,
  useAnimationControls,
  useCycle,
  useScroll,
} from "framer-motion";
import { useDispatch } from "react-redux";
import CartIcon from "./CartIcon";
import { MenuToggle } from "./MenuToggle";
import MessagesIcon from "./MessagesIcon";
import { useScrollDirection } from "../../utils/ScrollDirectionListener";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import velix3 from "../../assets/velix/VELIX 3.png";
///animation stuff
const linkVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const wrapperVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const mainVariants = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 100% 0%)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(0px at 100% 0%)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const MOBILE_BREAKPOINT = 800;

function NavbarNew(props) {
  const width = useWindowWidth();
  const controls = useAnimationControls();
  const user = useSelector(selectType);
  const cartItems = useSelector(selectCartItems);
  const [isOpen, toggleOpen] = useCycle(false, true);
  const dispatch = useDispatch();
  const scrollDirection = useScrollDirection();

  const unauthorizedLinks = [
    {name: "Regulaminy", to: "/regulaminy", order:-1},
    // { name: "Strona główna", to: "/" },
    { name: "Aktualności", to: "/aktualnosci", subpages: true ,
      order:1
  },
    // { name: "Laboratoria", to: "/resources" },
    {
      name: "Kontakt",
      to: '/?scroll=contact',
      order:5
    },
    {
      name: "SOWA",
      to: "/sowa",
      order:4,
    }
  ];

  const unauthorizedOnlyLinks = [
    {
      name: "Zaloguj się",
      to: "/login",
      customRender: (
        <Tooltip title="Zaloguj się">
          <LoginIcon />
        </Tooltip>
      ),
    },
  ];

  const userLinks = [
    { name: "Panel", to: "/panel" },
    {
      name: "Wiadomości",
      to: "/messages",
      subpages: true,
      customRender: <MessagesIcon />,
    },

    // { POWIADOMIENIA TURNED OFF - W RAZIE CO TO DO OSTAJLOWANIA
    //   name: "Powiadomienia",
    //   preventClose: true,
    //   customRender: <NotificationsIcon />,
    // },

    {
      name: "Wyloguj się",
      to: "/",
      customAction: () => {
        dispatch(logout());
    },
      preventMatch: true,
      customRender: (
        <Tooltip placement="top" disableInteractive title={"Wyloguj się"}>
          <LogoutIcon />
        </Tooltip>
      ),
    },
  ];

  const userOnlyLinks = [
    {
      name: "Koszyk",
      to: "/koszyk",
      subpages: true,
      customRender: <CartIcon />,
    },
  ];

  const goBackPages = [
    { path: "/aktualnosci/:id", title: "Powrót do AKTUALNOŚCI" },
    { path: "/oferta/:id", title: "Powrót do ZAJĘĆ" },
  ];

  const mobileOnBackgroundClick = (e) => {
    if (width < MOBILE_BREAKPOINT) {
      handleCloseNavMenu();
    }
  };

  const handleCloseNavMenu = () => {
    if (width < MOBILE_BREAKPOINT) {
      controls.start("closed");
      toggleOpen();
    }
  };

  const openMenu = () => {
    if (width <= MOBILE_BREAKPOINT) {
      controls.start("open");
    }
  };

  useEffect(() => {
    if (width > MOBILE_BREAKPOINT) {
      controls.start("open");
    }
    if (width < MOBILE_BREAKPOINT) {
      controls.start("closed");
    }
  }, [width]);

  const closeMenu = () => {
    if (width <= MOBILE_BREAKPOINT) {
      controls.start("closed");
    }
  };

  const renderLinks = () => {
    let linksToRender = unauthorizedLinks;

    if (user.authorized) linksToRender = [...linksToRender, ...userLinks];
    else linksToRender = [...linksToRender, ...unauthorizedOnlyLinks];

    if (user.authorized && (user.type === "admin" || user.type == "mod"))
      linksToRender = [
        {
          name: "Zajęcia",
          to: "/cycles",
        },
        ...linksToRender,
      ];

    if (user.authorized && user.type === "user") {
      linksToRender = [...userOnlyLinks, ...linksToRender];
    }

    if (
      (user.authorized && user.type === "user") ||
      !user.authorized ||
      (user.type !== "admin" && user.type !== "mod")
    ) {
      linksToRender = [
        ...linksToRender,
        { name: "Zajęcia", to: "/oferta", subpages: true, order:2 },
        { name: "Zajęcia cykliczne", to: "/zajecia-cykliczne", subpages: true, order:2.5 },
        { name: "Oferta specjalna", to: "/oferta-specjalna", subpages: true, order:3 }
      ];
    }

    linksToRender = linksToRender.sort((a, b) => a.order - b.order);

    return (
      <>
        {linksToRender
          .filter((e) => !e.customRender)
          .map((page, index) => {
            const match = matchPath(props.location.pathname, {
              path: page.subpages ? [page.to, page.to + "/:id"] : page.to, //żeby zmatchować też np news/id newsa
              exact: true,
            });

            const exactMatch = matchPath(props.location.pathname, {
              path: page.to,
              exact: true,
            });

            const cs = [styles.linkButton];
            if (match) cs.push(styles.activeLinkButton);

            return (
              <motion.div
                variants={linkVariants}
                key={index}
                className={classNames(cs)}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!exactMatch) props.history.push(page.to);
                  handleCloseNavMenu();
                }}
              >
                <Link to={page.to} className={classNames(cs)}>
                  <Typography textAlign="center">
                    {page.name.toUpperCase()}
                  </Typography>
                </Link>
              </motion.div>
            );
          })}

        <div className={styles.icons}>
          {linksToRender
            .filter((e) => e.customRender)
            .map((page, index) => {
              const match = matchPath(props.location.pathname, {
                path: page.subpages ? [page.to, page.to + "/:id"] : page.to, //żeby zmatchować też np news/id newsa
                exact: true,
              });

              const exactMatch = matchPath(props.location.pathname, {
                path: page.to,
                exact: true,
              });

              const cs = [styles.linkButton];
              if (match && !page.preventMatch) cs.push(styles.activeLinkButton);
              cs.push(styles.customLinkButton);

              return (
                <motion.div
                  variants={linkVariants}
                  key={index}
                  className={classNames(cs)}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!exactMatch) props.history.push(page.to);
                    if (page.customAction) page.customAction();
                    if (!page.preventClose) handleCloseNavMenu();
                  }}
                >
                  {page.customRender ? (
                    page.customRender
                  ) : (
                    <Typography textAlign="center">{page.name}</Typography>
                  )}
                </motion.div>
              );
            })}
        </div>
      </>
    );
  };

  const renderGoBack = () => {
    for (var el of goBackPages) {
      const match = matchPath(props.location.pathname, {
        path: el.path,
        exact: true,
      });
      if (match)
        return (
          <div
            onClick={() => {
              props.history.goBack();
            }}
            className={
              scrollDirection === "down"
                ? classNames(styles.dynamicPanel)
                : classNames(styles.dynamicPanel, styles.dynamicPanelVisible)
            }
          >
            <KeyboardBackspaceIcon className={styles.dynamicIcon} />
            <span className={styles.dynamicText}>{el.title}</span>
            <img className={styles.velixP} src={velix3} />
          </div>
        );
    }

    return <></>;
  };

  const showcaseMatch = matchPath(props.location.pathname, {
    path: ["/showcase", "/showcase/:id"], //żeby zmatchować też np showcasd/id newsa
    exact: true,
  });
  if (showcaseMatch) return <></>;

  return (
    <div className={styles.newNavbar}>
      <div className={styles.navbarWrapper}>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
        >
        
            <img
              onClick={() => {
                const match = matchPath(props.location.pathname, {
                  path: ["/"], //żeby zmatchować też np news/id newsa
                  exact: true,
                });
                if (!match) props.history.push("/");
              }}

              className={styles.logo}
              src={stolicaLogo}
              alt="Stolica eXperymentu"
            />
  
        </motion.div>

        <motion.div
          className={styles.linksHolder}
          onClick={mobileOnBackgroundClick}
          animate={controls}
          variants={mainVariants}
        >
          <motion.div
            className={styles.linksWrapper}
            variants={wrapperVariants}
          >
            {renderLinks()}
          </motion.div>
        </motion.div>

        <MenuToggle
          animate={isOpen ? "open" : "closed"}
          toggle={() => {
            if (isOpen) {
              closeMenu();
            } else {
              openMenu();
            }
            toggleOpen();
          }}
        />
      </div>
      {renderGoBack()}
    </div>
  );
}

export default withRouter(connect()(NavbarNew));
