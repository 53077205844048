import React from "react";
import { useHistory } from "react-router-dom";
import Pagination from "../../../../components/Pagination/Pagination";
import {
  selectMyCyclesData,
  selectMyCyclesLoading,
  selectMyCyclesPages,
  setMyCyclesPage,
} from "../../../../redux/slices/eventsSlice";
import styles from "./MyCycles.module.scss";

import CycleCard from "./CycleCard";

function MyCycles(props) {
  const history = useHistory();

  const handleClick = (el) => {
    history.push(`/zajecia/${el.slug ? el.slug : el._id}`);
  };

  return (
    <div className={styles.myCyclesMainContainer}>
      <div className={styles.header}>Twoje cykle zajęć</div>
      <div className={styles.cyclesContainer}>
        <Pagination
          pageRange={1}
          key="MYCYCLES"
          dataSelector={selectMyCyclesData}
          pagesSelector={selectMyCyclesPages}
          loadingSelector={selectMyCyclesLoading}
          setSingle={handleClick}
          setPage={setMyCyclesPage}
          ElementCard={CycleCard}
          dataContainerClassName={styles.paginationContainer}
          emptyText={"Brak cykli"}
          emptyHint={props.type === "user" ? "Tutaj pojawią się cykle zajęć, w których uczestniczysz" :
            "Tutaj pojawią się cykle zajęć, w których prowadzisz jakieś zajęcia"
          }
        />
      </div>
    </div>
  );
}

export default MyCycles;
