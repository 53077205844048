import React from "react";

import { connect, useSelector } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";

import { selectType } from "../redux/slices/userSlice";

//import Loading from "../components/universal/Loading";

const GuestOnlyRoute = ({ component, ...rest }) => {
  let ComponentToRender = component;

  const data = useSelector(selectType);

  return (
    <Route
      {...rest}
      render={(props) =>
        !data.authorized ? (
          <ComponentToRender {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

export default withRouter(connect()(GuestOnlyRoute));
