import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../API/APIService";
import { emmitError, emmitSuccess } from "../../utils/ToastEmmiter";

export const fetchPromoCode = createAsyncThunk(
  "promoCodes/fetch",
  async (id, { rejectWithValue }) => {
    let response = null;
    await API.get(`/promoCodes/?id=${id}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const addPromoCodes = createAsyncThunk(
  "promoCodes/add",
  async (data, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    let response = null;
    await API.post("/promoCodes/", data)
      .then((r) => {
        response = { data: r.data };
        thunkApi.dispatch(setPage({ page: 1 }));
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const setPage = createAsyncThunk(
  "promoCodes/setPage",
  async (obj, { rejectWithValue }) => {
    let response = null;
    const { page } = obj;
    await API.get(`/promoCodes/?page=${page}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const updatePromoCode = createAsyncThunk(
  "promoCodes/update",
  async (obj, { rejectWithValue }) => {
    let response = null;
    await API.put("/promoCodes/", obj)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const deletePromoCode = createAsyncThunk(
  "promoCodes/delete",
  async (obj, { rejectWithValue }) => {
    let response = null;
    await API.delete(`/promoCodes/?_id=${obj._id}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return obj._id;
  }
);
export const newsSlice = createSlice({
  name: "promoCodes",
  initialState: {
    promoCodes: [],
    singlePromoCode: {},
    newPromoCodes: [],
    page: 1,
    totalPages: 0,
    loading: false,
    loadingSetPage: false,
    loadingDelete: false,
    loadingAdd: false,
    loadingUpdate: false,
  },
  reducers: {
    setSinglePromoCode: (state, action) => {
      state.singlePromoCode = action.payload;
    },
    setNewPromoCodes: (state, action) => {
      state.newPromoCodes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPromoCode.fulfilled, (state, action) => {
      state.singlePromoCode = action.payload;
      state.loading = false;
      //return { ...{ authorized: true }, ...action.payload };
    });

    builder.addCase(fetchPromoCode.pending, (state, action) => {
      state.loading = true;
      //return { ...{ authorized: true }, ...action.payload };
    });

    builder.addCase(fetchPromoCode.rejected, (state, action) => {
      state.loading = false;
      //return { ...{ authorized: true }, ...action.payload };
    });

    builder.addCase(deletePromoCode.pending, (state, action) => {
      state.loadingDelete = true;
      //return { ...{ authorized: true }, ...action.payload };
    });

    builder.addCase(deletePromoCode.fulfilled, (state, action) => {
      const _id = action.payload;
      const index = state.promoCodes.findIndex((el) => el._id === _id);
      if (index >= 0) state.promoCodes.splice(index, 1);
      state.loadingDelete = false;
    });

    builder.addCase(addPromoCodes.pending, (state, action) => {
      state.loadingAdd = true;
      //return { ...{ authorized: true }, ...action.payload };
    });

    builder.addCase(addPromoCodes.fulfilled, (state, action) => {
      state.newPromoCodes = action.payload;
      state.loadingAdd = false;
      emmitSuccess("Pomyślnie dodano kod promocyjny");
    });
    builder.addCase(addPromoCodes.rejected, (state, action) => {
      state.loadingAdd = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(updatePromoCode.pending, (state, action) => {
      state.loadingUpdate = true;
      //return { ...{ authorized: true }, ...action.payload };
    });

    builder.addCase(updatePromoCode.fulfilled, (state, action) => {
      const _id = action.payload._id;
      const index = state.promoCodes.findIndex((el) => el._id === _id);
      if (index >= 0) {
        state.promoCodes[index] = action.payload;
      }
      state.loadingUpdate = false;
      emmitSuccess("Pomyślnie zaktualizowano kod promocyjny");
    });

    builder.addCase(updatePromoCode.rejected, (state, action) => {
      state.loadingUpdate = false;
      emmitSuccess(action.payload.data.error);
    });

    builder.addCase(setPage.fulfilled, (state, action) => {
      state.promoCodes = action.payload.docs;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.loadingSetPage = false;
      //return { ...{ authorized: true }, ...action.payload };
    });

    builder.addCase(setPage.pending, (state, action) => {
      state.loadingSetPage = true;
    });

    builder.addCase(setPage.rejected, (state, action) => {
      state.loadingSetPage = false;
      emmitError(action.payload.data.error);
    });
  },
});

const { actions, reducer } = newsSlice;

// Action creators are generated for each case reducer function
export const { setSinglePromoCode, setNewPromoCodes } = actions;

export const selectPromoCodes = (state) => {
  return state.promoCodes.promoCodes;
};

export const selectPages = (state) => {
  return {
    page: state.promoCodes.page,
    pageCount: state.promoCodes.totalPages,
  };
};

export const selectSingle = (state) => {
  return {
    promoCode: state.promoCodes.singlePromoCode,
    loading: state.promoCodes.loading,
  };
};

export const selectNewPromoCodes = (state) => {
  return {
    promoCodes: state.promoCodes.newPromoCodes,
    loading: state.promoCodes.loadingAdd,
  };
};

export const selectPagesLoading = (state) => {
  return state.promoCodes.loadingSetPage;
};

export const selectLoadingAdd = (state) => {
  return state.promoCodes.loadingAdd;
};

export default reducer;
