import styles from "./BuyCycleFlow.module.scss";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import moment from "moment";
import classnames from "classnames";
import EventBar from "../calendarComponents/EventBar";
import { day_Handler, hoursLeft_Handler } from "../../svc/svc";

const DATE_FORMAT = "dddd, DD MMMM YYYY";

function EventCard(props) {
  const event = props.event;

  const ended = moment(event.end).isBefore(moment());
  const in_progress = moment(event.start).isBefore(moment()) && !ended;
  const hoursLeft = Math.ceil(moment(event.start).diff(moment(), 'minutes') / 60);
  
  const startDay = moment(event.start).startOf('day');
  const nowDay = moment().startOf('day');
  
  const daysLeft = moment(startDay).diff(nowDay, 'days');


  const dayHandler = () => {
    return day_Handler(daysLeft, hoursLeft)
  }
  

  return (
    <div className={!ended ? styles.eventCardWrapper : classnames(styles.eventCardWrapper, styles.greyBg)}>
      <div className={styles.eventCard}>
        <div className={styles.icon}>
          <CalendarTodayIcon />
        </div>
        <div className={styles.info}>
          <div className={styles.timestamp}>
            <div className={styles.date}>
              {moment(event.start).format(DATE_FORMAT)}
            </div>
            <div className={styles.duration}>
              <div className={styles.timespan}>
                {moment(event.start).day == moment(event.end).day && (
                  <>
                    {moment(event.start).format("HH:mm")}{" - "} {moment(event.end).format("HH:mm")}
                  </>
                )}
              </div>
              <div className={styles.durationMinutes}>
                {moment(event.end).diff(moment(event.start), "minutes")} min

              </div>
            </div>
          </div>
          <div className={styles.name}>
            <div>{event.title}</div> 
            <div className={styles.timeTxt}>{ended ? "ZAKOŃCZONE" : in_progress ? "W TRAKCIE" : dayHandler()}</div>
          </div>
          {in_progress &&
            <EventBar startDate={event.start} endDate={event.end} />}
        </div>
      </div>
    </div>
  );
}

export default EventCard;
