import React from "react";
import PanelButton from "../../../../components/panelButton/PanelButton";

import FeedIcon from "@mui/icons-material/Feed";

import { useHistory } from "react-router-dom";

function AddPostButton(props) {
  const history = useHistory();

  return (
    <PanelButton
      label={"Dodaj aktualność"}
      icon={<FeedIcon style={{ fontSize: 40 }} />}
      onClick={() => history.push("/addPost")}
      width={props.inheritSize ? "unset" : 100}
      height={props.inheritSize ? "unset" : 100}
    />
  );
}

export default AddPostButton;
