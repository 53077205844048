import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../API/APIService";
import { emmitError, emmitSuccess } from "../../utils/ToastEmmiter";

export const fetchMaxLengths = createAsyncThunk(
  "utils/fetchMaxLengths",
  async (obj, { rejectWithValue }) => {
    let response = null;
    await fetch("/data/maxLengths.json")
      .then((res) => res.json())
      .then((data) => {
        response = { data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error);
      });

    // await API.get(`/svc/maxLengths`)
    //   .then((r) => {
    //     response = { data: r.data };
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     throw rejectWithValue(error.response);
    //   });

    return response.data;
  }
);

export const fetchHomepage = createAsyncThunk(
  "utils/fetchHomepage",
  async (obj, { rejectWithValue }) => {
    let response = null;
    await API.get("/pages/home")
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    // await API.get(`/svc/maxLengths`)
    //   .then((r) => {
    //     response = { data: r.data };
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     throw rejectWithValue(error.response);
    //   });

    return response.data;
  }
);

export const fetchSlider = createAsyncThunk(
  "utils/fetchSlider",
  async (_, { rejectWithValue }) => {
    let response = null;
    await API.get("/news/forSlider")
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const fetchHomepageComponent = createAsyncThunk(
  "utils/fetchHomepageComponent",
  async (obj, { rejectWithValue }) => {
    let response = null;
    //url = /featuredEvents np
    // index = 0
    await API.get(obj.url)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return {
      data: response.data.docs ? response.data.docs : response.data,
      index: obj.index,
    };
  }
);

export const updateHomepage = createAsyncThunk(
  "utils/updateHomepage",
  async (obj, { rejectWithValue }) => {
    let response = null;
    await API.put("/pages/home", obj)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    // await API.get(`/svc/maxLengths`)
    //   .then((r) => {
    //     response = { data: r.data };
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     throw rejectWithValue(error.response);
    //   });

    return response.data;
  }
);

export const utilsSlice = createSlice({
  name: "utils",
  initialState: {
    maxLengths: {
      fetched: "false",
    },
    homepage: {},
    slider: [],
    sliderLoading: false,
    populatedHomepage: [],

    homepageLoading: false,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMaxLengths.fulfilled, (state, action) => {
      state.maxLengths = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchMaxLengths.rejected, (state, action) => {
      state.loading = false;
      emmitError(action.payload.data.error);
    });
    builder.addCase(fetchMaxLengths.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchHomepage.fulfilled, (state, action) => {
      state.homepage = action.payload;
      state.homepageLoading = false;
    });
    builder.addCase(fetchHomepage.rejected, (state, action) => {
      state.homepageLoading = false;
      emmitError(action.payload.data.error);
    });
    builder.addCase(fetchHomepage.pending, (state, action) => {
      state.homepageLoading = true;
    });

    builder.addCase(fetchHomepageComponent.fulfilled, (state, action) => {
      state.populatedHomepage[action.payload.index] = action.payload.data;
    });
    builder.addCase(fetchHomepageComponent.rejected, (state, action) => {
      emmitError(action.payload.data.error);
    });

    builder.addCase(updateHomepage.fulfilled, (state, action) => {
      state.homepageLoading = false;
      emmitSuccess("Pomyślnie edytowano stronę główną");
    });
    builder.addCase(updateHomepage.rejected, (state, action) => {
      state.homepageLoading = false;
      emmitError(action.payload.data.error);
    });
    builder.addCase(updateHomepage.pending, (state, action) => {
      state.homepageLoading = true;
    });
    builder.addCase(fetchSlider.fulfilled, (state, action) => {
      state.slider = action.payload;
      state.sliderLoading = false;
    });
    builder.addCase(fetchSlider.rejected, (state, action) => {
      state.sliderLoading = false;
      emmitError(action.payload.data.error);
    });
    builder.addCase(fetchSlider.pending, (state, action) => {
      state.sliderLoading = true;
    });
  },
});

const { actions, reducer } = utilsSlice;

export const {} = actions;

export const selectMaxLengths = (state) => {
  return state.utils.maxLengths;
};

export const selectPopulatedHomepage = (state) => {
  return state.utils.populatedHomepage;
};

export const selectHomepage = (state) => {
  return {
    config: state.utils.homepage,
    loading: state.utils.homepageLoading,
  };
};
export const selectSlider = (state) => {
  return {
    slider: state.utils.slider,
    loading: state.utils.sliderLoading,
  };
}

export default reducer;
