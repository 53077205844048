import moment from "moment";
import React from "react";
import styles from "./Logs.module.scss";
import { useSelector } from "react-redux";
import { selectMaxLengths } from "../../redux/slices/utilsSlice";
const DATE_FORMAT = "dddd, DD-MM-YYYY HH:mm";

function LogCard(props) {
  const { data } = props;

  const { userTypeParsed } = useSelector(selectMaxLengths)
  return (
    <div className={styles.logCardContainer}>
      <div className={styles.topInfo}>
        <div className={styles.metaSection}>
          <div
            style={{ backgroundColor: `${data.color}` }}
            className={styles.type}
          >
            {data.type.toUpperCase()}
          </div>
          <div className={styles.endpoint}>{data.endpoint}</div>
          {data.user && <div className={styles.user}>
            <div className={styles.cred}>{data.user?.fullName}</div>
            <div
              style={{ color: userTypeParsed[data.user?.type].color }}
              className={styles.type}
            >
              {userTypeParsed[data.user?.type].text.toUpperCase()}
            </div>
          </div>}
        </div>
        <div className={styles.messageSection}>
          <div className={styles.message}>{data.message}</div>
        </div>
      </div>

      <div className={styles.bottomInfo}>
        <div className={styles.timestamp}>
          {moment(data.createdAt).locale("pl").format(DATE_FORMAT)}
        </div>
      </div>
    </div>
  );
}

export default LogCard;
