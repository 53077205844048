import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { connect } from "react-redux"
import {
  fetchHomepageComponent,
  selectPopulatedHomepage,
} from "../../../redux/slices/utilsSlice"
import styles from "./components.module.scss"
import classNames from "classnames"
import HomeNewsCard from "./homeNewsCard/HomeNewsCard"
import { TitleText } from "./utils/CustomText"
import { motion } from "framer-motion"
import { staggerContainer } from "./utils/motion"

function RecentNews(props) {
  const { data, index } = props
  const dispatch = useDispatch()
  const populatedHomepage = useSelector(selectPopulatedHomepage)
  useEffect(() => {
    if (populatedHomepage[index] !== undefined) return //nie fetchujemy jeśli już jest
    dispatch(
      fetchHomepageComponent({
        url: `/news/?page=1&limit=${data.count ? parseInt(data.count) : 6}`,
        index,
      })
    )
  }, [])

  const news = populatedHomepage[index]
  const loading =
    populatedHomepage[index] === null || populatedHomepage[index] === undefined

  if (loading) {
    return (
      <div>
        <p>Ładowanie wiadomości...</p>
      </div>
    )
  }

  const content = (
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={classNames(
        styles.recentNewsSection,
        styles.maxContainer,
        styles.sectionMargin
      )}
    >
      <TitleText title="Aktualności" />
      <div className={styles.recentNewsContainer}>
        {news.map((el, index) => (
          <HomeNewsCard data={el} key={index} index={index}></HomeNewsCard>
        ))}
      </div>
    </motion.div>
  )

  return content
}

export default connect()(RecentNews)
