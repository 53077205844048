import React from "react";
import PanelButton from "../../../../components/panelButton/PanelButton";

import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { logout } from ".././../../../redux/slices/userSlice";
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
function HourlyAssessmentButton(props) {
  const history = useHistory();

  return (
    <PanelButton
    label={"Zestawienie Godzinowe"}
    icon={<HistoryToggleOffIcon style={{ fontSize: 40 }} />}
    width={100}
    height={100}
    onClick={() => {
      history.push("/hourlyAssessment");
    }}
  />
  );
}

export default HourlyAssessmentButton;
