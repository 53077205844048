import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import styles from "./scroller.module.scss";

import useVirtual from "react-cool-virtual";

import AutoSizer from "react-virtualized-auto-sizer";



import Empty from "../universal/Empty";

let itemLoadMap = [];

function InfiniteScroller(props) {
  const { itemsPerLoad, ItemCard, Loading } = props;
  const { data, hasNextPage, loading } = useSelector(props.dataSelector);
  const { single } = useSelector(props.selectedSingle);

  const loadItems = (loadIndex) => {
    itemLoadMap[loadIndex] = true;
    if (loading || !hasNextPage) {
      return;
    } else {
      dispatch(props.loadMoreItems({ limit: itemsPerLoad }));
    }
  };

  useEffect(() => {
    if (data.length === 0) {
      itemLoadMap = [];
      loadItems(0);
    }
  }, [data]);

  const { outerRef, innerRef, items, scrollTo } = useVirtual({
    // Provide the number of comments
    itemCount: data.length,
    ssrItemCount: data.length,
    resetScroll: props.resetScroll ? true : false,
    // Starts to pre-fetch data when the user scrolls within every 5 items
    // e.g. 1 - 5, 6 - 10 and so on (default = 15)
    loadMoreCount: itemsPerLoad,
    // Provide the loaded state for a batch items to tell the hook
    // whether the `loadMore` should be triggered or not
    isItemLoaded: (loadIndex) => {
      return itemLoadMap[loadIndex];
    },
    // The callback will be invoked when more data needs to be loaded
    loadMore: (e) => {
      loadItems(e.loadIndex);
    },
  });

  const dispatch = useDispatch();

  const handleSetSingle = (el) => {
    props.setSingle(el);
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <div
          className={styles.outerScroller}
          style={{
            width,
            height,
            overflow: "auto",
            overflowX: "hidden",
          }}
          ref={outerRef}
        >
          <div  ref={innerRef}>
            {!loading ? data.length > 0 ? (
              items.map(({ index, measureRef }) => {
                const showLoading = (hasNextPage && index === data.length - 1) || loading;
                if (index >= data.length) {
                  return <></>;
                }

                return (
                  <div
                    onClick={() => {
                      handleSetSingle(data[index]);
                    }}
                    ref={measureRef}
                    key={data[index]?._id}
                  >
                    <ItemCard
                      key={data[index]?._id}
                      additionalProps={props.additionalProps}
                      isSelected={data[index]._id === single._id}
                      data={data[index]}
                    />
                    {showLoading && <Loading />}
                  </div>
                );
              })
            ) : (
              <Empty text={props.emptyText} hint={props.emptyHint} />
            ) : <Loading />}
          </div>
        </div>
      )}
    </AutoSizer>
  );
}

export default connect()(InfiniteScroller);
