import axios from "axios"
import { store } from "../redux/store"
import Cookies from "js-cookie"
import { history } from "../index"
import { setLogOut } from "../redux/slices/userSlice"

const backendURL =
  process.env.REACT_APP_SERVER_HOST + process.env.REACT_APP_SERVER_URL

var instance

var notInterceptedInstance

var development = true && process.env.NODE_ENV === "development"

if (development) {
  if (process.env.REACT_APP_TYPE === "k8s") {
    instance = axios.create({
      baseURL: "http://localhost/api",
    })

    notInterceptedInstance = axios.create({
      baseURL: "http://localhost/api",
    })
  } else {
    instance = axios.create({
      baseURL: "http://localhost/api",
    })

    notInterceptedInstance = axios.create({
      baseURL: "http://localhost/api",
    })
  }
} else {
  //console.log("Prod Axios", backendURL);
  instance = axios.create({
    baseURL: "/api",
  })

  notInterceptedInstance = axios.create({
    baseURL: "/api",
  })
}

instance.defaults.withCredentials = true
notInterceptedInstance.defaults.withCredentials = true

instance.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    const originalRequest = error.config

    if (
      error.response.status === 403 &&
      (originalRequest.url === "/auth/refresh" ||
        originalRequest.url === "/api/auth/refresh")
    ) {
      Cookies.remove("AccessToken")
      history.push("/login")
      store.dispatch(setLogOut())

      return Promise.reject(error)
    }

    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true

      return instance.get("/auth/refresh").then((res) => {
        if (res.status === 201) {
          console.log("Refreshed token")
          Cookies.set("AccessToken", res.data.accessToken)
          instance.defaults.headers.common["Authorization"] =
            "Bearer " + res.data.accessToken
          originalRequest.headers.Authorization =
            "Bearer " + res.data.accessToken
          return instance(originalRequest)
        }
      })
    }
    return Promise.reject(error)
  }
)

export { instance as default, notInterceptedInstance }
