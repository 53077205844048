import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../API/APIService";
import { toast } from "react-toastify";

import { emmitError, emmitSuccess } from "../../utils/ToastEmmiter";
import { set } from "js-cookie";

export const populateCart = createAsyncThunk(
  "cart/populate",
  async (obj, thunkApi) => {
    const { rejectWithValue } = thunkApi;

    let response = null;
    await API.post(`/cart/getCart`, thunkApi.getState().cart.items)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);


export const getPrice = createAsyncThunk(
  "cart/getPrice",
  async (obj, { rejectWithValue }) => {
    let response = null;
    
    await API.post(`/cart/getCart?ignoreValidation=true`, obj)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    
    return response.data;
  }
);

export const checkout = createAsyncThunk(
  "cart/checkout",
  async (obj, { rejectWithValue }) => {
    let response = null;
    await API.post(
      `/cart/checkout`,
      obj /// populatedCart with additonal props
    )
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return { data: response.data, history: obj.history };
  }
);

export const verifyTaxNo = createAsyncThunk(
  "cart/verifyTaxNo",
  async (taxno, { rejectWithValue }) => {
    let response = null;
    await API.get(`/cart/companyFiscalData?nip=${taxno}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    errors: [],
    priceErrors: [],
    order: {},
    populatedItems: [],
    priceItems: [],
    populationLoading: false,
    priceLoading: false,
    checkoutLoading: false,
    companyFiscalData: {},
    companyFiscalDataLoading: false,
  },
  reducers: {
    addToCart: (state, action) => {
      const { cartLimit = 222, item } = action.payload;

      if (state.items.length > cartLimit) {
        emmitError("Koszyk osiągnął limit przedmiotów");
        return;
      }
      if (item.type === "cycle") {
        var index = state.items.findIndex((el) => el._id === item._id);
        if (index !== -1) return;
      }
      state.items.push(item);
      // emmitSuccess("Pomyślnie dodano do koszyka");
    },

    setMailingList: (state, action) => {
      var index = state.items.findIndex((el) => el._id === action.payload._id);
      if (index >= 0) {
        state.populatedItems[index].mailingList = action.payload.emails;
      }
    },

    clearCart: (state, action) => {
      state.items = [];
      state.populatedItems = [];
    },
    removeFromCart: (state, action) => {
      const l = state.items.length;
      state.items = state.items.filter((el) => el._id !== action.payload);
      state.populatedItems = state.populatedItems.filter(
        (el) => el._id !== action.payload
      );

      // if (l === state.items.length) {
      //   emmitError("Nie znaleziono przedmiotu w koszyku");
      // } else {
      //   emmitSuccess("Pomyślnie usunięto przedmiot z koszyka");
      // }
    },
    removeFromErrorList: (state, action) => {
      ///action.payload to text z errora
      state.errors = state.errors.filter((el) => el.text !== action.payload);
    },
    addParticipantsToCartItem: (state, action) => {
      const { id, participants } = action.payload;
      const index = state.items.findIndex((el) => el._id === id);
      if (index >= 0) {
        state.items[index].participants = participants;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(populateCart.fulfilled, (state, action) => {
      const prevLength = state.items.length;
      const actualLength = action.payload.populatedItems.length;
      if (prevLength !== actualLength) {
        emmitError("Niektóre przedmioty zostały usunięte z twojego koszyka");
        state.items = action.payload.populatedItems.map((el) => {
          return { _id: el._id, type: el.cartType };
        });
      }
      if (action.payload.errors.length >= 0) {
        state.errors = action.payload.errors;
      }
      state.populationLoading = false;
      state.populatedItems = action.payload.populatedItems;
    });
    builder.addCase(populateCart.pending, (state, action) => {
      state.populationLoading = true;
    });
    builder.addCase(populateCart.rejected, (state, action) => {
      state.populationLoading = false;
      state.items = [];
      state.populatedItems = [];
      emmitError(action.payload.data.error);
    });

    builder.addCase(getPrice.fulfilled, (state, action) => {
      state.priceLoading = false;
      state.priceItems = action.payload.populatedItems;
    });
    builder.addCase(getPrice.pending, (state, action) => {
      state.priceLoading = true;
    });
    builder.addCase(getPrice.rejected, (state, action) => {
      state.priceLoading = false;
      state.priceItems = [];
      emmitError(action.payload.data.error);
    });

    builder.addCase(checkout.fulfilled, (state, action) => {
      state.checkoutLoading = false;
      const { data, history } = action.payload;
      state.companyFiscalData = {};
      history.push(`/orders/${data.orderID}`);
      emmitSuccess("Pomyślnie utworzono zamówienie");
      state.items = [];
      state.populatedItems = [];
    });
    builder.addCase(checkout.pending, (state, action) => {
      state.checkoutLoading = true;
    });
    builder.addCase(checkout.rejected, (state, action) => {
      state.checkoutLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(verifyTaxNo.fulfilled, (state, action) => {
      state.companyFiscalDataLoading = false;
      state.companyFiscalData = action.payload;
    });
    builder.addCase(verifyTaxNo.pending, (state, action) => {
      state.companyFiscalDataLoading = true;
    });
    builder.addCase(verifyTaxNo.rejected, (state, action) => {
      state.companyFiscalDataLoading = false;
      emmitError(action.payload.data.error);
    });
  },
});

const { actions, reducer } = cartSlice;

// Action creators are generated for each case reducer function
export const {
  addToCart,
  clearCart,

  removeFromErrorList,
  setMailingList,
  addParticipantsToCartItem,
  removeFromCart,
} = actions;

export const selectCart = (state) => {
  return {
    cart: state.cart.items,
    populatedCart: state.cart.populatedItems,
    loading: state.cart.populationLoading,
    checkoutLoading: state.cart.checkoutLoading,
    errors: state.cart.errors,
  };
};

export const selectPrice = (state) => {
  return {
    items: state.cart.priceItems,
    loading: state.cart.priceLoading,
    errors: state.cart.priceErrors,
    overallPrice: state.cart.priceItems.reduce((a, b) => a + b.price, 0),
    overallInitialPrice: state.cart.priceItems.reduce((a, b) => a + b.initialPrice, 0),
    
  };
};

export const selectCartItems = (state) => {
  return state.cart.items;
};

export const isCycleInCart = (state, cycleId) => {
  return state.cart.items.find((e) => e._id === cycleId);
};

export const selectCompanyFiscalItems = (state) => {
  return {
    companyFiscalData: state.cart.companyFiscalData,
    companyFiscalDataLoading: state.cart.companyFiscalDataLoading,
  };
};

export default reducer;
