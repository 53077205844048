import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../API/APIService";

import { emmitError, emmitSuccess } from "../../utils/ToastEmmiter";

export const fetchCustomPages = createAsyncThunk(
  "customPages/fetch",
  async (id, { rejectWithValue }) => {
    let response = null;
    await API.get(`/pages/custom`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const fetchSingleCustomPage = createAsyncThunk(
  "customPages/fetchSingle",
  async (slug, { rejectWithValue }) => {
    let response = null;
    await API.get(`/pages/custom/${slug}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const addCustomPage = createAsyncThunk(
  "customPages/add",
  async (data, { rejectWithValue }) => {
    const { handleClose, ...rest } = data;
    let response = null;
    await API.post("/pages/custom", rest)
      .then((r) => {
        response = { data: r.data };
        handleClose();
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const updateCustomPages = createAsyncThunk(
  "customPages/update",
  async (obj, { rejectWithValue }) => {
    const { turnOffEditMode, ...rest } = obj;

    let response = null;
    await API.put("/pages/custom", rest)
      .then((r) => {
        response = { data: r.data };
        turnOffEditMode();
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const deleteCustomPages = createAsyncThunk(
  "customPages/delete",
  async (obj, { rejectWithValue }) => {
    let response = null;
    await API.delete(`/pages/custom?_id=${obj._id}`)
      .then((r) => {
        response = { data: r.data };
        obj.turnOffEditMode();
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return obj._id;
  }
);
export const customPagesSlice = createSlice({
  name: "customPages",
  initialState: {
    customPages: [],
    singleCustomPage: {},
    loading: false,
    loadingDelete: false,
    loadingAdd: false,
    loadingUpdate: false,
  },
  reducers: {
    setSingleCustomPage: (state, action) => {
      state.singleCustomPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomPages.fulfilled, (state, action) => {
      state.customPages = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchCustomPages.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchCustomPages.rejected, (state, action) => {
      state.loading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(fetchSingleCustomPage.fulfilled, (state, action) => {
      state.singleCustomPage = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchSingleCustomPage.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchSingleCustomPage.rejected, (state, action) => {
      state.loading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(deleteCustomPages.pending, (state, action) => {
      state.loadingDelete = true;
    });

    builder.addCase(deleteCustomPages.rejected, (state, action) => {
      state.loadingDelete = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(deleteCustomPages.fulfilled, (state, action) => {
      const _id = action.payload;
      state.singleCustomPage = {};
      const index = state.customPages.findIndex((el) => el._id === _id);
      if (index >= 0) state.customPages.splice(index, 1);
      state.loadingDelete = false;
      emmitSuccess("Pomyślnie usunięto stronę");
    });

    builder.addCase(addCustomPage.pending, (state, action) => {
      state.loadingAdd = true;
    });

    builder.addCase(addCustomPage.rejected, (state, action) => {
      state.loadingAdd = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(addCustomPage.fulfilled, (state, action) => {
      state.customPages = state.customPages.concat(action.payload);
      state.loadingAdd = false;
      state.singleCustomPage = action.payload;

      emmitSuccess("Pomyślnie dodano stronę");
    });

    builder.addCase(updateCustomPages.pending, (state, action) => {
      state.loadingUpdate = true;
    });

    builder.addCase(updateCustomPages.rejected, (state, action) => {
      state.loadingUpdate = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(updateCustomPages.fulfilled, (state, action) => {
      const _id = action.payload._id;
      const index = state.customPages.findIndex((el) => el._id === _id);
      if (index >= 0) {
        state.customPages[index] = action.payload;
      }
      state.singleCustomPage = action.payload;

      state.loadingUpdate = false;
      emmitSuccess("Pomyślnie zaktualizowano stronę");
    });
  },
});

const { actions, reducer } = customPagesSlice;

// Action creators are generated for each case reducer function
export const { setSingleCustomPage } = actions;

export const selectCustomPages = (state) => {
  return state.customPages.customPages;
};

export const selectSingleCustomPage = (state) => {
  return state.customPages.singleCustomPage;
};

export const selectLoadings = (state) => {
  return {
    general: state.customPages.loading,
    update: state.customPages.loadingUpdate,
    delete: state.customPages.loadingDelete,
    add: state.customPages.loadingAdd,
  };
};

export default reducer;
