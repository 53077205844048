import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Modal from "../../../../components/modal/Modal";
import PanelButton from "../../../../components/panelButton/PanelButton";
import styles from "./addReservation.module.scss";

import Pagination from "../../../../components/Pagination/Pagination";

import {
  selectPages,
  selectPagesLoading,
  selectPromoCodes,
  setPage,
  setSinglePromoCode,
} from "../../../../redux/slices/promoCodesSlice";

// import PromoCodeModal from "../../../../components/PromoCodeModal/PromoCodeModal";

import AssignmentIcon from "@mui/icons-material/Assignment";
import UserCycleReservationAdd from "../../../users/components/CycleReservations/UserCycleReservationAdd";
import { selectFastAddReservationModal, setFastAddReservationModal } from "../../../../redux/slices/eventsSlice";

function FastAddReservation(props) {
  const dispatch = useDispatch();

  const { modal, cycle } = useSelector(selectFastAddReservationModal)

  const setVisible = () => {
    dispatch(setFastAddReservationModal({ modal: true, cycle: props.initCycle }))
  };

  const setInvisible = () => {
    dispatch(setFastAddReservationModal({ modal: false, cycle: null }))
  };

  return (
    <>
      <PanelButton
        label={"Zapisz użytkownika na zajęcia"}
        icon={<AssignmentIcon style={{ fontSize: 40 }} />}
        onClick={setVisible}
        width={100}
        height={100}
      />
      {!props.noOwnModal &&
        <Modal
          visible={modal}
          closeModal={setInvisible}
          title={"Zapisz użytkownika na zajęcia"}
          isDarkTitle={false}
          isPadding={true}
        >
          <UserCycleReservationAdd anonymusUser={true} onSuccess={setInvisible} initCycle={cycle} />
        </Modal>}
    </>
  );
}

export default connect()(FastAddReservation);
