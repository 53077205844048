import React from "react";
import styles from "./styles.module.scss";
import CloudOffIcon from "@mui/icons-material/CloudOff";
function Empty(props) {
  return (
    <div style={props.style} className={styles.emptyContainer}>
      <div className={styles.mainInfo}>
        <CloudOffIcon />
        <span>{props.text}</span>
      </div>
      <div className={styles.hint}>{props.hint}</div>
    </div>
  );
}

export default Empty;
