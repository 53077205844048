import { Radio, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styles from "./checkout.module.scss";

import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ConfirmButton from "../../components/confirmButton/ConfirmButton";

import {
  removeFromCart,
  setConsentType,
  setGuardian,
  setMailingList,
} from "../../redux/slices/cartSlice";
import { selectMaxLengths } from "../../redux/slices/utilsSlice";
import SchoolIcon from '@mui/icons-material/School';
import { selectType } from "../../redux/slices/userSlice";

import CustomMultiMail from "../../components/CustomMultiMail/CustomMultiMail";

import { Delete } from "@mui/icons-material";
import { emmitError } from "../../utils/ToastEmmiter";
import PeopleIcon from '@mui/icons-material/People';
import Count from "./Count";

import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import BoyIcon from '@mui/icons-material/Boy';

function CheckoutCard(props) {

  const {
    updateParticipants,
    data,
    count,
    ownParticipants,
    modifyCount,
    modifyMatrix,
    user
  } = props;
  const maxLengths = useSelector(selectMaxLengths);
  const events = data.events.slice(0, 3);


  const renderInputs = () => {
    let inputs = [];
    for (let i = 0; i < count; i++) {

      const isChild = ownParticipants[i]?.age < 18;

      inputs.push(<>
        <div className={styles.participantInfo}>
          <div className={styles.priceDetails}>
            <div className={styles.icon}>
              {isChild ? <BoyIcon /> : <EscalatorWarningIcon />}
            </div>
            <div className={styles.name}>
              {isChild ? "Dziecko" : "Dorosły"}
            </div>
            <div className={styles.price}>
              <span className={styles.digits}>
                {isChild ? data.price : data.adultPrice ? data.adultPrice : data.price}
              </span>
              <span className={styles.currency}>
                {props.currencySign}
              </span>
            </div>
          </div>
          <div className={styles.inputsHolder}>
            <TextField
              key={5 * i}
              onBlur={handleOnInputsDone}
              inputProps={{ maxLength: maxLengths.name * 2 }}
              fullWidth
              className={styles.participant}
              value={ownParticipants[i]?.fullName}
              label={`Imię i nazwisko uczestnika #${i + 1}`}
              onChange={(e) => handleInputOnChange(e, i, "fullName")}
            />
            <TextField
              key={5 * i + 1}
              onBlur={handleOnInputsDone}
              label={"Wiek uczestnika #" + (i + 1)}
              variant="outlined"
              className={styles.phone}
              type="number"
              name="age"
              inputProps={{ min: 1, max: 120 }}
              value={ownParticipants[i]?.age || 10}
              onChange={(e) => handleInputOnChange(e, i, "age")}
            />
            <TextField
              key={5 * i + 2}
              onBlur={handleOnInputsDone}
              label={"Nr tel. #" + (i + 1)}
              variant="outlined"
              className={styles.phone}
              inputProps={{ maxLength: maxLengths.name * 2 }}
              value={ownParticipants[i]?.phone || `${user?.phone?.prefix} ${user?.phone?.number}`}
              onChange={(e) => handleInputOnChange(e, i, "phone")}
            />
            {ownParticipants[i]?.age < 18 && (
              <TextField
                key={5 * i + 3}
                onBlur={handleOnInputsDone}
                label={"Opiekun #" + (i + 1)}
                variant="outlined"
                className={styles.participant}
                inputProps={{ maxLength: maxLengths.name * 2 }}
                value={ownParticipants[i]?.supervisor || user?.fullName}
                onChange={(e) => handleInputOnChange(e, i, "supervisor")}
              />
            )}
          </div>
        </div>


        {i < count - 1 &&
          <div className={styles.smallSpacer}>
          </div>}

      </>
      );
    }
    return inputs;
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const DATE_FORMAT = "DD-MM-YYYY HH:mm";
  const maxInIndividualType = 3;

  const handleDelete = async () => {
    dispatch(removeFromCart(data._id));
  };


  const handleAdd = () => {
    modifyCount(data._id, "add");
  };

  const handleRemove = () => {
    modifyCount(data._id, "remove");
  };

  const handleInputOnChange = (e, index, key) => {

    const value = e.target.value;
    let participantObject = { ...ownParticipants[index] }
    let toUpdateCosts = false;
    if (key === "age") {
        toUpdateCosts = true;
    }

    participantObject[key] = value;
    modifyMatrix(data._id, "add", index, participantObject, toUpdateCosts);


  };

  const handleOnInputsDone = () => {
    updateParticipants();
  };



  return (
    <div className={styles.cycleCardContainer}>

      <div className={styles.itemInfo}>
        <div className={styles.leftSide}>
          <div className={styles.deleteArea}>
            <ConfirmButton
              cancelStartIcon={<CloseIcon />}
              confirmStartIcon={<DoneAllIcon />}
              questionText="Czy chcesz usunąć cykl z koszyka?"
              onConfirm={handleDelete}
            >
              <Delete sx={{ fontSize: 36, marginTop: "2px" }}></Delete>
            </ConfirmButton>
          </div>

          <div
            style={{ backgroundImage: `url(${data.thumbnail})` }}
            className={styles.thumbnail}
          ></div>

        </div>

        <div className={styles.rightSide}>
          <div className={styles.monetary}>
            <div className={styles.productContainer}>
              <div className={styles.title}>{data.product?.name}</div>
            </div>
            <div className={styles.titleContainer}>
              <div className={styles.title}>{data.name}</div>
            </div>
            <div className={styles.reservationCountWrapper}>
              <PeopleIcon />
              <div className={styles.reservationCount}>
                {data.reservationsCount} {"  "} <div className={styles.countr}>{"+( "}{count}{" )"}</div> {"  "} / {"  "} {data.capacity}
              </div>
            </div>
            {data.ageString && 
            <div className={styles.ageStringInfo}>
              <SchoolIcon/>
              <div>
                <span>{data.ageString}</span>
              <span> {"lat"}</span>
              </div>
            </div> }
            <div className={styles.itemDetails}>
              <div className={styles.price}>
                <div className={styles.priceText}>{props.itemPrice?.price}</div>
                <div className={styles.priceCurrencySign}>
                  &nbsp;{`${props.currencySign}`}
                </div>
              </div>

              <div className={styles.countbox}>
                <Count add={handleAdd} remove={handleRemove} count={count} />
              </div>
            </div>
          </div>

          {/* <div className={styles.mailingList}>
          {data.cartType === "cycle" && renderMailing(data)}
        </div> */}
        </div>
      </div>
      <div className={styles.inputs}>{renderInputs()}</div>
    </div>
  );
}

export default connect()(CheckoutCard);
