import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API, { notInterceptedInstance } from "../../API/APIService";
import { emmitError, emmitSuccess } from "../../utils/ToastEmmiter";
import Cookie from "js-cookie";
import { history } from "../../index.jsx";
import socketService from "../../API/socketService";

export const me = createAsyncThunk("auth/me", async (obj, thunkApi) => {
  try {
    const res = await notInterceptedInstance.get("/auth/refresh");
    Cookie.set("AccessToken", res.data.accessToken);
    API.defaults.headers.common["Authorization"] =
      "Bearer " + res.data.accessToken;
    socketService.setupConnection(res.data.user._id);

    return res.data.user;
  } catch (error) {
    console.log(error.response);

    throw error;
  }
});

export const logout = createAsyncThunk("auth/logout", async () => {
  try {
    const response = await API.get("/auth/logout");
    Cookie.remove("AccessToken");
    emmitSuccess("Pomyślnie wylogowano");
    socketService.closeConnection();
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const login = createAsyncThunk("auth/login", async (obj, thunkApi) => {
  try {
    const res = await API.post("/auth/login", obj);
    Cookie.set("AccessToken", res.data.accessToken);
    API.defaults.headers.common["Authorization"] =
      "Bearer " + res.data.accessToken;

    socketService.setupConnection(res.data.user._id);

    return res.data.user;
  } catch (error) {
    if (error.response.status === 406) {
      // jeżeli niezweryfikowany phone number
      emmitError(error.response.data.error);

      history.push("/verifyPhone", { ...obj, ...error.response.data.phone });

      throw error;
    }

    emmitError(error.response.data.error);
    throw error;
  }
});

export const register = createAsyncThunk(
  "auth/register",
  async (obj, thunkApi) => {
    try {
      const res = await API.post("/auth/register", obj);
      history.push("/login");
      emmitSuccess("Zarejestrowano pomyślnie! Sprawdź skrzynkę mailową.");

      return res.data;
    } catch (error) {
      console.log(error.response);

      emmitError(error.response.data.error);
      throw error;
    }
  }
);

export const verifyPhone = createAsyncThunk(
  "auth/verifyPhone",
  async (obj, thunkApi) => {
    try {
      const res = await API.post("/auth/verifyPhone", obj);
      Cookie.set("AccessToken", res.data.accessToken);
      API.defaults.headers.common["Authorization"] =
        "Bearer " + res.data.accessToken;

      emmitSuccess("Pomyślnie zweryfikowano numer telefonu!");

      return res.data.user;
    } catch (error) {
      console.log(error.response);

      emmitError(error.response.data.error);
      throw error;
    }
  }
);

export const resendVerificationMail = createAsyncThunk(
  "auth/resendVerMail",
  async (obj, thunkApi) => {
    try {
      const res = await API.post("/auth/resendVerificationEmail", {
        email: obj.email,
      });

      emmitSuccess("Pomyślnie wysłano maila");

      obj.handleClose();

      return res.data.user;
    } catch (error) {
      console.log(error.response);

      emmitError(error.response.data.error);
      throw error;
    }
  }
);

export const resendVerificationPhone = createAsyncThunk(
  "auth/resendVerPhone",
  async (obj, thunkApi) => {
    try {
      const res = await API.post("/auth/resendVerificationPhone", obj);

      emmitSuccess("Pomyślnie wysłano sms z kodem potwierdzającym");

      return res.data.user;
    } catch (error) {
      console.log(error.response);

      emmitError(error.response.data.error);
      throw error;
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (obj, thunkApi) => {
    try {
      const res = await API.post("/auth/sendPasswordReset", {
        email: obj.email,
      });

      emmitSuccess("Pomyślnie wysłano maila");
      //console.log(res.data);
      obj.handleClose();

      return res.data.user;
    } catch (error) {
      console.log(error.response);

      emmitError(error.response.data.error);
      throw error;
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (obj, thunkApi) => {
    try {
      const res = await API.post("/auth/resetPassword", obj);

      emmitSuccess("Pomyślnie zresetowano hasło");
      history.push("/login");
      //console.log(res.data);
      //obj.handleClose();

      return res.data;
    } catch (error) {
      console.log(error.response);

      emmitError(error.response.data.error);
      throw error;
    }
  }
);

export const fetchUserData = createAsyncThunk(
  "user/",
  async (id, { rejectWithValue }) => {
    let response = null;
    await API.get(id ? `/users/?id=${id}` : "/users/")
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

//management


const initial = {
  authorized: false,
  loading: false,
  email: undefined,
  type: undefined,
  scope: [],
  fullName: undefined,
  ticketVerifiedModal: false,
  ticketModal: false,
  loadings: {
    resendVer: false,
    resendPhone: false,
    resetPass: false,
    forgotPass: false,
    userPasswordResetting: false,
  },

 
};

export const userSlice = createSlice({
  name: "user",
  initialState: initial,
  reducers: {
    setLogOut: (state, action) => {
      return initial;
    },
    setSingleUser: (state, action) => {
      state.singleUser = action.payload;
    },
    setTicketVerifiedModal: (state, action) => {
      state.ticketVerifiedModal = action.payload
    },
    setTicketModal: (state, action) => {
      state.ticketModal = action.payload
    }
   
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      //history.push(action.payload.redirect || "/");
      return {
        ...state,
        ...{ authorized: true, loading: false },
        ...action.payload,
      };
    });

    builder.addCase(login.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(register.fulfilled, (state,action) => {
      state.loading = false;
    })

    builder.addCase(register.pending, (state,action) => {
      state.loading = true;
    })

    builder.addCase(register.rejected, (state,action) => {
      state.loading = false;
    })

    builder.addCase(me.fulfilled, (state, action) => {
      return {
        ...state,
        ...{ authorized: true, loading: false },
        ...action.payload,
      };
    });

    builder.addCase(me.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(me.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(verifyPhone.fulfilled, (state, action) => {
      history.push("/");
      return { ...{ authorized: true }, ...action.payload };
    });

    builder.addCase(logout.fulfilled, (state, action) => {
      history.push("/");
      return {
        authorized: false,
        loading: false,
        email: undefined,
        type: undefined,
        scope: [],
        fullName: undefined,

        loadings: {
          resendVer: false,
          resendPhone: false,
          resetPass: false,
          forgotPass: false,
        },
      };
    });



    builder.addCase(resendVerificationMail.pending, (state, action) => {
      state.loadings.resendVer = true;
    });
    builder.addCase(resendVerificationMail.rejected, (state, action) => {
      state.loadings.resendVer = false;
    });
    builder.addCase(resendVerificationMail.fulfilled, (state, action) => {
      state.loadings.resendVer = false;
    });

    builder.addCase(resendVerificationPhone.pending, (state, action) => {
      state.loadings.resendPhone = true;
    });
    builder.addCase(resendVerificationPhone.rejected, (state, action) => {
      state.loadings.resendPhone = false;
    });
    builder.addCase(resendVerificationPhone.fulfilled, (state, action) => {
      state.loadings.resendPhone = false;
    });

    builder.addCase(resetPassword.pending, (state, action) => {
      state.loadings.resetPass = true;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loadings.resetPass = false;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loadings.resetPass = false;
    });

    builder.addCase(forgotPassword.pending, (state, action) => {
      state.loadings.forgotPass = true;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.loadings.forgotPass = false;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.loadings.forgotPass = false;
    });



  },
});

const { actions, reducer } = userSlice;

// Action creators are generated for each case reducer function
export const {
  setLogOut,
  setTicketVerifiedModal,
  setTicketModal

} = actions;

export const selectUser = (state) => {
  return {
    fullName: state.user.fullName,
    type: state.user.type,
    authorized: state.user.authorized,
    scope: state.user.scope,
    email: state.user.email,
    _id: state.user._id,
  };
};

export const selectAuthLoadings = (state) => {
  return state.user.loadings;
};

export const selectType = (state) => {
  return {
    type: state.user.type,
    authorized: state.user.authorized,
    scope: state.user.scope,
    authorizing: state.user.loading,
    fullName: state.user.fullName,
    phone: state.user.phone
  };
};

export const selectScopes = (state) => {
  return { scopes: state.user.scopes, loading: state.user.scopesLoading };
};

export const selectTicketVerifiedModal = (state) => {
  return state.user.ticketVerifiedModal
}

export const selectTicketModal = (state) => {
  return state.user.ticketModal
}

export default reducer;
