import React from "react";
import styles from "./resourceCard.module.scss";
import moment from "moment";
import {
  fadeIn,
  resourceCardOnHoverShow,
  resourceImageOnHover,
} from "../utils/motion";
import { motion } from "framer-motion";
import classNames from "classnames";

function ResourceCardNew(props) {
  const { data, index } = props;

  return (
    <motion.div
      variants={fadeIn("left", "spring", index * 0.4, 1)}
      className={styles.resourceCard}
      onClick={() => {
        props.history.push("/resources/");
      }}
      {...props}
    >
      <div className={styles.header}>
        <img
          src={data.thumbnail}
          className={styles.thumbnail}
          alt="Miniatura"
        />
        <div className={styles.date}>{`Pojemność: ${data.capacity} osób`}</div>
        <div className={styles.shortResourceDesc}>
          {data.description?.length > 120
            ? data.description.substring(0, 120) + "..."
            : data.description}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.meta}>{data.name}</div>
      </div>
    </motion.div>
  );
}

export default ResourceCardNew;
