import React from "react";
import Lottie from "react-lottie";
import * as loadingAnim from "./loadingData.json";
import styles from "./styles.module.scss";

const defaultOptions = {
  loop: true,
  autoplay: true,

  rendererSettings: {
    preserveAspectRatio: "xMidYMid meet",
  },
};

function Loading(props) {
  return (
    <div className={styles.loadingContainer} {...props}>
      <div className={styles.lottieContainer}>
        <Lottie
          isClickToPauseDisabled={false}
          options={{
            ...defaultOptions,
            animationData: props.animationData
              ? props.animationData
              : loadingAnim,
          }}
        />
        {props.text}
      </div>
    </div>
  );
}

export default Loading;
