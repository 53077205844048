import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import MailIcon from "@mui/icons-material/Mail";
import Badge from "@mui/material/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Tooltip } from "@mui/material";
import { selectCartItems } from "../../redux/slices/cartSlice";

function MessagesIcon(props) {
  const itemsCount = useSelector(selectCartItems).length;

  return (
    <Badge badgeContent={itemsCount || 0} color="secondary">
      <Tooltip title="Koszyk">
        <ShoppingCartIcon color="#fffff" />
      </Tooltip>
    </Badge>
  );
}

export default connect()(MessagesIcon);
