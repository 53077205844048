import React from "react";

import { connect } from "react-redux";
import Pagination from "../../components/Pagination/Pagination";
import {
  selectLogs,
  selectLogsPages,
  selectSetLogsPageLoading,
  setLogsPage,
} from "../../redux/slices/logsSlice";
import styles from "./Logs.module.scss";

import LogCard from "./LogCard";

function Logs(props) {
  return (
    <div className={styles.logsContainer}>
      <div className={styles.logsWrapper}>
        <div className={styles.logsHeader}>Logi</div>

        <Pagination
          key="LOGS"
          dataSelector={selectLogs}
          pagesSelector={selectLogsPages}
          loadingSelector={selectSetLogsPageLoading}
          setPage={setLogsPage}
          ElementCard={LogCard}
          dataContainerClassName={styles.paginationContainer}
          emptyHint={"Tutaj pojawią się logi"}
          emptyText={"Brak logów"}
          paginationWrapperStyleOverride={{ height: "90%" }}
        />
      </div>
    </div>
  );
}

export default connect()(Logs);
