import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import NotFound from "./components/universal/NotFound";
import AdminRoute from "./utils/AdminRoute";
import GuestOnlyRoute from "./utils/GuestOnlyRoute";
import ModRoute from "./utils/ModRoute";
import UserRoute from "./utils/UserRoute";
import Login from "./views/auth/login/Login";
import Home from "./views/home/Home";

import News from "./views/news/News";
import Single from "./views/news/single/Single";
import Panel from "./views/panel/Panel";

// import ItemTypes from "./views/reservations/itemTypes/ItemTypes";
// import ItemType from "./views/reservations/itemType/ItemType";
import Loading from "./components/universal/Loading";
import PublicRoute from "./utils/PublicRoute";
import ScrollToTop from "./utils/ScrollToTop";
import Checkout from "./views/checkout/Checkout";
import CustomPage from "./views/customPage/CustomPage";
import Cycles from "./views/cycles/Cycles";
import SingleCycle from "./views/cycles/singleCycle/SingleCycle";
import Logs from "./views/logs/Logs";
import Messages from "./views/messages/Messages";
import Orders from "./views/orders/Orders";
import SingleEvent from "./views/singleEvent/SingleEvent";
import Resources from "./views/resources/Resources";
import Products from "./views/discover/Products/Products";
import ProductCycles from "./views/discover/ProductCycles/ProductCycles";
import RedirectRoute from "./utils/RedirectRoute";

const Register = lazy(() => import("./views/auth/register/Register"));
const ResetPassword = lazy(() =>
  import("./views/auth/resetPassword/ResetPassword")
);
const DisplaysManagement = lazy(() =>
  import("./views/displaysManagement/Displays")
);

const ManageCustomPages = lazy(() =>
  import("./views/manageCustomPages/ManageCustomPages")
);
const ShowcaseSelection = lazy(() =>
  import("./views/showcase/ShowcaseSelection")
);
const Showcase = lazy(() => import("./views/showcase/Showcase"));
const Users = lazy(() => import("./views/users/Users"));
const SingleOrder = lazy(() =>
  import("./views/orders/singleOrder/singleOrder")
);
const EditCycle = lazy(() => import("./views/editCycle/EditCycle"));
const EditProduct = lazy(() => import("./views/editProduct/EditProduct"));
const AddCycle = lazy(() => import("./views/addCycle/AddCycle"));
const AddProduct = lazy(() => import("./views/addProduct/AddProduct"));
const AddPost = lazy(() => import("./views/addPost/AddPost"));
const ManageHome = lazy(() => import("./views/manageHome/ManageHome"));
const HourlyAssessment = lazy(() =>
  import("./views/hourlyAssessment/HourlyAssessment")
);
const AdminProducts = lazy(() => import("./views/products/Products"));

class ViewManager extends Component {
  render() {
    return (
      <>
        <ToastContainer limit={5} />

        <Suspense
          fallback={
            <>
              <Loading />
            </>
          }
        >
          <ScrollToTop>
            <Switch>
              <PublicRoute exact path="/" component={Home} />
              
              
              <PublicRoute exact path="/resources" component={Resources} />

              {/* samo cycles jest dla moda ale podglad konkretnego cyklu bedzie dostepny z poziomu panelu */}
              <ModRoute redirectPath='/oferta' exact path="/cycles" component={Cycles} />
              <PublicRoute
                exact
                path="/cycles/:id"
                refreshToken
                component={SingleCycle}
              />
              <PublicRoute
                exact
                path="/zajecia/:id"
                refreshToken
                component={SingleCycle}
              />

              <PublicRoute noMods redirectPath='/cycles' exact path="/oferta" component={Products}/>
              <PublicRoute noMods redirectPath='/cycles' exact path="/oferta/:id" component={ProductCycles}/>
              <PublicRoute noMods redirectPath='/cycles' exact path="/offer" component={Products}/>
              <PublicRoute noMods redirectPath='/cycles' exact path="/offer/:id" component={ProductCycles}/>
         
              <PublicRoute exact redirectPath="/news" path="/aktualnosci" component={News} />
              <PublicRoute exact redirectPath="/news/:id" path="/aktualnosci/:id" component={Single} />
              <RedirectRoute exact path="/checkout" redirectPath="/koszyk" />
              <PublicRoute exact path="/koszyk" component={Checkout} />
              
              {/* <Route exact path="/reservations/types" component={ItemTypes} />
          <Route exact path="/reservations/types/:id" component={ItemType} /> */}

              <GuestOnlyRoute exact path="/login" component={Login} />
              {/* <GuestOnlyRoute exact path="/verifyPhone" component={VerifyPhone} /> */}
              <GuestOnlyRoute exact path="/register" component={Register} />
              <Route
                exact
                path="/resetPassword"
                component={ResetPassword}
              />

              {/* <UserRoute exact path="/profile" component={Profile} />
            <UserRoute exact path="/profile/:id" component={Profile} /> */}
              <UserRoute exact path="/panel" component={Panel} />
              <UserRoute exact path="/messages/:id?" component={Messages} />

              <ModRoute exact path="/addCycle" component={AddCycle} />
              <ModRoute exact path="/addProduct" component={AddProduct} />
              <ModRoute exact path="/addPost" component={AddPost} />
              <ModRoute exact path="/editCycle/:id" component={EditCycle} />
              <ModRoute exact path="/editProduct/:id" component={EditProduct} />

              <ModRoute exact path="/displays" component={DisplaysManagement} />
              <ModRoute
                exact
                path="/hourlyAssessment"
                component={HourlyAssessment}
              />

              <Route exact path="/showcase" component={ShowcaseSelection} />
              <Route exact path="/showcase/:id" component={Showcase} />

              <UserRoute userOnly exact path="/orders" component={Orders} />

              <UserRoute
                userOnly
                exact
                path="/orders/:id?"
                component={SingleOrder}
              />

              <ModRoute
                exact
                path="/users/:id?"
                requiredScope={"users"}
                component={Users}
              />

              <ModRoute
                exact
                path="/event/:id?"
                requiredScope={"teacher"}
                component={SingleEvent}
              />

              <AdminRoute exact path="/logs" component={Logs} />

              <ModRoute
                exact
                path="/manageHomePage"
                requiredScope={"manageHome"}
                component={ManageHome}
              />

              <ModRoute
                exact
                path="/manageCustomPages"
                requiredScope={"customPages"}
                component={ManageCustomPages}
              />

<ModRoute
                exact
                path="/products"
                requiredScope={"cycles"}
                component={AdminProducts}
              />

              <Route path="/:slug" component={CustomPage} />

              <Route component={NotFound} />
            </Switch>
          </ScrollToTop>
        </Suspense>
      </>
    );
  }
}

export default withRouter(connect()(ViewManager));
