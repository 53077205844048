import React, { useRef, useState } from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";

import Modal from "../modal/Modal";

import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { selectMaxLengths } from "../../redux/slices/utilsSlice";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/pl";
import styles from "./calendarComponents.module.scss";
import { emmitError } from "../../utils/ToastEmmiter";
moment.locale("pl");

function RepetetiveEvent(props) {
  const { teachers, buildings, calendar } = props;

  const [startDate, setStartDate] = React.useState("");
  const [teacher, setTeacher] = useState(teachers[0].id);
  const [resource, setResource] = useState(buildings[0].id);
  const [numOfEvents, setNumOfEvents] = useState(3);
  const [repeatEvery, setRepeatEvery] = useState(7);
  const [duration, setDuration] = useState(45);
  const maxLengths = useSelector(selectMaxLengths);
  const nameRef = useRef();

  const renderSelectTeacher = () => {
    return (
      <FormControl className={styles.formControl}>
        <InputLabel id="demo-simple-select-label">Nauczyciel</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={teacher}
          label="Nauczyciel"
          sx={{maxWidth: 273}}
          onChange={(e) => {
            setTeacher(e.target.value);
          }}
        >
          {teachers.map((el) => (
            <MenuItem key={el._id} value={el.id}>
              {el.fullName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const renderSelectResource = () => {
    return (
      <FormControl className={styles.formControl}>
        <InputLabel id="demo-simple-select-label">Miejsce</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={resource}
          label="Miejsce"
          sx={{maxWidth: 273}}
          onChange={(e) => {
            setResource(e.target.value);
          }}
        >
          {buildings.map((el) => (
            <MenuItem  key={el._id} value={el.id}>
              {el.building}-{el.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const handleAddRepetetive = async () => {
    // forem przeleciec caledar add
    if (startDate === "" || startDate === undefined)
      return emmitError("Nie wybrano początkowej daty");

    var start = moment(startDate, "YYYY-MM-DDThh:mm");

    const eventName = nameRef.current.value;
    if(eventName.trim() == "") {
      emmitError("Pole nazwy nie może być puste")
      return;
    }

    for (var i = 0; i < numOfEvents; i++) {
      var end = moment(start).add(duration, "minutes");

      var event = {
        start: start.format("YYYY-MM-DD HH:mm"),
        end: end.format("YYYY-MM-DD HH:mm"),
        title: `${eventName} ${(i + 1)}`,
        editable: true,
        durationEditable: true,
        startEditable: true,
        resourceIds: [teacher, resource],
        extendedProps: {
          teacher: teacher,
          postMail: {},
          preMail: {},
        },
      };
      calendar.current.getApi().addEvent(event);

      start = start.add(repeatEvery, "days");
    }

    handleCloseModal();
  };

  const handleCloseModal = async () => {
    props.setOpen(false);
  };

  if (!teachers || !buildings) return <></>;

  const handleStartDateChange = (value) => {
    setStartDate(value);
  };

  const handleNumOfEventsChange = (e) => {
    const value = e.target.value;
    if (value >= 0 && value <= 100) setNumOfEvents(value);
  };

  const handleRepeatEveryChange = (e) => {
    const value = e.target.value;
    if (value >= 0 && value <= 100) setRepeatEvery(value);
  };

  const handleDurationChange = (e) => {
    const value = e.target.value;
    if (value >= 0 && value <= 720) setDuration(value);
  };

  const eventsAdornment = () => {
    let noe = parseInt(numOfEvents);
    if (noe > 4) return "wydarzeń";
    else if (noe >= 2 && noe <= 4) return "wydarzenia";
    else if (noe === 1) return "wydarzenie";
    else return "";
  };

  const repeatAdornment = () => {
    let re = parseInt(repeatEvery);
    if (re >= 2) return "dni";
    else if (re === 1) return "dzień";
    else return "";
  };

  return (
    <>
      <Modal
        title={"Wydarzenie okresowe"}
        visible={props.open}
        closeModal={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        height={500}
        isPadding={true}
      >
        <div className={styles.repetetiveEventContainer}>
          <div className={styles.partNameWrapper}>
            <TextField
              className={styles.input}
              id="partName"
              fullWidth
              inputProps={{
                maxLength: maxLengths.name-4
              }}
              inputRef={nameRef}
              label="Nazwa wydarzenia"
            />
          </div>
          <div className={styles.info}>
            <div className={styles.logisticInfo}>
              <LocalizationProvider locale="pl" dateAdapter={AdapterMoment}>
                <DateTimePicker
                  className={styles.formControl}
                  //inputFormat={DATE_FORMAT}
                  label="Data początkowa"
                  value={startDate}
                  ampm={false}
                  locale="pl"
                  onChange={handleStartDateChange}
                  renderInput={(params) => (
                    <TextField {...params} ampm={false} />
                  )}
                />
              </LocalizationProvider>

              {teachers && renderSelectTeacher()}
              {buildings && renderSelectResource()}
            </div>

            <div className={styles.inputs}>
              <TextField
                className={styles.input}
                value={numOfEvents}
                id="numOfEvents"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {eventsAdornment()}
                    </InputAdornment>
                  ),
                }}
                onChange={handleNumOfEventsChange}
                label="Liczba spotkań"
              />

              <TextField
                className={styles.input}
                value={repeatEvery}
                id="repeatEvery"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {repeatAdornment()}
                    </InputAdornment>
                  ),
                }}
                onChange={handleRepeatEveryChange}
                label="Powtarzaj co"
              />

              <TextField
                className={styles.input}
                value={duration}
                id="duration"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">min</InputAdornment>
                  ),
                }}
                onChange={handleDurationChange}
                label="Czas trwania"
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <LoadingButton
              color="secondary"
              variant="outlined"
              onClick={handleCloseModal}
            >
              Anuluj
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              onClick={() => {
                handleAddRepetetive();
              }}
            >
              Dodaj
            </LoadingButton>
          </div>
        </div>
      </Modal>

      <div className={styles.calendar}></div>

      {/* <Button onClick={() => handleSubmit()}>zatwierdź</Button> */}
    </>
  );
}

export default RepetetiveEvent;
