import React from "react";
import { connect, useSelector } from "react-redux";
import styles from "./notifications.module.scss";

import { matchPath, withRouter } from "react-router";
import stolicaLogo from "../../assets/stolica-white.png";

import { motion, useAnimationControls } from "framer-motion";

import { useDispatch } from "react-redux";

import {
  closeNotifications,
  selectNotifications,
} from "../../redux/slices/notificationsSlice";
import PendingFeedback from "./PendingFeedback";

///animation stuff

const backgroundVariants = {
  hidden: { opacity: 0, zIndex: 1 },
  visible: { opacity: 1, zIndex: 72, pointerEvents: "auto" },
};

const notificationsVariants = {
  hidden: {
    opacity: 0,
    right: "-100%",
    transition: { ease: "easeInOut", duration: 0.6 },
  },
  visible: {
    opacity: 1,
    right: 0,
    transition: { ease: "easeInOut", duration: 0.6 },
  },
};

function Notifications(props) {
  const controls = useAnimationControls();

  const { notifications, open } = useSelector(selectNotifications);

  const dispatch = useDispatch();

  const onBackgroundClick = (e) => {
    handleCloseNavMenu();
  };

  const handleCloseNavMenu = () => {
    dispatch(closeNotifications());
  };

  const renderNotificationCard = (el) => {
    if (el.type === "eventToEvaluate")
      return <PendingFeedback data={el.data} key={el.data._id} />;
  };

  return (
    <>
      <motion.div
        variants={backgroundVariants}
        animate={open ? "visible" : "hidden"}
        className={styles.backgroundDarkener}
        onClick={onBackgroundClick}
      ></motion.div>

      <motion.div
        className={styles.notificationsList}
        variants={notificationsVariants}
        animate={open ? "visible" : "hidden"}
      >
        Powiadomienia
        {notifications.map((el) => renderNotificationCard(el))}
      </motion.div>
    </>
  );
}

export default withRouter(connect()(Notifications));
