import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import ScheduleIcon from "@mui/icons-material/Schedule";
import moment from "moment";
import React, { useState } from "react";
import Modal from "../modal/Modal";
import styles from "./calendarComponents.module.scss";
import {
  printEventAttendance,
  selectPrintLoading,
} from "../../redux/slices/eventsSlice";
import Rating from "@mui/material/Rating";
import { connect, useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
import PrintIcon from "@mui/icons-material/Print";

import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { useHistory } from "react-router-dom";
import { setSelectedFeedbackEvent } from "../../redux/slices/feedbackSlice";
import EdjsDisplay from "../edjsDisplay/EdjsDisplay";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { LoadingButton } from "@mui/lab";
import "moment/locale/pl";
import TeacherInfoPopover from "../teacherInfoPopover/TeacherInfoPopover";
import { Tooltip } from "@mui/material";
import ManIcon from "@mui/icons-material/Man";
import InventoryIcon from "@mui/icons-material/Inventory";
import cx from "classnames";
import Attendance from "../../views/singleEvent/Attendance/Attendance";
import EventBar from "./EventBar";
import FastAddReservation from "../../views/panel/components/fastAddReservation/FastAddReservation";
import { Edit } from "@mui/icons-material";
import PanelButton from "../panelButton/PanelButton";
import TicketChecker from "../../views/singleEvent/TicketChecker/TicketChecker";

function EventModal(props) {
  const DATETIME_FORMAT = "dddd, DD MMMM YYYY HH:mm";
  const DATE_FORMAT = "dddd, DD MMMM YYYY";

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const history = useHistory();

  const printLoading = useSelector(selectPrintLoading);

  const HAS_OWN_FEEDBACK =
    user.type === "user" &&
    props.event &&
    props.event.feedbacks &&
    props.event.feedbacks.length > 0;

  const HAS_OWN_ATTENDANCE =
    user.type === "user" &&
    props.event &&
    props.event.attendance &&
    props.event.attendance.length > 0;

  const OWN_FEEDBACK = HAS_OWN_FEEDBACK ? props.event.feedbacks[0] : undefined;
  const OWN_ATTEDANCE = HAS_OWN_ATTENDANCE
    ? props.event.attendance[0]
    : undefined;

  const HAS_DESC =
    user.type === "user" &&
    props.event &&
    props.event.description &&
    props.event.description.blocks?.length > 0;

  const HAS_PARTICIPANTS_LIST =
    props.event &&
    props.event.participants &&
    props.event.participants.length > 0;

  const [ticketCheckerBool, setTicketCheckerBool] = useState(false);

  const openTicketCheckerModal = () => setTicketCheckerBool(true);
  const closeTicketCheckerModal = () => setTicketCheckerBool(false);

  const DATE_NOW = moment(new Date());
  const ATTENDANCE_PERIOD = moment(props.event?.start).subtract(60, "minutes");
  const SHOULD_RENDER_ATTENDANCE = DATE_NOW >= ATTENDANCE_PERIOD;

  const RENDER_ATTENDANCE_CHECK =
    (user.type === "admin" || user.scope.includes("teacher")) &&
    SHOULD_RENDER_ATTENDANCE &&
    !props.singleCycle;

  const RENDER_FASTADDRESERVATION =
    (user.type === "admin" || user.scope.includes("users")) &&
    !props.singleCycle;

  let MODAL_HEIGHT = 650;

  if (HAS_OWN_FEEDBACK) MODAL_HEIGHT += 200;
  if (HAS_OWN_ATTENDANCE) MODAL_HEIGHT += 40;
  if (HAS_DESC) MODAL_HEIGHT += 350;
  if (HAS_PARTICIPANTS_LIST) MODAL_HEIGHT += 100;

  const rateOffset = moment().subtract(7, "days");

  const IS_IN_PROGRESS =
    moment() > moment(props.event?.start) &&
    moment() < moment(props.event?.end);

  const SHOULD_LEAVE_FEEDBACK =
    user.type === "user" &&
    !HAS_OWN_FEEDBACK &&
    props.event &&
    moment() > moment(props.event.end) &&
    props.event.reservationOwned === true &&
    moment(props.event.end).isAfter(rateOffset);

  const handle_feedback = () => {
    dispatch(setSelectedFeedbackEvent(props.event._id));
    if (!history.location.pathname.split("/").includes("panel"))
      history.push("/panel");
  };

  const handleCycleClick = () => {
    history.push(`/cycles/${props.event.cycle._id}`);
  };
  return (
    props.event && (
      <Modal
        visible={props.event}
        title={props.event.title}
        closeModal={props.closeModal}
        height={MODAL_HEIGHT}
        isPadding={true}
      >
        <div className={styles.eventModalContainer}>
          {IS_IN_PROGRESS && (
            <div className={styles.inProgressContainer}>
              <div className={styles.inProgress}>W TRAKCIE</div>
              <EventBar
                startDate={props.event.start}
                endDate={props.event.end}
              />
            </div>
          )}

          {(user.type === "mod" || user.type === "admin") &&
            user.scope.includes("teacher") && (
              <LoadingButton
                onClick={() => history.push(`/event/${props.event._id}`)}
                variant={"outlined"}
              >
                PANEL WYDARZENIA
              </LoadingButton>
            )}

          <div className={styles.panelButtons}>
            {RENDER_FASTADDRESERVATION && (
              <FastAddReservation noOwnModal initCycle={props.event.cycle} />
            )}
            {(user.type === "admin" || user.scope.includes("events")) && (
              <PanelButton
                icon={<Edit />}
                width={100}
                height={100}
                onClick={() => {
                  history.push(
                    "/editCycle/" +
                      props.event?.cycle?._id +
                      "?editEvent=" +
                      props.event?._id
                  );
                }}
                label="Edytuj wydarzenie"
              />
            )}
            {RENDER_ATTENDANCE_CHECK && (
              <>
                <TicketChecker
                  cycleID={props.event?.cycle?._id}
                  closeTicketCheckerModal={closeTicketCheckerModal}
                  ticketCheckerBool={ticketCheckerBool}
                  openTicketCheckerModal={openTicketCheckerModal}
                />
              </>
            )}
            {(user.type === "admin" || user.scope.includes("teacher")) && (
              <PanelButton
                icon={<PrintIcon />}
                width={100}
                height={100}
                disabled={printLoading}
                onClick={() => dispatch(printEventAttendance(props.event?._id))}
                label="Drukuj listę obecności"
              />
            )}
          </div>

          <div className={styles.timestamp}>
            <div className={styles.iconContainer}>
              <ScheduleIcon style={{ fontSize: 30 }} />
            </div>
            <div className={styles.date}>
              {moment(props.event.start).isSame(props.event.end, "day") ? (
                <div className={styles.sameDayDate}>
                  <span className={styles.d}>
                    {moment(props.event.start).locale("pl").format(DATE_FORMAT)}
                  </span>
                  <span className={styles.h}>
                    {moment(props.event.start).locale("pl").format("HH:mm")}
                  </span>
                  <span className={styles.d}>{" - "}</span>
                  <span className={styles.h}>
                    {moment(props.event.end).locale("pl").format("HH:mm")}
                  </span>
                </div>
              ) : (
                <>
                  <div className={styles.dateFrom}>
                    {moment(props.event.start)
                      .locale("pl")
                      .format(DATETIME_FORMAT)}
                  </div>

                  <ArrowRightAltIcon className={styles.arrowIcon} />

                  <div className={styles.dateTo}>
                    {moment(props.event.end)
                      .locale("pl")
                      .format(DATETIME_FORMAT)}
                  </div>
                </>
              )}
            </div>
          </div>

          <Tooltip title="Miejsce" placement="left">
            <div className={styles.resourceContainer}>
              <div className={styles.iconContainer}>
                <LocationOnIcon sx={{ fontSize: 30 }} />
              </div>
              <div className={styles.info}>
                <div className={styles.name}>{props.event.resource.name}</div>
                <div className={styles.building}>
                  {props.event.resource.building}
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `url(${props.event.resource.thumbnail})`,
                }}
                className={styles.thumbnail}
              ></div>
            </div>
          </Tooltip>

          <Tooltip title="Cykl zajęć" placement="left">
            <div onClick={handleCycleClick} className={styles.cycleContainer}>
              <div className={styles.name}>
                <div className={styles.iconContainer}>
                  <BookmarksIcon sx={{ fontSize: 30 }} />
                </div>
                <span className={styles.nameSpan}>
                  {props.event.cycle.name}
                </span>
              </div>
            </div>
          </Tooltip>

          <Tooltip title="Produkt" placement="left">
            <div className={styles.productContainer}>
              <div className={styles.name}>
                <div className={styles.iconContainer}>
                  <InventoryIcon sx={{ fontSize: 30 }} />
                </div>
                <span className={styles.nameSpan}>
                  {props.event.cycle.product?.name}
                </span>
              </div>

              <div
                style={{
                  backgroundImage: `url(${props.event.cycle.thumbnail})`,
                }}
                className={styles.thumbnail}
              ></div>
            </div>
          </Tooltip>

          <TeacherInfoPopover teacher={props.event.teacher}>
            <Tooltip title="Nauczyciel" placement="left">
              <div className={styles.teacherContainer}>
                <div className={styles.iconContainer}>
                  <PersonIcon sx={{ fontSize: 30 }} />
                </div>

                <span className={styles.name}>
                  {props.event.teacher?.fullName}
                </span>
              </div>
            </Tooltip>
          </TeacherInfoPopover>

          {props.event.additionalTeachers?.length > 0 && (
            <Tooltip title="Nauczyciele dodatkowi" placement="bottom">
              <div className={styles.teacherContainer}>
                <div className={styles.iconContainer}>
                  <PeopleAltIcon sx={{ fontSize: 30 }} />
                </div>
                <div className={styles.additionalTeachers}>
                  {props.event.additionalTeachers.map((teacher) => (
                    <span className={styles.name} key={teacher._id}>
                      {teacher?.fullName}
                    </span>
                  ))}
                </div>
              </div>
            </Tooltip>
          )}

          {RENDER_ATTENDANCE_CHECK && (
            <Attendance myEventID={props.event._id} />
          )}

          {HAS_DESC && (
            <div className={styles.cycledescription}>
              <EdjsDisplay data={props.event.description} />
            </div>
          )}

          {HAS_PARTICIPANTS_LIST && (
            <div className={styles.participantListContainer}>
              <div className={styles.header}>
                Zapisani przez Ciebie uczestnicy
              </div>
              <div className={styles.list}>
                {props.event.participants.map((p, i) => (
                  <div className={styles.item} key={i}>
                    {p?.fullName}
                  </div>
                ))}
              </div>
            </div>
          )}

          {OWN_ATTEDANCE && (
            <div className={styles.ownAttendance}>
              <div className={styles.icon}>
                <ManIcon sx={20} />
              </div>
              <div
                className={cx([
                  styles.status,
                  OWN_ATTEDANCE.status ? styles.present : styles.absent,
                ])}
              >
                {OWN_ATTEDANCE.status ? "OBECNOŚĆ" : "NIEOBECNOŚĆ"}
              </div>
            </div>
          )}

          {OWN_FEEDBACK && (
            <div className={styles.ownFeedback}>
              <div className={styles.header}>
                <div className={styles.txt}>Twoja opinia</div>
                <div className={styles.rating}>
                  <Rating
                    icon={<LightbulbIcon />}
                    emptyIcon={<LightbulbOutlinedIcon />}
                    value={OWN_FEEDBACK.rating}
                    readOnly
                  />
                </div>
              </div>
              <div className={styles.description}>
                <div className={styles.value}>{OWN_FEEDBACK.description}</div>
              </div>
              <div className={styles.timestamp}>
                {moment(OWN_FEEDBACK.createdAt)
                  .locale("pl")
                  .format(DATETIME_FORMAT)}
              </div>
            </div>
          )}

          {SHOULD_LEAVE_FEEDBACK && (
            <div styles={styles.feedbackButton}>
              <LoadingButton
                endIcon={<LightbulbOutlinedIcon />}
                variant="outlined"
                onClick={handle_feedback}
              >
                OCEŃ WYDARZENIE
              </LoadingButton>
            </div>
          )}
        </div>
      </Modal>
    )
  );
}

export default connect()(EventModal);
