import { useDispatch, useSelector } from "react-redux";
import styles from "./BuyCycleFlow.module.scss";
import { selectMaxLengths } from "../../redux/slices/utilsSlice";
import InfoIcon from "@mui/icons-material/Info";
import { Link, useHistory } from "react-router-dom";
import LoopIcon from "@mui/icons-material/Loop";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import GroupIcon from "@mui/icons-material/Group";
import classnames from "classnames";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SchoolIcon from '@mui/icons-material/School';
import EventCard from "./EventCard";
import {
  addToCart,
  isCycleInCart,
  removeFromCart,
} from "../../redux/slices/cartSlice";
import { selectType } from "../../redux/slices/userSlice";
import { day_Handler } from "../../svc/svc";
import { emitInfo } from "../../utils/ToastEmmiter";

import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import BoyIcon from '@mui/icons-material/Boy';

const DATE_FORMAT = "dddd, DD-MM-YYYY HH:mm";

function CycleCard(props) {
  const { cycle, forAdmin, handleSelectCycle, key } = props;
  const [expanded, setExpanded] = useState(undefined);
  const { maxCartItems, currencySign } = useSelector(selectMaxLengths);

  const isInCart = useSelector((state) => isCycleInCart(state, cycle._id));
  const freeSlots = cycle.reservationsCount < cycle.capacity;
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector(selectType);

  const aLoggedUser = user.type == 'user'

  const handleInCartToggle = () => {
    if (forAdmin) {
      history.push(`/cycles/${cycle.slug ? cycle.slug : cycle._id}`)
      return;
    }
    if (!aLoggedUser) {
      history.push('/login')
      emitInfo('Aby wykupić udział w zajęciach zaloguj się')
      return;
    };
    if (cycle.hasElapsedEvent) {
      handleSelectCycle(cycle);
      return;
    };
    if (!isInCart && freeSlots)
      dispatch(
        addToCart({
          item: { _id: cycle._id, type: "cycle" },
          cartLimit: maxCartItems,
        })
      );
    else if (isInCart) dispatch(removeFromCart(cycle._id));
  };

  const toggleExpanded = () => setExpanded(!expanded);

  const cardClassNames = [styles.cycleCardWrapper]

  if (isInCart && !forAdmin) cardClassNames.push(styles.inCart)
  if (forAdmin) cardClassNames.push(styles.fullWidthCard)

  if (!freeSlots) cardClassNames.push(styles.noFreeSlots)

  const oneTimeCycle = cycle?.events?.length == 1;
  return (
    <div key={key} className={classnames(cardClassNames)}>
      <div className={styles.bottomButtons}>
        <div className={styles.priceTag}>
          <div className={styles.pricePart}>
            <div className={styles.iconContainer}>
              <BoyIcon />
            </div>
            <div className={styles.priceInfo}>
              <span className={styles.price}>{cycle.price}</span>
              <span>{currencySign}</span>
            </div>

          </div>
          {cycle.adultPrice && (
            <>
              <span className={styles.spanner}> {' | '}</span>
              <div className={styles.pricePart}>
                <div className={styles.iconContainer}>
                  <EscalatorWarningIcon />
                </div>
                <div className={styles.priceInfo}>
                  <span className={styles.price}>{cycle.adultPrice}</span>
                  <span>{currencySign}</span>
                </div>
              </div>
            </>
          )}
        </div>
        {cycle?.events?.length > 1 && (
          <div onClick={toggleExpanded} className={styles.showMore}>
            <div className={styles.text}>
              <span>{!expanded ? <AddIcon /> : <RemoveIcon />}</span>
              <span>POKAŻ {!expanded ? "WIĘCEJ" : "MNIEJ"}</span>
            </div>
            <div className={styles.count}>{cycle?.events?.length}</div>
          </div>
        )}
      </div>

      {isInCart && !forAdmin && (
        <div className={styles.inCartAlert}>
          W KOSZYKU
        </div>
      )}

      {
        cycle.hasElapsedEvent == 1 && cycle.hasPendingEvent == 1 && (
          <div className={styles.inProgressAlert}>
            ROZPOCZĘTO
          </div>
        )
      }

      {
        cycle.hasElapsedEvent == 1 && cycle.hasPendingEvent == 0 && (
          <div className={styles.endedAlert}>
            ZAKOŃCZONO
          </div>
        )
      }

      <div
        onClick={handleInCartToggle}
        className={
          !expanded
            ?
            classnames(styles.cycleCard, styles.cycleCardRound) :
            classnames(styles.cycleCard, styles.cycleCardFlat)
        }
      >
        <div className={styles.cycleInfo}>
          <div className={styles.title}>{cycle.name}</div>

          {forAdmin && (
            <div className={styles.productName}>{cycle.product?.name}</div>
          )}


          <div className={styles.logisticInfo}>
            <div
              className={
                oneTimeCycle
                  ? classnames(styles.timeSpec, styles.red)
                  : styles.timeSpec
              }
            >
              {oneTimeCycle ? (
                <>
                  <BookmarkIcon /> <span>Zajęcia jednorazowe</span>
                </>
              ) : (
                <>
                  <LoopIcon /> <span>Zajęcia cykliczne</span>
                </>
              )}
            </div>

            <div className={styles.capacity}>
              {!freeSlots && (
                <span className={styles.noFree}>
                  BRAK MIEJSC
                </span>
              )}
              <span className={styles.reservationsCount}>{cycle.reservationsCount}</span>
              <span>/</span>
              <span>{cycle.capacity}</span>
              <GroupIcon />
            </div>
          </div>
          {cycle.ageString && 
          <div className={styles.ageStringInfo}>
              <SchoolIcon />
              <div className={styles.asi}>
              <span>{cycle.ageString}</span> <span>lat</span>
              </div>
          </div> }
        </div>
        <div className={styles.nearestEvent}>
          {oneTimeCycle && (
            <>

              <div className={styles.duration}>
                {moment(cycle?.events[0].end).diff(
                  moment(cycle?.events[0].start),
                  "minutes"
                )}{" "}
                min
              </div>

              <div className={styles.timeTo}>

                {moment(moment()).isBefore(moment(cycle?.events[0].start)) && day_Handler(moment(moment(cycle?.events[0].start).startOf('day')).diff(moment().startOf('day'), 'days'), Math.ceil(moment(cycle?.events[0].start).diff(moment(), 'minutes') / 60))}

              </div>
            </>
          )}
          <div
            className={
              expanded == undefined
                ?
                styles.eventContainer : expanded == true ?
                  classnames(
                    styles.eventContainerContracted,
                    styles.eventContainer
                  )
                  :
                  classnames(
                    styles.eventContainerExpanded,
                    styles.eventContainer
                  )
            }
          >
            <CalendarMonthIcon />
            <div className={styles.date}>
              {moment(cycle?.events[0]?.start).format(DATE_FORMAT)}
              {moment(cycle?.events[0].start).day ==
                moment(cycle?.events[0].end).day && (
                  <>
                    {" - "} {moment(cycle?.events[0].end).format("HH:mm")}
                  </>
                )}
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          expanded == undefined ? styles.bottomPanel : expanded == false
            ? classnames(styles.bottomPanel, styles.hidden)
            : classnames(styles.bottomPanel, styles.expanded)
        }
      >
        {cycle?.events?.length > 1 &&
          cycle.events.map((event, index) => (
            <>
              <EventCard key={index} event={event} />
              {index != cycle.events.length - 1 && (
                <div className={styles.divider} />
              )}
            </>
          ))}
      </div>
    </div>
  );
}

export default CycleCard;
