import EventCard from "../../../components/buyCycleFlowComponents/EventCard";
import Modal from "../../../components/modal/Modal";
import styles from "./ProductCycles.module.scss";

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

function InProgressInstructionModal(props) {
    const { selectedCycle, closeModal } = props;

    const iconPacket = [
        {
            icon: <AlternateEmailIcon />,
            href: 'mailto:rezerwacja@stolicaexperymentu.edu.pl'
        },
        {
            icon: <LocalPhoneIcon />,
            href: 'tel:786815155'
        }
    ]

    return (
        selectedCycle &&
        <Modal
            visible={selectedCycle}
            closeModal={closeModal}
            height={500}
            isPadding={true}
            title='Rozpoczęty cykl zajęć'
        >
            <div className={styles.instructionWrapper}>

                <div className={styles.info1}>
                    TEN CYKL ZAJĘĆ JUŻ SIĘ ROZPOCZĄŁ
                </div>

                <div className={styles.info2}>
                    <div>{`Zajęcia ${selectedCycle.name} rozpoczęły się.`}</div>
                    <div>
                        Aby zakupić udział w reszcie zajęć z tego cyklu, skontaktuj się z nami.
                    </div>
                </div>

                <div className={styles.contact}>

                    {
                        iconPacket.map((item, index) => (
                            <div className={styles.iconPack}>
                                <div className={styles.icon}>
                                    {item.icon}
                                </div>
                                <div className={styles.link}>
                                    <a href={item.href} className={styles.link}> {item.href.split(":")[1]} </a>
                                </div>
                            </div>
                        ))
                    }

                </div>

                <div className={styles.events}>
                    <div className={styles.header}>
                        Zajęcia w tym cyklu zajęć:
                    </div>

                    {selectedCycle.events?.map((event, index) => (

                        <>
                            <EventCard key={index} event={event} />
                            {index != selectedCycle.events.length - 1 && (
                                <div className={styles.divider} />
                            )}
                        </>

                    ))}

                </div>


            </div>
        </Modal>
    )
}


export default InProgressInstructionModal;