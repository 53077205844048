import React, { useState, useEffect, useRef } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout, selectUser } from "../../redux/slices/userSlice";
import styles from "./home.module.scss";

import { toast } from "react-toastify";
import { fetchNews } from "../../redux/slices/newsSlice";
import { Helmet } from "react-helmet";
import { fetchHomepage, selectHomepage } from "../../redux/slices/utilsSlice";
import Loading from "../../components/universal/Loading";
import VariableSection from "./components/VariableSection";
import WhoAreWe from "./components/WhoAreWe";
import Resources from "./components/resources/Resources";
import RecentNews from "./components/RecentNews";
import Partners from "./components/Partners";
import FoundationNew from "./components/FoundationNew";
import Contact from "./components/Contact";
import FeaturedCycles from "./components/featuredCycles/FeaturedCycles";
import { Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import classNames from "classnames";
import Hero from "./Hero";
import Snowfall from 'react-snowfall'
import stolicawhite from "../../assets/stolica-white.png";
import { getFullPath } from "../../svc/svc";
import VelixSign from "../../components/velixPrompt/VelixSign";

import velix3 from "../../assets/velix/VELIX 1.png";

const description =
  "Stolica eXperymentu to projekt edukacyjny, który ma przede wszystkim podnieść jakość kształcenia młodych ludzi z Gniezna i powiatu gnieźnieńskiego. To narzędzie w tworzeniu szkoły na miarę dzisiejszych, dynamicznie zmieniających się czasów Zakładamy, że  kształcenie w ramach Stolicy eXperymentu będzie STEM (Science, Technology, Engineering i Mathematics). Chcemy w młodych ludziach wykształcić nie tylko fascynację techniką  i technologią ale także wzbudzić chęć poszukiwania nowych rozwiązań, innowacji, czy prowadzenia badań. Tylko w ten sposób jesteśmy w stanie przygotować młodzież do industry 4.0.";

function Home(props) {
  const userData = useSelector(selectUser);
  const history = useHistory();
  const dispatch = useDispatch();

  const contactRef = useRef(null);

  const { config, loading } = useSelector(selectHomepage);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const scrollParam = urlParams.get("scroll");
    console.log(contactRef);
    if (scrollParam === "contact" && contactRef?.current) {
      console.log("scrollin");
      contactRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [window.location.search]);

  useEffect(() => {
    if (config && Object.keys(config).length > 0) return;
    dispatch(fetchHomepage());
  }, []);

  const renderHomepage = () => {
    let { body } = config;
    let DOM_OBJECTS = [];

    for (var i = 0; i < body?.length; i++) {
      let item = body[i];
      switch (item.type) {
        case "variableSection":
          DOM_OBJECTS.push(<VariableSection key={i} data={item} index={i} />);
          break;
        case "whoarewe":
          DOM_OBJECTS.push(<WhoAreWe key={i} data={item} index={i} />);
          break;
        case "resources":
          DOM_OBJECTS.push(<Resources key={i} data={item} index={i} />);
          break;
        case "recentNews":
          DOM_OBJECTS.push(<RecentNews key={i} data={item} index={i} />);
          break;
        case "partners":
          DOM_OBJECTS.push(<Partners key={i} data={item} index={i} />);
          break;
        case "foundation":
          DOM_OBJECTS.push(<FoundationNew key={i} data={item} index={i} />);
          break;
        case "contact":
          DOM_OBJECTS.push(<Contact ref={contactRef} key={i} data={item} />);
          break;
        // case "featuredCycles":
        //   DOM_OBJECTS.push(<FeaturedCycles key={i} data={item} index={i} />)
        //   break

        default:
          break;
      }
    }

    return DOM_OBJECTS;
  };

  const goToOffer = () => {
    history.push("/oferta");
  };


  const isWinter = () => {
    const date = new Date();
    const month = date.getMonth();
    return month === 11 || month === 0 || month === 1;
  };

  return (
    <div className={styles.homeWrapper}>
      <Helmet>
        <title>Stolica eXperymentu</title>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="https://stolicaexperymentu.pl" />
        <link rel="canonical" href="https://stolicaexperymentu.pl" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="pl_PL" />
        <meta property="og:image" content={getFullPath(stolicawhite)} />
        <meta property="og:site_name" content="Stolica eXperymentu" />
      </Helmet>


      <div className={styles.homeVelix}>
        <VelixSign
          textOnClick={goToOffer}
          textClassname={styles.velixText}
          rotate="-2.5deg"
          left={9}
          top={16}
          fontSize="1.8rem"
          textWidth={152}
          text={"Zapisz się na zajęcia!"}
          height={400}
        />
      </div>

      <div className={styles.homeHeader}>
        <Hero></Hero>
      </div>

      <div className={styles.banner}>
        
        <div>
          Sprawdź naszą
        </div>
        <div className={styles.ofsp} onClick={()=>history.push('/oferta-specjalna')}>
          OFERTĘ SPECJALNĄ
        </div>
        {isWinter() ? <Snowfall snowflakeCount={30}/> : null}
        <img src={velix3} className={styles.vel}/>
      
      </div>

      <div className={styles.homeContainer}>
        <div style={{ display: "flow-root" }} className={styles.homeContent}>
          {loading || !config || Object.keys(config).length === 0 ? (
            <Loading />
          ) : (
            renderHomepage()
          )}
        </div>
      </div>
    </div>
  );
}

export default connect()(Home);
