import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import React from "react";
import styles from "./calendarComponents.module.scss";
import PersonIcon from "@mui/icons-material/Person";
import { getContrastTextColor } from "../../svc/svc";
import variables from "../../assets/variables.scss";
import EventBar from "./EventBar";
import classnames from "classnames";

const DATE_FORMAT = "dddd, DD MMMM YYYY HH:mm";

function EventCard(props) {
  const IS_IN_PROGRESS =
    moment() > moment(props.event?.start) &&
    moment() < moment(props.event?.end);

  return (
    <div className={styles.eventCard}>
      <div
        className={styles.holder}
        onClick={() => props.handleClick(props.event)}
      >
        {!props.singleCycle && (
          <div
            style={{
              backgroundColor: props.event.color,
              color: getContrastTextColor(props.event.color),
            }}
            className={styles.productName}
          >
            {props.event.cycle?.product?.name}
          </div>
        )}
        <div className={styles.title}>
          {props.singleCycle ? props.event.title : props.event.cycle.name}
          {!props.singleCycle && (
            <div className={styles.personIcon}>
              <PersonIcon />
              {props.userType === "user"
                ? props.event.participants && props.event.participants.length
                : `${props.event.cycle?.reservationsCount}/${props.event.cycle.capacity}`}
            </div>
          )}
        </div>

        {!props.singleCycle && (
          <div className={styles.eventName}>{props.event.title}</div>
        )}

        <div className={styles.date}>
          {moment(props.event.start).locale("pl").format(DATE_FORMAT)}
        </div>

        {IS_IN_PROGRESS && (
          <EventBar startDate={props.event.start} endDate={props.event.end} />
        )}

        {props.event.teacher && (
          <div
            className={
              props.event.teacher._id === props.userId
                ? classnames(styles.teacherName, styles.youAreTheTeacher)
                : styles.teacherName
            }
          >
            {props.event.teacher?.fullName}
          </div>
        )}
      </div>

      <div
        onClick={() => props.handleChangeDisplay(props.event)}
        className={styles.jumpButton}
      >
        <SearchIcon />
      </div>
    </div>
  );
}

export default EventCard;
