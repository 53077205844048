import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import {
  fetchArchives,
  fetchFeaturedEvents,
  selectArchives,
  selectFeaturedEvents,
} from "../../redux/slices/newsSlice";
import styles from "./news.module.scss";
import stolicax from "../../assets/stolica-x.png";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import classNames from "classnames";
import { useSelector } from "react-redux";
import { getMonthName } from "../../svc/svc";
import { useDispatch } from "react-redux";
import Loading from "../../components/universal/Loading";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useHistory } from "react-router-dom";
import moment from "moment";
const DATE_FORMAT = "DD-MM-YYYY HH:mm";
function SingleEvent(props) {
  const { event } = props;

  return (
    <div className={styles.singleEvent}>
      <p className={styles.eventName}>{event.title}</p>
      <div className={styles.eventInfo}>
        <CalendarMonthIcon className={styles.infoIcon} />
        <p className={styles.infoText}>
          {moment(event.start).locale("pl").format(DATE_FORMAT)}
        </p>
      </div>
    </div>
  );
}

function Group(props) {
  const { cycle } = props;
  const history = useHistory();
  const [expanded, setExpanded] = useState(props.default ? true : false);

  return (
    <div className={styles.section}>
      <div className={styles.headerSection}>
        <div
          style={{ backgroundImage: `url(${cycle.thumbnail})` }}
          className={styles.cycleThumbnail}
          onClick={() => setExpanded(!expanded)}
        ></div>

        <div
          className={styles.cycleTitle}
          onClick={() => setExpanded(!expanded)}
        >
          <p>{cycle.name}</p>
        </div>

        <div className={styles.href}>
          <DoubleArrowIcon
            className={styles.icon}
            onClick={() => history.push("/cycles/" + cycle.slug)}
          />
        </div>
      </div>

      <div
        className={
          expanded
            ? classNames(styles.eventsList, styles.expanded)
            : styles.eventsList
        }
      >
        {cycle.events.slice(0, 3).map((el) => (
          <SingleEvent key={el._id} event={el} />
        ))}
      </div>
    </div>
  );
}

function Events(props) {
  const { cycles, loading } = useSelector(selectFeaturedEvents);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!cycles || cycles?.length === 0) dispatch(fetchFeaturedEvents(5));
  }, []);

  return (
    <>
      <div className={styles.block}>
        <div className={styles.header}>
          <p>Oferta</p>
        </div>

        <div className={styles.content}>
          {loading ? (
            <Loading />
          ) : (
            cycles.map((cycle, idx) => (
              <Group default={idx === 0} key={cycle._id} cycle={cycle} />
            ))
          )}
        </div>
      </div>
    </>
  );
}

export default connect()(Events);
