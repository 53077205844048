import { Button } from "@mui/material";
import styles from "./BuyCycleFlow.module.scss";
import { useHistory } from "react-router-dom";
import classnames from "classnames";

function LoginCard(props) {

    const history = useHistory();

    const handleLogin = () => {
        history.push('/login')
    }

    return (
        <div className={styles.cycleCardWrapper}>

            <div className={classnames(styles.cycleCard, styles.cycleCardRound)}>

                    <div className={styles.loginCardContent}>
                        <div className={styles.prompt}>
                            Aby wykupić udział w zajęciach
                        </div>
                        <div className={styles.button}>
                                <Button variant="outlined" color="primary" onClick={handleLogin}>
                                    Zaloguj się
                                </Button>
                        </div>
                    </div>

            </div>

        </div>
    )
}


export default LoginCard;