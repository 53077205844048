import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import API from "../../API/APIService";

import { history } from "../../index.jsx";
import { constructQuery } from "../../svc/svc";
import { emmitError, emmitSuccess } from "../../utils/ToastEmmiter";
import { el } from "date-fns/locale";
import moment from "moment";

export const fetchWorkers = createAsyncThunk(
  "events/fetchWorkers",
  async (obj, { rejectWithValue }) => {
    let response = null;
    await API.get(`/events/getWorkers`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const addCycle = createAsyncThunk(
  "events/addCycle",
  async (obj, { rejectWithValue }) => {
    let response = null;

    await API.post(`/events/addCycle`, obj)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const addProduct = createAsyncThunk(
  "events/addProduct",
  async (obj, { rejectWithValue }) => {
    let response = null;

    await API.post(`/events/addProduct`, obj)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const editCycle = createAsyncThunk(
  "events/editCycle",
  async (obj, { rejectWithValue }) => {
    let response = null;

    await API.patch(`/events/cycles`, obj)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const editProduct = createAsyncThunk(
  "events/editProduct",
  async (obj, { rejectWithValue }) => {
    let response = null;
    await API.patch(`/events/products`, obj)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const deleteCycle = createAsyncThunk(
  "events/deleteCycle",
  async (id, { rejectWithValue }) => {
    let response = null;
    await API.delete(`/events/cycles?id=` + id)
      .then((r) => {
        response = {
          data: r.data,
        };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const deleteProduct = createAsyncThunk(
  "events/deleteProduct",
  async (id, { rejectWithValue }) => {
    let response = null;
    await API.delete(`/events/products?id=` + id)
      .then((r) => {
        response = {
          data: r.data,
        };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const fetchEvents = createAsyncThunk(
  "events/fetchEvents",
  async (obj, { rejectWithValue }) => {
    var response = null;
    const { cycleId, month, year } = obj;

    let query = "";
    if (month && year) {
      query = query + "month=" + month + "&year=" + year;
    }

    await API.get(`/events/fetchEvents?${query}`)
      .then((r) => {
        response = {
          data: r.data,
        };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    var events = response.data;

    if (cycleId) {
      for (var i = 0; i < events.length; i++) {
        var event = events[i];

        if (event.cycle._id === cycleId || event.cycle.slug === cycleId) {
          events[i].editable = true;
          events[i].durationEditable = true;
          events[i].resourceEditable = true;
        }
      }
    }

    //await new Promise((r) => setTimeout(r, 3000));

    return events;
  }
);

export const setPage = createAsyncThunk(
  "cycles/setPage",
  async (obj, { rejectWithValue }) => {
    const query = constructQuery(obj);

    let response = null;
    await API.get(`/events/cycles?${query}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const setMyCyclesPage = createAsyncThunk(
  "cycles/setMyCyclesPage",
  async (obj, { rejectWithValue }) => {
    let response = null;
    const { page } = obj;
    await API.get(`/events/myCycles?page=${page}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const setPageForNonConfirmed = createAsyncThunk(
  "cycles/setPageForNonConfirmed",
  async (obj, { rejectWithValue }) => {
    let response = null;
    const { page } = obj;
    await API.get(
      `/events/cycles?page=${page}&confirmed=false&date_from=${moment()
        .subtract(1, "months")
        .format("YYYY-MM-DD")}&date_to=${moment()
        .add(1, "months")
        .format("YYYY-MM-DD")}`
    )
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const setPageForCycleReservations = createAsyncThunk(
  "cycles/setPageForCycleReservations",
  async ({ id, page }, { rejectWithValue }) => {
    let response = null;
    await API.get(`/events/cycleReservations?page=${page}&id=${id}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const setPageForProducts = createAsyncThunk(
  "cycles/setPageForProducts",
  async (obj, { rejectWithValue }) => {
    let response = null;
    const { page } = obj;
    await API.get(`/events/products?page=${page}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
  
    return response.data;
  }
);

export const getSingleCycle = createAsyncThunk(
  "cycles/getSingleCycle",
  async (id, { rejectWithValue }) => {
    let response = null;
    await API.get(`/events/cycles?id=${id}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const getSingleProduct = createAsyncThunk(
  "cycles/getSingleProduct",
  async (id, { rejectWithValue }) => {
    let response = null;
    await API.get(`/events/products?id=${id}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const getMyEvents = createAsyncThunk(
  "cycles/getMyEvents",
  async (id, { rejectWithValue }) => {
    let response = null;
    await API.get(`/events/myEvents`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const getEventsToEvaluate = createAsyncThunk(
  "cycles/getEventsToEvaluate",
  async (id, { rejectWithValue }) => {
    let response = null;
    await API.get(`/feedback/eventsToEvaluate`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const sendMSGtoCycleAttendees = createAsyncThunk(
  "cycles/msgCycleAttendees",
  async (data, { rejectWithValue }) => {
    let response = null;
    await API.post("/communication/msgCycleAttendees", data)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const evaluateEvent = createAsyncThunk(
  "events/evaluateEvent",
  async (data, { rejectWithValue }) => {
    let response = null;
    await API.post("/feedback/evaluateEvent", data)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const fetchSingleEvent = createAsyncThunk(
  "events/singleEvent",
  async (id, { rejectWithValue }) => {
    let response = null;

    await API.get(`/events/singleEvent?id=${id}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const markEventAttendance = createAsyncThunk(
  "events/markEventAttendance",
  async (obj, { rejectWithValue }) => {
    let response = null;

    await API.post(`/events/markEventAttendance`, obj)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return { response: response.data, request: obj };
  }
);

export const getAvailableProducts = createAsyncThunk(
  "events/getAvailableProducts",
  async (obj, { rejectWithValue }) => {
    let response = null;
    await API.get(`/events/availableProducts`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const getAvailableCycles = createAsyncThunk(
  "events/getAvailableCycles",
  async (id, { rejectWithValue }) => {
    let response = null;
    await API.get(`/events/availableCycles?product=${id}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const printParticipantsList = createAsyncThunk(
  "events/printParticipantsList",
  async (id, { rejectWithValue, getState }) => {
    let response = null;

    await API.get(`/events/print/cycleParticipants?id=${id}`)
      .then((r) => {
        const blob = new Blob([r.data], { type: "text/html" });
        const url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "lista uczestników";
        a.click();
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response;
  }
);

export const printEventAttendance = createAsyncThunk(
  "events/printEventAttendance",
  async (id, { rejectWithValue, getState }) => {
    let response = null;

    await API.get(`/events/print/eventAttendance?id=${id}`)
      .then((r) => {
        console.log(r.data)
        let minif = r.data
        const blob = new Blob([minif], { type: "text/html" });
        const url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "lista obecności";
        a.click();
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response;
  }
);

export const eventsSlice = createSlice({
  name: "events",
  initialState: {
    teachers: [],
    events: [],
    filterVisibility: false,
    eventToEditData: {
      event: {},
      modal: false,
    },
    eventsLoading: [],
    addCycleLoading: false,
    addProductLoading: false,
    workersLoading: false,
    myEvents: [], /// do panelu czy gdzies
    myEventsDup: [],
    myEventsLoading: false,
    eventsToEvaluate: [],
    eventsToEvaluateLoading: false,
    cycles: [],

    singleCycle: {},
    singleEvent: {},
    singleProduct: {},
    singleEventLoading: false,
    singleCycleLoading: false,
    singleProductLoading: false,
    singleCycleEventToShow: null,
    msgCycleAttendeesLoading: false,
    evaluateEventLoading: false,
    editCycleLoading: false,
    editProductLoading: false,
    deleteProductLoading: false,
    deleteCycleLoading: false,
    fetchNotConfirmedCyclesLoading: false,

    // attendance
    markEventAttendanceLoading: false,
    ///paginacja
    fetchCyclesLoading: false,
    fetchProductsLoading: false,
    ////
    cycleReservations: [],
    cycleReservationsLoading: false,
    cycleReservationsPage: 1,
    cycleReservationsTotalPages: 0,
    cycleReservationsTotalDocs: 0,

    myCycles: [],
    myCyclesPage: 1,
    myCyclesTotalPages: 0,
    myCyclesLoading: false,

    page: 1,
    totalPages: 0,
    loadingCyclePages: false,

    products: [],
    productsPage: 1,
    productsTotalPages: 0,
    loadingProductsPages: false,

    availableProducts: [],
    availableProductsLoading: false,
    availableCycles: [],
    availableCyclesLoading: false,
    selectedProduct: null,

    fastAddReservationModal: false,
    fastAddReservationCycle: null,

    printLoading: false,
  },
  reducers: {
    setFastAddReservationModal: (state, action) => {
      state.fastAddReservationModal = action.payload.modal;
      state.fastAddReservationCycle = action.payload.cycle ? action.payload.cycle : null;
    },
    setSingleCycle: (state, action) => {
      state.singleCycle = action.payload;
    },
    setSingleProduct: (state, action) => {
      state.singleProduct = action.payload;
    },

    setEventToEditData: (state, action) => {
      state.eventToEditData = {
        event: action.payload.event,
        modal: action.payload.modal,
      };
    },
    setTicketCheckerSuccessID: (state, action) => {
      state.singleEvent.ticketCheckerSuccessID = action.payload;
    },
    setSingleCycleEventToShow: (state, action) => {
      state.singleCycleEventToShow = action.payload;
    },
    removeCycleById: (state, action) => {
      state.cycles = state.cycles.filter(
        (cycle) => cycle._id !== action.payload
      );
    },
    toggleFilterVisibility: (state, action) => {
      const userId = action.payload;
      let actual = state.filterVisibility;
      state.filterVisibility = !state.filterVisibility;
      if (actual) {
        // jesli filtr jest wlaczony
        state.myEvents = state.myEventsDup;
        return;
      }

      state.myEventsLoading = true;

      let newEvents = state.myEvents.filter((ell) => {
        let event = current(ell);
        return (
          event.teacher === userId ||
          event.teacher?._id === userId ||
          event.additionalTeachers?.includes(userId) ||
          event.additionalTeachers?.map((el) => el._id)?.includes(userId)
        );
      });

      state.myEvents = newEvents;

      state.myEventsLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addCycle.fulfilled, (state, action) => {
      emmitSuccess("Pomyślnie stworzono cykl");
      state.addCycleLoading = false;
    });

    builder.addCase(addCycle.pending, (state, action) => {
      state.addCycleLoading = true;
    });

    builder.addCase(addCycle.rejected, (state, action) => {
      state.addCycleLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(addProduct.fulfilled, (state, action) => {
      emmitSuccess("Pomyślnie stworzono produkt");
      state.addProductLoading = false;
    });

    builder.addCase(addProduct.pending, (state, action) => {
      state.addProductLoading = true;
    });

    builder.addCase(addProduct.rejected, (state, action) => {
      state.addProductLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(fetchWorkers.fulfilled, (state, action) => {
      state.teachers = action.payload;
      state.workersLoading = false;
    });

    builder.addCase(fetchWorkers.pending, (state, action) => {
      state.workersLoading = true;
      //state.resources = {};
    });

    builder.addCase(fetchWorkers.rejected, (state, action) => {
      state.workersLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      state.events = action.payload;
      state.eventsLoading = false;
    });

    builder.addCase(fetchEvents.pending, (state, action) => {
      state.eventsLoading = true;
      //state.resources = {};
    });

    builder.addCase(fetchEvents.rejected, (state, action) => {
      state.eventsLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(setPage.fulfilled, (state, action) => {
      const searchParams = new URLSearchParams(history.location.search);
      searchParams.set("page", action.payload.page);
      history.replace({ search: searchParams.toString() });

      state.cycles = action.payload.docs;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.fetchCyclesLoading = false;
    });

    builder.addCase(setPage.pending, (state, action) => {
      state.fetchCyclesLoading = true;
    });

    builder.addCase(setPage.rejected, (state, action) => {
      emmitError(action.payload.data.error);
      state.fetchCyclesLoading = false;
    });

    builder.addCase(setMyCyclesPage.fulfilled, (state, action) => {
      state.myCycles = action.payload.cycles;
      state.myCyclesPage = action.payload.page;
      state.myCyclesTotalPages = action.payload.totalPages;
      state.myCyclesLoading = false;
    });

    builder.addCase(setMyCyclesPage.pending, (state, action) => {
      state.fetchCyclesLoading = true;
    });

    builder.addCase(setMyCyclesPage.rejected, (state, action) => {
      emmitError(action.payload.data.error);
      state.fetchCyclesLoading = false;
    });

    builder.addCase(setPageForNonConfirmed.fulfilled, (state, action) => {
      state.cycles = action.payload.docs;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.fetchNotConfirmedCyclesLoading = false;
    });

    builder.addCase(setPageForNonConfirmed.pending, (state, action) => {
      state.fetchNotConfirmedCyclesLoading = true;
    });

    builder.addCase(setPageForNonConfirmed.rejected, (state, action) => {
      emmitError(action.payload.data.error);
      state.fetchNotConfirmedCyclesLoading = false;
    });

    builder.addCase(setPageForProducts.fulfilled, (state, action) => {
      state.products = action.payload.docs;
      state.productsPage = action.payload.page;
      state.productsTotalPages = action.payload.totalPages;
      state.loadingProductsPages = false;
    });

    builder.addCase(setPageForProducts.pending, (state, action) => {
      state.loadingProductsPages = true;
    });

    builder.addCase(setPageForProducts.rejected, (state, action) => {
      emmitError(action.payload.data.error);
      state.loadingProductsPages = false;
    });

    builder.addCase(getSingleCycle.fulfilled, (state, action) => {
      state.singleCycle = action.payload;
      state.singleCycleLoading = false;
    });

    builder.addCase(getSingleCycle.pending, (state, action) => {
      state.singleCycleLoading = true;
      //state.resources = {};
    });

    builder.addCase(getSingleCycle.rejected, (state, action) => {
      state.singleCycleLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(editCycle.fulfilled, (state, action) => {
      state.editCycleLoading = false;
      emmitSuccess("Pomyślnie edytowano cykl");
    });

    builder.addCase(editCycle.pending, (state, action) => {
      state.editCycleLoading = true;
      //state.resources = {};
    });

    builder.addCase(editCycle.rejected, (state, action) => {
      state.editCycleLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(deleteCycle.fulfilled, (state, action) => {
      state.deleteCycleLoading = false;
      history.push("/cycles");
      emmitSuccess("Pomyślnie usunięto cykl");
    });

    builder.addCase(deleteCycle.pending, (state, action) => {
      state.deleteCycleLoading = true;
      //state.resources = {};
    });

    builder.addCase(deleteCycle.rejected, (state, action) => {
      state.deleteCycleLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(getSingleProduct.fulfilled, (state, action) => {
      state.singleProduct = action.payload;
      state.singleProductLoading = false;
    });

    builder.addCase(getSingleProduct.pending, (state, action) => {
      state.singleProductLoading = true;
      //state.resources = {};
    });

    builder.addCase(getSingleProduct.rejected, (state, action) => {
      state.singleProductLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(editProduct.fulfilled, (state, action) => {
      state.editProductLoading = false;
      emmitSuccess("Pomyślnie edytowano produkt");
    });

    builder.addCase(editProduct.pending, (state, action) => {
      state.editProductLoading = true;
      //state.resources = {};
    });

    builder.addCase(editProduct.rejected, (state, action) => {
      state.editProductLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(deleteProduct.fulfilled, (state, action) => {
      state.deleteProductLoading = false;
      history.push("/cycles");
      emmitSuccess("Pomyślnie usunięto produkt");
    });

    builder.addCase(deleteProduct.pending, (state, action) => {
      state.deleteProductLoading = true;
      //state.resources = {};
    });

    builder.addCase(deleteProduct.rejected, (state, action) => {
      state.deleteProductLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(getMyEvents.fulfilled, (state, action) => {
      state.myEvents = action.payload;
      state.myEventsDup = action.payload;
      state.myEventsLoading = false;
    });

    builder.addCase(getMyEvents.pending, (state, action) => {
      state.myEventsLoading = true;
      //state.resources = {};
    });

    builder.addCase(getMyEvents.rejected, (state, action) => {
      state.myEventsLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(getEventsToEvaluate.fulfilled, (state, action) => {
      state.eventsToEvaluate = action.payload;
      state.eventsToEvaluateLoading = false;
    });

    builder.addCase(getEventsToEvaluate.pending, (state, action) => {
      state.eventsToEvaluateLoading = true;
    });

    builder.addCase(getEventsToEvaluate.rejected, (state, action) => {
      state.eventsToEvaluateLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(sendMSGtoCycleAttendees.fulfilled, (state, action) => {
      state.msgCycleAttendeesLoading = false;
      emmitSuccess("Pomyślnie wysłano wiadomość do uczestników");
    });

    builder.addCase(sendMSGtoCycleAttendees.pending, (state, action) => {
      state.msgCycleAttendeesLoading = true;
    });

    builder.addCase(sendMSGtoCycleAttendees.rejected, (state, action) => {
      state.msgCycleAttendeesLoading = false;

      emmitError(action.payload.data.error);
    });

    builder.addCase(evaluateEvent.fulfilled, (state, action) => {
      state.evaluateEventLoading = false;
      console.log(action.payload);
      const indexToSplice = state.eventsToEvaluate.findIndex(
        (e) => e._id === action.payload.event
      );
      state.eventsToEvaluate.splice(indexToSplice, 1);

      if (state.myEvents.length > 0) {
        let found = state.myEvents.findIndex(
          (e) => e._id === action.payload.event
        );
        console.log(found);

        if (found >= 0) state.myEvents[found].feedbacks.push(action.payload);
      }

      emmitSuccess("Pomyślnie oceniono wydarzenie");
    });

    builder.addCase(evaluateEvent.pending, (state, action) => {
      state.evaluateEventLoading = true;
    });

    builder.addCase(evaluateEvent.rejected, (state, action) => {
      state.evaluateEventLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(setPageForCycleReservations.fulfilled, (state, action) => {
      state.cycleReservationsLoading = false;

      state.cycleReservations = action.payload.docs;
      state.cycleReservationsPage = action.payload.page;
      state.cycleReservationsTotalPages = action.payload.totalPages;
      state.cycleReservationsTotalDocs = action.payload.totalDocs;
    });

    builder.addCase(setPageForCycleReservations.rejected, (state, action) => {
      state.cycleReservationsLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(setPageForCycleReservations.pending, (state, action) => {
      state.cycleReservationsLoading = true;
    });

    builder.addCase(fetchSingleEvent.pending, (state, action) => {
      state.singleEventLoading = true;
    });

    builder.addCase(fetchSingleEvent.fulfilled, (state, action) => {
      state.singleEventLoading = false;
      state.singleEvent = {
        ...action.payload,
        ticketCheckerSuccessID: state.singleEvent?.ticketCheckerSuccessID,
      };
    });

    builder.addCase(fetchSingleEvent.rejected, (state, action) => {
      state.singleEventLoading = false;
      emmitError(action.payload.data.error);
    });
    builder.addCase(markEventAttendance.pending, (state, action) => {
      state.markEventAttendanceLoading = true;
    });

    builder.addCase(markEventAttendance.fulfilled, (state, action) => {
      state.markEventAttendanceLoading = false;
      const request = action.payload.request;
      const response = action.payload.response;
      if (request.singleEvent) state.singleEvent.attendance = response;
      else
        state.myEvents.find((e) => e._id == request.eventID).attendance =
          response;

      emmitSuccess("Pomyślnie zapisano obecność");
    });

    builder.addCase(markEventAttendance.rejected, (state, action) => {
      state.markEventAttendanceLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(getAvailableProducts.pending, (state, action) => {
      state.availableProductsLoading = true;
    });

    builder.addCase(getAvailableProducts.fulfilled, (state, action) => {
      state.availableProductsLoading = false;
      state.availableProducts = action.payload;
    });

    builder.addCase(getAvailableProducts.rejected, (state, action) => {
      state.availableProductsLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(getAvailableCycles.pending, (state, action) => {
      state.availableCyclesLoading = true;
    });

    builder.addCase(getAvailableCycles.fulfilled, (state, action) => {
      state.availableCyclesLoading = false;
      state.availableCycles = action.payload?.cycles;
      state.selectedProduct = action.payload?.product;
    });

    builder.addCase(getAvailableCycles.rejected, (state, action) => {
      state.availableCyclesLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(printParticipantsList.pending, (state, action) => {
      state.printLoading = true;
    });

    builder.addCase(printParticipantsList.fulfilled, (state, action) => {
      state.printLoading = false;
    });

    builder.addCase(printParticipantsList.rejected, (state, action) => {
      state.printLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(printEventAttendance.pending, (state, action) => {
      state.printLoading = true;
    });

    builder.addCase(printEventAttendance.fulfilled, (state, action) => {
      state.printLoading = false;
    });

    builder.addCase(printEventAttendance.rejected, (state, action) => {
      state.printLoading = false;
      emmitError(action.payload.data.error);
    });

    
  },
});

const { actions, reducer } = eventsSlice;

// Action creators are generated for each case reducer function
export const {
  setSingleCycle,
  setEventToEditData,
  checkAndSelectEventToEditData,
  setTicketCheckerSuccessID,
  setSingleCycleEventToShow,
  removeCycleById,
  toggleFilterVisibility,
  setFastAddReservationModal
} = actions;

export const selectFastAddReservationModal = (state) => {
  return {
    modal: state.events.fastAddReservationModal,
    cycle: state.events.fastAddReservationCycle
  };
}

export const selectPrintLoading = (state) => {
  return state.events.printLoading;
}

export const selectTeachers = (state) => {
  return state.events.teachers;
};

export const isAnyTeachers = (state) => {
  return state.events.teachers.length != 0;
};

export const isTeachersLoading = (state) => {
  return state.events.workersLoading;
};

export const selectEvents = (state) => {
  return {
    events: state.events.events,
    eventsLoading: state.events.eventsLoading,
  };
};

export const selectEventsToEvaluate = (state) => {
  return {
    eventsToEvaluate: state.events.eventsToEvaluate,
    eventsLoading: state.events.eventsToEvaluateLoading,
  };
};

export const selectEvaluateEventLoading = (state) => {
  return state.events.evaluateEventLoading;
};

export const selectEventToEvaluateByID = (state, id) => {
  return !id ? null : state.events.eventsToEvaluate.find((e) => e._id == id);
};

export const selectCycles = (state) => {
  return state.events.cycles;
};

export const selectSingleCycle = (state) => {
  return {
    cycle: state.events.singleCycle,
    loading: state.events.singleCycleLoading,
    eventToShow: state.events.singleCycleEventToShow,
  };
};

export const selectProducts = (state) => {
  return state.events.products;
};

export const selectSingleProduct = (state) => {
  return {
    product: state.events.singleProduct,
    loading: state.events.singleProductLoading,
  };
};

export const selectSingleCycleLateEvents = (state) => {
  const lateEvents = [];
  const DATE_NOW = new Date(Date.now());
  state.events.singleCycle.events.map((event) => {
    if (new Date(event.end) < DATE_NOW) lateEvents.push(event);
  });

  return lateEvents;
};

export const selectSingleCycleUpcomingEvents = (state, id) => {
  const upcomingEvents = [];
  const DATE_NOW = new Date(Date.now());
  const cycle = state.events.cycles.find((e) => e._id == id);
  cycle.events.map((event) => {
    if (new Date(event.end) > DATE_NOW) upcomingEvents.push(event);
  });

  return upcomingEvents;
};

//for teacher
export const selectSingleCycleOWNLateEvents = (state, teacherID) => {
  const lateEvents = [];
  const DATE_NOW = new Date(Date.now());

  state.events.singleCycle.events.map((event) => {
    if (new Date(event.end) < DATE_NOW && event.teacher._id === teacherID)
      lateEvents.push(event);
  });

  return lateEvents;
};

export const selectMyEvents = (state) => {
  var now = new Date();

  let inprogressEvents = state.events.myEvents.filter(
    (el) => new Date(el.start) <= now && new Date(el.end) >= now
  );

  let upcomingEvents = state.events.myEvents.filter(
    (el) => new Date(el.start) > now
  );
  upcomingEvents = upcomingEvents.sort(
    (a, b) => new Date(a.start) - new Date(b.start)
  );

  return {
    upcomingEvents: upcomingEvents,
    inprogressEvents: inprogressEvents,
    events: state.events.myEvents,
    loading: state.events.myEventsLoading,
    filterVisibility: state.events.filterVisibility,
  };
};

export const selectMyEventByID = (state, id) => {
  return !id ? null : state.events.myEvents.find((e) => e._id == id);
};

//from MyEvents
export const selectMyCycles = (state) => {
  let events = state.events.myEvents;
  const cycles = [];
  const cycleIDs = [];
  events.forEach((event) => {
    let cycle = event.cycle;
    if (cycleIDs.indexOf(cycle._id) === -1) {
      cycles.push(cycle);
      cycleIDs.push(cycle._id);
    }
  });

  return cycles;
};

export const selectMsgCycleAttendeesLoading = (state) => {
  return state.events.msgCycleAttendeesLoading;
};

export const selectPages = (state) => {
  return {
    page: state.events.page,
    pageCount: state.events.totalPages,
  };
};

export const selectMyCyclesPages = (state) => {
  return {
    page: state.events.myCyclesPage,
    pageCount: state.events.myCyclesTotalPages,
  };
};

export const selectProductsPages = (state) => {
  return {
    page: state.events.productsPage,
    pageCount: state.events.productsTotalPages,
  };
};

export const selectProductsLoading = (state) => {
  return state.events.loadingProductsPages;
};

export const selectMyCyclesLoading = (state) => {
  return state.events.myCyclesLoading;
};

export const selectMyCyclesData = (state) => {
  return state.events.myCycles;
};

export const selectFetchNonConfirmedCyclesLoading = (state) => {
  return state.events.fetchNotConfirmedCyclesLoading;
};

export const selectFetchCyclesLoading = (state) => {
  return state.events.fetchCyclesLoading;
};

export const selectCycleReservationsData = (state) => {
  return state.events.cycleReservations;
};
export const selectCycleReservationsLoading = (state) => {
  return state.events.cycleReservationsLoading;
};
export const selectCycleReservationsPage = (state) => {
  return {
    page: state.events.cycleReservationsPage,
    pageCount: state.events.cycleReservationsTotalPages,
  };
};
export const selectCycleReservationsTotalDocs = (state) => {
  return state.events.cycleReservationsTotalDocs;
};

export const selectAddCycleLoading = (state) => {
  return state.events.addCycleLoading;
};

export const selectEditCycleLoading = (state) => {
  return state.events.editCycleLoading;
};

export const selectDeleteCycleLoading = (state) => {
  return state.events.deleteCycleLoading;
};

//now for products

export const selectAddProductLoading = (state) => {
  return state.events.addProductLoading;
};

export const selectEditProductLoading = (state) => {
  return state.events.editProductLoading;
};

export const selectDeleteProductLoading = (state) => {
  return state.events.deleteProductLoading;
};

export const selectSingleEvent = (state) => {
  return {
    event: state.events.singleEvent,
    loading: state.events.singleEventLoading,
    mark_loading: state.events.markEventAttendanceLoading,
  };
};

export const selectEventToEditData = (state) => {
  return state.events.eventToEditData;
};

export const selectTicketCheckerFuncID = (state) =>
  state.events.singleEvent.ticketCheckerSuccessID;

export const selectAvailableProducts = (state) => {
  return {
    products: state.events.availableProducts,
    loading: state.events.availableProductsLoading,
  };
};

export const selectAvailableCycles = (state) => {
  return {
    cycles: state.events.availableCycles,
    loading: state.events.availableCyclesLoading,
    product: state.events.selectedProduct,
  };
};

export default reducer;
