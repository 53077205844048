import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import styles from "./messages.module.scss";

import { motion, useAnimationControls } from "framer-motion";

import { ArrowBack } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useWindowWidth } from "@react-hook/window-size";
import moment from "moment";
import { Helmet } from "react-helmet";
import EdjsDisplay from "../../components/edjsDisplay/EdjsDisplay";
import InfiniteScroller from "../../components/infiniteScroller/InfiniteScroller";
import Empty from "../../components/universal/Empty";
import {
  fetchSingleMessage,
  loadMessages,
  markSeen,
  selectMessages,
  selectSingleMessage,
  setSingleMessage,
} from "../../redux/slices/messagesSlice";
import { selectType } from "../../redux/slices/userSlice";
import MessageCard from "./MessageCard";

import Loading from "../../components/universal/Loading";

const variants = {
  hidden: { x: "-100%", transition: { ease: "linear" } },
  visible: { x: "0%", transition: { ease: "linear" } },
};
const backgroundVariants = {
  hidden: { opacity: 0, zIndex: 1 },
  visible: { opacity: 1, zIndex: 3 },
};

const MOBILE_BREAKPOINT = 800;

function Messages(props) {
  const dispatch = useDispatch();
  const width = useWindowWidth();
  const { singleLoading, single } = useSelector(selectSingleMessage);
  const message = single;
  const loading = singleLoading;

  const user = useSelector(selectType);
  const controls = useAnimationControls();
  const backgroundControls = useAnimationControls();

  const handleSetSingleMessage = (el) => {
    dispatch(setSingleMessage(el));
    if (user.type === "user" && !el.seen) {
      dispatch(markSeen(el._id));
    }
    props.history.replace("/messages/" + el._id);

    closeSidebar();
  };

  const openSidebar = () => {
    if (width <= MOBILE_BREAKPOINT) {
      controls.start("visible");
      backgroundControls.start("visible");
    }
  };

  const closeSidebar = () => {
    if (width <= MOBILE_BREAKPOINT) {
      controls.start("hidden");
      backgroundControls.start("hidden");
    }
  };

  useEffect(() => {
    async function fetchSingleIfExsists() {
      if (props.match.params.id) {
        const result = await dispatch(
          fetchSingleMessage(props.match.params.id)
        );
        if (result.meta.requestStatus === "fulfilled") {
          closeSidebar();
        }
      }
    }
    fetchSingleIfExsists();
  }, []);

  useEffect(() => {
    if (width > MOBILE_BREAKPOINT) controls.start("visible");
    if (width <= MOBILE_BREAKPOINT) openSidebar();
  }, [width]);

  return (
    <div className={styles.messagesWrapper}>
      <Helmet>
        <title>Wiadomości - Stolica eXperymentu</title>
      </Helmet>

      <motion.div
        animate={controls}
        variants={variants}
        className={styles.messagesList}
      >
        <InfiniteScroller
          dataSelector={selectMessages}
          loadMoreItems={loadMessages}
          setSingle={handleSetSingleMessage}
          selectedSingle={selectSingleMessage}
          itemsPerLoad={15}
          ItemCard={MessageCard}
          emptyText="Brak wiadomości"
          emptyHint="Tutaj pojawią się wiadomości zaadresowane do Ciebie"
          Loading={Loading}
        />
      </motion.div>

      <motion.div
        variants={backgroundVariants}
        animate={backgroundControls}
        className={styles.backgroundDarkener}
        onClick={closeSidebar}
      ></motion.div>

      <div className={styles.messagesPreview}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <LoadingButton
              variant="outlined"
              startIcon={<ArrowBack className={styles.goBackIcon} />}
              className={styles.goBack}
              onClick={openSidebar}
            >
              <p className={styles.goBackText}>Odebrane</p>
            </LoadingButton>
            {Object.keys(message).length === 0 ? (
              <Empty
                hint={"Wybierz wiadomość z listy"}
                text="Nie wybrano wiadomości"
              />
            ) : (
              <>
                <div className={styles.messageContent}>
                  <div className={styles.subject}>
                    <div className={styles.text}>{message?.subject}</div>
                  </div>
                  <div className={styles.metaData}>
                    <div className={styles.metaSection}>
                      <p className={styles.text}>
                        Nadawca:
                        <a
                          className={styles.mailHref}
                          alt={message?.senderMail}
                          href={`mailto:${message?.senderMail}`}
                        >
                          {message.sender
                            ? ` ${message.sender.fullName} (${message.senderMail})`
                            : ` ${message.senderMail}`}
                        </a>
                      </p>

                      <p className={styles.text}>
                        Odbiorca:
                        <a
                          className={styles.mailHref}
                          alt={message?.recipientMail}
                          href={`mailto:${message?.recipientMail}`}
                        >
                          {message.recipient
                            ? ` ${message.recipient.fullName} (${message.recipientMail})`
                            : ` ${message.recipientMail}`}
                        </a>
                      </p>
                    </div>

                    <div className={styles.metaSection}>
                      <p className={styles.textDate}>
                        Wysłano:{" "}
                        {moment(message.sentAt)
                          .locale("pl")
                          .format("DD-MM-YYYY, HH:mm")}
                      </p>

                      {message.seenAt && (
                        <p className={styles.textDate}>
                          Przeczytano:{" "}
                          {moment(message.seenAt)
                            .locale("pl")
                            .format("DD-MM-YYYY, HH:mm")}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className={styles.content}>
                    <EdjsDisplay data={message.content} />
                  </div>
                </div>{" "}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default connect()(Messages);
