import classNames from "classnames";
import React from "react";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import styles from "./CustomMultiMail.module.scss";

function CustomMultiMail(props) {
  return (
    <div
      className={
        props.isPadding
          ? classNames(
              styles.emailInputWrapper,
              styles.emailInputWrapperPadding
            )
          : classNames(styles.emailInputWrapper)
      }
    >
      <ReactMultiEmail
        className={styles.emailInput}
        validateEmail={(email) => {
          return props.customValidator
            ? props.customValidator(email)
            : isEmail(email); // return boolean
        }}
        getLabel={(email, index, removeEmail) => {
          return (
            <div className={styles.mailTag} data-tag key={index}>
              {email}
              <span
                className={styles.handle}
                data-tag-handle
                onClick={() => removeEmail(index)}
              >
                ×
              </span>
            </div>
          );
        }}
        {...props}
      />
    </div>
  );
}

export default CustomMultiMail;
