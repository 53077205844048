import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import AttendeeCard from "./AttendeeCard";
import { CircularProgress } from "@mui/material";
import styles from "./Attendance.module.scss";
import Loading from "../../../components/universal/Loading";
import Empty from "../../../components/universal/Empty";
import {
  markEventAttendance,
  selectMyEventByID,
  selectSingleEvent,
  selectTicketCheckerFuncID,
  setTicketCheckerSuccessFunction,
} from "../../../redux/slices/eventsSlice";
import DoneIcon from "@mui/icons-material/Done";
import { useHistory } from "react-router-dom"

function Attendance(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  let { loading, mark_loading, event } = useSelector(selectSingleEvent);
  const eFromMyEvents = useSelector(state => selectMyEventByID(state, props.myEventID));
  if (eFromMyEvents) event = eFromMyEvents;

  const [attendanceSet, setAttendanceSet] = useState({});
  const [attendanceData, setAttendanceData] = useState({});
  const [changesDataSet, setChangesDataSet] = useState({});
  //console.log("EVENT IN  ATTENDANCE", event);
  useEffect(() => {
    if (event?.attendance?.length >= 0) {
      let temp = {};

      event.attendance.forEach((a) => {
        temp[a.reservation._id] = {
          status: a.status,
          timestamp: a.timestamp,
          checkedBy: a.checkedBy,
        };
      });
      setAttendanceSet(temp);
    }

    console.log("ATTENDANCE SET", attendanceSet);
  }, [event.attendance]);

  //za każdą zmianą pochodzącą z reduxa trzeba zaktualizować forhendową reprezentację setu obecności
  //jesli typek juz pochodzi z attendance to nie zostanie doliczony do puli zmian - po zmianie jeego stanu jednak
  //juz normalnie bedzie uznawany za change i bedzie sie go dalo przeslac
  useEffect(() => {
    if (event?.attendees?.length >= 0) {
      let temp = {};
      let temp_changes = {};
      event.attendees.map((attendee) => {
        const { _id, fullName } = attendee;
        console.log(attendee)
        const isInAttendanceSet = attendanceSet.hasOwnProperty(_id);
        if (!isInAttendanceSet) temp_changes[_id] = false;
        console.log(attendee)
        temp[_id] = {
          userID: _id,
          originalUserId: attendee.user?._id,
          fullName: attendee.participant ? attendee.participant.fullName : attendee.user.fullName,
          status: isInAttendanceSet ? attendanceSet[_id].status : false,
        };

        if (isInAttendanceSet) {
          temp[_id].timestamp = attendanceSet[_id].timestamp;
          temp[_id].checkedBy = attendanceSet[_id].checkedBy;
        }
      });
      setAttendanceData(temp);
      setChangesDataSet(temp_changes);
    }
  }, [attendanceSet, event.attendees]);

  const toggleUserAttendanceById = (id) => {
    let temp = { ...attendanceData };
    let temp_changes = { ...changesDataSet };
    temp[id].status = !temp[id].status;
    if (temp_changes.hasOwnProperty(id) && temp_changes[id] === true)
      delete temp_changes[id];
    else temp_changes[id] = false;
    setChangesDataSet(temp_changes);
    setAttendanceData(temp);
  };

  const ticketCheckerAttFunc = (id) => {
    const present = attendanceData.hasOwnProperty(id);
    if (!present) return;
    if (attendanceData[id].status === true) return;

    toggleUserAttendanceById(id);
  };

  const ticketSuccessID = useSelector(selectTicketCheckerFuncID);

  useEffect(() => {
    if (ticketSuccessID != undefined) {
      ticketCheckerAttFunc(ticketSuccessID);
    }
  }, [ticketSuccessID]);

  const applyMarkAttendance = () => {
    if (mark_loading) return;
    let data = [];
    Object.keys(changesDataSet).forEach((userID) => {
      data.push({
        status: attendanceData[userID].status,
        userID,
      });
    });
    let requestData = {
      attendanceData: data,
      eventID: event._id,
      singleEvent: !props.event
    };
    dispatch(markEventAttendance(requestData));
  };

  return (
    <div className={styles.attendance}>
      <div className={styles.attendanceContainer}>
        <div className={styles.header}>Obecność</div>
        {Object.keys(changesDataSet).length > 0 && (
          <div onClick={applyMarkAttendance} className={styles.addNew}>
            {!mark_loading ? (
              <>
                {" "}
                Zatwierdź <DoneIcon style={{ marginLeft: 5 }} />{" "}
              </>
            ) : (
              <CircularProgress size={20} />
            )}
          </div>
        )}
        <div className={styles.attendanceCardsContainer}>
          <div className={styles.cardsContainer}>
            {!loading ? (
              Object.keys(attendanceData).length === 0 ? (
                <Empty
                  text={"Brak uczestników"}
                  hint={
                    "Tutaj pojawi się sprawdzanie obecności dla uczestników wydarzenia"
                  }
                />
              ) : (
                <>
                  {Object.keys(attendanceData).map((key, index) => {
                    const attendee = attendanceData[key];

                    let details = undefined;
                    if (
                      attendanceSet[key] &&
                      attendanceSet[key].timestamp &&
                      attendanceSet[key].checkedBy
                    ) {
                      details = {
                        timestamp: attendanceSet[key].timestamp,
                        checkedBy: attendanceSet[key].checkedBy,
                      };
                    }

                    return attendee ? (
                      <AttendeeCard
                        key={index}
                        status={attendee.status}
                        name={attendee.fullName}
                        originalUserId={attendee.originalUserId}
                        id={attendee.userID}
                        details={details}
                        history={history}
                        toggleUserAttendanceById={toggleUserAttendanceById}
                      />
                    ) : (
                      <></>
                    );
                  })}
                </>
              )
            ) : (
              <Loading />
            )}{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect()(Attendance);
