import React from "react";

import { connect,  } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";

const RedirectRoute = ({ component, requiredScope, redirectPath, ...rest }) => {
 
       
         return <Route {...rest}>  <Redirect
            to={{
              pathname: redirectPath,
            }}
          />
          </Route>
      
   
};

export default withRouter(connect()(RedirectRoute));
