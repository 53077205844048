import React from "react";
import styles from "./feedback.module.scss";

function OwnFeedbackCard(props) {
  return (
    <div
      key={props.eventID}
      onClick={() => props.onClick(props.eventID)}
      className={styles.ownFeedbackCard}
    >
      <div className={styles.cycleName}>{props.cycleName}</div>
      <div className={styles.eventName}>{props.eventName}</div>
    </div>
  );
}

export default OwnFeedbackCard;
