import { createBrowserHistory } from "history"
import React, { useRef, useState } from "react"

import { createRoot } from "react-dom/client"
import "react-indiana-drag-scroll/dist/style.css"
import { Provider } from "react-redux"
import { Router } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"
import "./index.scss"
import { persistor, store } from "./redux/store"
import reportWebVitals from "./reportWebVitals"
import ViewManager from "./ViewManager"

import { createTheme, ThemeProvider } from "@mui/material/styles"
import variables from "./assets/variables.scss"
import NavbarNew from "./components/navbar/NavbarNew"
import Footer from "./components/footer/Footer"
import Notifications from "./components/notifications/Notifications"

const theme = createTheme({
  palette: {
    primary: {
      main: variables.orangelight,
    },
    secondary: {
      main: variables.orangered,
    },
    white: {
      main: variables.white,
    },
    bordergrey: {
      main: variables.bordergrey,
    },
    text: {
      primary: "#173A5E",
      secondary: "#46505A",
    },
  },
  components: {
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          borderWidth: 2,
          borderRadius: '1rem',
          "&:hover": {
            borderWidth: 2,
          },
          "&:disabled": {
            borderWidth: 2,
          },
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        icon: {
          color: variables.orangelight,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius:'2rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '2rem',
        },
      },
    },
  },
  typography: {
    fontFamily: [
      "Space Grotesk",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
})

const container = document.getElementById("root")
const root = createRoot(container)

export const history = createBrowserHistory()

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <NavbarNew />
          <div id="SXWrapper" className="content">
            <ViewManager />
            <Footer />
          </div>
          <Notifications />
        </Router>
      </PersistGate>
    </Provider>
  </ThemeProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
