import React, { useState } from "react";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import API from "../../API/APIService";
import { emmitError } from "../../utils/ToastEmmiter";
import styles from "./imageUploader.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import Preview from "./Preview";
import { getUploadPath } from "../../svc/svc";
import { Controller } from "react-hook-form";

function ImageUploader(props) {
  const [internalImage, setInternalImage] = useState("");
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  console.log(props.additionalInputProps);
  const handleFileUpload = async (e) => {
    setLoading(true);
    let urls = [];
    if (props.multiple) {
      for (const file of e.target.files) {
        if (file.size > 15 * 1048576)
          return emmitError("Wybrany plik jest za duży");
        let fd = new FormData();
        fd.append("file", file);
        const res = await API.post(getUploadPath(), fd);

        //const base64 = await convertToBase64(file);
        urls.push(res.data.url);
      }

      props.setImage(urls);
      setLoading(false);
    } else {
      const file = e.target.files[0];
      if (file.size > 15 * 1048576)
        return emmitError("Wybrany plik jest za duży");
      let fd = new FormData();
      setFileName(file.name);
      fd.append("file", file);
      try {
        const res = await API.post(getUploadPath(), fd);
        //console.log(res.data);
        props.setImage(res.data.url);
        setInternalImage(res.data.url);

        // props.setImage(urls);
      } catch (err) {
        emmitError("Wystąpił błąd podczas dodawania obrazka");
      }
    }

    setLoading(false);

    //const base64 = await convertToBase64(file);
  };

  return (
    <div className={styles.inputDiv}>
      <label
        className={loading ? styles.disabledInput : undefined}
        disabled
        htmlFor={props.inputKey ? props.inputKey : "fileUpload"}
      >
        <UploadFileIcon />{" "}
        <span>
          {loading ? (
            <CircularProgress size={10} />
          ) : props.uploadText ? (
            props.uploadText
          ) : (
            "Prześlij obrazek"
          )}
        </span>
      </label>

      <input
        multiple={props.multiple ? true : false}
        id={props.inputKey ? props.inputKey : "fileUpload"}
        type="file"
        key={props.inputKey ? props.inputKey : "fileUpload"}
        label="Image"
        name="myFile"
        disabled={loading}
        accept=".jpeg, .png, .jpg"
        onChange={(e) => handleFileUpload(e)}
      />
      {props.preview && !props.multiple && internalImage !== "" && (
        <Preview fileName={fileName} image={internalImage} />
      )}
    </div>
  );
}

export default ImageUploader;
