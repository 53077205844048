import Embed from "@editorjs/embed";
import ImageTool from "@editorjs/image";
import CheckList from "@editorjs/checklist";

import AttachesTool from "attaches_readnly";
import List from "@editorjs/list";
import Delimiter from "@editorjs/delimiter";
import Table from "@editorjs/table";
import ColorPlugin from "editorjs-text-color-plugin";
import Paragraph from "editorjs-paragraph-with-alignment";
import Carousel from "@piotreksl/editor-js-image-carousel";

import API from "../../API/APIService";
import { getUploadPath } from "../../svc/svc";

const Header = require("editorjs-header-with-alignment");

export const EDITOR_JS_TOOLS = {
  // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
  // paragraph: Paragraph,
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    config: {
      placeholder: "Zacznij pisać...",
    },
  },

  carousel: {
    class: Carousel,
    config: {
      buttonContent: "Dodaj pliki",
      captionPlaceholder: "Dodaj podpis",
      uploader: {
        uploadByFile(file) {
          return new Promise(async (res, rej) => {
            const urls = [];

            for (var i = 0; i < file.length; i++) {
              const fd = new FormData();
              fd.append("file", file[i]);
              const response = await API.post(getUploadPath(), fd, {
                headers: { "Content-Type": "multipart/form-data" },
              });

              urls.push(response.data.url);
            }
            res(urls);
          })
            .then((res) => {
              return {
                success: 1,
                files: res,
              };
            })
            .catch((err) => {
              console.error(err);
            });
        },
      },
    },
  },

  image: {
    class: ImageTool,
    config: {
      buttonContent: "Dodaj pliki",
      captionPlaceholder: "Dodaj podpis",
      uploader: {
        uploadByFile(file) {
          const fd = new FormData();
          fd.append("file", file);
          return API.post(getUploadPath(), fd, {
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then((res) => {
              return {
                success: 1,
                file: {
                  url: res.data.url,
                },
              };
            })
            .catch((err) => {
              console.log(err);
              return {
                success: 0,
              };
            });
        },
        uploadByUrl(url) {
          return {
            success: 1,
            file: {
              url: url,
            },
          };
        },
      },
    },
  },
  checkList: CheckList,
  header: {
    class: Header,
    config: {
      placeholder: "Wpisz nagłówek",
      defaultAlignment: "left",
    },
  },
  embed: {
    class: Embed,
    inlineToolbar: true,
  },

  list: {
    class: List,
    inlineToolbar: true,
    config: {
      defaultStyle: "unordered",
    },
  },
  delimiter: Delimiter,
  table: Table,
  attaches: {
    class: AttachesTool,
    config: {
      /**
       * Custom uploader
       */
      uploader: {
        /**
         * Upload file to the server and return an uploaded image data
         * @param {File} file - file selected from the device or pasted by drag-n-drop
         * @return {Promise.<{success, file: {url}}>}
         */
        uploadByFile(file) {
          const fd = new FormData();

          fd.append("file", file);
          return API.post(getUploadPath(), fd, {
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then((res) => {
              return {
                success: 1,
                file: {
                  url: res.data.url,
                  title: file.name,
                  name: file.name,
                },
              };
            })
            .catch((err) => {
              return {
                success: 0,
              };
            });
        },
      },
    },
  },
  Color: {
    class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
    config: {
      colorCollections: [
        "#222222",
        "#EC7878",
        "#9C27B0",
        "#673AB7",
        "#3F51B5",
        "#0070FF",
        "#03A9F4",
        "#00BCD4",
        "#4CAF50",
        "#8BC34A",
        "#CDDC39",
        "#FF0000",
      ],
      defaultColor: "#FF0000",
      type: "text",
    },
  },
  Marker: {
    class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
    config: {
      defaultColor: "#FFBF00",
      type: "marker",
    },
  },
};

export const EDITOR_JS_TOOLS_NO_FILES = {
  // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
  // paragraph: Paragraph,
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    config: {
      placeholder: "Zacznij pisać...",
    },
  },

  checkList: CheckList,
  header: Header,
  embed: {
    class: Embed,
    inlineToolbar: true,
  },

  list: {
    class: List,
    inlineToolbar: true,
    config: {
      defaultStyle: "unordered",
    },
  },
  delimiter: Delimiter,
  table: Table,
  Color: {
    class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
    config: {
      colorCollections: [
        "#222222",
        "#EC7878",
        "#9C27B0",
        "#673AB7",
        "#3F51B5",
        "#0070FF",
        "#03A9F4",
        "#00BCD4",
        "#4CAF50",
        "#8BC34A",
        "#CDDC39",
        "#FF0000",
      ],
      defaultColor: "#FF0000",
      type: "text",
    },
  },
  Marker: {
    class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
    config: {
      defaultColor: "#FFBF00",
      type: "marker",
    },
  },
};

export const EDITOR_JS_TEXT_ONLY = {
  // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
  // paragraph: Paragraph,
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    config: {
      placeholder: "Zacznij pisać...",
    },
  },
  header: Header,
  list: {
    class: List,
    inlineToolbar: true,
    config: {
      defaultStyle: "unordered",
    },
  },
  delimiter: Delimiter,
  table: Table,
  Color: {
    class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
    config: {
      colorCollections: [
        "#222222",
        "#EC7878",
        "#9C27B0",
        "#673AB7",
        "#3F51B5",
        "#0070FF",
        "#03A9F4",
        "#00BCD4",
        "#4CAF50",
        "#8BC34A",
        "#CDDC39",
        "#FF0000",
      ],
      defaultColor: "#FF0000",
      type: "text",
    },
  },
  Marker: {
    class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
    config: {
      defaultColor: "#FFBF00",
      type: "marker",
    },
  },
};
