import React from "react";

import { connect, useSelector } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";
import Loading from "../components/universal/Loading";

import { selectType } from "../redux/slices/userSlice";
import { emitInfo } from "./ToastEmmiter";

const UserRoute = ({ component, userOnly, ...rest }) => {
  let ComponentToRender = component;

  const data = useSelector(selectType);
  const USER_TYPE_CONDITION = userOnly
    ? data.type === "user"
    : data.type === "user" || data.type === "mod" || data.type === "admin";

  return (
    <Route
      {...rest}
      render={(props) =>
        data.authorizing ? (
          <Loading />
        ) : data.authorized && USER_TYPE_CONDITION ? (
          <ComponentToRender {...props} />
        ) : (
          <>
            {emitInfo("Zaloguj się, aby przejść dalej")}
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          </>
        )
      }
    />
  );
};

export default withRouter(connect()(UserRoute));
