import React from "react";

import PanelButton from "../../../../components/panelButton/PanelButton";

import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
function ManageHomePage(props) {
  const history = useHistory();
  return (
    <div>
      <PanelButton
        onClick={() => history.push("/manageHomePage")}
        label={"Zarządzaj stroną główną"}
        icon={<HomeIcon style={{ fontSize: 40 }} />}
        width={100}
        height={100}
      />
    </div>
  );
}

export default ManageHomePage;
