import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import MailIcon from "@mui/icons-material/Mail";
import Badge from "@mui/material/Badge";
import { socket } from "../../API/socketService";
import { selectUnseenCount, setUnseen } from "../../redux/slices/messagesSlice";
import { Tooltip } from "@mui/material";

function MessagesIcon(props) {
  const unseenCount = useSelector(selectUnseenCount);
  const dispatch = useDispatch();
  useEffect(() => {
    if (socket)
      socket.on("unseenMessages", (data) => {
        dispatch(setUnseen(data));
      });
  }, []);

  return (
    <Badge badgeContent={unseenCount || 0} color="secondary">
      <Tooltip title="Wiadomości">
        <MailIcon color="#fffff" />
      </Tooltip>
    </Badge>
  );
}

export default connect()(MessagesIcon);
