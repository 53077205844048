import styles from './Products.module.scss'

function HelpCard(props) {
    return (
      
        <div className={styles.helpup}>
            <div className={styles.info}>
            W przypadku problemów z logowaniem, zakładaniem konta bądź rejestracją uczestnika prosimy o kontakt telefoniczny: <a href="tel:786815155"className={styles.special}>786 815 155</a> lub mailowo: <a href="mailto:rezerwacja@stolicaexperymentu.edu.pl" className={styles.special}>rezerwacja@stolicaexperymentu.edu.pl</a>
            </div>
        </div>

    )
}

export default HelpCard