import React, { useState, useEffect, useRef } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import styles from "./single.module.scss";
import ImageUploader from "../../../components/imageUploader/ImageUploader";
import moment from "moment";
import ConfirmButton from "../../../components/confirmButton/ConfirmButton";
import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  fetchNews,
  selectSingle,
  updatePost,
  deletePost,
} from "../../../redux/slices/newsSlice";
import TextEditor from "../../../components/textEditor/TextEditor";
import { selectType } from "../../../redux/slices/userSlice";
import { Helmet } from "react-helmet";
import Loading from "../../../components/universal/Loading";
import Empty from "../../../components/universal/Empty";
import EdjsDisplay from "../../../components/edjsDisplay/EdjsDisplay";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton";
import { selectMaxLengths } from "../../../redux/slices/utilsSlice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import ImageModal from "../../../components/imageUploader/ImageModal";
import CustomMultiMail from "../../../components/CustomMultiMail/CustomMultiMail";
import { emmitError } from "../../../utils/ToastEmmiter";
import { getFullPath } from "../../../svc/svc";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import StarIcon from '@mui/icons-material/Star';
import { StarBorder } from "@mui/icons-material";

import "moment/locale/pl";
import classnames from 'classnames'

const DATE_FORMAT = "DD-MM-YYYY";
const maxKeywords = 10;

function Single(props) {
  const news = useSelector(selectSingle);
  const user = useSelector(selectType);
  const maxLengths = useSelector(selectMaxLengths);
  const history = useHistory();
  const dispatch = useDispatch();
  const editorCore = useRef();
  const [editMode, setEditMode] = useState(false);
  const [imagePreviewVisible, setImagePreviewVisible] = useState(false);

  const [title, setTitle] = useState(news.singleNews.title);
  const [slider,setSlider] = useState(news.singleNews.slider)
  const [publicatedAt, setPublicatedAt] = useState(
    news.singleNews.publicatedAt
  );
  const [image, setImage] = useState(news.singleNews.thumbnail);
  const [keywords, setKeywords] = useState(news.singleNews.keywords);

  const titleRef = useRef(null);

  useEffect(() => {
    if (Object.keys(news.singleNews).length === 0 || ((news.singleNews.slug !== props.match.params.id) && (news.singleNews._id !== props.match.params.id))) {
      const id = props.match.params.id;
      dispatch(fetchNews(id));
    }
  }, []);

  useEffect(() => {
    setTitle(news.singleNews.title);
  }, [news.singleNews.title]);

  useEffect(() => {
    setImage(news.singleNews.thumbnail);
  }, [news.singleNews.thumbnail]);

  useEffect(() => {
    setPublicatedAt(news.singleNews.publicatedAt);
  }, [news.singleNews.publicatedAt]);

  useEffect(() => {
    setKeywords(news.singleNews.keywords);
  }, [news.singleNews.keywords]);

  const handleSubmit = async () => {
    const savedData = await editorCore.current.save();

    var data = {
      title: title,
      thumbnail: image,
      data: savedData,
      slider: slider,
      _id: news.singleNews._id,
      publicatedAt: publicatedAt,
      keywords,
    };

    dispatch(updatePost(data));
    setEditMode(false);
  };

  const canEdit = user.type === "admin" || user.scope?.includes("posts");

  const handleChangeEditMode = () => {
    setEditMode((p) => !p);
  };

  if (news.loading) return <Loading />;

  if (Object.keys(news.singleNews).length === 0 && news.loading === false)
    return <Empty text={"Nie znaleziono aktualności"} />;

  const articleStructuredData = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    headline: news.singleNews.title,
    description: news.singleNews?.shortDesc,
    image: getFullPath(news.singleNews?.thumbnail),
    datePublished: news.singleNews?.createdAt,
    dateModified: news.singleNews?.updatedAt,
    author: {
      "@type": "Person",
      name: news.singleNews?.createdBy,
    },
  };

  const breadcrumbListStructuredData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Stolica eXperymentu",
        item: getFullPath(""),
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Aktualności",
        item: getFullPath("/aktualnosci"),
      },
      {
        "@type": "ListItem",
        position: 3,
        name: news.singleNews.title,
        item: getFullPath("/aktualnosci/" + news.singleNews.slug),
      }, 
    ],
  };

  const handleSliderStateChange = (event) => {
    setSlider(event.target.checked);
  }

  return (
    <div className={styles.newsWrapper}>
      <Helmet>
        <title>{news.singleNews?.title} | Stolica eXperymentu</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={news.singleNews?.shortDesc} />
        <meta property="og:description" content={news.singleNews?.shortDesc} />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={getFullPath(news.singleNews?.thumbnail)}
        />
        <meta property="og:title" content={news.singleNews?.title} />
        <meta name="twitter:title" content={news.singleNews?.title} />
        <meta name="twitter:description" content={news.singleNews?.shortDesc} />
        <meta
          name="twitter:image"
          content={getFullPath(news.singleNews?.thumbnail)}
        />

        <script type="application/ld+json">
          {JSON.stringify(articleStructuredData)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbListStructuredData)}
        </script>
      </Helmet>

      <div className={styles.topHeader}>
        <div className={editMode ? classnames(styles.leftHeader, styles.spreadHeader) : styles.leftHeader}>
          <div className={styles.controls}>
            <div className={styles.mode}>
              {canEdit ? (
                editMode ? (
                  <>
                    <EditIcon></EditIcon>
                    <Typography className={styles.modeText}>
                      Tryb edycji
                    </Typography>{" "}
                  </>
                ) : (
                  <>
                    <VisibilityIcon></VisibilityIcon>
                    <Typography className={styles.modeText}>
                      Tryb podglądu
                    </Typography>
                  </>
                )
              ) : null}
            </div>

            {canEdit ? (
              <LoadingButton
                endIcon={<EditIcon />}
                variant="outlined"
                onClick={handleChangeEditMode}
              >
                {editMode ? "Anuluj edycję" : "Edytuj post"}
              </LoadingButton>
            ) : null}
          </div>

          <div className={styles.title}>
            {editMode ? (
              <>
                <TextField
                  className={styles.titleTextEdit}
                  defaultValue={news.singleNews.title}
                  inputProps={{
                    maxLength: maxLengths.title,
                    style: { fontSize: 36, color: "black" },
                  }}
                  variant="outlined"
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </>
            ) : (
              <h1>
                <Typography className={styles.titleText}>
                  {news.singleNews.title}
                </Typography>
              </h1>
            )}
          </div>
          <div className={styles.createdContainer}>
            <div className={styles.sectionContainer}>
              <div className={styles.sectionIcon}>
                <AccessTimeIcon sx={{ fontSize: 32 }}></AccessTimeIcon>
              </div>
              {editMode ? (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Data publikacji"
                    value={publicatedAt}
                    onChange={(p) => setPublicatedAt(p)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              ) : (
                <Typography
                  className={styles.sectionText}
                  sx={{ fontSize: "16px" }}
                >
                  {moment(news.singleNews.publicatedAt)
                    .locale("pl")
                    .format(DATE_FORMAT)}
                </Typography>
              )}
            </div>
            <div className={styles.sectionContainer}>
              <div className={styles.sectionIcon}>
                <PersonIcon sx={{ fontSize: 32 }}></PersonIcon>
              </div>
              <Typography
                className={styles.sectionText}
                sx={{ fontSize: "16px" }}
              >
                {news.singleNews.createdBy}
              </Typography>
            </div>
            {!editMode && !news.singleNews.slider ? <> </>
            :  <div className={styles.sectionContainer}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox icon={<StarBorder sx={{ fontSize: 32, paddingLeft: '6px', paddingRight: '5px' }} />} checkedIcon={<StarIcon sx={{ fontSize: 32, paddingLeft: '6px',paddingRight: '5px' }} />} disabled={!editMode} onChange={handleSliderStateChange} checked={slider} />}
                label="Wyróżnione"
              />
            </FormGroup>
            </div> 
          }
            
            
          </div>


          {editMode && (
            <div className={styles.buttons}>
              <LoadingButton
                variant="outlined"
                onClick={handleSubmit}
                endIcon={<CheckIcon />}
              >
                Zatwierdź
              </LoadingButton>
              <ConfirmButton
                cancelStartIcon={<CloseIcon />}
                confirmStartIcon={<DoneAllIcon />}
                questionText="Czy na pewno chcesz usunąć post?"
                onConfirm={() => dispatch(deletePost(news.singleNews._id))}
              >
                <LoadingButton
                  variant="outlined"
                  endIcon={<DeleteForeverIcon></DeleteForeverIcon>}
                >
                  Usuń post
                </LoadingButton>
              </ConfirmButton>
            </div>
          )}
        </div>


        <div
          onClick={() => !editMode && setImagePreviewVisible(true)}
          style={{ backgroundImage: `url(${image})` }}
          className={styles.rightHeader}
        >
          {editMode && <ImageUploader setImage={setImage} />}
        </div>

        <ImageModal
          standalone={true}
          visible={imagePreviewVisible}
          image={image}
          closeImageModal={() => setImagePreviewVisible(false)}
        />

      </div>

      <div className={styles.bottomContent}>
        <div className={styles.newsContent}>
          {editMode ? (
            <TextEditor
              defaultValue={news.singleNews.data}
              editorCore={editorCore}
              readOnly={false}
              useFiles={true}
            />
          ) : (
            <EdjsDisplay data={news.singleNews.data} />
          )}
        </div>
        <div className={styles.keywordsContent}>
          {editMode ? (
            <>
              Słowa kluczowe
              <CustomMultiMail
                isPadding={false}
                customValidator={(val) => {
                  if (maxLengths.name > val.length) return true;
                  return false;
                }}
                placeholder={`Wpisz maksymalnie ${maxKeywords} słów kluczowych`}
                emails={keywords}
                onChange={(_keywords) => {
                  if (_keywords.length > maxKeywords) {
                    return emmitError(
                      "Nie możesz dodać więcej niż" +
                      maxKeywords +
                      " słów kluczowych"
                    );
                  }
                  setKeywords(_keywords);
                }}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect()(Single);
