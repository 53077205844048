import React, { useEffect, useState } from "react";
import styles from "./TicketButton.module.scss"
import PanelButton from "../../../../components/panelButton/PanelButton";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Modal from "../../../../components/modal/Modal";
import { QRCode } from "react-qrcode-logo";
import { useDispatch, useSelector } from "react-redux";
import { selectTicketModal, selectUser, setTicketModal } from "../../../../redux/slices/userSlice";
import StolicaXLogo from "../../../../assets/stolica-x.png";
import PersonIcon from '@mui/icons-material/Person';
import velix3 from "../../../../assets/velix/VELIX 3.png";

function TicketButton(props) {
    const { _id, fullName } = useSelector(selectUser)
    const dispatch = useDispatch()
    const modalState = useSelector(selectTicketModal)

    const openModal = () => {
        dispatch(setTicketModal(true))
    }

    const closeModal = () => {
        dispatch(setTicketModal(false))
    }

    return (
        <>
            <PanelButton
                label="Bilet"
                icon={<QrCode2Icon style={{ fontSize: 40 }} />}
                width={100}
                height={100}
                onClick={openModal}
            />
            <Modal
                visible={modalState}
                closeModal={closeModal}
                height={500}
                title={"Twój bilet"}
                isDarkTitle={true}
                fullBody
                width={400}
                >

                <div className={styles.qrCodeContainer}>
                    <div className={styles.userNameContainer}>
                        <div className={styles.userIcon}>
                            <PersonIcon />
                        </div>
                        <div className={styles.nameContainer}>
                            {fullName}
                        </div>
                    </div>
                    <QRCode
                        removeQrCodeBehindLogo
                        ecLevel="H"
                        logoImage={StolicaXLogo}
                        eyeRadius={[
                            {
                                // top/left eye
                                outer: [0, 0, 0, 10],
                                inner: [0, 10, 0, 10]
                            },
                            [0, 10, 10, 0], // top/right eye
                            [10, 0, 10, 0] // bottom/left
                        ]}
                        size={300}
                        value={_id}
                        fgColor="#1F1F1F"
                        renderAs="svg"
                    />
                </div>

            </Modal>
        </>
    )
}

export default (TicketButton)