import React from "react"
import { connect } from "react-redux"
import styles from "./components.module.scss"
import { motion } from "framer-motion"
import classNames from "classnames"
import { TypingText } from "./utils/CustomText"
import { fadeIn, staggerContainer, zoomIn } from "./utils/motion"
import veluxLogo from "../../../assets/veluxFoundations.png"

function FoundationNew(props) {
  const { data } = props

  return (
    <div>
      <div
        className={classNames(
          styles.whoAreWeSection,
          styles.maxContainer,
          styles.sectionMargin
        )}
      >
        {/* <div className={styles.gradient03}></div>
        <div className={styles.gradient00}></div> */}
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.25 }}
          className={styles.contentFoundation}
        >
          <TypingText title="| Fundacja Velux" />
          <div className={styles.letterContainer}>
            <motion.div
              className={styles.veluxLogoContainer}
              variants={zoomIn(0.4, 1)}
            >
              <img
                src={veluxLogo}
                alt="Logo fundacji Velux"
                className={styles.veluxLogo}
              ></img>
            </motion.div>
            <div className={styles.textContainer}>
              <motion.p
                variants={fadeIn("up", "tween", 0.2, 1)}
                className={styles.description}
              >
                Projekt współfinansowany jest przez&nbsp;
                <a
                  href="https://veluxfoundations.dk/en/about/projects-granted#/0060X00000VteRsQAJ"
                  target="_blank"
                  className={styles.link}
                  rel="noreferrer"
                >
                  <span className={styles.bold}>FUNDACJĘ VILLUM</span>
                </a>
                &nbsp;(VILLUM FONDEN wraz z VELUX FONDEN wchodzą w skład&nbsp;
                <a
                  href="https://www.facebook.com/hashtag/veluxfoundation"
                  target="_blank"
                  className={styles.link}
                  rel="noreferrer"
                >
                  <span className={styles.bold}>#VeluxFoundation</span>
                </a>
                ).
              </motion.p>
              <motion.p
                variants={fadeIn("up", "tween", 0.2, 1)}
                className={styles.description}
              >
                Podczas październikowych uroczystości mieliśmy zaszczyt gościć
                reprezentującą Fundację Velux Vofinden – panią Lidię
                Mikołajczyk-Gmur oraz Prezesa Velux sp. z o. o. – pana Roberta
                Purola, dla których idea Przemysłu 4.0 jest równie ważna jak dla
                nas.
              </motion.p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default connect()(FoundationNew)
