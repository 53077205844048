import DownloadIcon from "@mui/icons-material/Download";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Output from "editorjs-react-renderer";
import parse from "html-react-parser";
import React from "react";
import ImageGallery from "../imageGallery/ImageGallery";
import styles from "./edjsDisplay.module.scss";
import ImageModal from "../imageUploader/ImageModal";


//#endregion

/**********************************************      GLOBALS      ******************************************/

const supportedKeys = ['img', 'figure', 'figcaption'];

const defaultStyle = {
  image: {
    maxWidth: '100%',
    maxHeight: '400px',
  },
  figure: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
    width: '100%',
    maxWidth: '100%',
    maxHeight: '400px',
    overflow: 'hidden',
    backgroundColor: 'aliceblue',
    border: '1px solid #eee'
  },
  figcaption: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    padding: '5px 10px',
    fontSize: '12px',
    backgroundColor: '#2d333a',
    color: 'white',
    borderRadius: '2px',
    cursor: 'default',
  }
};




/**********************************************     FUNCTIONS     ******************************************/

const ImageOutput = ({ data, style, classNames, config }) => {

  const [imagePreviewVisible, setImagePreviewVisible] = React.useState(false);

  if (!data || !data.file || !data.file.url) return <></>;
  if (!style || typeof style !== 'object') style = {};
  if (!config || typeof config !== 'object') config = { disableDefaultStyle: false };
  if (!classNames || typeof classNames !== 'object') classNames = {};

  supportedKeys.forEach(key => {
    if (!style[key] || typeof style[key] !== 'object') style[key] = {};
    if (!classNames[key] || typeof classNames[key] !== 'string') classNames[key] = '';
  });

  const imageStyle = config.disableDefaultStyle ? style.img : { ...defaultStyle.image, ...style.img };
  imageStyle['width'] = data.stretched ? '100%' : '';

  const figureStyle = config.disableDefaultStyle ? style.figure : { ...defaultStyle.figure, ...style.figure };
  if (!data.withBorder) figureStyle['border'] = 'none';
  if (!data.withBackground) figureStyle['backgroundColor'] = 'none';

  const figcaptionStyle = config.disableDefaultStyle ? style.figcaption : { ...defaultStyle.figcaption, ...style.figcaption };

  return (
    <figure style={ figureStyle } className={ classNames.figure }>
      <img onClick={() => setImagePreviewVisible(true)} src={ data.file.url } alt={ data.caption || '' } style={ imageStyle } className={ classNames.img } />

      <ImageModal
          
          visible={imagePreviewVisible}
          image={data.file.url}
          closeImageModal={() => setImagePreviewVisible(false)}
        />
     
      { data.caption && <figcaption style={ figcaptionStyle } className={ classNames.figcaption }>{ parse(data.caption) }</figcaption> }
    </figure>
  );
};


const CustomParagraphRenderer = ({ data, style, classNames, config }) => {
  let content = data.text;

  return content ? (
    <p
      style={{ ...style, textAlign: data.alignment }}
      className={styles.paragraph}
    >
      {parse(content)}
    </p>
  ) : (
    ""
  );
};

const customAttacheRenderer = ({ data, style, classNames, config }) => {
  // validate props here...or not :)

  return (
    <div className={styles.attacheWrapper}>
      <div className={styles.info}>
        <InsertDriveFileIcon className={styles.fileIcon} />
        <p className={styles.fileName}>{data.title}</p>
      </div>
      <div className={styles.downloadSection}>
        <a className={styles.downloadIconHref} href={data.file.url} download>
          <DownloadIcon className={styles.downladIcon} />
        </a>
      </div>
    </div>
  );
};

const customTableRenderer = ({ data }) => {

  const rows = data.content.map((row, index) => {
    return (
      <tr>
        {row.reduce((acc, cell) => {
          if (data.withHeadings && index === 0)
            return acc + <th className={styles.tableCell}>${cell}</th>;
          return acc.concat(<td className={styles.tableCell}>{parse(cell)}</td>);
        }, [])}
      </tr>
    );
  });

  return (
    <table className={styles.tableWrapper}>
      <tbody>{rows}</tbody>
    </table>
  );
};

const customGalleryRenderer = ({ data, style, classNames, config }) => {
  //data = [{url: '123', caption: ''}, ...]

  let additionalArr = [];
  data.map((el) => {
    additionalArr.push({
      ...el,
      src: el.url,
      source: el.url,
    });
  });

  return <ImageGallery photos={additionalArr} />;
};

const CustomHeaderRenderer = ({ data, style, classNames, config }) => {
  // validate props here...or not :)

  let content = data.text;

  if (!content) return "";
  let html = "";
  switch (data.level) {
    case 1:
      html = (
        <h1
          style={{ ...style, textAlign: data.alignment }}
          className={styles.header}
        >
          {parse(content)}
        </h1>
      );
      break;
    case 2:
      html = (
        <h2
          style={{ ...style, textAlign: data.alignment }}
          className={styles.header}
        >
          {parse(content)}
        </h2>
      );
      break;
    case 3:
      html = (
        <h3
          style={{ ...style, textAlign: data.alignment }}
          className={styles.header}
        >
          {parse(content)}
        </h3>
      );
      break;
    case 4:
      html = (
        <h4
          style={{ ...style, textAlign: data.alignment }}
          className={styles.header}
        >
          {parse(content)}
        </h4>
      );
      break;
    case 5:
      html = (
        <h5
          style={{ ...style, textAlign: data.alignment }}
          className={styles.header}
        >
          {parse(content)}
        </h5>
      );
      break;
    case 6:
      html = (
        <h6
          style={{ ...style, textAlign: data.alignment }}
          className={styles.header}
        >
          {parse(content)}
        </h6>
      );
      break;
    default:
      console.log("error");
  }

  return html;
};

const renderers = {
  paragraph: CustomParagraphRenderer,
  header: CustomHeaderRenderer,
  attaches: customAttacheRenderer,
  table: customTableRenderer,
  carousel: customGalleryRenderer,
  image: ImageOutput,
};

function EdjsDisplay(props) {
  return (
    <>
      {props.data !== undefined && props.data?.blocks !== undefined ? (
        <Output renderers={renderers} data={props.data} />
      ) : (
        <></>
      )}
    </>
  );
}

export default EdjsDisplay;
