import React from "react";
import styles from "./EventFeedbacks.module.scss";

import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";

import PersonIcon from "@mui/icons-material/Person";
import Rating from "@mui/material/Rating";

import moment from "moment";
const DATE_FORMAT = "dddd, DD MMMM YYYY hh:mm";

function FeedbackCard(props) {
  const { data } = props;
  return (
    <div className={styles.feedbackCard}>
      <div className={styles.top}>
        <div className={styles.name}>
          <PersonIcon sx={{ fontSize: 30 }} />
          <span> {data.user.fullName} </span>
        </div>
        <div className={styles.rating}>
          <Rating
            icon={<LightbulbIcon />}
            emptyIcon={<LightbulbOutlinedIcon />}
            value={data.rating}
            readOnly
          />
        </div>
      </div>
      <div className={styles.description}>{data.description}</div>
      <div className={styles.timestamp}>
        {moment(data.createdAt).locale("pl").format(DATE_FORMAT)}
      </div>
    </div>
  );
}

export default FeedbackCard;
