import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import styles from "./teacherIP.module.scss";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIcon from "@mui/icons-material/Phone";

export default function TeacherInfoPopover(props) {
  const { children, teacher } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      {React.cloneElement(children, {
        "aria-owns": open ? "mouse-over-popover" : undefined,
        "aria-haspopup": "true",
        onMouseEnter: handlePopoverOpen,
        onMouseLeave: handlePopoverClose,
      })}
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className={styles.teacherPopoverHolder}>
          <div className={styles.teacherName}>{teacher.fullName}</div>
          <div className={styles.teacherSect}>
            <AlternateEmailIcon className={styles.icon} />
            <p className={styles.text}>
              {teacher?.email ? teacher.email : "Nie podano"}
            </p>
          </div>

          {/* <div className={styles.teacherSect}>
            <PhoneIcon className={styles.icon} />
            <p className={styles.text}>
              {teacher?.phone?.number
                ? `${teacher.phone.prefix}${teacher.phone.number}`
                : "Nie podano"}
            </p>
          </div> */}
        </div>
      </Popover>
    </div>
  );
}
