import { LoadingButton } from "@mui/lab";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import * as React from "react";
import styles from "./confirmButton.module.scss";

export default function ConfirmButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      {props.children &&
        React.cloneElement(props.children, { onClick: handleClick })}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <div className={styles.popoverContent}>
          <Typography>{props.questionText || "Czy jesteś pewien?"}</Typography>
          <div className={styles.buttons}>
            <LoadingButton
              startIcon={props.confirmStartIcon}
              variant="outlined"
              onClick={() => {
                props.onConfirm();
                handleClose();
              }}
            >
              {props.confirmText || "Potwierdź"}
            </LoadingButton>
            <LoadingButton
              startIcon={props.cancelStartIcon}
              onClick={handleClose}
              variant="outlined"
            >
              {props.cancelText || "Anuluj"}
            </LoadingButton>
          </div>
        </div>
      </Popover>
    </div>
  );
}
