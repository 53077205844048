import React, { useEffect, useRef } from "react";

import { connect, useDispatch, useSelector } from "react-redux";

import ClassicCalendar from "../../../../components/calendar/ClassicCalendar";
import ResourceCalendar from "../../../../components/calendar/ResourceCalendar";
import Loading from "../../../../components/universal/Loading";
import NotFound from "../../../../components/universal/NotFound";
import {
  getMyEvents,
  selectMyEvents,
} from "../../../../redux/slices/eventsSlice";
import { selectUser } from "../../../../redux/slices/userSlice";

function MyEvents(props) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const myEvents = useSelector(selectMyEvents);
  const calendarCore = useRef();

  useEffect(() => {
    dispatch(getMyEvents());
  }, []);

  const renderCyclesForUser = () => {
    return (
      <ClassicCalendar
        events={myEvents.events}
        upcomingEvents={myEvents.upcomingEvents}
        inprogressEvents={myEvents.inprogressEvents}
        loading={myEvents.loading}
      />
    );
  };

  const renderCyclesForMod = () => {
    return (
      <ResourceCalendar
        showVisibilityToggle={props.showVisibilityToggle}
        events={myEvents.events}
        upcomingEvents={myEvents.upcomingEvents}
        inprogressEvents={myEvents.inprogressEvents}
        canAddEvents={false}
        preventFetchingEvents /// nie muismy zaciągać eventów
        eventSelector={selectMyEvents}
        calendarCore={calendarCore}
        loading={myEvents.loading}
      />
    );
  };

  return (
    <>
      {user.type === "user" && renderCyclesForUser()}

      {user.type !== "user" && renderCyclesForMod()}
    </>
  );
}

export default connect()(MyEvents);
