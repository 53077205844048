import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Pagination from "../../../../components/Pagination/Pagination";
import styles from "./CycleReservations.module.scss";

import {
  printParticipantsList,
  selectCycleReservationsData,
  selectCycleReservationsLoading,
  selectCycleReservationsPage,
  selectCycleReservationsTotalDocs,
  selectPrintLoading,
  setPageForCycleReservations,
} from "../../../../redux/slices/eventsSlice";

import CycleReservationCard from "./CycleReservationCard";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import Communication from "../../../panel/components/communication/Communication";
import { AttachEmailOutlined, Print } from "@mui/icons-material";
import { selectUser } from "../../../../redux/slices/userSlice";

function CycleReservations(props) {
  const history = useHistory();
  const user = useSelector(selectUser);

  const totalDocs = useSelector(selectCycleReservationsTotalDocs);

  const printLoading = useSelector(selectPrintLoading);
  const dispatch = useDispatch();

  return (
    <div className={styles.cycleReservationsContainer}>
      <div className={styles.headerWrapper}>
        <div className={styles.header}>Rezerwacje użytkowników</div>
        <div className={styles.totalDocs}>
          <div className={styles.text}>Łączna ilość rezerwacji</div>
          <div className={styles.amount}>{totalDocs}</div>
        </div>
      </div>

      <Communication
        cycleID={props.cycleID}
        cycleName={props.cycleName}
        customButton={
          <LoadingButton
            variant="outlined"
            disabled={parseInt(totalDocs) === 0}
            className={styles.communicationButton}
            endIcon={<AttachEmailOutlined style={{ fontSize: 25 }} />}
          >
            Napisz wiadomość do uczestników cyklu
          </LoadingButton>
        }
      />

        <LoadingButton
            variant="outlined"
            disabled={parseInt(totalDocs) === 0}
            className={styles.communicationButton}
            endIcon={<Print style={{ fontSize: 25 }} />}
            onClick={()=> dispatch(printParticipantsList(props.cycleID))}
            loading={printLoading}
          >
            Wydrukuj listę uczestników cyklu
          </LoadingButton>

  <div className={styles.localPaginationWrapper}>
  <Pagination
        dataSelector={selectCycleReservationsData}
        pagesSelector={selectCycleReservationsPage}
        loadingSelector={selectCycleReservationsLoading}
        setPage={setPageForCycleReservations}
        emptyText={"Brak rezerwacji do wyświetlenia"}
        emptyHint={"Tutaj pojawią się rezerwacje zapisanych użytkowników"}
        dataContainerClassName={styles.paginationContainer}
        customDispatchProps={{ id: props.cycleID }}
        ElementCard={CycleReservationCard}
        customRedirectHandler={(el) => {
          if ((user.type === "admin" || user.scope.includes("users"))&&el.user?._id)

            history.push(
              `/users/${el.user._id}?tab=Cykle+zajęć&reservationID=${el._id}`
            );
        }}
      />
    </div>
    
    </div>
  );
}

export default connect()(CycleReservations);
