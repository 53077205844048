import { Typography } from "@mui/material";
import React from "react";
import styles from "./newNewsCard.module.scss";
import moment from "moment";
import EdjsDisplay from "../../../components/edjsDisplay/EdjsDisplay";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import Box from "@mui/material/Box";
import { Link, useHistory } from "react-router-dom";

const DATE_FORMAT = "DD MMMM YYYY";

function NewNewsCard(props) {
  const { data } = props;

  return (
    <div className={styles.newsCard} {...props}>
      <div className={styles.header}>
        <img
          src={data.thumbnail}
          className={styles.thumbnail}
          alt="Miniatura"
        />
        <div className={styles.date}>
          {console.log(data.publicatedAt)}
          {moment(data.publicatedAt).locale("pl").format(DATE_FORMAT)}
        </div>
      </div>
      <div className={styles.content}>
        <Link to={"/aktualnosci/" + (data.slug ? data.slug : data._id)}>
          <div className={styles.meta}>{data.title}</div>
        </Link>
        <div className={styles.desc}>{data.shortDesc}</div>
        <div className={styles.buttons}>
          <div className={styles.readMore}>Czytaj dalej</div>
        </div>
      </div>
    </div>
  );
}

export default NewNewsCard;
