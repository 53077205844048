import LoadingButton from "@mui/lab/LoadingButton";
import { Radio, Tooltip, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "react-multi-email/style.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import APIService from "../../API/APIService";
import Loading from "../../components/universal/Loading";
import AnimatedNumbers from "react-animated-numbers";
import {
  addParticipantsToCartItem,
  checkout,
  clearCart,
  getPrice,
  populateCart,
  removeFromErrorList,
  selectCart,
  selectCompanyFiscalItems,
  selectPrice,
  verifyTaxNo
} from "../../redux/slices/cartSlice";
import { selectType } from "../../redux/slices/userSlice";
import styles from "./checkout.module.scss";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PaymentIcon from "@mui/icons-material/Payment";

import { Delete } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import CategoryIcon from "@mui/icons-material/Category";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ErrorIcon from "@mui/icons-material/Error";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import SummarizeIcon from "@mui/icons-material/Summarize";
import IconButton from "@mui/material/IconButton";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import ConfirmButton from "../../components/confirmButton/ConfirmButton";
import { selectMaxLengths } from "../../redux/slices/utilsSlice";
import { emmitError, emmitSuccess } from "../../utils/ToastEmmiter";
import CheckoutCard from "./CheckoutCard";
import FiscalData from "./components/FiscalData";

function CheckOut(props) {
  const { cart, populatedCart, loading, checkoutLoading, errors } =
    useSelector(selectCart);
  const user = useSelector(selectType);
  const [countTable, setCountTable] = useState({});
  const [participantsMatrix, setParticipantsMatrix] = useState({});
  const [acceptableCountTable, setAcceptableCountTable] = useState({});
  const [cost, setCost] = useState(0);
  const [originalCost, setOriginalCost] = useState(0);
  const [promoCode, setPromoCode] = useState("");
  const [usedPromoCode, setUsedPromoCode] = useState(undefined);
  const [isErrorCode, setIsErrorCode] = useState(false);
  const [isSuccessCode, setIsSuccessCode] = useState(false);
  const [isBuyButtonDisabled, setBuyButtonDisabled] = useState({
    error: "Uzupełnij zgody dotyczące zajęć",
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const items = cart;
  const { currencySign } = useSelector(selectMaxLengths);
  const maxLengths = useSelector(selectMaxLengths);
  const priceCart = useSelector(selectPrice);
  const priceItems = priceCart.items;

  const priceLoading = priceCart.loading;

  const modifyCount = (id, action) => {
    const newCountTable = { ...countTable };
    if (action === "add") {
      if (newCountTable[id] >= acceptableCountTable[id]) {
        emmitError("Osiągnięto limit wolnych miejsc");
        return;
      }
      newCountTable[id] = newCountTable[id] ? newCountTable[id] + 1 : 1;
      modifyMatrix(id, "add", newCountTable[id] - 1);
    } else if (action === "remove") {
      if (newCountTable[id] > 1) {
        newCountTable[id] = newCountTable[id] - 1;
        modifyMatrix(id, "remove", newCountTable[id] - 1);
      } else {
        return;
      }
    }
    setCountTable(newCountTable);
    updateCosts();
  };



  useEffect(() => {
    updateCosts();
  }, [usedPromoCode]);

  const updateCosts = () => {
    let itemsToSend = [];
    for (const [key, value] of Object.entries(participantsMatrix)) {
      itemsToSend.push({
        _id: key,
        id: key,
        cartType: "cycle",
        participants: value,
      });
    }
    if(itemsToSend.length > 0)
      dispatch(getPrice({ promo: usedPromoCode?.code, items: itemsToSend }));
  }


  const modifyMatrix = (id, action, index, participant = {age:10, supervisor: user?.fullName, phone:`${user?.phone?.prefix} ${user?.phone?.number}`}, updateCostsExec = false) => {
    const newParticipantsMatrix = { ...participantsMatrix };
    if (action === "add") {
      newParticipantsMatrix[id][index] = participant;
    } else if (action === "remove") {
      newParticipantsMatrix[id].pop();
    }
    setParticipantsMatrix(newParticipantsMatrix)
      if(updateCostsExec){
        setTimeout(() => {
          updateCosts();
        }, 200);
      }
    
   
  };

  const prepTables = () => {
    const newCountTable = {};
    const newParticipantsMatrix = {};
    const newAcceptableCountTable = {};
    populatedCart.forEach((item) => {
      const existingParticipants = items.find(
        (el) => el._id === item._id
      ).participants;
      if (existingParticipants) {
        newCountTable[item._id] = existingParticipants.length
        newParticipantsMatrix[item._id] = [...existingParticipants]
        newAcceptableCountTable[item._id] =
          item.capacity - item.reservationsCount - existingParticipants.length;
      } else {
        newCountTable[item._id] = 1;
        newParticipantsMatrix[item._id] = [{age:10, supervisor: user?.fullName, phone: `${user?.phone?.prefix}${user?.phone?.number}`}];
        newAcceptableCountTable[item._id] =
          item.capacity - item.reservationsCount;
      }
    });
    setCountTable(newCountTable);
    setParticipantsMatrix(newParticipantsMatrix);
    setAcceptableCountTable(newAcceptableCountTable);

    dispatch(getPrice({ promo: usedPromoCode?.code, items: populatedCart }));
    
  };

  const [consentType, setConsentType] = useState("");
  const [guardian, setGuardian] = useState("");
  const [renderAdditional, setRenderAdditional] = useState(true);

  //individual or company
  const [taxType, setTaxType] = useState("individual");
  const [fiscalFullname, setFiscalFullname] = useState(user.fullName);
  const [fiscalCity, setFiscalCity] = useState();
  const [fiscalEmail, setFiscalEmail] = useState(user.email);
  const [fiscalTaxNo, setFiscalTaxNo] = useState();
  const [fiscalCompanyManual, setFiscalCompanyManual] = useState(false);
  const [fiscalCompanyName, setFiscalCompanyName] = useState();

  const { companyFiscalData, companyFiscalDataLoading } = useSelector(
    selectCompanyFiscalItems
  );

  const updateParticipants = () => {
    
    Object.keys(participantsMatrix).forEach((key) => {
      const temp_participants = [];
      participantsMatrix[key].forEach((participant) => {
        temp_participants.push(participant); //participant being an object
      });

      dispatch(
        addParticipantsToCartItem({ id: key, participants: temp_participants })
      );
    });
  };

  const toggleFiscalCompanyManual = () =>
    setFiscalCompanyManual(!fiscalCompanyManual);

  const renderErrorsList = () => {
    return errors.map((el) => {
      return (
        <div className={styles.errorFrame} key={el.text}>
          <CloseIcon
            className={styles.closeButton}
            onClick={() => dispatch(removeFromErrorList(el.text))}
          />
          <div className={styles.errorContainer}>
            <div className={styles.errorBody}>
              <div className={styles.errorIcon}>
                {" "}
                <ErrorIcon className={styles.icon} />
              </div>
              <div className={styles.errorText}>{el.text}</div>
            </div>
            <div className={styles.errorFooter}>
            </div>
          </div>
        </div>
      );
    });
  };

  const checkPromoCode = async (code) => {
    await APIService.get(`/promoCodes/check?code=${code}`)
      .then((res) => {
        const data = res.data;
        setUsedPromoCode(data);
        updateCosts();
        
        emmitSuccess("Pomyślnie zastosowano kod");
        setIsErrorCode(false);
        setIsSuccessCode(true);
      })
      .catch((err) => {
        //console.log(err);
        emmitError(err.response.data.error);
        setIsErrorCode(true);
        setIsSuccessCode(false);
      });
  };

  const validatePopulatedCartConsents = () => {
    if (renderAdditional) {
      if (consentType === "")
        return {
          error: "Uzupełnij informacje dotyczące zgody",
        };

      if (
        consentType === "minor" &&
        (guardian === "" || guardian.length > 70 || guardian.length < 4)
      ) {
        return { error: "Niepoprawny opiekun" };
      }
    }

    return { success: true };
  };

  const handleCheckout = async () => {
    const res = validatePopulatedCartConsents();
    if (res.error) return emmitError(res.error);
    let fiscalData;

    if (taxType == "individual") {
      // if (!fiscalEmail || !fiscalCity || !fiscalFullname)
      //   return emmitError("Niekompletne dane fiskalne");
      fiscalData = {
        fiscalType: taxType,
        // fiscalCity,
        // fiscalFullname,
        // fiscalEmail,
      };
    } else {
      if (!fiscalTaxNo) return emmitError("Niekompletne dane fiskalne");
      fiscalData = {
        fiscalType: taxType,
        fiscalTaxNo,
        fiscalCompanyName,
        fiscalCompanyManual,
      };
    }
    // console.log(participantsMatrix)
    Object.keys(participantsMatrix).forEach((key) => {
      participantsMatrix[key].forEach((participant) => {
        if (participant == "") {
          emmitError("Uzupełnij dane uczestników");
          return;
        }
      })
    })

    let popCart = populatedCart.map((item) => {
      let modItem = { ...item };
      if (modItem.type === "individual") {
        modItem.consentType = consentType;
        if (consentType === "minor") {
          modItem.guardian = guardian;
        }
      }

      modItem.participants = [];
      participantsMatrix[item._id].forEach((participant) => {
        modItem.participants.push(participant);
      });

      return modItem;
    });

    // console.log(popCart);

    dispatch(checkout({ promoCode, cart: popCart, fiscalData, history }));
  };


  useEffect(() => {
    dispatch(populateCart());
    setIsErrorCode(false);
  }, []);

  useEffect(() => {
    if (!usedPromoCode) {
      /// calculate initial price
      if (populatedCart.length > 0) {
        var total = 0;

        populatedCart.forEach((element) => {
          total += element.price;
        });

        setOriginalCost(total);
        setCost(total);
        setPromoCode("");
        setUsedPromoCode(undefined);
        setIsErrorCode(false);
        setIsSuccessCode(false);
      }
    }

    prepTables();

  }, [populatedCart]);


  useEffect(() => {
    const anyIndividual = populatedCart.reduce(
      (acc, c) => acc || c.type === "individual",
      false
    );
    setRenderAdditional(anyIndividual);
    if (!anyIndividual) setBuyButtonDisabled({ success: true });
  
  }, [populatedCart.length]);

  useEffect(() => {
    setBuyButtonDisabled(validatePopulatedCartConsents());
  }, [consentType, guardian]);

  const checkoutPromoHintClass = isErrorCode
    ? classnames(styles.checkoutPromoHint, styles.checkoutPromoHintRed)
    : isSuccessCode
      ? classnames(styles.checkoutPromoHint, styles.checkoutPromoHintGreen)
      : styles.checkoutPromoHint;

  const handleDeletePromoCode = () => {
    dispatch(populateCart());
    setUsedPromoCode(null);
    setPromoCode("");
    setCost(originalCost);

    setIsErrorCode(false);
    setIsSuccessCode(false);
    emmitError("Usunąłeś kod z koszyka");
  };

  const verifyFiscalTaxNo = () => {
    dispatch(verifyTaxNo(fiscalTaxNo));
  };

  if (loading) return <Loading />;

  if (populatedCart.length === 0) {
    return (
      <div className={styles.emptyCardContainer}>
        {renderErrorsList()}
        <div className={styles.emptyWrapper}>
          <div className={styles.txt}> Twój koszyk jest pusty </div>
          <div
            onClick={() => history.push("/cycles")}
            className={styles.linkItem}
          >
            Przejdź do dostępnych zajęć
          </div>
        </div>
      </div>
    );
  }

  const overallPrice = priceItems.reduce((a, b) => a + b.price, 0);
  const overallInitialPrice = priceItems.reduce((a, b) => a + b.initialPrice, 0);
  const isThereAnyDiscount = overallPrice < overallInitialPrice;

  return (
    <div className={styles.checkoutWrapper}>
      <Helmet>
        <title>Koszyk - Stolica eXperymentu</title>
      </Helmet>

      <div className={styles.checkoutContent}>
        <div className={styles.checkoutCyclesContainer}>
          {renderErrorsList()}
          <div className={styles.checkoutCycles}>
            <div className={styles.checkoutCyclesHeader}>
              <div className={styles.headerText}>
                <CategoryIcon
                  sx={{ fontSize: "36px", marginRight: "10px" }}
                ></CategoryIcon>
                <Typography className={styles.headerTitle}>
                  Przedmioty
                </Typography>
              </div>

              <IconButton
                color="primary"
                onClick={() => dispatch(clearCart())}
                disabled={loading}
              >
                <DeleteIcon />
              </IconButton>
            </div>

            <div className={styles.checkoutCardsContainer}>
              <AnimatePresence mode={"popLayout"} initial={false}>
                {populatedCart.map((el,i) => {
                  return (
                    <motion.div
                      layout
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ type: "spring", stiffness: 200, duration: 0.2 }}
                      key={el._id}
                    >
                      <CheckoutCard
                        currencySign={currencySign}
                        data={el}
                        updateParticipants={updateParticipants}
                        modifyCount={modifyCount}
                        modifyMatrix={modifyMatrix}
                        user={user}
                        count={countTable[el._id]}
                        itemPrice={priceItems.find((item) => item._id === el._id)}
                        ownParticipants={participantsMatrix[el._id]}
                      ></CheckoutCard>
                      {i < populatedCart.length - 1 && (
                        <div className={styles.spacer}></div>
                      )}
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            </div>
          </div>

          {renderAdditional && (
            <div className={styles.additionalInfo}>
              <div className={styles.header}>
                <AssignmentLateIcon
                  sx={{ fontSize: "36px", marginRight: "10px" }}
                />

                <div className={styles.content}>Dodatkowe informacje</div>
              </div>
              <div className={styles.content}>
                <div className={styles.consentHolder}>
                  <h4 className={styles.consentInfo}>
                    Jeśli jesteś osobą niepełnoletnią Twój opiekun musi wyrazić
                    zgodę na udział w zajęciach{" "}
                  </h4>
                  <div className={styles.consentSection}>
                    <div className={styles.radioButton}>
                      <Radio
                        checked={consentType === "adult"}
                        onChange={() => setConsentType("adult")}
                      />
                      <p
                        className={styles.radioLabel}
                        onClick={() => setConsentType("adult")}
                      >
                        Jestem osobą pełnoletnią.
                      </p>
                    </div>
                    <div
                      className={classNames(
                        styles.radioButton,
                        styles.radioButtonMargin
                      )}
                    >
                      <Radio
                        style={{ alignSelf: "start" }}
                        checked={consentType === "minor"}
                        onChange={() => setConsentType("minor")}
                      />
                      <span
                        className={styles.radioLabel}
                        onClick={(e) => setConsentType("minor")}
                      >
                        Wyrażam zgodę na udział na udział mojego podopiecznego w
                        zajęciach. <br />
                      </span>
                    </div>
                    {consentType == "minor" && (
                      <TextField
                        className={styles.guardianInput}
                        style={{ minWidth: 250 }}
                        disabled={consentType !== "minor"}
                        value={guardian}
                        id="promoCode"
                        onChange={(e) => {
                          setGuardian(e.target.value);
                        }}
                        inputProps={{ maxLength: maxLengths.name * 2 }}
                        label="Imię i nazwisko opiekuna"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <FiscalData
            setTaxType={setTaxType}
            setFiscalTaxNo={setFiscalTaxNo}
            taxType={taxType}
            fiscalTaxNo={fiscalTaxNo}
            fiscalCompanyManual={fiscalCompanyManual}
            companyFiscalDataLoading={companyFiscalDataLoading}
            verifyFiscalTaxNo={verifyFiscalTaxNo}
            fiscalCompanyName={fiscalCompanyName}
            setFiscalCompanyName={setFiscalCompanyName}
            companyFiscalData={companyFiscalData}
            toggleFiscalCompanyManual={toggleFiscalCompanyManual}
            maxLengths={maxLengths}
          />

        </div>

        <div className={styles.checkoutControls}>
          <div className={styles.checkoutPromo}>
            <div className={styles.checkoutControlsHeader}>
              <div className={styles.headerText}>
                <LocalOfferIcon
                  sx={{ fontSize: "36px", marginRight: "10px" }}
                ></LocalOfferIcon>
                <Typography className={styles.headerTitle}>
                  Kod promocyjny
                </Typography>
              </div>
            </div>
            <div className={styles.checkoutPromoInput}>
              <TextField
                className={styles.input}
                value={promoCode}
                id="promoCode"
                onChange={(e) => {
                  setPromoCode(e.target.value);
                }}
                label="Wpisz kod"
              />
              <LoadingButton onClick={() => checkPromoCode(promoCode)}>
                Zastosuj kod
              </LoadingButton>
            </div>
            <div className={checkoutPromoHintClass}>
              {isErrorCode ? (
                <Typography>Niepoprawny kod. Spróbuj ponownie</Typography>
              ) : (
                isSuccessCode && <Typography>Zastosowano kod</Typography>
              )}
            </div>
          </div>

          <div className={
            priceLoading ? classnames(styles.checkoutSummary, styles.checkoutSummaryLoading) :
            styles.checkoutSummary
            }>
            <div className={styles.checkoutControlsHeader}>
              <div className={styles.headerText}>
                <SummarizeIcon
                  sx={{ fontSize: "36px", marginRight: "10px" }}
                ></SummarizeIcon>
                <Typography className={styles.headerTitle}>
                  Podsumowanie
                </Typography>
              </div>
            </div>
            <div className={styles.horizontal}></div>
            {usedPromoCode && (
              <div className={styles.promoCalculations}>
                <div className={styles.boxContainer}>
                  <div className={styles.deletePromoCode}>
                    <ConfirmButton
                      cancelStartIcon={<CloseIcon />}
                      confirmStartIcon={<DoneAllIcon />}
                      questionText="Czy chcesz usunąć ten kod promocyjny?"
                      onConfirm={handleDeletePromoCode}
                    >
                      <Delete
                        sx={{
                          fontSize: 36,
                          marginLeft: "-5px",
                          marginRight: "5px",
                        }}
                      ></Delete>
                    </ConfirmButton>
                  </div>

                  <div className={styles.box}>
                    <div className={styles.boxHeader}>
                      <Typography> {usedPromoCode.code}</Typography>
                    </div>
                    <div className={styles.boxAmount}>
                      <Typography sx={{ textAlign: "center" }}>
                        {usedPromoCode.amount}{" "}
                        {usedPromoCode.type === "percent"
                          ? "%"
                          : ` ${currencySign}`}
                      </Typography>
                    </div>
                  </div>
                </div>

                <ArrowForwardIcon sx={{ fontSize: "36px" }}></ArrowForwardIcon>
                <div className={styles.substraction}>
                  <Typography>
                    &nbsp;{overallInitialPrice} {currencySign}
                  </Typography>
                  <Typography>
                    {usedPromoCode.type === "balance" ? (
                      <>
                        -{usedPromoCode.amount} {currencySign}
                      </>
                    ) : (
                      <>-{usedPromoCode.amount}%</>
                    )}
                  </Typography>
                  <div className={styles.substractionHorizontal}></div>
                  <Typography>
                    {overallPrice} {currencySign}
                  </Typography>
                </div>
              </div>
            )}
            <div className={styles.checkoutSummaryContent}>
              <div className={styles.totalCost}>
                <PaymentIcon sx={{ fontSize: 36 }}></PaymentIcon>
                <div className={styles.totalCostArea}>
                  <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                    Całkowity koszt
                  </Typography>
                  <div className={styles.cost}>
                    {usedPromoCode && (
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontWeight: 500,
                          marginRight: "5px",
                        }}
                        className={styles.crossedOut}
                      >
                        {overallInitialPrice} {`${currencySign}`}
                      </Typography>
                    )}
                    

                    <Typography sx={{ fontSize: 24, fontWeight: 700 }}>
                      {overallPrice} {`${currencySign}`}
                    </Typography>
                  </div>
                </div>
              </div>
              <Tooltip
                disableHoverListener={isBuyButtonDisabled.success}
                title={isBuyButtonDisabled.error || ""}
                placement="top"
              >
                <span>
                  <LoadingButton
                    onClick={handleCheckout}
                    variant="contained"
                    className={styles.payButton}
                    endIcon={<KeyboardArrowRightIcon />}
                    loading={checkoutLoading || priceLoading}
                    disabled={isBuyButtonDisabled.error}
                  >
                    Zapłać
                  </LoadingButton>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect()(CheckOut);


