import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../API/APIService";

import { history } from "../../index.jsx";
import { constructQuery } from "../../svc/svc";
import { emmitError, emmitSuccess } from "../../utils/ToastEmmiter";

export const fetchNews = createAsyncThunk(
  "news/fetch",
  async (id, { rejectWithValue }) => {
    let response = null;
    await API.get(`/news/?id=${id}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const addPost = createAsyncThunk(
  "news/add",
  async (data, { rejectWithValue }) => {
    let response = null;
    await API.post("/news/", data)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const setPage = createAsyncThunk(
  "news/setPage",
  async (obj, { rejectWithValue }) => {
    let response = null;
    await API.get(`/news?${constructQuery(obj)}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const updatePost = createAsyncThunk(
  "news/update",
  async (obj, { rejectWithValue }) => {
    let response = null;
    await API.put("/news/", obj)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const deletePost = createAsyncThunk(
  "news/delete",
  async (id, { rejectWithValue }) => {
    let response = null;
    await API.delete(`/news/?_id=${id}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const fetchArchives = createAsyncThunk(
  "news/archives",
  async (id, { rejectWithValue }) => {
    let response = null;

    await API.get(`/news/archives`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const fetchFeaturedEvents = createAsyncThunk(
  "news/featuredEvents",
  async (limit, { rejectWithValue }) => {
    let response = null;

    await API.get(`/events/cycles?page=1&limit=${limit}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const newsSlice = createSlice({
  name: "news",
  initialState: {
    news: [],
    singleNews: {},
    archives: [],
    featuredEvents: [],
    page: 1,
    totalPages: 0,
    loading: false,
    newsLoading: false,
    addPostLoading: false,
    updatePostLoading: false,
    deletePostLoading: false,
    archivesLoading: false,
    featuredLoading: false,
  },
  reducers: {
    setSingleNews: (state, action) => {
      state.singleNews = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNews.fulfilled, (state, action) => {
      state.singleNews = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchNews.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchNews.rejected, (state, action) => {
      state.loading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(setPage.pending, (state, action) => {
      state.newsLoading = true;
    });

    builder.addCase(setPage.rejected, (state, action) => {
      state.newsLoading = false;
    });

    builder.addCase(setPage.fulfilled, (state, action) => {
      const searchParams = new URLSearchParams(history.location.search);
      if (action.payload.page === 1) searchParams.delete("page");
      else searchParams.set("page", action.payload.page);
      history.replace({ search: searchParams.toString() });

      state.news = action.payload.docs;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.newsLoading = false;
    });

    builder.addCase(addPost.fulfilled, (state, action) => {
      state.addPostLoading = false;
      emmitSuccess("Pomyślnie dodano post");
    });

    builder.addCase(addPost.pending, (state, action) => {
      state.addPostLoading = true;
    });

    builder.addCase(addPost.rejected, (state, action) => {
      state.addPostLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(updatePost.fulfilled, (state, action) => {
      state.updatePostLoading = false;
      state.singleNews = action.payload;
      emmitSuccess("Pomyślnie zaktualizowano post");
    });

    builder.addCase(updatePost.pending, (state, action) => {
      state.updatePostLoading = true;
    });

    builder.addCase(updatePost.rejected, (state, action) => {
      state.updatePostLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(deletePost.fulfilled, (state, action) => {
      state.deletePostLoading = false;
      emmitSuccess("Pomyślnie usunięto post");
      history.push("/news");
    });

    builder.addCase(deletePost.pending, (state, action) => {
      state.deletePostLoading = true;
    });

    builder.addCase(deletePost.rejected, (state, action) => {
      state.deletePostLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(fetchArchives.fulfilled, (state, action) => {
      state.archivesLoading = false;
      state.archives = action.payload;
    });

    builder.addCase(fetchArchives.pending, (state, action) => {
      state.archivesLoading = true;
    });

    builder.addCase(fetchArchives.rejected, (state, action) => {
      state.archivesLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(fetchFeaturedEvents.fulfilled, (state, action) => {
      state.featuredLoading = false;

      state.featuredEvents = action.payload.docs;
    });

    builder.addCase(fetchFeaturedEvents.pending, (state, action) => {
      state.featuredLoading = true;
    });

    builder.addCase(fetchFeaturedEvents.rejected, (state, action) => {
      state.featuredLoading = false;
      emmitError(action.payload.data.error);
    });
  },
});

const { actions, reducer } = newsSlice;

// Action creators are generated for each case reducer function
export const { setSingleNews } = actions;

export const selectNews = (state) => {
  return state.news.news;
};

export const selectNewsLoading = (state) => {
  return state.news.newsLoading;
};
export const selectPages = (state) => {
  return {
    page: state.news.page,
    pageCount: state.news.totalPages,
  };
};

export const selectSingle = (state) => {
  return {
    singleNews: state.news.singleNews,
    loading: state.news.loading,
  };
};

export const selectAddPostLoading = (state) => {
  return state.news.addPostLoading;
};

export const selectArchives = (state) => {
  return {
    archives: state.news.archives,
    loading: state.news.archivesLoading,
    years: state.news.archives
      .map((e) => e.year)
      .filter(function (item, pos, self) {
        return self.indexOf(item) === pos;
      }),
  };
};

export const selectFeaturedEvents = (state) => {
  return {
    cycles: state.news.featuredEvents,
    loading: state.news.featuredLoading,
  };
};

export default reducer;
