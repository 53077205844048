import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { fetchArchives, selectArchives } from "../../redux/slices/newsSlice";
import styles from "./news.module.scss";
import stolicax from "../../assets/stolica-x.png";

import classNames from "classnames";
import { useSelector } from "react-redux";
import { getMonthName } from "../../svc/svc";
import { useDispatch } from "react-redux";
import Loading from "../../components/universal/Loading";

function SingleLink(props) {
  const { month, year, selectDate } = props;

  return (
    <p
      className={styles.singleLink}
      onClick={() => {
        selectDate(year, month);
      }}
    >
      {getMonthName(month)}
    </p>
  );
}

function Group(props) {
  const { archives, year, ...rest } = props;
  const [expanded, setExpanded] = useState(props.default ? true : false);

  return (
    <div className={styles.section}>
      <p onClick={() => setExpanded(!expanded)} className={styles.title}>
        {year}
      </p>
      <div
        className={
          expanded
            ? classNames(styles.linkList, styles.expanded)
            : styles.linkList
        }
      >
        {archives
          .filter((e) => e.year === year)
          .map((arch) => (
            <SingleLink
              key={year * 12 + parseInt(arch.month)}
              year={year}
              month={arch.month}
              selectDate={props.selectDate}
            />
          ))}
      </div>
    </div>
  );
}

function Archive(props) {
  const { archives, loading, years } = useSelector(selectArchives);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!archives || archives?.length === 0) dispatch(fetchArchives());
  }, []);

  return (
    <>
      <div className={styles.block}>
        <div className={styles.header}>
          <p>Archiwum</p>
          <img
            onClick={() => props.selectDate(undefined, undefined)}
            src={stolicax}
            alt="X"
            className={styles.xLogo}
          ></img>
        </div>

        <div className={styles.content}>
          {loading ? (
            <Loading />
          ) : (
            years.map((year) => {
              return (
                <Group
                  key={year * 12}
                  year={year}
                  default={year === new Date().getFullYear()}
                  archives={archives.filter((e) => e.year === year)}
                  selectDate={props.selectDate}
                />
              );
            })
          )}
        </div>
      </div>
    </>
  );
}

export default connect()(Archive);
