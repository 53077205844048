import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../API/APIService";

import { emmitError, emmitSuccess } from "../../utils/ToastEmmiter";

export const fetchDisplays = createAsyncThunk("displays/fetch", async (id, { rejectWithValue }) => {

  let response = null;
  await API.get(`/displays/`).then(r => { response = { data: r.data } }).catch(error => {
    console.log(error);
    throw rejectWithValue(error.response);
  })

  return response.data;

});

export const fetchSingleDisplay = createAsyncThunk(
  "displays/fetchSingle",
  async (id, { rejectWithValue }) => {

    let response = null;
    await API.get(`/displays/?_id=${id}`).then(r => { response = { data: r.data } }).catch(error => {
      console.log(error);
      throw rejectWithValue(error.response);
    })

    return response.data;

  }
);

export const addDisplay = createAsyncThunk(
  "displays/add",
  async (data, { rejectWithValue }) => {

    const { handleClose, ...rest } = data;
    let response = null;
    await API.post("/displays/", rest).then(r => { response = { data: r.data }; handleClose(); }).catch(error => {
      console.log(error);
      throw rejectWithValue(error.response);
    })



    return response.data;

  }
);

export const updateDisplay = createAsyncThunk(
  "displays/update",
  async (obj, { rejectWithValue }) => {
    const { turnOffEditMode, ...rest } = obj;

    let response = null;
    await API.put("/displays/", rest)
      .then(r => { response = { data: r.data }; turnOffEditMode(); })
      .catch(error => {
        console.log(error);
        throw rejectWithValue(error.response);
      })

    return response.data;

  }
);

export const sendConfig = createAsyncThunk(
  "displays/sendConfig",
  async (obj, { rejectWithValue }) => {

    let response = null;
    await API.post("/displays/sendConfig", obj).then(r => { response = { data: r.data }; })
      .catch(error => {
        console.log(error);
        throw rejectWithValue(error.response);
      })


    return response.data;

  }
);

export const sendPause = createAsyncThunk(
  "displays/sendPause",
  async (id, { rejectWithValue }) => {

    let response = null;
    await API.get("/displays/sendPause?_id=" + id).then(r => { response = { data: r.data }; })
      .catch(error => {
        console.log(error);
        throw rejectWithValue(error.response);
      })

    return response.data;

  }
);

export const sendResume = createAsyncThunk(
  "displays/sendResume",
  async (id, { rejectWithValue }) => {

    let response = null;
    await API.get("/displays/sendResume?_id=" + id).then(r => { response = { data: r.data }; })
      .catch(error => {
        console.log(error);
        throw rejectWithValue(error.response);
      })

    return response.data;

  }
);

export const deleteDisplay = createAsyncThunk(
  "displays/delete",
  async (obj, { rejectWithValue }) => {

    let response = null;
    await API.delete(`/displays/?_id=${obj._id}`)
      .then(r => { response = { data: r.data }; obj.turnOffEditMode(); })
      .catch(error => {
        console.log(error);
        throw rejectWithValue(error.response);
      })


    return obj._id;

  }
);
export const displaysSlice = createSlice({
  name: "displays",
  initialState: {
    displays: [],
    singleDisplay: {},
    loading: false,
    loadingDelete: false,
    loadingAdd: false,
    loadingUpdate: false,
    loadingConfig: false,
  },
  reducers: {
    setSingleDisplay: (state, action) => {
      state.singleDisplay = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDisplays.fulfilled, (state, action) => {
      state.displays = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchDisplays.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchDisplays.rejected, (state, action) => {
      state.loading = false;
      emmitError(action.payload.data.error)
    });

    builder.addCase(fetchSingleDisplay.fulfilled, (state, action) => {
      state.singleDisplay = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchSingleDisplay.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchSingleDisplay.rejected, (state, action) => {
      state.loading = false;
      emmitError(action.payload.data.error)
    });

    builder.addCase(deleteDisplay.pending, (state, action) => {
      state.loadingDelete = true;
    });

    builder.addCase(deleteDisplay.rejected, (state, action) => {
      state.loadingDelete = false;
      emmitError(action.payload.data.error)
    });

    builder.addCase(deleteDisplay.fulfilled, (state, action) => {
      const _id = action.payload;
      state.singleDisplay = {};
      const index = state.displays.findIndex((el) => el._id === _id);
      if (index >= 0) state.displays.splice(index, 1);
      state.loadingDelete = false;
      emmitSuccess("Pomyślnie usunięto wyświetlacz")
    });

    builder.addCase(addDisplay.pending, (state, action) => {
      state.loadingAdd = true;
    });

    builder.addCase(addDisplay.rejected, (state, action) => {
      state.loadingAdd = false;
      emmitError(action.payload.data.error)
    });

    builder.addCase(addDisplay.fulfilled, (state, action) => {
      state.displays = state.displays.concat(action.payload);
      state.loadingAdd = false;
      emmitSuccess("Pomyślnie dodano wyświetlacz")
    });

    builder.addCase(updateDisplay.pending, (state, action) => {
      state.loadingUpdate = true;
    });

    builder.addCase(updateDisplay.rejected, (state, action) => {
      state.loadingUpdate = false;
      emmitError(action.payload.data.error)
    });

    builder.addCase(updateDisplay.fulfilled, (state, action) => {
      const _id = action.payload._id;
      const index = state.displays.findIndex((el) => el._id === _id);
      if (index >= 0) {
        state.displays[index] = action.payload;
      }
      state.singleDisplay = action.payload;

      state.loadingUpdate = false;
      emmitSuccess("Pomyślnie zaktualizowano wyświetlacz")
    });

    builder.addCase(sendConfig.pending, (state, action) => {
      state.loadingConfig = true;
    });

    builder.addCase(sendConfig.rejected, (state, action) => {
      state.loadingConfig = false;
      emmitError(action.payload.data.error)
    });

    builder.addCase(sendConfig.fulfilled, (state, action) => {
      const _id = action.payload._id;
      const index = state.displays.findIndex((el) => el._id === _id);
      if (index >= 0) {
        state.displays[index].data = action.payload.data;
      }
      state.singleDisplay.data = action.payload.data;

      state.loadingConfig = false;
      emmitSuccess("Pomyślnie przesłano konfigurację")
    });
  },
});

const { actions, reducer } = displaysSlice;

// Action creators are generated for each case reducer function
export const { setSingleDisplay } = actions;

export const selectDisplays = (state) => {
  return state.displays.displays;
};

export const selectSingleDisplay = (state) => {
  return state.displays.singleDisplay;
};

export const selectLoadings = (state) => {
  return {
    general: state.displays.loading,
    update: state.displays.loadingUpdate,
    delete: state.displays.loadingDelete,
    add: state.displays.loadingAdd,
    config: state.displays.loadingConfig,
  };
};

export default reducer;
