import React, { useEffect, useState } from "react";
import Pagination from "../../components/Pagination/Pagination";
import styles from "./Orders.module.scss"

import { setSingleOrder, selectOrders, selectOrdersLoading, selectOrdersPagination, setPageOrdersByUser } from "../../redux/slices/ordersSlice"
import OrderCard from "./OrderCard"

function Orders(props) {
    return (
        <div className={styles.ordersContainer}>

            <div className={styles.ordersWrapper}>
                <div className={styles.header}>
                    Twoje zamówienia
                </div>
                <Pagination
                    double
                    dataSelector={selectOrders}
                    pagesSelector={selectOrdersPagination}
                    loadingSelector={selectOrdersLoading}
                    setPage={setPageOrdersByUser}
                    emptyText={"Brak zamówień do wyświetlenia"}
                    emptyHint={"Tutaj pojawią się dostępne zamówienia"}
                    dataContainerClassName={styles.feedbackCardsContainer}
                    customDispatchProps={{ user: undefined }}
                    ElementCard={OrderCard}
                    singlePath="/orders/"
                    setSingle={setSingleOrder}
                />
            </div>

        </div>
    )
}



export default Orders;