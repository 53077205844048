import React, { useEffect, useState, useHref } from "react";

import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  createUserCycleReservation,
  selectUserCycleReservationsActionLoading,
  selectUserCycleReservationsLoading,
} from "../../../../redux/slices/manageUsersSlice";
import styles from "./CycleReservations.module.scss";

import FormGroup from "@mui/material/FormGroup";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { TextField, Tooltip } from "@mui/material";
import AutoCompleteSearch from "../../../../components/autoCompleteSearch/AutoCompleteSearch";
import { selectMaxLengths } from "../../../../redux/slices/utilsSlice";
import { emmitError } from "../../../../utils/ToastEmmiter";
import Count from "../../../checkout/Count";

import FormControl from "@mui/material/FormControl";

import FormControlLabel from "@mui/material/FormControlLabel";

import InputLabel from "@mui/material/InputLabel";
import classnames from "classnames";
import Loading from "../../../../components/universal/Loading";
import {
  getPrice,
  selectCompanyFiscalItems,
  selectPrice,
  verifyTaxNo
} from "../../../../redux/slices/cartSlice";
import FiscalData from "../../../checkout/components/FiscalData";


const DEFAULT_PARTICIPANT = { fullName: "", phone: "", age: 10, shouldRenderSupervisor: true }
const DEFAULT_GROUP = { fullName: "", phone: "", age: 99, shouldRenderSupervisor: false, note: "" }


function UserCycleReservationAdd({
  userData,
  anonymusUser,
  onSuccess,
  wantToClearData,
  initCycle,
}) {
  const loading = useSelector(selectUserCycleReservationsLoading);
  const actionLoading = useSelector(selectUserCycleReservationsActionLoading);
  const [particpantsCount, setParticpantsCount] = useState(1);
  const [participants, setParticipants] = useState([
    DEFAULT_PARTICIPANT
  ]);
  const [paymentOptionChecked, setPaymentOptionChecked] = React.useState(false);

  const maxLengths = useSelector(selectMaxLengths);
  const { currencySign, name, onTheSpotPaymentMethods, description } = maxLengths;
  const [selectedCycle, setSelectedCycle] = useState(initCycle ? [initCycle] : []);
  const [duplicateNewParticipant, setDuplicateNewParticipant] = useState(false);
  //const [newMailingList, setNewMailingList] = useState([]);

  //const [editMailingList, setEditMailingList] = useState([]);
  //const [editStatus, setEditStatus] = useState("pending");
  const cart = useSelector(selectPrice);
  const priceItems = cart.items
  const grouppedPriceItems = priceItems.reduce((a, b) => {
    let existsbool = a.hasOwnProperty(b.name)
    if (existsbool)
      a[b.name].push(b)
    else {
      a[b.name] = [b]
    }
    return a
  }, {})
  const priceLoading = cart.loading;

  const dispatch = useDispatch();

  const handleCreateCycleReservation = async () => {
    if (selectedCycle.length == 0) return emmitError("Nie wybrano cyklu zajęć!");
    if (paymentOptionChecked && !paymentMethod)
      return emmitError("Nie wybrano metody płatności!");



    if (
      participants.length === 0
    )
      return emmitError("Nie podano uczestników!");

    for (let i = 0; i < participants.length; i++) {
      let participant = participants[i];
      if (!participant.fullName || participant.fullName.length === 0)
        return emmitError("Nie podano imienia i nazwiska uczestnika #" + (i + 1) + "!");

      if (!participant.phone || participant.phone.length === 0)
        return emmitError("Nie podano numeru telefonu uczestnika #" + (i + 1) + "!");

      if (!participant.age || participant.age.length === 0 || participant.age < 0)
        return emmitError("Nie podano wieku uczestnika #" + (i + 1) + "!");

      if (participant.age < 18 && !participant.supervisor)
        return emmitError("Nie podano opiekuna uczestnika #" + (i + 1) + "!");

    }


    const res = await dispatch(
      createUserCycleReservation({
        userId: userData?._id,
        cycleIds: selectedCycle.reduce((a, b) => [...a, b._id], []),
        participants: participants,
        organizedGroup: isOrganizedGroup,
        anonymusUser,
        paymentOptionChecked,
        paymentMethod,
        fiscalData: {
          fiscalType: taxType,
          fiscalTaxNo,
          fiscalCompanyManual,
          fiscalCompanyName,
        },
        //mailingList: newMailingList,
      })
    );
    if (res.meta.requestStatus === "fulfilled" && onSuccess) {
      onSuccess();
    }
    if (res.meta.requestStatus === "fulfilled" && wantToClearData) {
      clearData();
    }
  };

  const handleSetParticipantsCount = (count) => {
    if (count > 0) setParticpantsCount((p) => p + count);
    else setParticpantsCount((p) => p - 1);
    if (duplicateNewParticipant) {
      if (count < 0)
        setParticipants((participants) =>
          participants.slice(0, participants.length - 1)
        );
      else {
        let participantsCopy = [...participants];
        participantsCopy.push({ ...participantsCopy[participantsCopy.length - 1] });
        setParticipants(participantsCopy);
      }
    } else {
      if (count < 0)
        setParticipants((participants) =>
          participants.slice(0, participants.length - 1)
        );
      else
        setParticipants((participants) => [...participants, { ...DEFAULT_PARTICIPANT }]);
    }
  };

  ///on participantsLengthChange
  useEffect(() => {
    if (participants.length > 0 && selectedCycle.length > 0) {
      calculatePrice();
    }
  }, [participants.length]);

  //onPaymentOptionChange
  //onParticipantAgeChange

  useEffect(() => {
    if (participants.length > 0 && selectedCycle.length > 0) {
      calculatePrice();
    }
  }, [paymentOptionChecked]);


  //onNewSelectedCycle
  useEffect(() => {
    if (participants.length > 0 && selectedCycle.length > 0) {
      calculatePrice();
    }
  }, [selectedCycle.length]);


  const calculatePrice = () => {
    if (participants.length > 0 && selectedCycle.length > 0 && paymentOptionChecked)
      dispatch(getPrice(
        selectedCycle.map(e => {
          return {
            _id: e._id,
            participants: participants,
            cartType: "cycle",
          }
        })
      ))
  };
  //FOR ADDING RESERVATION

  const [taxType, setTaxType] = useState("individual");
  const [fiscalTaxNo, setFiscalTaxNo] = useState();
  const [fiscalCompanyManual, setFiscalCompanyManual] = useState(false);
  const [fiscalCompanyName, setFiscalCompanyName] = useState();
  const [isOrganizedGroup, setIsOrganizedGroup] = useState(false)



  const { companyFiscalData, companyFiscalDataLoading } = useSelector(
    selectCompanyFiscalItems
  );

  const verifyFiscalTaxNo = () => {
    dispatch(verifyTaxNo(fiscalTaxNo));
  };

  const toggleFiscalCompanyManual = () =>
    setFiscalCompanyManual(!fiscalCompanyManual);

  const handleSetSelectedCycle = (cycle) => {
    setSelectedCycle(cycle);
  };



  const handlePaymentOptionChecked = (event) => {
    if (selectedCycle.length > 0) setPaymentOptionChecked(event.target.checked);
    else emmitError("Nie wybrano cyklu zajęć!");
  };

  const [paymentMethod, setPaymentMethod] = React.useState(undefined);

  const handlePaymentMethodSelectionChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleChangeParticipantInfo = (e, i) => {
    if (!e.target.name) return;
    let newParticipants = [...participants];
    let newParticipant = newParticipants[i];
    newParticipant[e.target.name] = e.target.value;
    newParticipants[i] = newParticipant;

    if (e.target.name === "age") {
      newParticipant.shouldRenderSupervisor = parseInt(e.target.value) < 18;
    }



    setParticipants(newParticipants);

  };

  const handleSetParticipantFromAutocomplete = (user, i) => {

    let newParticipants = [...participants];
    let newParticipant = newParticipants[i];
    if (user.fullName)
      newParticipant.fullName = user.fullName;
    const userPhone = user.phone?.number || user.participant?.phone;
    if (userPhone)
      newParticipant.phone = userPhone;
    if (user.participant?.age)
      newParticipant.age = user.participant.age;
    if (user.participant?.supervisor) {
      newParticipant.shouldRenderSupervisor = true;
      newParticipant.supervisor = user.participant.supervisor;
    }
    else if (user.participant?.age < 18)
      newParticipant.shouldRenderSupervisor = true;


    newParticipants[i] = newParticipant;

    setParticipants(newParticipants);

  }



  const clearData = () => {
    setSelectedCycle([]);
    setParticpantsCount(1);
    setParticipants([DEFAULT_PARTICIPANT]);
    setPaymentMethod(undefined);
    setPaymentOptionChecked(false);
    setTaxType("individual");
    setFiscalTaxNo(undefined);
    setFiscalCompanyManual(false);
    setFiscalCompanyName(undefined);
  };

  const handleSetIsOrganizedGroup = e => {
    const value = e.target.checked
    if (value) {
      setParticpantsCount(1)
      setParticipants([DEFAULT_GROUP])
      setPaymentOptionChecked(false);
      setPaymentMethod(undefined);
      setIsOrganizedGroup(value);
    } else {
      setIsOrganizedGroup(value)
      clearData()
    }
  }

  const handleCycleNewTab = (id) => {
    const to = `/cycles/${id}`
    window.open(to, '_blank')
  }

  return (
    <div className={styles.addSection}>
      <div className={styles.title}>
        {anonymusUser
          ? "Zapisz uczestnika na cykl zajęć"
          : "Zapisz uczestnika zajęć, przypisanego do użytkownika " +
          userData.fullName}
      </div>
      {/* <div className={styles.description}>
        {anonymusUser === true && (
          <p>
            {" "}
            Rezerwacja przypisana będzie do <b>użytkownika anonimowego</b>.
            Jeśli osoba zapisująca założy później konto, będzie można ręcznie
            podpiąć tę rezerwację do innego użytkownika.{" "}
          </p>
        )}
        Wyszukaj odpowiedni cykl zajęć, a następnie zatwierdź wybór. Po
        zatwierdzeniu uczestnicy zostaną zapisany na dany cykl, bez względu na
        maksymalną liczbę osób oraz nie będzie wymagana żadna dodatkowa
        płatność.
      </div> */}
      <div className={styles.autoComplete}>
        {initCycle ? (
          <div className={styles.initCycleName}>{initCycle.name}</div>
        ) : (
          <AutoCompleteSearch
            multiple
            searchUrl="/events/searchCycles"
            limit={15}
            setSingle={handleSetSelectedCycle}
            placeholder="Wybierz cykl zajęć"
            renderCycleMeta
            className={styles.searchBar}
          />
        )}
      </div>
      {!isOrganizedGroup ? <>
        <div className={styles.description}>
          Wybierz liczbę osób oraz dane uczestków, którzy zostaną zapisani na wybrane cykle zajęć.
        </div>

        <div className={styles.particpantsCount}>
          <Count
            count={particpantsCount}
            add={() => handleSetParticipantsCount(1)}
            remove={() => handleSetParticipantsCount(-1)}
          />
          <div className={styles.cyclesCounts}>
            {selectedCycle?.length > 0 && (
              selectedCycle.map(cycle => (
                <div className={styles.cycle}>
                  <div className={styles.name}>{cycle.name}</div>
                  <div
                    className={
                      particpantsCount + cycle.reservationsCount <=
                        cycle.capacity
                        ? styles.summary
                        : classnames(styles.summary, styles.warnn)
                    }
                  >
                    {`${particpantsCount} + ${cycle.reservationsCount}`} {"/"}{" "}
                    {cycle.capacity}
                  </div>
                </div>
              ))
            )}
          </div>

        </div>

      </> :
        <>
          <div className={styles.description}>
            Zapisując grupę zorganizowaną zajętość cyklu przybierze formę 1/1. W polu notatka zapisz istotne informacje np. NIP do faktury.
          </div>

          {selectedCycle.filter(a => a.reservationsCount > 0).length > 0 &&
            <div className={styles.cycleHeadup}>
              Uwaga! Niektóre na niektóre z wybranych cykli zajęć, które chcesz zarezerwować dla grupy zorganizowanej, zapisali się wcześniej użytkownicy!
              Kliknij w nazwę cyklu, aby w osobnej karcie otworzyć jego stronę, skąd można zarządzać owymi rezerwacjami.
              {
                selectedCycle.filter(a => a.reservationsCount > 0).map((e,i) =>
                  <div key={i} className={styles.cycle}>
                    <span onClick={()=>handleCycleNewTab(e._id)} className={styles.name}>{e.name}</span> <span className={styles.capacity}>{`${e.reservationsCount} / ${e.capacity}`}</span>
                  </div>
                )
              }
            </div>}
        </>
      }

      {!isOrganizedGroup && <div className={styles.sameParticipantName}>
        <Tooltip
          placement="top"
          title="Duplikowanie ma efekt tylko na ostatniego użytkownika z listy"
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  value={duplicateNewParticipant}
                  onChange={(e) => {
                    setDuplicateNewParticipant(e.target.checked);
                  }}
                />
              }
              label="Duplikuj dane ostatniego uczestnika dla nowo dodawanych uczestników"
            />
          </FormGroup>
        </Tooltip>
      </div>}

      <div className={styles.organizedGroup}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value={isOrganizedGroup}
                onChange={handleSetIsOrganizedGroup}
              />
            }
            label="Grupa zorganizowana"
          />
        </FormGroup>
      </div>

      <div className={styles.participants}>
        {Array.from(Array(particpantsCount).keys()).map((i) => (
          <div key={2 * i}>
            {" "}
            <div key={102 * i + 102} className={styles.inputsHolder}>
              <AutoCompleteSearch
                key={5 * i}
                searchUrl={"/users/searchPeople"}
                label={"Imię i nazwisko #" + (i + 1)}
                variant="outlined"
                name="fullName"
                renderPhone
                freeSolo={true}
                className={styles.participant}
                inputProps={{ maxLength: name * 2 }}
                //value={participants[i]?.fullName || ""}
                //defaultValue={participants[i]?.fullName || ""}
                onChange={(e) => handleChangeParticipantInfo(e, i)}
                setSingle={(e) => handleSetParticipantFromAutocomplete(e, i)}
              />

              <TextField
                key={5 * i + 2}
                label={"Wiek uczestnika #" + (i + 1)}
                variant="outlined"
                className={styles.phone}
                type="number"
                name="age"
                onBlur={calculatePrice}
                inputProps={{ min: 1, max: 120 }}
                value={participants[i]?.age || 10}
                onChange={(e) => handleChangeParticipantInfo(e, i)}
              />

              <TextField
                key={5 * i + 1}
                label={"Nr tel. #" + (i + 1)}
                variant="outlined"
                name="phone"
                className={styles.phone}
                inputProps={{ maxLength: name * 2 }}
                value={participants[i]?.phone || ""}
                onChange={(e) => handleChangeParticipantInfo(e, i)}
              />

              {isOrganizedGroup ?

                <TextField
                  key={99}
                  label={'Notatka'}
                  variant='outlined'
                  name='note'
                  inputProps={{ maxLength: description }}
                  fullWidth
                  rows={4}
                  maxRows={4}
                  multiline
                  value={participants[i]?.note || ""}
                  onChange={(e) => handleChangeParticipantInfo(e, i)} />
                : null}

              {participants[i].shouldRenderSupervisor ? (


                <TextField
                  key={5 * i + 3}
                  label={"Opiekun #" + (i + 1)}
                  variant="outlined"
                  name="supervisor"
                  className={styles.participant}
                  inputProps={{ maxLength: name * 2 }}
                  value={participants[i]?.supervisor || ""}
                  onChange={(e) => handleChangeParticipantInfo(e, i)}
                />

              ) : null}
            </div>
            {/* {// not the last element} */}
            {i < particpantsCount - 1 && <div key={5 * i + 4} className={styles.divider}></div>}
          </div>
        ))}
      </div>


      <div className={styles.onTheSpotPaymentContainer}>
        {!isOrganizedGroup && <div className={styles.checkboxHolder}>
          <Checkbox
            checked={paymentOptionChecked}
            onChange={handlePaymentOptionChecked}
          />
          <div className={styles.label}>Płatność na miejscu</div>
        </div>}

        {paymentOptionChecked && !isOrganizedGroup && (
          <div className={styles.summaryContainer}>
            <div className={styles.disclaimer}>
              <span>
                Zaznaczenie opcji płaności na miejscu wiążę się z obowiązkiem
                zapłaty przez klienta. Przypisanie rezerwacji wiążę się w tym
                momencie z wydrukiem paragonu fiskalnego, co jest czynnością
                nieodwracalną.
              </span>
              <span className={styles.warning}>
                Przed potwierdzeniem rezerwacji klient musi uiścić płatność!
              </span>
            </div>
            <div className={styles.paymentMethod}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Metoda płatności
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={paymentMethod}
                  label="Metoda płatności"
                  onChange={handlePaymentMethodSelectionChange}
                >
                  {Object.keys(onTheSpotPaymentMethods).map((key) => (
                    <MenuItem value={key}>
                      {onTheSpotPaymentMethods[key]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={styles.amountContainer}>
              <div className={styles.header}>Łącznie do zapłaty</div>
              <div className={styles.calculations}>
                {priceLoading ? <Loading /> :
                  <>
                    {priceItems.map(e =>
                      <div className={styles.cycleGroup}>

                        <div className={styles.cycleName}>
                          {e.name}
                        </div>

                        {e?.priceDetails?.map((item) => (
                          <div key={item.price} className={styles.calcList}>
                            <div>{item.noOfParticipants + " " + item.typeOfParticipant}</div>
                            <div className={styles.icon}>
                              <CloseIcon />
                            </div>
                            <div>
                              {item?.unitPrice} {currencySign}
                            </div>
                            <div className={styles.icon}>
                              <ArrowForwardIcon />
                            </div>
                            <div className={styles.finalPrice}>
                              {item.price} {currencySign}
                            </div>
                          </div>
                        ))}

                      </div>

                    )}
                    <div className={styles.calcSummary}>
                      Łącznie: {priceItems.reduce((x, y) => x + y.priceDetails.reduce((a, b) => a + b.price, 0), 0)} {currencySign}
                    </div>
                  </>
                }

              </div>
            </div>

            <FiscalData
              noBorder
              setTaxType={setTaxType}
              setFiscalTaxNo={setFiscalTaxNo}
              taxType={taxType}
              fiscalTaxNo={fiscalTaxNo}
              fiscalCompanyManual={fiscalCompanyManual}
              companyFiscalDataLoading={companyFiscalDataLoading}
              verifyFiscalTaxNo={verifyFiscalTaxNo}
              fiscalCompanyName={fiscalCompanyName}
              setFiscalCompanyName={setFiscalCompanyName}
              companyFiscalData={companyFiscalData}
              toggleFiscalCompanyManual={toggleFiscalCompanyManual}
              maxLengths={maxLengths}
            />
          </div>
        )}
      </div>

      <div className={styles.buttons}>
        <LoadingButton
          disabled={selectedCycle.length === 0}
          variant="outlined"
          loading={actionLoading}
          className={styles.loadingButton}
          onClick={handleCreateCycleReservation}
        >
          Zatwierdź
        </LoadingButton>
      </div>
    </div>
  );
}

export default connect()(UserCycleReservationAdd);
