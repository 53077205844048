import styles from "./checkout.module.scss";
import { IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function Count(props) {
    const { add, remove, count } = props;
    return (
        <div className={styles.countWidget}>
            <IconButton
                disabled={count === 1}
                onClick={remove}
            >
                <RemoveIcon />
            </IconButton>
            <div className={styles.countText}>{count}</div>
            <IconButton
                onClick={add}
            >
                <AddIcon />
            </IconButton>
        </div>
    )
}


export default Count;