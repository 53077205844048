import EdjsDisplay from '../../../components/edjsDisplay/EdjsDisplay'
import Modal from '../../../components/modal/Modal'
import styles from './Products.module.scss'


function ProductDetailsModal(props){
    const {product, visible, closeModal} = props
    return (
        <Modal
        visible={visible}
        title={product?.name}
        closeModal={closeModal}
        height={500}
        isPadding={true}
        >
            <div className={styles.productDetailsModal}>
                {product && (
                    <EdjsDisplay data={product?.description}/>
                )}
            </div>
        </Modal>
    )
}


export default ProductDetailsModal