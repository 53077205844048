import React from "react";

import styles from "./calendarComponents.module.scss";
import EventCard from "./EventCard";
import classnames from 'classnames'

function EventsList(props) {
  if (!props.events || props.events.length === 0) return;

  return (
    <div className={props.strList ? classnames(styles.str8List, styles.eventsListContainer) : styles.eventsListContainer}>
      {!props.noHeader &&
      <div className={styles.header}>
        {props.headerTitle ? props.headerTitle : props.canAddEvents
          ? "Dodane wydarzenia"
          : props.upcoming
            ? "Nadchodzące wydarzenia"
            : "Wydarzenia"}
      </div> }
      <div className={styles.cardsHolder}>
        {props.events.map((event) => (
          <EventCard
            userId={props.userId}
            singleCycle={props.singleCycle}
            userType={props.userType}
            key={event?._id}
            event={event}
            handleClick={props.handleClick}
            handleChangeDisplay={props.handleChangeDisplay}
          />
        ))}
      </div>
    </div>
  );
}

export default EventsList;
