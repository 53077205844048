import PeopleIcon from "@mui/icons-material/People";
import classnames from "classnames";
import React from "react";
import styles from "./Communication.module.scss";
function CycleCard(props) {
  return (
    <div className={styles.card} onClick={() => props.onClick(props.id)}>
      <div
        style={{ backgroundImage: `url(${props.thumbnail})` }}
        className={styles.thumbnail}
      ></div>
      <div
        className={
          props.selected ? classnames(styles.txt, styles.active) : styles.txt
        }
      >
        <div className={styles.name}>{props.name}</div>

        <div className={styles.capacity}>
          <PeopleIcon style={{ marginRight: 2 }} />
          {props.capacity}
        </div>
      </div>
    </div>
  );
}

export default CycleCard;
