import React from "react";
import PanelButton from "../../../../components/panelButton/PanelButton";

import DataObjectIcon from "@mui/icons-material/DataObject";

import { useHistory } from "react-router-dom";

function AddProductButton(props) {
  const history = useHistory();

  return (
    <PanelButton
      onClick={() => history.push("/addProduct")}
      label={"Dodaj produkt"}
      icon={<DataObjectIcon style={{ fontSize: 40 }} />}
      width={100}
      height={100}
    />
  );
}

export default AddProductButton;
