import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIService from "../../../../API/APIService";
import Modal from "../../../../components/modal/Modal";
import PanelButton from "../../../../components/panelButton/PanelButton";
import Loading from "../../../../components/universal/Loading";
import {
  selectUser
} from "../../../../redux/slices/userSlice";
import styles from "./external.module.scss";
function ExternalCalendar(props) {
  const [modal, setModal] = useState(false);
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState("");
  const { _id, fullName } = useSelector(selectUser);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  useEffect(() => {
    async function FetchData() {
      try {
        setLoading(true);
        const res = await APIService.get("/events/getMyICS");
        setLoading(false);
      
        setLink(res.data.link);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    if (modal === true && link.length === 0) FetchData();
  }, [modal]);

  return (
    <>
      <PanelButton
        label="Eksportuj wydarzenia"
        icon={<CalendarMonthIcon style={{ fontSize: 40 }} />}
        width={100}
        height={100}
        onClick={openModal}
      />
      <Modal
        visible={modal}
        closeModal={closeModal}
        height={500}
        title={"Eksportuj wydarzenia do zewnętrzengo kalendarza"}
        isDarkTitle={true}
        fullBody
      >
        {loading ? (
          <Loading />
        ) : (
          <div className={styles.exportContainer}>
            <a href={link} className={styles.downloadButton}>
              <LoadingButton variant="outlined" className={styles.control}>
                Pobierz wydarzenia
              </LoadingButton>
            </a>
            <br />
            ICS Feed:
            <a className={styles.nameContainer} href={link}>
              {link}
            </a>
          </div>
        )}
      </Modal>
    </>
  );
}

export default ExternalCalendar;
