import React, { useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CircularProgress, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import EdjsDisplay from "../../components/edjsDisplay/EdjsDisplay";
import ImageModal from "../../components/imageUploader/ImageModal";
import Empty from "../../components/universal/Empty";
import Loading from "../../components/universal/Loading";
import InventoryIcon from "@mui/icons-material/Inventory";
import PrintIcon from '@mui/icons-material/Print';
import {
  fetchSingleEvent,
  markEventAttendance,
  printEventAttendance,
  selectPrintLoading,
  selectSingleEvent,
} from "../../redux/slices/eventsSlice";
import { selectType } from "../../redux/slices/userSlice";
import styles from "./SingleEvent.module.scss";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Tooltip } from "@mui/material";
import moment from "moment";
import "moment/locale/pl";
import PanelButton from "../../components/panelButton/PanelButton";
import TeacherInfoPopover from "../../components/teacherInfoPopover/TeacherInfoPopover";
import TicketChecker from "./TicketChecker/TicketChecker";
import EventFeedbacks from "./EventFeedbacks/EventFeedbacks";
import Attendance from "./Attendance/Attendance";

function SingleEvent(props) {
  const DATE_FORMAT = "dddd, DD MMMM YYYY HH:mm";

  const dispatch = useDispatch();

  const user = useSelector(selectType);
  const history = useHistory();
  const [imagePreviewVisible, setImagePreviewVisible] = useState(false);

  const { event, loading, mark_loading } = useSelector(selectSingleEvent);

  const [ticketCheckerBool, setTicketCheckerBool] = useState(false);

  const openTicketCheckerModal = () => setTicketCheckerBool(true);
  const closeTicketCheckerModal = () => setTicketCheckerBool(false);
  const printLoading = useSelector(selectPrintLoading)

  const DATE_NOW = moment(new Date());
  const ATTENDANCE_PERIOD = moment(event?.start).subtract(30, "minutes");
  const FEEDBACK_PERIOD = moment(event?.end);

  const SHOULD_RENDER_ATTENDANCE = DATE_NOW >= ATTENDANCE_PERIOD;
  const SHOULD_RENDER_FEEDBACK = DATE_NOW >= FEEDBACK_PERIOD;

  useEffect(() => {
    dispatch(fetchSingleEvent(props.match.params.id));
  }, [props.match.params.id]);

  const HAS_DESC =
    event && event.description && event.description.blocks?.length > 0;

  const handleCycleClick = () => {
    history.push(`/cycles/${event.cycle._id}`);
  };

  if (loading) return <Loading />;

  if (Object.keys(event).length === 0 && loading === false)
    return <Empty text={"Nie znaleziono żądanego cyklu zajęć."} />;

  return (
    <div className={styles.singleEventContainer}>
      <Helmet>
        <title>{event?.title} - Stolica eXperymentu</title>
        <meta name="description" content={event?.description} />
        <meta property="og:description" content={event?.description} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={event?.thumbnail} />
        <meta property="og:title" content={event?.name} />
        <meta name="twitter:title" content={event?.name} />
        <meta name="twitter:description" content={event?.shortDesc} />
        <meta name="twitter:image" content={event?.thumbnail} />
      </Helmet>
      <div className={styles.topHeader}>
        <div className={styles.leftHeader}>
          <div className={styles.controls}>
            <div className={styles.mode}>
              <VisibilityIcon></VisibilityIcon>
              <Typography className={styles.modeText}>
                Podgląd wydarzenia
              </Typography>
            </div>
          </div>

          <div className={styles.title}>
            <Typography className={styles.titleText}>{event.title}</Typography>
          </div>
          <div className={styles.eventModalContainer}>
            <Tooltip title="Cykl zajęć" placement="left">
              <div onClick={handleCycleClick} className={styles.cycleContainer}>
                <div className={styles.name}>
                  <div className={styles.iconContainer}>
                    <BookmarksIcon sx={{ fontSize: 30 }} />
                  </div>
                  <span className={styles.nameSpan}>{event.cycle?.name}</span>
                </div>

                <div
                  style={{
                    backgroundImage: `url(${event.cycle?.thumbnail})`,
                  }}
                  className={styles.thumbnail}
                ></div>
              </div>
            </Tooltip>

            <div className={styles.timestamp}>
              <div className={styles.iconContainer}>
                <ScheduleIcon style={{ fontSize: 30 }} />
              </div>
              <div className={styles.date}>
                <div className={styles.dateFrom}>
                  {moment(event.start).locale("pl").format(DATE_FORMAT)}
                </div>

                <ArrowRightAltIcon className={styles.arrowIcon} />

                <div className={styles.dateTo}>
                  {moment(event.end).locale("pl").format(DATE_FORMAT)}
                </div>
              </div>
            </div>

            <Tooltip title="Produkt" placement="left">
              <div className={styles.productContainer}>
                <div className={styles.name}>
                  <div className={styles.iconContainer}>
                    <InventoryIcon sx={{ fontSize: 30 }} />
                  </div>
                  <span className={styles.nameSpan}>
                    {event.cycle?.product?.name}
                  </span>
                </div>

                <div
                  style={{
                    backgroundImage: `url(${event.cycle?.thumbnail})`,
                  }}
                  className={styles.thumbnail}
                ></div>
              </div>
            </Tooltip>

            <TeacherInfoPopover teacher={event.teacher}>
              <Tooltip title="Nauczyciel" placement="left">
                <div className={styles.teacherContainer}>
                  <div className={styles.iconContainer}>
                    <PersonIcon sx={{ fontSize: 30 }} />
                  </div>

                  <span className={styles.name}>{event.teacher?.fullName}</span>
                </div>
              </Tooltip>
            </TeacherInfoPopover>

            <Tooltip title="Miejsce" placement="left">
              <div className={styles.resourceContainer}>
                <div className={styles.iconContainer}>
                  <LocationOnIcon sx={{ fontSize: 30 }} />
                </div>
                <div className={styles.info}>
                  <div className={styles.name}>{event.resource?.name}</div>
                  <div className={styles.building}>
                    {event.resource?.building}
                  </div>
                </div>
                <div
                  style={{
                    backgroundImage: `url(${event.resource?.thumbnail})`,
                  }}
                  className={styles.thumbnail}
                ></div>
              </div>
            </Tooltip>

            {HAS_DESC && (
              <div className={styles.cycledescription}>
                <EdjsDisplay data={event?.description} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.bottomContent}>
        <div className={styles.buttons}>
          {(user.type === "admin" || user.scope.includes("events")) && (
            <PanelButton
              icon={<EditIcon />}
              width={100}
              height={100}
              onClick={() => {
                history.push(
                  "/editCycle/" + event?.cycle?._id + "?editEvent=" + event?._id
                );
              }}
              label="Edytuj wydarzenie"
            />
          )}

          {SHOULD_RENDER_ATTENDANCE &&
            (user.type === "admin" || user.scope.includes("teacher")) && (
              <TicketChecker
                cycleID={event.cycle?._id}
                closeTicketCheckerModal={closeTicketCheckerModal}
                ticketCheckerBool={ticketCheckerBool}
                openTicketCheckerModal={openTicketCheckerModal}
                onSuccess={event.ticketCheckerSuccessFunc}
              />
            )}
            
            <PanelButton
              icon={<PrintIcon />}
              width={100}
              height={100}
              disabled={printLoading}
              onClick={() => dispatch(printEventAttendance(event._id))}
              label="Drukuj listę obecności"
            />

        </div>

        <div className={styles.components}>
          {SHOULD_RENDER_ATTENDANCE &&
            (user.type === "admin" || user.scope.includes("teacher")) && (
              <Attendance />
            )}

          {SHOULD_RENDER_FEEDBACK &&
            (user.type === "admin" ||
              (user.scope.includes("teacher") &&
                event.teacher?._id === user._id)) && (
              <EventFeedbacks eventID={event?._id} />
            )}
        </div>
      </div>
    </div>
  );
}

export default connect()(SingleEvent);
