import styles from './singleOrder.module.scss'
import { LoadingButton } from "@mui/lab";
import { motion } from "framer-motion";
import { zoomIn } from "../../home/components/utils/motion";

function FunkyButton(props) {

    const {style, text, onClick} = props

    return (

        <motion.div
            variants={zoomIn(0.4, 1)}
        >
            <LoadingButton
                fullWidth
                onClick={onClick}
                style={style}
                variant="contained"
                className={styles.control}
            >
                {text}
            </LoadingButton>
        </motion.div>

    )
}

export default FunkyButton
