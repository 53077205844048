import classnames from "classnames";
import React, { forwardRef, useState } from "react";
import TextEditor from "../textEditor/TextEditor";
import styles from "./expandableTextEditor.module.scss";

import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

const ExpandableTextEditor = forwardRef((props, ref) => {
  const [expanded, setExpanded] = useState(props.expanded);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={styles.expandableContainer}>
      <div onClick={toggleExpanded} className={styles.header}>
        {props.title}
        {!expanded ? (
          <KeyboardDoubleArrowDownIcon />
        ) : (
          <KeyboardDoubleArrowUpIcon />
        )}
      </div>

      <div
        className={
          expanded
            ? classnames(styles.textArea, styles.expanded)
            : classnames(styles.textArea, styles.contracted)
        }
      >
        <TextEditor
          defaultValue={props.defaultValue}
          useFiles={true}
          editorCore={ref}
          holder={props.holder}
          readOnly={props.readOnly}
        />
      </div>
    </div>
  );
});

export default ExpandableTextEditor;
