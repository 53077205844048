import React from "react";
import { connect, useSelector } from "react-redux";
import styles from "./footer.module.scss";

import classNames from "classnames";
import { matchPath, withRouter } from "react-router";
import stolicaMap from "../../assets/stolicamap.png";
import { selectType } from "../../redux/slices/userSlice";

import Typography from "@mui/material/Typography";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import classnames from "classnames";
import { Link } from "react-router-dom";

const excludedPaths = [
  ////strony bez stopki
  "/checkout",
  "/login",
  "/register",
  "/resetPassword",
  "/messages/:id?",
  "/addCycle",
  "/editCycle/:id",
  "/displays",
  "/hourlyAssessment",
  "/showcase",
  "/showcase/:id",
  "/users/:id?",
  "/logs",
  "/manageHomePage",
  "/manageCustomPages",
];

function Footer(props) {
  const user = useSelector(selectType);

  const match = matchPath(props.location.pathname, {
    path: excludedPaths, //żeby zmatchować też np showcasd/id newsa
    exact: true,
  });
  if (match) return <></>;

  const unauthorizedLinks = [
    {name: "Regulaminy", to: "/regulaminy"},
    // { name: "Strona główna", to: "/" },
    { name: "Aktualności", to: "/aktualnosci", subpages: true },
    { name: "Zajęcia", to: "/oferta", subpages: true },
    // { name: "Laboratoria", to: "/resources" },
  ];

  const unauthorizedOnlyLinks = [
    {
      name: "Zaloguj się",
      to: "/login",
    },
  ];

  const userLinks = [
    { name: "Panel", to: "/panel" },
    {
      name: "Wiadomości",
      to: "/messages",
      subpages: true,
    },
    // { POWIADOMIENIA TURNED OFF - W RAZIE CO TO DO OSTAJLOWANIA
    //   name: "Powiadomienia",
    //   preventClose: true,
    //   customRender: <NotificationsIcon />,
    // },

    // { DAĆ LOGOUT NA PANELU NP
    //   name: "Wyloguj się",
    //   to: "/",
    //   customAction: () => {
    //     dispatch(logout());
    //   },
    //   preventMatch: true,
    //   customRender: (
    //     <Tooltip placement="top" disableInteractive title={"Wyloguj się"}>
    //       <LogoutIcon />
    //     </Tooltip>
    //   ),
    // },
  ];

  const userOnlyLinks = [
    {
      name: "Koszyk",
      to: "/koszyk",
      subpages: true,
    },
  ];

  const renderLinks = () => {
    let linksToRender = unauthorizedLinks;

    if (user.authorized) linksToRender = [...linksToRender, ...userLinks];
    else linksToRender = [...linksToRender, ...unauthorizedOnlyLinks];

    if (user.authorized && (user.type === "admin" || user.type == "mod"))
      linksToRender = [
        {
          name: "Cykle zajęć",
          to: "/cycles",
        },
        ...linksToRender,
      ];

    if (user.authorized && user.type === "user") {
      linksToRender = [...linksToRender, ...userOnlyLinks];
    }

    return (
      <>
        {linksToRender
          .filter((e) => !e.customRender)
          .map((page, index) => {
            const match = matchPath(props.location.pathname, {
              path: page.subpages ? [page.to, page.to + "/:id"] : page.to, //żeby zmatchować też np news/id newsa
              exact: true,
            });

            const exactMatch = matchPath(props.location.pathname, {
              path: page.to,
              exact: true,
            });

            const cs = [styles.linkButton];
            if (match) cs.push(styles.activeLinkButton);

            return (
              <div

                key={index}
                className={classNames(cs)}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!exactMatch) props.history.push(page.to);
                }}
              >
                <Link to={page.to}>
                  <Typography>{page.name.toUpperCase()}</Typography>
                </Link>
              </div>
            );
          })}
      </>
    );
  };

  return (
    <div id='contact' className={styles.footer}>
      <div className={styles.maxContainer}>
        <div className={styles.row}>
          <div className={styles.contact}>
            <h1>KONTAKT</h1>
            <p>
              ul. Słowackiego 45/47
              <br></br>
              62-200 Gniezno<br></br>
            </p>
            <p>
              <strong>786 815 155</strong>
              <br />
              <strong>
                {" "}
                <a
                  href="mailto:biuro@stolicaexperymentu.edu.pl"
                  target="_blank"
                  rel="noreferrer"
                >
                  biuro@stolicaexperymentu.edu.pl
                </a>
                <br />{" "}
              </strong>

              <strong>
                {" "}
                <a
                  href="mailto:rezerwacja@stolicaexperymentu.edu.pl"
                  target="_blank"
                  rel="noreferrer"
                >
                  rezerwacja@stolicaexperymentu.edu.pl
                </a>
                <br />{" "}
              </strong>
            </p>
          </div>

          <a
            alt="Odnośnik do lokalizacji budynku Stolicy eXperymentu na mapie Gniezna"
            target="blank"
            href="https://goo.gl/maps/3tphW1AsJbDHJys17"
          >
            <div
              alt="Lokalizacja Stolicy eXperymentu na mapie Gniezna"
              id="logo"
              className={styles.logo}
              style={{ backgroundImage: `url(${stolicaMap})` }}
            ></div>
          </a>
          <div className={styles.resources}>
            <h1 className={styles.resourcesTitle}>PODSTRONY</h1>
            {renderLinks()}
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={classnames(styles.copyright, styles.col12)}>
            <p align="center">
              Copyright Stolica eXperymentu {new Date().getFullYear()}
            </p>
          </div>
          <div className={styles.socials}>
            <a
              target="blank"
              href="https://www.facebook.com/StolicaeXperymentu"
            >
              <FacebookIcon sx={{ fontSize: 36 }}></FacebookIcon>{" "}
            </a>
            <a
              target="blank"
              href="https://www.youtube.com/@stolicaexperymentu"
            >
              <YouTubeIcon sx={{ fontSize: 36 }}></YouTubeIcon>{" "}
            </a>
            <a target="blank" href="https://instagram.com/stolicaexperymentu">
              <InstagramIcon sx={{ fontSize: 36 }}></InstagramIcon>{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect()(Footer));
