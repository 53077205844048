import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styles from "./singleCycle.module.scss";
import sowaLogo from "../../../assets/sowa-white.png";
import stolicaLogo from "../../../assets/stolica-white.png";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import PanelButton from "../../../components/panelButton/PanelButton";
import ClassicCalendar from "../../../components/calendar/ClassicCalendar";
import EdjsDisplay from "../../../components/edjsDisplay/EdjsDisplay";
import { addToCart, isCycleInCart } from "../../../redux/slices/cartSlice";
import {
  getSingleCycle,
  selectSingleCycle,
  setSingleCycleEventToShow,
} from "../../../redux/slices/eventsSlice";
import { selectType } from "../../../redux/slices/userSlice";
import CycleFeedback from "./CycleFeedback/CycleFeedback";

import { LoadingButton } from "@mui/lab";
import { Helmet } from "react-helmet";
import Empty from "../../../components/universal/Empty";
import Loading from "../../../components/universal/Loading";
import CycleReservations from "./CycleReservations/CycleReservations";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import GroupsIcon from "@mui/icons-material/Groups";
import SellIcon from "@mui/icons-material/Sell";
import SchoolIcon from "@mui/icons-material/School";
import { selectMaxLengths } from "../../../redux/slices/utilsSlice";
import ImageModal from "../../../components/imageUploader/ImageModal";
import * as robotHappyAnim from "./happyRobot.json";
import Lottie from "react-lottie";
import { Tooltip } from "@mui/material";
import { emmitError } from "../../../utils/ToastEmmiter";
import StoreIcon from "@mui/icons-material/Store";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FastAddReservation from "../../panel/components/fastAddReservation/FastAddReservation";
import velix4 from "../../../assets/velix/VELIX 4.png";
import VelixSign from "../../../components/velixPrompt/VelixSign";

const defaultOptions = {
  loop: true,
  autoplay: true,

  rendererSettings: {
    preserveAspectRatio: "xMidYMid meet",
  },
};

function Single(props) {
  const { cycle, loading } = useSelector(selectSingleCycle);
  const user = useSelector(selectType);
  const history = useHistory();
  const dispatch = useDispatch();
  const { maxCartItems } = useSelector(selectMaxLengths);

  const [imagePreviewVisible, setImagePreviewVisible] = useState(false);

  const shouldRender = !cycle.reservationOwned && user.type === "user";
  const isInCart = useSelector((state) => isCycleInCart(state, cycle._id));

  const addCart = () => {
    dispatch(
      addToCart({
        item: {
          _id: cycle._id,
          type: "cycle",
        },
        cartLimit: maxCartItems,
      })
    );
  };

  const renderAddToCartButton = () => {
    if (user.type !== "user") return <></>;

    if (cycle.reservationOwned)
      return (
        <>
          <div className={styles.reservationInfo}>
            <VelixSign
              height="300px"
              left="5px"
              top="14px"
              rotate="-3deg"
              fontSize="1rem"
              text="Jesteś zapisany na te zajęcia!"
              textWidth="120px"
            />
          </div>
        </>
      );

    return (
      <>
        {isInCart ? (
          <Tooltip placement="top" title={"Cykl już dodany do koszyka"}>
            <span>
              <PanelButton
                label={"Dodaj do koszyka"}
                icon={<AddShoppingCartIcon style={{ fontSize: 40 }} />}
                width={100}
                height={100}
                onClick={() =>
                  emmitError("Cykl znajduje się już w twoim koszyku")
                }
                disabled={true}
              />
            </span>
          </Tooltip>
        ) : (
          <PanelButton
            label={"Dodaj do koszyka"}
            icon={<AddShoppingCartIcon style={{ fontSize: 40 }} />}
            width={100}
            height={100}
            onClick={() => addCart()}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    const id = props.match.params.id;
    dispatch(getSingleCycle(id));
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    if (cycle?.events?.length > 0 && searchParams.get("selectedEvent")) {
      const event = cycle.events.find(
        (e) => e._id === searchParams.get("selectedEvent")
      );
      if (event) {
        dispatch(setSingleCycleEventToShow(event));
        history.replace({
          search: "",
        });
      } else {
        dispatch(setSingleCycleEventToShow(null));
      }
    }
  }, [cycle.events]);

  if (loading) return <Loading />;

  if (Object.keys(cycle).length === 0 && loading === false)
    return <Empty text={"Nie znaleziono żądanego cyklu zajęć."} />;

  const courseSchema = {
    "@context": "https://schema.org",
    "@type": "Course",
    name: cycle?.name,
    description: cycle?.shortDesc,
    provider: {
      "@type": "Organization",
      name: "Stolica eXperymentu",
      sameAs: "https://stolicaexperymentu.edu.pl",
    },
  };

  return (
    <div className={styles.singleCycleContainer}>
      <Helmet>
        <title>{cycle?.name} - Stolica eXperymentu</title>
        <meta name="description" content={cycle?.shortDesc} />
        <meta property="og:description" content={cycle?.shortDesc} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={cycle?.thumbnail} />
        <meta property="og:title" content={cycle?.name} />
        <meta name="twitter:title" content={cycle?.name} />
        <meta name="twitter:description" content={cycle?.shortDesc} />
        <meta name="twitter:image" content={cycle?.thumbnail} />
        <link rel="canonical" href={window.location.href} />
        <script type="application/ld+json">
          {JSON.stringify(courseSchema)}
        </script>
      </Helmet>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.infoAndStats}>
              {cycle.thumbnail && (
                <div
                  onClick={() => setImagePreviewVisible(true)}
                  style={{ backgroundImage: `url(${cycle.thumbnail})` }}
                  className={styles.imagePreview}
                ></div>
              )}
              <ImageModal
                standalone={true}
                visible={imagePreviewVisible}
                image={cycle.thumbnail}
                closeImageModal={() => setImagePreviewVisible(false)}
              />

              <div className={styles.info}>
                <div className={styles.name}>{cycle.name}</div>
                <div className={styles.productName}>{cycle.product?.name}</div>
                <div className={styles.subInfo}>
                  <div className={styles.subInfoItem}>
                    <div className={styles.reg1}>Biorący udział</div>
                    <div className={styles.amounts}>
                      <div className={styles.peopleCount}>
                        <div className={styles.current}>
                          {`${cycle.reservationsCount} `}
                        </div>
                        <div className={styles.max}>{`/${cycle.capacity}`}</div>
                      </div>
                      <div className={styles.icon}>
                        <PersonIcon sx={{ fontSize: 24 }} />
                      </div>
                    </div>
                  </div>

                  <div className={styles.subInfoItem}>
                    <div className={styles.reg1}>Cena</div>
                    <div className={styles.amounts}>
                      <div className={styles.max}>{`${cycle.price} zł`}</div>
                      <div className={styles.icon}>
                        <SellIcon sx={{ fontSize: 24 }}></SellIcon>
                      </div>
                    </div>
                  </div>

                  {cycle.adultPrice &&
                    <div className={styles.subInfoItem}>
                      <div className={styles.reg1}>Cena dla dorosłego</div>
                      <div className={styles.amounts}>
                        <div className={styles.max}>{`${cycle.adultPrice} zł`}</div>
                        <div className={styles.icon}>
                          <SellIcon sx={{ fontSize: 24 }}></SellIcon>
                        </div>
                      </div>
                    </div>
                  }

                    {cycle.ageString &&
                    <div className={styles.subInfoItem}>
                      <div className={styles.reg1}>Przedział wiekowy</div>
                      <div className={styles.amounts}>
                        <div className={styles.max}>{`${cycle.ageString} lat`}</div>
                        <div className={styles.icon}>
                          <SchoolIcon sx={{ fontSize: 24 }}></SchoolIcon>
                        </div>
                      </div>
                    </div>
                  }
                  
                  <div className={styles.subInfoItem}>
                    <div className={styles.reg1}>Typ zajęć</div>
                    <div className={styles.amounts}>
                      <div className={styles.max}>
                        {cycle.type === "individual"
                          ? "Indywidualne"
                          : "Grupowe"}
                      </div>
                      <div className={styles.icon}>
                        {cycle.type === "individual" ? (
                          <EmojiPeopleIcon sx={{ fontSize: 24 }} />
                        ) : (
                          <GroupsIcon sx={{ fontSize: 24 }} />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.subInfoItem}>
                    <div className={styles.reg1}>Operator zajęć</div>
                    <div className={styles.amounts}>
                      <div className={styles.icon}>
                        {cycle.operator === "Stolica eXperymentu" ? (
                          <img
                            src={stolicaLogo}
                            alt="Stolica eXperymentu"
                            className={styles.imgIcon}
                          />
                        ) : (
                          <img
                            src={sowaLogo}
                            alt="Sowa"
                            className={styles.imgIcon}
                          />
                        )}
                      </div>
                      <div className={styles.icon}>
                        <StoreIcon sx={{ fontSize: 24 }}></StoreIcon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.description}>
              <EdjsDisplay data={cycle.description} />
            </div>

            <div className={styles.buttons}>
              {(user.type === "admin" || user.scope.includes("cycles")) && (
                <PanelButton
                  label={"Edytuj cykl"}
                  icon={<EditIcon style={{ fontSize: 40 }} />}
                  width={100}
                  height={100}
                  onClick={() => {
                    history.push("/editCycle/" + props.match.params.id);
                  }}
                />
              )}

              {(user.type === "admin" || user.scope.includes("cycles")) && (
                <PanelButton
                  label={"Edytuj produkt"}
                  icon={<EditIcon style={{ fontSize: 40 }} />}
                  width={100}
                  height={100}
                  onClick={() => {
                    history.push("/editProduct/" + cycle.product._id);
                  }}
                />
              )}

              {(user.type === "admin" || user.scope.includes("users")) && (
                <FastAddReservation initCycle={cycle} />
              )}
            </div>
          </div>
          {cycle.events.length > 0 &&
            (user.type === "admin" ||
              user.scope.includes("users") ||
              user.scope.includes("teacher")) && (
              <CycleReservations cycleName={cycle.name} cycleID={cycle._id} />
            )}
          <div className={styles.cartHandlers}>{renderAddToCartButton()}</div>
        </div>

        <ClassicCalendar singleCycle events={cycle.events} />
        <div className={styles.singleCycleWidgets}>
          {cycle.events.length > 0 &&
            (user.type === "admin" || user.scope.includes("cycles")) && (
              <CycleFeedback />
            )}
        </div>
      </div>
    </div>
  );
}

export default connect()(Single);
