import React from "react";

import PanelButton from "../../../../components/panelButton/PanelButton";

import GroupIcon from "@mui/icons-material/Group";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
function ManageUsers(props) {
  const history = useHistory();
  return (
    <PanelButton
      onClick={() => history.push("/users")}
      label={"Użytkownicy"}
      icon={<GroupIcon style={{ fontSize: 40 }} />}
      width={100}
      height={100}
    />
  );
}

export default ManageUsers;
