import LockResetIcon from '@mui/icons-material/LockReset';
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIService from "../../../../API/APIService";
import Modal from "../../../../components/modal/Modal";
import PanelButton from "../../../../components/panelButton/PanelButton";

import { emitInfo, emmitError, emmitSuccess } from "../../../../utils/ToastEmmiter";
import { selectUser } from "../../../../redux/slices/userSlice";
function ResetMyPassword(props) {
    const [loading, setLoading] = useState(false);
    const user = useSelector(selectUser);

    const resetMyPassword = async () => {
        setLoading(true);
        emitInfo("Wysyłanie wiadomości z linkiem resetującym hasło...");
        try {
            const result = await APIService.post("/auth/sendPasswordReset", {
                email: user.email,
            });
            console.log(result.data.success)
            emmitSuccess(result.data.success);
        } catch (error) {
            console.log(error)
            emmitError(error.response.data.error);
        }
    
        

    };

  return (
    <>
      <PanelButton
        label="Zresetuj hasło"
        icon={<LockResetIcon style={{ fontSize: 40 }} />}
        width={100}
        height={100}
        onClick={resetMyPassword}
      />
     
    </>
  );
}

export default ResetMyPassword;
