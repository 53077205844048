import React from "react";
import styles from "./Orders.module.scss";
import { connect, useSelector } from "react-redux";
import moment from "moment";

import a from "color-alpha";
import { selectMaxLengths } from "../../redux/slices/utilsSlice";

const DATE_FORMAT = "dddd, DD-MM-YYYY HH:mm";

function OrderCard(props) {
  const { orderStates, currencySign } = useSelector(selectMaxLengths);

  const getStatusColor = (status) => {
    return orderStates[status].color;
  };

  const getStatusText = (status) => {
    return orderStates[status].text;
  };

  const getCountText = (amount) => {
    switch (true) {
      case amount === 1:
        return `${amount} przedmiot`;

      case amount > 1 && amount < 5:
        return `${amount} przedmioty`;
      case amount >= 5:
        return `${amount} przedmiotów`;
      default:
        break;
    }
  };

  const { data } = props;

  const crossedOutOriginalPrice = data.promoCode && (
    <span className={styles.crossedOut}>
      {`${data.originalAmount} ${currencySign}`}
    </span>
  );

  return (
    <div onClick={props.onClick} className={styles.orderCard}>
      <div className={styles.meta}>
        <div className={styles.orderID}>
          <div className={styles.txt}>Zamówienie</div>
          <div className={styles.id}>{data._id}</div>
        </div>
        <div className={styles.timestamp}>
          {moment(data.createdAt).locale("pl").format(DATE_FORMAT)}
        </div>
      </div>
      <div className={styles.logisticInfo}>
        <div className={styles.price}>
          {`${data.amount} ${currencySign}`} {crossedOutOriginalPrice}
        </div>
        <div className={styles.items}>{getCountText(data.items.length)}</div>
      </div>

      <div
        style={{
          color: getStatusColor(data.status),
          backgroundColor: a(getStatusColor(data.status), 0.1),
        }}
        className={styles.status}
      >
        {getStatusText(data.status)}
      </div>
    </div>
  );
}

export default OrderCard;
