import styles from "./ProductCycles.module.scss";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getAvailableCycles,
  selectAvailableCycles,
} from "../../../redux/slices/eventsSlice";
import Loading from "../../../components/universal/Loading";
import Empty from "../../../components/universal/Empty";
import CycleCard from "../../../components/buyCycleFlowComponents/CycleCard";
import ReturnHeader from "../../../components/buyCycleFlowComponents/ReturnHeader";
import CartFooter from "../../../components/buyCycleFlowComponents/CartFooter";
import Tutorial from "./Tutorial";
import { selectType } from "../../../redux/slices/userSlice";
import LoginCard from "../../../components/buyCycleFlowComponents/LoginCard";
import InProgressInstructionModal from "./InProgressInstructionModal";
import { Helmet } from "react-helmet";
import { getFullPath } from "../../../svc/svc";


function ProductCycles(props) {
  const dispatch = useDispatch();
  const user = useSelector(selectType);
  const aLoggedUser = user.type === "user";
  const [selectedCycle, setSelectedCycle] = useState(null);

  const handleSelectCycle = (cycle) => {
    setSelectedCycle(cycle);
  }

  const handleDeselectCycle = () => {
    setSelectedCycle(null);
  }

  useEffect(() => {
    const id = props.match.params.id; // or slug
    dispatch(getAvailableCycles(id));
  }, []);

  const { cycles, loading, product } = useSelector(selectAvailableCycles);

  const productName = product?.name;

  const courseSchema = {
    "@context": "https://schema.org",
    "@type": "Course",
    name: product?.name,
    description: product?.shortDesc,
    provider: {
      "@type": "Organization",
      name: "Stolica eXperymentu",
      sameAs: "https://stolicaexperymentu.edu.pl",
    },
  };

  const breadcrumbListStructuredData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Stolica eXperymentu",
        item: getFullPath(""),
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Oferta",
        item: getFullPath("/oferta"),
      },
      {
        "@type": "ListItem",
        position: 3,
        name: productName,
        item: getFullPath("/oferta/" + product?.slug),
      }, 
    ],
  };

  return (
    <>
    <Helmet>
        (<title>{`${product?.name} | Stolica eXperymentu`} </title>)
        <meta name="description" content={product?.shortDesc} />
        <meta property="og:description" content={product?.shortDesc} />

        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={product?.thumbnail} />
        <meta property="og:title" content={product?.name} />
        <meta name="twitter:title" content={product?.name} />
        <meta name="twitter:description" content={product?.shortDesc} />
        <meta name="twitter:image" content={product?.thumbnail} />
        <link rel="canonical" href={window.location.href} />
        <script type="application/ld+json">
          {JSON.stringify(courseSchema)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbListStructuredData)}
        </script>

      </Helmet>  
    <div className={styles.productCyclesWrapper}>
      <Tutorial />
      <ReturnHeader currentProduct={productName} />
      <div className={styles.productCycleCards}>
        {loading ? (
          <Loading />
        ) : cycles.length > 0 ? (
          <>{!aLoggedUser && <LoginCard />}{cycles.map((cycle, index) => <CycleCard handleSelectCycle={handleSelectCycle} key={index} cycle={cycle} />)}</>
        ) : (
          <Empty text="Brak cykli do wyświetlenia" />
        )}
      </div>
      <InProgressInstructionModal selectedCycle={selectedCycle} closeModal={handleDeselectCycle} />
      <CartFooter />
    </div>
    </>
  );
}

export default ProductCycles;
