import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ReactPaginate from "react-paginate";
import { parseQuery } from "../../svc/svc";
import Empty from "../universal/Empty";
import styles from "./pagination.module.scss";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import Loading from "../../components/universal/Loading";

function Pagination(props) {
  const data = useSelector(props.dataSelector);
  const pages = useSelector(props.pagesSelector);
  const loading = useSelector(props.loadingSelector);

  const history = useHistory();
  const dispatch = useDispatch();

  const getInitialPage = () => {
    if (props.location && props.location.search) {
      var query = parseQuery(props.location.search.substring(1));
      if (query.page && query.page !== pages.page) return query.page;
    }

    return pages.page;
  };
  const [currentPage, setCurrentPage] = React.useState(getInitialPage() - 1);

  useEffect(() => {
    if (props.disableInitialCallback) return;
    onPageChange({ selected: currentPage });
  }, []);

  const handleSetSingle = (el) => {
    if (props.customRedirectHandler) {
      return props.customRedirectHandler(el);
    }

    dispatch(props.setSingle(el));
    const addition = props.additionalQuery ? props.additionalQuery : "";
    if (!props.dontRedirect)
      history.push(props.singlePath + (el.slug ? el.slug : el._id) + addition);
  };

  const ElementCard = props.ElementCard;

  const onPageChange = (event) => {
    const additional = parseQuery(props.location?.search);

    if(props.obligatoryQuery) {
      const additionalKeys=Object.keys(additional)
      const condition = additionalKeys.some(e=>props.obligatoryQuery.includes(e))
      console.log(props.obligatoryQuery, additionalKeys, condition)
      if (!condition) {
        return 0
      }
    }

    const additionalHistory = parseQuery(history.location.search);
    console.log(additionalHistory);


    if (props.customDispatchProps) {
      dispatch(
        props.setPage({
          ...additionalHistory,
          ...props.customDispatchProps,
          ...additional,
          page: event.selected + 1,
        })
      );
    } else
      dispatch(
        props.setPage({
          ...additionalHistory,
          ...additional,
          page: event.selected + 1,
        })
      );
    setCurrentPage(event.selected + 1);
    return event.selected + 1;
  };

  // if (data?.length === 0) return <p>pusto</p>;

  const paginationDOM = () => (
    <div className={styles.pagination}>
      <ReactPaginate
        breakLabel={<MoreHorizIcon />}
        nextLabel={<KeyboardArrowRightIcon />}
        previousLabel={<KeyboardArrowLeftIcon />}
        forceState={{ page: props.forceState }}
        onPageChange={onPageChange}
        pageRangeDisplayed={props.pageRange ? props.pageRange : 3}
        marginPagesDisplayed={1}
        disableInitialCallback={props.disableInitialCallback}
        pageCount={pages.pageCount}
        renderOnZeroPageCount={null}
        forcePage={currentPage - 1}
        pageClassName={styles.page}
        activeClassName={styles.active}
        previousClassName={styles.previous}
        nextClassName={styles.next}
        disabledClassName={styles.disabled}
        breakClassName={styles.break}
      />
    </div>
  );

  return (
    <div
      style={props.paginationWrapperStyleOverride}
      className={styles.paginationWrapper}
    >
      {props.double && paginationDOM()}

      {!loading ? (
        data?.length > 0 ? (
          <div className={props.dataContainerClassName}>
            {data.map((el) => {
              return (
                <ElementCard
                  data={el}
                  key={el._id}
                  onClick={() => handleSetSingle(el)}
                  {...props.cardProps}
                />
              );
            })}{" "}
          </div>
        ) : (
          <Empty text={props.emptyText} hint={props.emptyHint} />
        )
      ) : (
        <Loading />
      )}

      {paginationDOM()}
    </div>
  );
}

export default connect()(Pagination);
