import React, { useState, useEffect, useRef } from "react";

import { useDispatch, connect } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import styles from "../../authCommon.module.scss";
import Typography from "@mui/material/Typography";
import Modal from "../../../../components/modal/Modal";
import TextField from "@mui/material/TextField";
import {
  forgotPassword,
  selectAuthLoadings,
} from "../../../../redux/slices/userSlice";
import { LoadingButton } from "@mui/lab";
import FormTextField from "../../../../components/formMuiComponents/FormTextField";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import constants from "../../../../assets/constants";

const emailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Niepoprawny adres email")
    .required("Email wymagany!"),
});

function ForgotPassword(props) {
  const [open, setOpen] = React.useState(false);
  const loadings = useSelector(selectAuthLoadings);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const { formState, watch, handleSubmit, reset, control } = useForm({
    resolver: yupResolver(emailSchema),
  });

  const onSubmit = (values) => {
    console.log(values);
    dispatch(
      forgotPassword({
        email: values.email,
        handleClose: handleClose,
      })
    );
  };

  return (
    <>
      <Button onClick={handleOpen}>Zapomniałeś hasła?</Button>
      <Modal
        visible={open}
        closeModal={handleClose}
        title="Zapomniałeś hasła?"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        height={"auto"}
      >
        <div className={styles.modalBodyWrapper}>
          <div className={styles.modalFormHolder}>
            <Typography className={styles.modalHint}>
              Podaj adres email, na który zarejestrowane było konto. Wysłany
              zostanie tam link, za pomocą którego dokonasz przywrócenia hasła.{" "}
              <br /> Jeśli nie pamiętasz adresu email{" "}
              <a
                className={styles.mailHref}
                href={"mailto:" + constants.contactMail}
              >
                skontaktuj się z Stolicą eXperymentu
              </a>
              .
            </Typography>
            <FormTextField
              id="email-forgot"
              className={styles.formInput}
              autoComplete="email"
              name="email"
              control={control}
              label="Adres email"
              autoFocus
              enterClick={handleSubmit(onSubmit)}
            />

            <LoadingButton
              onClick={handleSubmit(onSubmit)}
              variant="outlined"
              type="submit"
              loading={loadings?.forgotPass}
            >
              Wyślij
            </LoadingButton>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default connect()(ForgotPassword);
