import styles from './VelixPrompt.module.scss'
import velix2 from '../../assets/velix/VELIX 2.png'
import classnames from 'classnames'




function VelixSign(props){
    return (
        <div className={styles.velixSignHolder}>
            <img width={props.width} height={props.height} src={velix2} />
            <div onClick={props.textOnClick} style={{rotate: props.rotate, left: props.left, top:props.top, fontSize: props.fontSize, width: props.textWidth}} className={classnames(styles.textHolder,props.textClassname)}>
                {props.text}
            </div>
        </div>
    )
}


export default VelixSign