export const i18n = {
  /**
   * @type {I18nDictionary}
   */
  messages: {
    /**
     * Other below: translation of different UI components of the editor.js core
     */
    ui: {
      blockTunes: {
        toggler: {
          "Click to tune": "Kliknij aby dostosować",
          "or drag to move": "lub przeciągnij żeby ruszyć",
        },
      },
      inlineToolbar: {
        converter: {
          "Convert to": "Konwertuj na",
        },
        table: {
          "Delete column": "teest",
        },
      },
      toolbar: {
        toolbox: {
          Add: "Dodaj",
          Filter: "Filtruj",
          "Nothing found": "Nic nie znaleziono",
        },
      },
    },

    /**
     * Section for translation Tool Names: both block and inline tools
     */
    toolNames: {
      Text: "Tekst",
      Heading: "Nagłówek",
      List: "Lista",
      Warning: "Ostrzeżenie",
      Checklist: "Lista kontrolna",
      Quote: "Cytat",
      Code: "Kod",
      Delimiter: "Oddzielnik",
      "Raw HTML": "HTML",
      Table: "Tabela",
      Link: "Link",
      Marker: "Marker",
      Bold: "Pogrubienie",
      Italic: "Kursywa",
      InlineCode: "Wewnętrzny kod",
      Image: "Obraz",
      Attaches: "Załączniki",
      Color: "Kolor",
    },

    /**
     * Section for passing translations to the external tools classes
     */
    tools: {
      /**
       * Each subsection is the i18n dictionary that will be passed to the corresponded plugin
       * The name of a plugin should be equal the name you specify in the 'tool' section for that plugin
       */
      warning: {
        // <-- 'Warning' tool will accept this dictionary section
        Title: "Tytuł",
        Message: "Ostrzeżenie",
      },

      /**
       * Link is the internal Inline Tool
       */
      link: {
        "Add a link": "Dodaj link",
      },
      /**
       * The "stub" is an internal block tool, used to fit blocks that does not have the corresponded plugin
       */
      stub: {
        "The block can not be displayed correctly.":
          "Nie można wyświetlić poprawnie",
      },
      image: {
        Caption: "Obraz",
        "Select an Image": "Wybierz obraz",
        "With border": "Z obramowaniem",
        "Stretch image": "Rozciągnij obraz",
        "With background": "Z tłem",
      },
      carousel: {
        Caption: "Podpis",
      },

      embed: {
        Caption: "Dodaj podpis",
      },

      code: {
        "Enter a code": "Wpisz kod",
      },
      linkTool: {
        Link: "Link",
        "Couldn't fetch the link data": "Nie można pozyskać linku",
        "Couldn't get this link data, try the other one":
          "Nie można pozyskać linku, spróbuj innego",
        "Wrong response format from the server": "Błąd serwera",
      },
      header: {
        Header: "Nagłówek",
      },
      paragraph: {
        "Enter something": "Wpisz coś...",
      },
      list: {
        Ordered: "Numerowana",
        Unordered: "Nieuporządkowona",
      },
    },

    /**
     * Section allows to translate Block Tunes
     */
    blockTunes: {
      /**
       * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
       * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
       *
       * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
       */
      delete: {
        Delete: "Usuń",
      },
      moveUp: {
        "Move up": "Przesuń w górę",
      },
      moveDown: {
        "Move down": "Przesuń w dół",
      },
    },
  },
};
