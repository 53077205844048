import React, { useState } from "react";

import { connect } from "react-redux";

import Pagination from "../../../../components/Pagination/Pagination";
import {
  selectCycles,
  selectFetchNonConfirmedCyclesLoading,
  selectPages,
  setPageForNonConfirmed,
  setSingleCycle,
} from "../../../../redux/slices/eventsSlice";
import PendingCycleCard from "../../../cycles/PendingCycleCard";
import styles from "./pendingCycles.module.scss";

import DoneAllIcon from "@mui/icons-material/DoneAll";
import PanelButton from "../../../../components/panelButton/PanelButton";
import Modal from "../../../../components/modal/Modal";
import BookmarksIcon from '@mui/icons-material/Bookmarks';

function PendingCycles(props) {

  const [modal, setModal] = useState(false);

  const setVisible = () => {
    setModal(true);
  };

  const setInvisible = () => {
    setModal(false);
  };
  return (<>
    <PanelButton 
    label={"Oczekujące cykle"}
    icon={<BookmarksIcon style={{ fontSize: 40 }} />}
    onClick={setVisible}
    width={100}
    height={100}
    />
    <Modal
    visible={modal}
    closeModal={setInvisible}
    title={"Niezatwierdzone cykle"}
    isDarkTitle={false}
    isPadding={true}
    height={600}
    >

    <div className={styles.pendingCyclesBox}>
      <div className={styles.pendingCyclesContainer}>
        <div className={styles.cardsContainer}>
          <Pagination
            dataContainerClassName={styles.dataContainerClassName}
            emptyText="Brak oczekujących cykli"
            emptyHint="Tutaj pojawiają się cykle, które wymagają zatwierdzenia"
            location={props.location}
            dataSelector={selectCycles}
            pagesSelector={selectPages}
            setSingle={setSingleCycle}
            singlePath="/editCycle/"
            loadingSelector={selectFetchNonConfirmedCyclesLoading}
            setPage={setPageForNonConfirmed}
            ElementCard={PendingCycleCard}
          />
        </div>
      </div>
    </div>
    </Modal>

    </>
  );
}

export default connect()(PendingCycles);
