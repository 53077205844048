import { useSelector } from "react-redux";
import CartIcon from "../navbar/CartIcon";
import styles from "./BuyCycleFlow.module.scss";
import { Button } from "@mui/material";
import { selectCartItems } from "../../redux/slices/cartSlice";
import { useHistory } from "react-router-dom";

import Badge from "@mui/material/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { selectType } from "../../redux/slices/userSlice";
import classNames from "classnames";

function CartFooter(props) {
  const itemsCount = useSelector(selectCartItems).length;
  const user = useSelector(selectType);
  const aLoggedUser = user.type == "user";

  const history = useHistory();
  const handleCheckout = () => {
    history.push("/checkout");
  };

  const handleLogIn = () => {
    history.push("/login");
  };

  return (
    <div className={aLoggedUser ? styles.cartFooterWrapper : classNames(styles.cartFooterWrapper, styles.flexDirCol)}>
      <div className={styles.cartInfo}>
        {aLoggedUser ? (
          itemsCount == 0 ? (
            <span className={styles.chooseTxt}>Wybierz zajęcia</span>
          ) : (
            <Badge
              className={styles.badge}
              badgeContent={itemsCount || 0}
              color="secondary"
            >
              <ShoppingCartIcon color="#222222" />
            </Badge>
          )
        ) : (
          <span className={styles.chooseTxt}>
            Aby wykupić udział w zajęciach musisz się zalogować
          </span>
        )}
      </div>

      <div className={styles.buttons}>
        {aLoggedUser ? (
          <Button
            variant="outlined"
            onClick={handleCheckout}
            disabled={itemsCount == 0}
          >
            DALEJ
          </Button>
        ) : (
          <Button sx={{width: 200}} variant="outlined" onClick={handleLogIn}>ZALOGUJ SIĘ</Button>
        )}
      </div>
    </div>
  );
}

export default CartFooter;
