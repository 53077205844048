import React, { useEffect, useRef, useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { connect, useDispatch, useSelector } from "react-redux";

import { Delete, DoneAll } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import LoadingButton from "@mui/lab/LoadingButton";

import styles from "./CycleReservations.module.scss";

import {
  createUserCycleReservation,
  deleteUserCycleReservation,
  updateUserCycleReservation,
  setUserCycleReservationsPage,
  selectUserCycleReservationsData,
  selectUserCycleReservationsPage,
  selectUserCycleReservationsLoading,
  selectUserCycleReservationsActionLoading,
  getSpecificUserCycleReservation,
} from "../../../../redux/slices/manageUsersSlice";

import Loading from "../../../../components/universal/Loading";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import moment from "moment";

import CustomMultiMail from "../../../../components/CustomMultiMail/CustomMultiMail";
import AutoCompleteSearch from "../../../../components/autoCompleteSearch/AutoCompleteSearch";
import ConfirmButton from "../../../../components/confirmButton/ConfirmButton";
import Modal from "../../../../components/modal/Modal";
import Empty from "../../../../components/universal/Empty";
import { emmitError } from "../../../../utils/ToastEmmiter";
import UserCycleReservationCard from "./UserCycleReservationCard";
import { selectMaxLengths } from "../../../../redux/slices/utilsSlice";
import { useHistory } from "react-router-dom";
import Pagination from "../../../../components/Pagination/Pagination";
import UserCycleReservationAdd from "./UserCycleReservationAdd";
import { TextField } from "@mui/material";
import { parseQuery } from "../../../../svc/svc";

const DATE_FORMAT = "dddd, DD-MM-YYYY HH:mm";

function UserCycleReservations({ userData }) {
  const loading = useSelector(selectUserCycleReservationsLoading);
  const actionLoading = useSelector(selectUserCycleReservationsActionLoading);

  const history = useHistory();

  const { cycleReservationStates, currencySign, name, onTheSpotPaymentMethods } = useSelector(selectMaxLengths);

  const [selectedCycle, setSelectedCycle] = useState(undefined);
  const [newMailingList, setNewMailingList] = useState([]);



  const [editMailingList, setEditMailingList] = useState([]);
  const [editStatus, setEditStatus] = useState("pending");
  const [selectedCycleReservation, setSelectedCycleReservation] =
    useState(undefined);

  const [editParticipant, setEditParticipant] = useState(
    selectedCycleReservation?.participant?.fullName
  );
  const [editParticipantAge, setEditParticipantAge] = useState(
    selectedCycleReservation?.participant?.age
  );
  const [editParticipantPhone, setEditParticipantPhone] = useState(
    selectedCycleReservation?.participant?.phone
  );
  const [editParticipantSupervisor, setEditParticipantSupervisor] = useState(
    selectedCycleReservation?.participant?.supervisor
  );

  const [participantFilter, setParticipantFilter] = useState(
    parseQuery(history.location.search).participant || undefined
  );

  const [editUserOpen, setEditUserOpen] = useState(false);
  const [editUser, setEditUser] = useState(undefined);

  const handleEditCycleReservationUser = async () => {
    if (!editUser) return emmitError("Nie wybrano użytkownika");
    const res = await dispatch(
      updateUserCycleReservation({
        _id: selectedCycleReservation._id,
        user: editUser._id,
      })
    );
    if (res.meta.requestStatus === "fulfilled") {
      history.push(`/users/${editUser._id}?tab=Cykle+zajęć`);
      history.go(0);
    }
  };

  const handleCloseModal = () => {
    setSelectedCycleReservation(undefined);
  };

  const dispatch = useDispatch();

  const handleDeleteCycleReservation = async () => {
    if (!selectedCycleReservation._id)
      return emmitError("Nie wybrano cyklu zajęć!");
    const res = await dispatch(
      deleteUserCycleReservation(selectedCycleReservation._id)
    );
    if (res.meta.requestStatus === "fulfilled") {
      handleCloseModal();
    }
  };

  const handleUpdateCycleReservation = async () => {
    if (!selectedCycleReservation) return emmitError("Nie wybrano cyklu");
    if (!editParticipant) return emmitError("Nie wybrano uczestnika");

    const res = await dispatch(
      updateUserCycleReservation({
        _id: selectedCycleReservation._id,
        status: editStatus,
        mailingList: editMailingList,
        participant: {
          fullName: editParticipant,
          age: editParticipantAge,
          phone: editParticipantPhone,
          supervisor: editParticipantSupervisor,
        },
      })
    );
    if (res.meta.requestStatus === "fulfilled") {
      handleCloseModal();
    }
  };

  //FOR ADDING RESERVATION

  const handleSetSelectedCycleReservation = (res) => {
    setEditStatus(res.status);
    setEditMailingList(res.mailingList);
    setEditParticipant(res.participant?.fullName);
    setEditParticipantAge(res.participant?.age);
    setEditParticipantPhone(res.participant?.phone);
    setEditParticipantSupervisor(res.participant?.supervisor);
    setSelectedCycleReservation(res);
  };

  const handleSetSingleUserSearch = (user) => {
    setEditUser(user);
  };

  const handleRemoveParticipantFilter = () => {
    setParticipantFilter(undefined);
    history.replace(`/users/${userData._id}?tab=Cykle+zajęć`);
    dispatch(
      setUserCycleReservationsPage({
        page: 1,
        _id: userData._id,
        id: userData._id,
      })
    );
  };

  useEffect(() => {
    async function fetchData() {
      if (userData && userData._id) {
        const searchParams = new URLSearchParams(history.location.search);

        if (searchParams.get("reservationID")) {
          dispatch(
            getSpecificUserCycleReservation({
              userID: userData._id,
              reservationID: searchParams.get("reservationID"),
              setSelected: handleSetSelectedCycleReservation,
            })
          );
          // const allegedIndex = res.payload.findIndex(
          //   (e) => e._id === searchParams.get("reservationID")
          // );
          // if (allegedIndex >= 0) {
          //   handleSetSelectedCycleReservation(res.payload[allegedIndex]);
          // }
        }
      }
    }

    fetchData();
  }, [userData]);

  if (Object.keys(userData).length === 0 && !loading)
    return <Empty text="Nie wybrano użytkownika" hint="Wybierz z listy" />;

  return (
    <div className={styles.mainUserCycleReservationsContainer}>
      <div className={styles.header}>
        <div className={styles.txt}>Cykle zajęć</div>

        <div className={styles.userName}>{userData.fullName}</div>
      </div>

      <UserCycleReservationAdd
        anonymusUser={true}
        wantToClearData
        userData={userData}
      />

      {participantFilter && (
        <div className={styles.filterSection}>
          <p className={styles.filterName}>Uczestnik: {participantFilter} </p>
          <div
            className={styles.filterRemove}
            onClick={handleRemoveParticipantFilter}
          >
            {" "}
            X{" "}
          </div>
        </div>
      )}
      <Pagination
        key="USERCYCLESRESERVATIONS"
        dataSelector={selectUserCycleReservationsData}
        pagesSelector={selectUserCycleReservationsPage}
        loadingSelector={selectUserCycleReservationsLoading}
        setPage={setUserCycleReservationsPage}
        ElementCard={UserCycleReservationCard}
        customDispatchProps={{ id: userData._id }}
        customRedirectHandler={handleSetSelectedCycleReservation}
        dataContainerClassName={styles.paginationContainer}
        emptyHint={"Tutaj pojawią się kody rezerwacje użytkownika"}
        emptyText={"Brak rezerwacji"}
        dontRedirect
      />

      <Modal
        visible={
          selectedCycleReservation &&
          Object.keys(selectedCycleReservation).length > 0
        }
        closeModal={handleCloseModal}
        title={"Edytuj szczegóły rezerwacji"}
        isPadding={true}
        height="auto"
      >
        <div className={styles.modalWrapper}>
          <div className={styles.metaSection}>
            <div className={styles.resText}>Rezerwacja cyklu</div>
            <div className={styles.cycleName}>
              {selectedCycleReservation?.cycle?.name}
            </div>
            <div className={styles.userName}>{userData?.fullName}</div>

            <div className={styles.timestampsSection}>
              <div className={styles.timestamp}>
                <div className={styles.icon}>
                  <AddIcon sx={{ fontSize: 35 }} />
                </div>
                <div className={styles.text}>
                  {moment(selectedCycleReservation?.createdAt)
                    .locale("pl")
                    .format(DATE_FORMAT)}
                  {selectedCycleReservation?.createdBy?.fullName &&
                    ` przez ${selectedCycleReservation.createdBy.fullName}`}
                </div>
              </div>
              <div className={styles.timestamp}>
                <div className={styles.icon}>
                  <EditIcon />{" "}
                </div>
                <div className={styles.text}>
                  {moment(selectedCycleReservation?.updatedAt)
                    .locale("pl")
                    .format(DATE_FORMAT)}
                  {selectedCycleReservation?.editedBy?.fullName &&
                    ` przez ${selectedCycleReservation.editedBy.fullName}`}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.editSection}>
            <div className={styles.subSection}>
              <div className={styles.inputName}>STATUS REZERWACJI</div>
              <Select
                className={styles.statusSelect}
                variant="outlined"
                value={editStatus || "rejected"}
                onChange={(e) => {
                  setEditStatus(e.target.value);
                }}
              >
                {Object.keys(cycleReservationStates).map((k) => (
                  <MenuItem key={k} value={k}>
                    {cycleReservationStates[k].text}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className={styles.subSectionV}>
              <div className={styles.mailingListText}>Dane uczestnika: </div>

              <div className={styles.inputsHolder}>

                <TextField
                  label={"Imię i nazwisko uczestnika"}
                  className={styles.participant}
                  onChange={(e) => setEditParticipant(e.target.value)}
                  value={editParticipant}
                  inputProps={{ maxLength: name * 2 }}
                />

                <TextField
                  label={"Wiek uczestnika"}
                  variant="outlined"
                  className={styles.phone}
                  type="number"
                  name="age"
                  inputProps={{ min: 1, max: 120 }}
                  onChange={(e) => setEditParticipantAge(e.target.value)}
                  value={editParticipantAge}
                />

                <TextField
                  label={"Nr tel."}
                  variant="outlined"
                  name="phone"
                  className={styles.phone}
                  inputProps={{ maxLength: name * 2 }}
                  onChange={(e) => setEditParticipantPhone(e.target.value)}
                  value={editParticipantPhone}
                />



                {editParticipantAge < 18 ? (

                  <TextField
                    label={"Opiekun"}
                    variant="outlined"
                    name="supervisor"
                    className={styles.participant}
                    inputProps={{ maxLength: name * 2 }}
                    onChange={(e) => setEditParticipantSupervisor(e.target.value)}
                    value={editParticipantSupervisor}
                  />

                ) : null}

              </div>


              {/* <div className={styles.mailingListText}>
                Rodzaj wyrażonej zgody:
              </div>
              <div className={styles.consentText}>
                {selectedCycleReservation?.consentType === "adult" ? (
                  "Osoba dorosła"
                ) : (
                  <>
                    {"Niepełnoletni, imię i nazwisko opiekuna: "}{" "}
                    <span className={styles.consentGuard}>
                      {selectedCycleReservation?.guard}
                    </span>{" "}
                  </>
                )}
              </div> */}
            </div>
          </div>

          <div className={styles.buttonSection}>
            <LoadingButton
              variant="outlined"
              color="secondary"
              endIcon={<ManageAccountsIcon />}
              onClick={() => setEditUserOpen(true)}
            >
              Przepisz rezerwację na innego użytkownika
            </LoadingButton>

            <ConfirmButton
              cancelStartIcon={<CloseIcon />}
              confirmStartIcon={<DoneAllIcon />}
              questionText="Czy na pewno chcesz usunąć tą rezerwację?"
              onConfirm={handleDeleteCycleReservation}
            >
              <LoadingButton
                loading={actionLoading}
                variant="outlined"
                color="secondary"
                endIcon={<Delete />}
              >
                Usuń
              </LoadingButton>
            </ConfirmButton>

            <LoadingButton
              onClick={handleUpdateCycleReservation}
              loading={actionLoading}
              variant="outlined"
              endIcon={<DoneAll />}
            >
              Zatwierdź
            </LoadingButton>
          </div>
        </div>
      </Modal>

      <Modal
        isPadding={true}
        height="auto"
        visible={editUserOpen}
        closeModal={() => setEditUserOpen(false)}
        title={"Edytuj użytkownika, do którego przypisana jest ta rezerwacja"}
      >
        <div className={styles.modalWrapper}>
          <div className={styles.subSectionV}>
            <p>
              Rezerwacja cyklu : <b>{selectedCycleReservation?.cycle?.name}</b>
            </p>
            <p>
              Użytkownik: <b>{userData?.fullName}</b>
            </p>
            <p>
              Zapisany uczestnik:{" "}
              <b>{selectedCycleReservation?.participant?.fullName}</b>
            </p>
          </div>

          <center>
            <p>
              Zmiana przypisanego użytkownika przydatna jest np. przy
              użytkownikach, którzy nie mieli konta w systemie w momencie
              zapisywania się na cykl zajęć. Ich rezerwacja została
              prawdopodobnie przypisana wtedy do konta <i>anonimowego</i>.
              Jednak kiedy użytkownik założył już konto można mu przypisać
              rezerwację do jego konta. Dzięki temu będzie mógł otrzymywać
              wiadomości mailowe oraz oceniać wydarzenia.
            </p>
          </center>

          <h5>Wyszukaj nowego użytkownika: </h5>
          <AutoCompleteSearch
            searchUrl="/users/searchUsers"
            limit={15}
            setSingle={handleSetSingleUserSearch}
            placeholder="Wyszukaj użytkowników"
            sx={{ width: 500 }}
            className={styles.searchBar}
          />
          <div className={styles.buttonSection}>
            <LoadingButton
              loading={actionLoading}
              variant="outlined"
              color="secondary"
              endIcon={<CloseIcon />}
              onClick={() => setEditUserOpen(false)}
            >
              Anuluj
            </LoadingButton>

            <ConfirmButton
              cancelStartIcon={<CloseIcon />}
              confirmStartIcon={<DoneAllIcon />}
              questionText={`Czy na pewno chcesz przypisać rezerwację użytkownikowi ${editUser?.fullName}?`}
              onConfirm={handleEditCycleReservationUser}
            >
              <LoadingButton
                loading={actionLoading}
                variant="outlined"
                disabled={!editUser}
                endIcon={<DoneAll />}
              >
                Zatwierdź
              </LoadingButton>
            </ConfirmButton>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default connect()(UserCycleReservations);
