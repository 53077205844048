import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../API/APIService";

import { emitInfo, emmitError, emmitSuccess } from "../../utils/ToastEmmiter";
import { constructQuery } from "../../svc/svc";

export const setPage = createAsyncThunk(
  "manageUsers/setPage",
  async (obj, { rejectWithValue }) => {
    let response = null;
    const { page } = obj;
    await API.get(`/users/?page=${page}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const loadMoreUsers = createAsyncThunk(
  "manageUsers/loadMoreUsers",
  async (obj, { rejectWithValue, getState }) => {
    const { limit } = obj;
    const search = obj.search || "";
    let query = "?count=" + limit + "&";
    const state = getState().manageUsers;

    if (state.search.length > 0 && search?.length === 0) {
      //reseting
    } else if (search?.length > 0) {
      query = query + "search=" + search;
    } else if (state.users.length > 0) {
      query = query + "lastId=" + state.users[state.users.length - 1]._id + "&";
    }

    //
    let response = null;

    await API.get(`/users/searchUsers/${query}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return { data: response.data, limit, search };
  }
);

export const fetchSingleUser = createAsyncThunk(
  "manageUsers/",
  async (id, { rejectWithValue }) => {
    let response = null;
    await API.get(`/users/?id=${id}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const editUser = createAsyncThunk(
  "manageUsers/editUser",
  async (obj, { rejectWithValue }) => {
    let response = null;
    await API.patch(`/users/`, obj)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const fetchScopes = createAsyncThunk(
  "manageUsers/fetchScopes",
  async (obj, { rejectWithValue }) => {
    let response = null;
    await API.get(`/users/scopes`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const setUserCycleReservationsPage = createAsyncThunk(
  "manageUsers/setUserCycleReservationsPage",
  async (data, { rejectWithValue }) => {
    const stringquery = constructQuery({ ...data, _id: data.id });
    let response = null;
    await API.get(`/users/userCycleReservations?${stringquery}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const deleteUserCycleReservation = createAsyncThunk(
  "manageUsers/deleteUserCycleReservation",
  async (id, { rejectWithValue }) => {
    let response = null;
    await API.delete(`/users/userCycleReservation?_id=${id}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const updateUserCycleReservation = createAsyncThunk(
  "manageUsers/updateUserCycleReservation",
  async (obj, { rejectWithValue }) => {
    //obj ={_id, status}
    let response = null;
    await API.patch(`/users/userCycleReservation`, obj)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const createUserCycleReservation = createAsyncThunk(
  "manageUsers/createUserCycleReservation",
  async (obj, { rejectWithValue }) => {
    let response = null;
    await API.post(`/users/userCycleReservation`, obj)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const getSpecificUserCycleReservation = createAsyncThunk(
  "manageUsers/getSpecificUserCycleReservation",
  async (obj, { rejectWithValue }) => {
    const { setSelected, ...rest } = obj;
    let response = null;
    let search = new URLSearchParams(rest);
    await API.get(`/users/userCycleReservation?` + search.toString())
      .then((r) => {
        response = { data: r.data };
        if (Object.keys(r.data).length > 0) setSelected(r.data);
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const setPageForUserOrders = createAsyncThunk(
  "manageUsers/setPageForUserOrders",
  async ({ user, page }, { rejectWithValue }) => {
    let response = null;
    await API.get(`/orders/byUser?user=${user}&page=${page}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const getSingleUserOrder = createAsyncThunk(
  "manageUsers/getSingleUserOrder",
  async (id, { rejectWithValue }) => {
    let response = null;
    await API.get(`/orders/?id=${id}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const performRefund = createAsyncThunk(
  "manageUsers/performRefund",
  async ({ orderID, description }, { rejectWithValue }) => {
    let response = null;
    await API.post(`/orders/refund`, { orderID, description })
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const updateOrderStatus = createAsyncThunk(
  "manageUsers/updateOrderStatus",
  async ({ orderID, newStatus }, { rejectWithValue }) => {
    let response = null;
    await API.patch(`/orders/modifyStatus`, { orderID, newStatus })
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const getTeacherCycles = createAsyncThunk(
  "manageUsers/getTeacherCycles",
  async ({ page, user }, { rejectWithValue }) => {
    let response = null;
    await API.get(`/events/teacherCycles?user=${user}&page=${page}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const getParticipantReservations = createAsyncThunk(
  "manageUsers/getParticipantReservations",
  async (participant, { rejectWithValue }) => {
    let response = null;
    await API.get(`/users/participantReservations?participant=${participant}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const getHourlyAssessment = createAsyncThunk(
  "manageUsers/getHourlyAssessment",
  async ({ page, dateFrom, dateTo, dateFromBool }, { rejectWithValue }) => {
    let response = null;
    await API.get(
      `/events/hourlyAssessment?page=${page}&dateFrom=${dateFrom}&dateTo=${dateTo}&dateFromBool=${dateFromBool}`
    )
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const getHourlyAssessmentTeacherByID = createAsyncThunk(
  "manageUsers/getHourlyAssessmentTeacherByID",
  async ({ id, dateFrom, dateTo, dateFromBool }, { rejectWithValue }) => {
    let response = null;
    await API.get(
      `/events/hourlyAssessment?page=${0}&id=${id}&dateFrom=${dateFrom}&dateTo=${dateTo}&dateFromBool=${dateFromBool}`
    )
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const generateHourlyAssessmentReport = createAsyncThunk(
  "manageUsers/generateHourlyAssessmentReport",
  async ({ dateFrom, dateTo, dateFromBool }, { rejectWithValue, getState }) => {
    let response = null;

    let body = { dateTo };
    if (dateFromBool) body.dateFrom = dateFrom;
    body.userIDs = getState().manageUsers.hourlyAssessmentTeacherIDs;

    await API.post(`/events/generateHourlyAssessmentReport`, body)
      .then((r) => {
        console.log(r);
        const blob = new Blob([r.data], { type: "text/html" });
        const url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "raport";
        a.click();
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response;
  }
);

export const resetUserPassword = createAsyncThunk(
  "user/resetUserPassword",
  async (_id, { rejectWithValue }) => {
    let response = null;
  await API.post(`/users/generateNewPassword`, {_id})
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
      return response.data;
  }
);

const initial = {
  users: [],
  usersHasNextPage: true,
  usersPageLoading: false,
  search: "",
  selectedUser: {},
  userCycleReservations: [],

  userCycleReservationsPage: 1,
  userCycleReservationsTotalPages: 0,
  userCycleReservationsLoading: false,
  userCycleReservationsActionLoading: false,
  specificUserCycleReservation: {},

  participantsReservations: [],
  participantsReservationsLoading: false,

  userOrders: [],
  userOrdersLoading: false,
  userOrdersPage: 1,
  userOrdersTotalPages: 0,
  userOrdersSelected: undefined,
  userOrdersRefundModal: false,
  refundLoading: false,
  orderStatusEdition: false,

  teacherCycles: [],
  teacherCyclesLoading: false,
  teacherCyclesPage: 1,
  teacherCyclesTotalPages: 0,

  userLoading: false,
  singleUser: {},
  scopes: {},
  scopesLoading: false,
  editUserLoading: false,

  loadings: {
    userPasswordResetting: false,
  },

  hourlyAssessmentDocs: [],
  hourlyAssessmentPage: 1,
  hourlyAssessmentTotalPages: 0,
  hourlyAssessmentLoading: false,
  hourlyAssessmentTeacherIDs: [],
  getHourlyAssessmentTeacherByIDLoading: false,
  generateHourlyAssessmentReportLoading: false,
};

export const manageUsersSlice = createSlice({
  name: "manageUsers",
  initialState: initial,
  reducers: {
    setSingleUser: (state, action) => {
      state.singleUser = action.payload;
    },
    setUserSearch: (state, action) => {
      state.search = action.payload;
    },
    clearUsers: (state, action) => {
      state.users = [];
      state.usersHasNextPage = true;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    setSelectedUserOrder: (state, action) => {
      state.userOrdersSelected = action.payload;
    },
    deselectUserOrder: (state, action) => {
      state.userOrdersSelected = undefined;
    },
    openUserOrderRefundModal: (state, action) => {
      state.userOrdersRefundModal = true;
    },
    closeUserOrderRefundModal: (state, action) => {
      state.userOrdersRefundModal = false;
    },
    toggleHATeacherID: (state, action) => {
      const index = state.hourlyAssessmentTeacherIDs.indexOf(action.payload);
      if (index == -1) state.hourlyAssessmentTeacherIDs.push(action.payload);
      else state.hourlyAssessmentTeacherIDs.splice(index, 1);
    },

    clearHATeacherIDs: (state, action) => {
      state.hourlyAssessmentTeacherIDs = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadMoreUsers.pending, (state, action) => {
      state.usersPageLoading = true;
    });

    builder.addCase(loadMoreUsers.fulfilled, (state, action) => {
      if (action.payload.search.length > 2) {
        // jesli wyszukujemy to po prostu podmieniam array users
        state.users = action.payload.data;
        state.usersHasNextPage = false;
        state.search = action.payload.search;
        state.usersPageLoading = false;

        return;
      }

      /// jesli nie wyszukujemy to patrzymy jak ilosciowo sie sprawuncja ma
      if (action.payload.data.length < action.payload.limit) {
        state.usersHasNextPage = false;
      } else {
        state.usersHasNextPage = true;
      }

      state.users = [...state.users, ...action.payload.data];
      state.search = action.payload.search;
      state.usersPageLoading = false;
    });

    builder.addCase(fetchSingleUser.fulfilled, (state, action) => {
      state.singleUser = action.payload;
      state.userLoading = false;
    });

    builder.addCase(fetchSingleUser.pending, (state, action) => {
      state.userLoading = true;
    });

    builder.addCase(fetchSingleUser.rejected, (state, action) => {
      state.userLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(fetchScopes.fulfilled, (state, action) => {
      state.scopes = action.payload;
      state.scopesLoading = false;
      //return { ...{ authorized: true }, ...action.payload };
    });
    builder.addCase(fetchScopes.pending, (state, action) => {
      state.scopesLoading = true;
      //return { ...{ authorized: true }, ...action.payload };
    });
    builder.addCase(fetchScopes.rejected, (state, action) => {
      state.scopesLoading = false;
      emmitError(action.payload.data.error);
    });
    builder.addCase(editUser.pending, (state, action) => {
      state.editUserLoading = true;
    });
    builder.addCase(editUser.rejected, (state, action) => {
      state.editUserLoading = false;
      emmitError(action.payload.data.error);
    });
    builder.addCase(editUser.fulfilled, (state, action) => {
      state.editUserLoading = false;

      state.singleUser = action.payload;
      const allegedIndex = state.users.findIndex(
        (el) => el._id === action.payload._id
      );
      if (allegedIndex >= 0) {
        state.users[allegedIndex] = action.payload;
      }

      emmitSuccess("Pomyślnie edytowano użytkownika");
    });

    builder.addCase(
      getSpecificUserCycleReservation.pending,
      (state, action) => {
        state.userCycleReservationsLoading = true;
      }
    );
    builder.addCase(
      getSpecificUserCycleReservation.rejected,
      (state, action) => {
        state.userCycleReservationsLoading = false;
        emmitError(action.payload.data.error);
      }
    );
    builder.addCase(
      getSpecificUserCycleReservation.fulfilled,
      (state, action) => {
        state.specificUserCycleReservation = action.payload;
        state.userCycleReservationsLoading = false;
      }
    );

    builder.addCase(deleteUserCycleReservation.pending, (state, action) => {
      state.userCycleReservationsActionLoading = true;
    });
    builder.addCase(deleteUserCycleReservation.rejected, (state, action) => {
      state.userCycleReservationsActionLoading = false;
      emmitError(action.payload.data.error);
    });
    builder.addCase(deleteUserCycleReservation.fulfilled, (state, action) => {
      state.userCycleReservations = state.userCycleReservations.filter(
        (e) => e._id !== action.payload
      );
      state.userCycleReservationsActionLoading = false;
      emmitSuccess("Pomyślnie usunięto rezerwację");
    });

    builder.addCase(updateUserCycleReservation.pending, (state, action) => {
      state.userCycleReservationsActionLoading = true;
    });
    builder.addCase(updateUserCycleReservation.rejected, (state, action) => {
      state.userCycleReservationsActionLoading = false;
      emmitError(action.payload.data.error);
    });
    builder.addCase(updateUserCycleReservation.fulfilled, (state, action) => {
      emmitSuccess("Pomyślnie edytowano rezerwację");
      const allegedIndex = state.userCycleReservations.findIndex(
        (e) => e._id === action.payload._id
      );
      if (allegedIndex >= 0) {
        state.userCycleReservations[allegedIndex] = action.payload;
      }
      state.userCycleReservationsActionLoading = false;
    });

    builder.addCase(createUserCycleReservation.pending, (state, action) => {
      state.userCycleReservationsActionLoading = true;
    });
    builder.addCase(createUserCycleReservation.rejected, (state, action) => {
      state.userCycleReservationsActionLoading = false;

      emmitError(action.payload.data.error);
    });
    builder.addCase(createUserCycleReservation.fulfilled, (state, action) => {
      console.log(action.payload);
      let newReservations = [...action.payload, ...state.userCycleReservations];
      state.userCycleReservations = newReservations;
      state.userCycleReservationsActionLoading = false;
      emmitSuccess("Pomyślnie dodano nowe rezerwacje!");
    });

    builder.addCase(setPageForUserOrders.pending, (state, action) => {
      state.userOrdersLoading = true;
    });
    builder.addCase(setPageForUserOrders.rejected, (state, action) => {
      state.userOrdersLoading = false;
      emmitError(action.payload.data.error);
    });
    builder.addCase(setPageForUserOrders.fulfilled, (state, action) => {
      state.userOrders = action.payload.docs;
      state.userOrdersPage = action.payload.page;
      state.userOrdersTotalPages = action.payload.totalPages;
      state.userOrdersLoading = false;
    });

    builder.addCase(performRefund.fulfilled, (state, action) => {
      state.refundLoading = false;
      state.userOrdersRefundModal = false;
      state.userOrders.find((e) => e._id == action.payload).status = "refunded";
      emmitSuccess("Pomyślnie utworzono zwrot");
    });

    builder.addCase(performRefund.pending, (state, action) => {
      state.refundLoading = true;
    });
    builder.addCase(performRefund.rejected, (state, action) => {
      state.refundLoading = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(updateOrderStatus.fulfilled, (state, action) => {
      const { newStatus, orderID, manuallyHandled = false } = action.payload;
      state.orderStatusEdition = false;
      state.userOrdersRefundModal = false;

      let orderRef = state.userOrders.find((e) => e._id == orderID);
      orderRef.status = newStatus;
      orderRef.manuallyHandled = manuallyHandled;

      state.userOrdersSelected.status = newStatus;
      state.userOrdersSelected.manuallyHandled = manuallyHandled;

      const successMessage =
        newStatus == "refunded"
          ? "Pomyślnie utworzono zwrot"
          : "Pomyślnie zmodyfikowano status zamówienia";

      !action.payload.message
        ? emmitSuccess(successMessage)
        : emitInfo(action.payload.message);
    });

    builder.addCase(updateOrderStatus.pending, (state, action) => {
      state.orderStatusEdition = true;
    });
    builder.addCase(updateOrderStatus.rejected, (state, action) => {
      state.orderStatusEdition = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(setUserCycleReservationsPage.pending, (state, action) => {
      state.userCycleReservationsLoading = true;
    });
    builder.addCase(setUserCycleReservationsPage.rejected, (state, action) => {
      state.userCycleReservationsLoading = false;
      emmitError(action.payload.data.error);
    });
    builder.addCase(setUserCycleReservationsPage.fulfilled, (state, action) => {
      state.userCycleReservations = action.payload.docs;
      state.userCycleReservationsPage = action.payload.page;
      state.userCycleReservationsTotalPages = action.payload.totalPages;
      state.userCycleReservationsLoading = false;
    });

    builder.addCase(getTeacherCycles.pending, (state, action) => {
      state.teacherCyclesLoading = true;
    });
    builder.addCase(getTeacherCycles.rejected, (state, action) => {
      state.teacherCyclesLoading = false;
      emmitError(action.payload.data.error);
    });
    builder.addCase(getTeacherCycles.fulfilled, (state, action) => {
      state.teacherCycles = action.payload.docs;
      state.teacherCyclesPage = action.payload.page;
      state.teacherCyclesTotalPages = action.payload.totalPages;
      state.teacherCyclesLoading = false;
    });

    builder.addCase(getParticipantReservations.pending, (state, action) => {
      state.participantsReservationsLoading = true;
    });
    builder.addCase(getParticipantReservations.rejected, (state, action) => {
      state.participantsReservationsLoading = false;
      emmitError(action.payload.data.error);
    });
    builder.addCase(getParticipantReservations.fulfilled, (state, action) => {
      state.participantsReservations = action.payload;
      state.participantsReservationsLoading = false;
    });

    builder.addCase(getSingleUserOrder.fulfilled, (state, action) => {
      state.userOrdersSelected = action.payload;
    });
    builder.addCase(getSingleUserOrder.rejected, (state, action) => {
      emmitError(action.payload.data.error);
    });

    builder.addCase(getHourlyAssessment.pending, (state, action) => {
      state.hourlyAssessmentLoading = true;
    });
    builder.addCase(getHourlyAssessment.rejected, (state, action) => {
      state.hourlyAssessmentLoading = false;
      emmitError(action.payload.data.error);
    });
    builder.addCase(getHourlyAssessment.fulfilled, (state, action) => {
      state.hourlyAssessmentDocs = action.payload.docs;
      state.hourlyAssessmentPage = action.payload.page;
      state.hourlyAssessmentTotalPages = action.payload.totalPages;
      state.hourlyAssessmentLoading = false;
    });

    builder.addCase(getHourlyAssessmentTeacherByID.pending, (state, action) => {
      state.getHourlyAssessmentTeacherByIDLoading = true;
    });
    builder.addCase(
      getHourlyAssessmentTeacherByID.rejected,
      (state, action) => {
        state.getHourlyAssessmentTeacherByIDLoading = false;
        emmitError(action.payload.data.error);
      }
    );
    builder.addCase(
      getHourlyAssessmentTeacherByID.fulfilled,
      (state, action) => {
        state.getHourlyAssessmentTeacherByIDLoading = false;
        console.log(action.payload);
        if (action.payload.length == 0)
          return emmitError(
            "Podany użytkownik nie prowadził zajęć w danym okresie"
          );
        state.hourlyAssessmentDocs.unshift(...action.payload);
        state.hourlyAssessmentDocs.splice(
          state.hourlyAssessmentDocs.length - 1,
          1
        );
      }
    );

    builder.addCase(generateHourlyAssessmentReport.pending, (state, action) => {
      state.generateHourlyAssessmentReportLoading = true;
    });
    builder.addCase(
      generateHourlyAssessmentReport.rejected,
      (state, action) => {
        state.generateHourlyAssessmentReportLoading = false;
        emmitError(action.payload.data.error);
      }
    );
    builder.addCase(
      generateHourlyAssessmentReport.fulfilled,
      (state, action) => {
        state.generateHourlyAssessmentReportLoading = true;
        emmitSuccess("Pomyślnie utworzono raport");
      }
    );

    builder.addCase(resetUserPassword.pending, (state, action) => {
      state.loadings.userPasswordResetting = true;
    });
    builder.addCase(resetUserPassword.rejected, (state, action) => {
      state.loadings.userPasswordResetting = false;
      emmitError(action.payload.data.error);
    });
    builder.addCase(resetUserPassword.fulfilled, (state, action) => {
      state.loadings.userPasswordResetting = false;
      emmitSuccess("Pomyślnie zresetowano hasło użytkownika")
    });
  },
});

const { actions, reducer } = manageUsersSlice;

export const {
  setSingleUser,
  clearUsers,
  setUserSearch,
  setSelectedUser,
  setSelectedUserOrder,
  deselectUserOrder,
  openUserOrderRefundModal,
  closeUserOrderRefundModal,
  pushToHATeacherIDs,
  toggleHATeacherID,
} = actions;

export const selectUsers = (state) => {
  return {
    data: state.manageUsers.users,
    hasNextPage: state.manageUsers.usersHasNextPage,
    count: state.manageUsers.usersCount,
    loading: state.manageUsers.usersPageLoading,
  };
};

export const selectSingleUser = (state) => {
  return {
    single: state.manageUsers.singleUser,
    singleLoading: state.manageUsers.userLoading,
  };
};

export const selectSelectedUser = (state) => {
  return state.manageUsers.selectedUser;
};

export const selectEditUserLoading = (state) => {
  return state.manageUsers.editUserLoading;
};

export const selectPages = (state) => {
  return {
    page: state.manageUsers.page,
    pageCount: state.manageUsers.totalPages,
  };
};

// export const selectUserCycleReservations = (state) => {
//     return {
//         reservations: state.manageUsers.userCycleReservations,
//         loading: state.manageUsers.userCycleReservationsLoading,
//         actionLoading: state.manageUsers.userCycleReservationsActionLoading,
//     };
// };

export const selectUserOrdersData = (state) => {
  return state.manageUsers.userOrders;
};

export const selectUserOrdersPage = (state) => {
  return {
    page: state.manageUsers.userOrdersPage,
    pageCount: state.manageUsers.userOrdersTotalPages,
  };
};

export const selectUserOrdersLoading = (state) => {
  return state.manageUsers.userOrdersLoading;
};

export const selectUserOrdersSelected = (state) => {
  return state.manageUsers.userOrdersSelected;
};

export const selectUserOrderByID = (state, id) => {
  return state.manageUsers.userOrders.find((e) => e._id == id);
};

export const selectUserOrdersRefundModalState = (state) => {
  return state.manageUsers.userOrdersRefundModal;
};

export const selectUserCycleReservationsData = (state) => {
  return state.manageUsers.userCycleReservations;
};

export const selectUserCycleReservationsPage = (state) => {
  return {
    page: state.manageUsers.userCycleReservationsPage,
    pageCount: state.manageUsers.userCycleReservationsTotalPages,
  };
};

export const selectUserCycleReservationsLoading = (state) => {
  return state.manageUsers.userCycleReservationsLoading;
};

export const selectUserCycleReservationsActionLoading = (state) => {
  return state.manageUsers.userCycleReservationsActionLoading;
};

export const selectTeacherCyclesData = (state) => {
  return state.manageUsers.teacherCycles;
};

export const selectTeacherCyclesPage = (state) => {
  return {
    page: state.manageUsers.teacherCyclesPage,
    pageCount: state.manageUsers.teacherCyclesTotalPages,
  };
};

export const selectTeacherCyclesLoading = (state) => {
  return state.manageUsers.teacherCyclesLoading;
};

export const selectHourlyAssessmentData = (state) => {
  return state.manageUsers.hourlyAssessmentDocs;
};

export const selectHourlyAssessmentPage = (state) => {
  return {
    page: state.manageUsers.hourlyAssessmentPage,
    pageCount: state.manageUsers.hourlyAssessmentTotalPages,
  };
};

export const selectHourlyAssessmentLoading = (state) => {
  return state.manageUsers.hourlyAssessmentLoading;
};

export const selectIsHATeacherSelected = (state, id) => {
  return state.manageUsers.hourlyAssessmentTeacherIDs.indexOf(id) != -1;
};

export const selectIsAnyTeacherSelected = (state) => {
  return state.manageUsers.hourlyAssessmentTeacherIDs.length != 0;
};

export const selectGetHourlyAssessmentTeacherByIDLoading = (state) => {
  return state.manageUsers.getHourlyAssessmentTeacherByIDLoading;
};

export const selectParticipantsReservations = (state) => {
  return {
    data: state.manageUsers.participantsReservations,
    loading: state.manageUsers.participantsReservationsLoading,
  };
};

export const selectManagementLoadings = (state) => {
  return {
    userPasswordResetting: state.manageUsers.loadings.userPasswordResetting,
  };
};

export default reducer;
