import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../API/APIService";

import { emmitError, emmitSuccess } from "../../utils/ToastEmmiter";

export const fetchResources = createAsyncThunk(
  "resources/fetch",
  async (id, { rejectWithValue }) => {
    let response = null;
    await API.get(`/resources/`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const addResource = createAsyncThunk(
  "resources/add",
  async (data, { rejectWithValue }) => {
    let response = null;
    await API.post("/resources/", data)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const updateResource = createAsyncThunk(
  "resources/update",
  async (obj, { rejectWithValue }) => {
    let response = null;
    await API.put("/resources/", obj)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const deleteResource = createAsyncThunk(
  "resources/delete",
  async (id, { rejectWithValue }) => {
    let response = null;
    await API.delete(`/resources/?_id=${id}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return id;
  }
);
export const resourcesSlice = createSlice({
  name: "resources",
  initialState: {
    resources: [],
    singleResource: {},
    loading: false,
    loadingDelete: false,
    loadingAdd: false,
    loadingUpdate: false,
    addResourceModalVisible: false,
    resourceViewModalID: undefined,
  },
  reducers: {
    setSingleResource: (state, action) => {
      state.singleResource = action.payload;
    },
    setAddResourceModalVisible: (state, action) => {
      state.addResourceModalVisible = true;
    },
    setAddResourceModalInvisible: (state, action) => {
      state.addResourceModalVisible = false;
    },
    setResourceViewModalID: (state, action) => {
      state.resourceViewModalID = action.payload;
    },
    closeResourceViewModal: (state) => {
      state.resourceViewModalID = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResources.fulfilled, (state, action) => {
      state.resources = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchResources.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchResources.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteResource.pending, (state, action) => {
      state.loadingDelete = true;
    });

    builder.addCase(deleteResource.fulfilled, (state, action) => {
      const _id = action.payload;
      const index = state.resources.findIndex((el) => el._id === _id);
      if (index >= 0) state.resources.splice(index, 1);
      state.loadingDelete = false;
      emmitSuccess("Pomyślnie usunięto zasób");
    });

    builder.addCase(deleteResource.rejected, (state, action) => {
      state.loadingDelete = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(addResource.pending, (state, action) => {
      state.loadingAdd = true;
    });

    builder.addCase(addResource.rejected, (state, action) => {
      state.loadingAdd = false;
      emmitError(action.payload.data.error);
    });

    builder.addCase(addResource.fulfilled, (state, action) => {
      state.resources = state.resources.concat(action.payload);
      state.loadingAdd = false;
      emmitSuccess("Pomyślnie dodano zasób");
    });

    builder.addCase(updateResource.pending, (state, action) => {
      state.loadingUpdate = true;
    });

    builder.addCase(updateResource.fulfilled, (state, action) => {
      const _id = action.payload._id;
      const index = state.resources.findIndex((el) => el._id === _id);
      if (index >= 0) {
        state.resources[index] = action.payload;
      }
      state.loadingUpdate = false;
      emmitSuccess("Pomyślnie zaktualizowano zasób");
    });

    builder.addCase(updateResource.rejected, (state, action) => {
      state.loadingUpdate = false;
      emmitError(action.payload.data.error);
    });
  },
});

const { actions, reducer } = resourcesSlice;

// Action creators are generated for each case reducer function
export const {
  setSingleResource,
  setAddResourceModalInvisible,
  setAddResourceModalVisible,
  setResourceViewModalID,
  closeResourceViewModal,
} = actions;

export const selectResources = (state) => {
  return {
    resources: state.resources.resources,
    loading: state.resources.loading,
  };
};

export const isAnyResources = (state) => {
  return (
    state.resources.resources.length !== 0
  );
};

export const isResourcesLoading = (state) => {
  return (
    state.resources.loading
  )
}

export const selectResourceByID = (state, id) => {
  return !id ? null : state.resources.resources.find((e) => e._id == id);
};

export const selectSingle = (state) => {
  return state.resources.singleResource;
};

export const selectLoadings = (state) => {
  return {
    update: state.resources.loadingUpdate,
    delete: state.resources.loadingDelete,
    add: state.resources.loadingAdd,
  };
};

export const selectAddResourceModalVisibility = (state) => {
  return state.resources.addResourceModalVisible;
};

export const selectResourceViewModalID = (state) => {
  return state.resources.resourceViewModalID;
};

export default reducer;
