import React, { useEffect, useState } from "react";

import { connect, useDispatch, useSelector } from "react-redux";

import styles from "./calendar.module.scss";

import plLocale from "@fullcalendar/core/locales/pl";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import {
  fetchEvents,
  fetchWorkers,
  selectEventToEditData,
  selectTeachers,
  setEventToEditData,
  toggleFilterVisibility,
} from "../../redux/slices/eventsSlice";

import moment from "moment";
import {
  fetchResources,
  selectResources,
} from "../../redux/slices/resourcesSlice";
import { selectUser } from "../../redux/slices/userSlice";
import EditEventData from "../calendarComponents/EditEventData";
import EventModal from "../calendarComponents/EventModal";
import EventsList from "../calendarComponents/EventsList";
import RepetetiveEvent from "../calendarComponents/RepetetiveEvent";

import LoadingButton from "@mui/lab/LoadingButton";
import Loading from "../universal/Loading";
import PeopleIcon from "@mui/icons-material/People";
import PasteEvent from "../calendarComponents/PasteEvent";

function ResourceCalendar(props) {
  const dispatch = useDispatch();
  const buildings = useSelector(selectResources).resources;
  const buildingsLoading = useSelector(selectResources).loading;
  const parentLoading = props.loading;
  const { events, eventsLoading, filterVisibility } = useSelector(
    props.eventSelector
  );
  const user = useSelector(selectUser);

  const teachers = useSelector(selectTeachers);

  const [editableEvents, setEditableEvents] = useState([]);
  const [popoverEvent, setPopoverEvent] = useState(null); ///dla modala

  //edit event
  const { event, modal } = useSelector(selectEventToEditData);
  const selectedEventToEdit = event; ///dla modala
  const editEventModal = modal; /// ten do edycji

  // console.log("selectedEvent", selectedEventToEdit);

  const [previewEventModal, setPreviewEventModal] = useState(null);
  const [repetetiveEventOpenModal, setRepetetiveEventOpenModal] =
    React.useState(false);
  const [pasteEventOpenModal, setPasteEventOpenModal] = React.useState(false);
  const [pasteMode, setPasteMode] = React.useState("event");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [currentlyViewedMonth, setCurrentlyViewedMonth] = useState(-1); // żeby onMountZaciągneło
  const [currentlyViewedYear, setCurrentlyViewedYear] = useState(-1);

  const handleDateChange = (dateInfo) => {
    if (props.preventFetchingEvents) return; // jeśli zapobiegamy zaciąganiu eventów to nie ma sensu ich zaciągać onchagne (bo jakoś już som)

    const mom = moment(dateInfo.start);
    const newMonth = mom.month();
    const newYear = mom.year();

    if (newMonth !== currentlyViewedMonth || newYear !== currentlyViewedYear) {
      dispatch(
        fetchEvents({
          cycleId: props.cycleId,
          month: newMonth,
          year: newYear,
        })
      );
      setCurrentlyViewedMonth(newMonth);
      setCurrentlyViewedYear(newYear);
    }
  };

  const handleOpenModal = (event) => {
    dispatch(setEventToEditData({ modal: true, event }));
  };

  const handleOpenPreviewModal = (event) => {
    setPreviewEventModal(event);
  };

  const handleClosePreviewModal = () => {
    setPreviewEventModal(null);
  };

  const calendarCore = props.calendarCore;

  useEffect(() => {
    if (
      props.stopInitialFetch === undefined ||
      props.stopInitialFetch === false
    ) {
      dispatch(fetchResources());
      dispatch(fetchWorkers());
    }

    // if (!props.preventFetchingEvents) {
    //   dispatch(
    //     fetchEvents({
    //       cycleId: props.cycleId,
    //       month: currentlyViewedMonth,
    //       year: currentlyViewedYear,
    //     })
    //   ); //id cyklu który ma być edytowalny (jeśli brak to wtedy edytowalne są tylko nowe eventy [o ile mogą być])
    // }

    if (props.canAddEvents) {
      let draggable = document.getElementById("reservation");

      new Draggable(draggable, {
        eventData: (event) => {
          return {
            title: "Upuść...",
            duration: "01:30",
            editable: true,
            durationEditable: true,
            resourceEditable: true,
            extendedProps: {
              preMail: {},
              afterMail: {},
            },
          };
        },
      });
    }
  }, []);

  const handleChangeDisplay = (el) => {
    let momentDate = moment(el.start).subtract(1, "hour").format("HH:mm");

    let calendarApi = calendarCore.current.getApi();
    calendarApi.changeView("resourceTimelineDay", el.start);

    setTimeout(() => {
      calendarApi.scrollToTime(momentDate);
      let htmlEvent = document.getElementById(el.id);
      ///get parent
      let parent = htmlEvent.parentElement.parentElement;

      ///add classname
      parent.classList.add(styles.highlightedEvent);

      setTimeout(() => {
        parent.classList.remove(styles.highlightedEvent);
      }, 4000);
    }, 100);
  };

  const [groupByBuildings, setGroupByBuildings] = useState(true);

  return (
    <>
      <EventModal
        event={previewEventModal}
        closeModal={handleClosePreviewModal}
        singleCycle={props.singleCycle}
      />

      {props.canAddEvents && (
        <>
          <div className={styles.addEventsBox}>
            <div className={styles.option}>
              <span>Wklej wydarzenia</span>
              <LoadingButton
                variant="outlined"
                onClick={() => {
                  setPasteMode("event");
                  setPasteEventOpenModal(true);
                }}
              >
                Pojedyńcze wydarzenie
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                onClick={() => {
                  setPasteMode("cycle");
                  setPasteEventOpenModal(true);
                }}
              >
                Wszystkie wydarzenia cyklu
              </LoadingButton>
            </div>
            <div className={styles.option}>
              <span>Kliknij aby dodać wydarzenie okresowe</span>
              <LoadingButton
                variant="outlined"
                onClick={() => {
                  setRepetetiveEventOpenModal(true);
                }}
              >
                Wydarzenie okresowe
              </LoadingButton>
            </div>

            <div className={styles.option}>
              <span>
                Przeciągnij wydarzenie w odpowiednie miejsce na kalendarzu
              </span>
              <div id="reservation" className={styles.draggableBox}>
                Wydarzenie
              </div>
            </div>
          </div>
        </>
      )}

      <div className={styles.fullCalendarAndList}>
        <div className={styles.calendarWrapper}>
          <FullCalendar
            events={events}
            height={"auto"}
            className={styles.calendar}
            ref={calendarCore}
            locale={plLocale}
            eventContent={(eventInfo) => {
              return <div id={eventInfo.event.id}>{eventInfo.event.title}</div>;
            }}
            customButtons={{
              toggleGroup: {
                text: "Grupowanie",
                click: function () {
                  setGroupByBuildings(!groupByBuildings);
                },
              },
              toggleVisibility: {
                text: filterVisibility ? "Pokaż wszystkie" : "Pokaż moje",
                click: function () {
                  dispatch(toggleFilterVisibility(user._id));
                },
              },
            }}
            headerToolbar={{
              left: props.showVisibilityToggle
                ? "today,prev,next,toggleGroup,toggleVisibility"
                : "today,prev,next,toggleGroup",
              center: "title",
              right:
                "resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth",
            }}
            titleFormat={{
              month: "numeric",
              day: "numeric",
              weekday: "long",
            }}
            selectable={true}
            selectMirror={true}
            slotDuration={"00:15"}
            expandRows={true}
            datesSet={handleDateChange}
            // slotLabelFormat={{
            //   hour: "numeric",
            //   minute: "2-digit",
            // }}
            schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
            plugins={[resourceTimelinePlugin, interactionPlugin]}
            editable={false}
            eventChange={(changeInfo) => {
              if (!groupByBuildings && !editEventModal) {
                //czyli jesli mamy ciąganie se eventów po teacherach
                const event = changeInfo.event;
                const oldEvent = changeInfo.oldEvent;

                const newRes = event._def.resourceIds;
                const oldRes = oldEvent._def.resourceIds;

                if (newRes.length <= 1 || oldRes.length <= 1) return;
                if (newRes[0] === oldRes[0] && newRes[1] === oldRes[1]) return;

                const oldTeacher = oldRes[0];
                const newTeacher = newRes[1];

                if (oldTeacher !== newTeacher) {
                  event.setExtendedProp("teacher", newTeacher);
                  event.setResources([newTeacher, oldRes[1]]);
                }
              }
            }}
            eventsSet={(events) => {
              setEditableEvents(
                events.filter((el) => el.durationEditable === true)
              ); ///nowe eventy to tylko te edytowalne
            }}
            eventReceive={(addInfo) => {
              const event = addInfo.event;
              event.setProp(
                "title",
                "Wydarzenie " + (editableEvents.length + 1)
              );
              const resources = event.getResources();

              if (!groupByBuildings) {
                /// ustawianie defaultowego nauczyciela w resources

                event.setResources([resources[0].id, buildings[0].id]); ///resourceIds nauczyciel/budynek
                event.setExtendedProp("teacher", resources[0].id);
                return;
              }

              /// ustawianie defaultowego nauczyciela w resources
              event.setResources([teachers[0].id, resources[0].id]); ///resourceIds nauczyciel/budynek
              event.setExtendedProp("teacher", teachers[0].id);
            }}
            droppable={true}
            initialView="resourceTimelineDay"
            resourceAreaWidth={"20%"}
            slotMinTime={"06:00:00"}
            eventClick={(eventClick) => {
              const isEditableEvent =
                editableEvents.findIndex(
                  (el) => el.id === eventClick.event.id
                ) !== -1;

              if (isEditableEvent) return handleOpenModal(eventClick.event);
              else
                return handleOpenPreviewModal({
                  ...eventClick.event._def.extendedProps,
                  start: eventClick.event.start,
                  end: eventClick.event.end,
                  title: eventClick.event.title,
                });
            }}
            nowIndicator={true}
            {...(groupByBuildings ? { resourceGroupField: "building" } : {})}
            resourceAreaColumns={
              groupByBuildings
                ? [
                    // {
                    //   group: true,
                    //   field: "building",
                    //   headerContent: "Budynek",
                    //   width: 80,
                    // },
                    {
                      field: "name",
                      headerContent: "Sala",
                      width: 200,
                    },
                  ]
                : [{ field: "fullName", headerContent: "Prowadzący" }]
            }
            resources={groupByBuildings ? buildings : teachers}
            resourceLabelContent={
              groupByBuildings
                ? (elem) => {
                    return (
                      <div className={styles.roomRender}>
                        <div className={styles.roomName}>{elem.fieldValue}</div>
                        <div className={styles.capacity}>
                          <PeopleIcon
                            style={{ marginBottom: "2px", marginRight: "3px" }}
                          />{" "}
                          {elem.resource._resource.extendedProps.capacity}{" "}
                        </div>
                      </div>
                    );
                  }
                : (elem) => elem.fieldValue
            }
          />

          {eventsLoading ||
            (parentLoading && (
              <div className={styles.loading}>
                {" "}
                <Loading />{" "}
              </div>
            ))}
        </div>
        <div className={styles.listsContainer}>
          {props.inprogressEvents?.length > 0 && (
            <EventsList
              singleCycle={props.singleCycle}
              events={props.inprogressEvents}
              userType={user.type}
              userId={user._id}
              strList
              noHeader
              handleChangeDisplay={handleChangeDisplay}
              canAddEvents={props.canAddEvents}
              handleClick={(el) => {
                handleOpenPreviewModal(el);
              }}
            />
          )}

          <EventsList
            singleCycle={props.singleCycle}
            noHeader
            events={
              editableEvents.length > 0 ? editableEvents : props.upcomingEvents
            }
            userType={user.type}
            userId={user._id}
            handleChangeDisplay={handleChangeDisplay}
            canAddEvents={props.canAddEvents}
            handleClick={(el) => {
              if (props.canAddEvents) handleOpenModal(el);
              else handleOpenPreviewModal(el);
            }}
          />
        </div>
      </div>

      {/* sekcja modali  */}
      {teachers.length > 0 && buildings.length > 0 && (
        <RepetetiveEvent
          open={repetetiveEventOpenModal}
          setOpen={setRepetetiveEventOpenModal}
          calendar={calendarCore}
          teachers={teachers}
          buildings={buildings}
        />
      )}

      <PasteEvent
        open={pasteEventOpenModal}
        setOpen={setPasteEventOpenModal}
        calendar={calendarCore}
        pasteMode={pasteMode}
      />

      {selectedEventToEdit && editEventModal && (
        <EditEventData
          open={editEventModal}
          event={selectedEventToEdit}
          teachers={teachers}
          buildings={buildings}
          canAddEvents={props.canAddEvents}
        />
      )}

      {/* <Button onClick={() => handleSubmit()}>zatwierdź</Button> */}
    </>
  );
}

export default connect()(ResourceCalendar);
