import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../API/APIService";
import { toast } from "react-toastify";
import Cookie from "js-cookie";
import { history } from "../../index.jsx";
import { emmitError, emmitSuccess } from "../../utils/ToastEmmiter";

export const setLogsPage = createAsyncThunk(
  "logs/setPage",
  async (obj, { rejectWithValue }) => {
    let response = null;
    const { page } = obj;
    await API.get(`/logs/?page=${page}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });

    return response.data;
  }
);

export const logsSlice = createSlice({
  name: "logs",
  initialState: {
    logs: [],
    logsPage: 1,
    totalLogsPages: 0,
    setLogsPageLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setLogsPage.fulfilled, (state, action) => {
      state.logs = action.payload.docs;
      state.logsPage = action.payload.page;
      state.totalLogsPages = action.payload.totalPages;
      state.setLogsPageLoading = false;
    });

    builder.addCase(setLogsPage.pending, (state, action) => {
      state.setLogsPageLoading = true;
    });

    builder.addCase(setLogsPage.rejected, (state, action) => {
      state.setLogsPageLoading = false;
    });
  },
});

const { actions, reducer } = logsSlice;

export const {} = actions;

export const selectSetLogsPageLoading = (state) => {
  return state.logs.setLogsPageLoading;
};

export const selectLogsPages = (state) => {
  return {
    page: state.logs.logsPage,
    pageCount: state.logs.totalLogsPages,
  };
};

export const selectLogs = (state) => {
  return state.logs.logs;
};

export default reducer;
