import React from "react"
import styles from "./featuredCyclesMOD.module.scss"
import moment from "moment"
import { fadeIn } from "../utils/motion"
import { motion } from "framer-motion"
import classNames from "classnames"
import { TitleText } from "../utils/CustomText"
import DoubleArrowOutlinedIcon from "@mui/icons-material/DoubleArrowOutlined"
import { useHistory } from "react-router-dom"

const DATE_FORMAT = "DD MMMM YYYY"

function FeaturedCycleCard({
  id,
  thumbnail,
  name,
  shortDesc,
  index,
  active,
  handleClick,
}) {
  const history = useHistory()

  return (
    <motion.div
      variants={fadeIn("right", "spring", index * 0.5, 0.75)}
      className={
        active === index
          ? classNames(styles.featuredCycleCardActive, styles.featuredCycleCard)
          : classNames(
              styles.featuredCycleCardInactive,
              styles.featuredCycleCard
            )
      }
      onClick={() => handleClick(index)}
    >
      <img src={thumbnail} alt="Miniatura" className={styles.thumbnail} />

      <div
        className={
          active !== index
            ? styles.cardContent
            : classNames(styles.cardContent, styles.cardContentActive)
        }
      >
        {active !== index ? (
          <TitleText title={name} customStyles={styles.titleInactive} />
        ) : (
          <div className={styles.contentLayout}>
            <div className={styles.left}>
              <TitleText title={name} customStyles={styles.titleActive} />
              <div className={styles.descriptionContainer}>
                <div className={styles.description}>{shortDesc}</div>
              </div>
            </div>

            <div
              onClick={() => {
                history.push("/cycles/" + id)
              }}
              className={classNames(styles.icon)}
            >
              <DoubleArrowOutlinedIcon
                sx={{ fontSize: 48 }}
              ></DoubleArrowOutlinedIcon>
            </div>
          </div>
        )}
        <div
          className={classNames(styles.cardBottomAccent, styles.basicOrange)}
        ></div>
      </div>
    </motion.div>
  )
}

export default FeaturedCycleCard
