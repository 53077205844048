import React from "react";
import PanelButton from "../../../../components/panelButton/PanelButton";

import AddIcon from "@mui/icons-material/Add";

import { useHistory } from "react-router-dom";

function AddCycleButton(props) {
  const history = useHistory();

  return (
    <PanelButton
      onClick={() => history.push("/addCycle")}
      label={"Dodaj cykl zajęć"}
      icon={<AddIcon style={{ fontSize: 40 }} />}
      width={100}
      height={100}
    />
  );
}

export default AddCycleButton;
