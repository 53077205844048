import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PercentIcon from "@mui/icons-material/Percent";
import { Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./promoCodes.module.scss";

import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ConfirmButton from "../../../../components/confirmButton/ConfirmButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import classNames from "classnames";
import moment from "moment";

import { deletePromoCode } from "../../../../redux/slices/promoCodesSlice";

function PromoCodeCard(props) {
  const dispatch = useDispatch();
  const { data } = props;
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handleDelete = async () => {
    dispatch(
      deletePromoCode({
        _id: data._id,
      })
    );
  };

  const DATE_FORMAT = "DD-MM-YYYY HH:mm";

  let CodeIcon;

  if (data.type === "percent") {
    CodeIcon = (
      <PercentIcon
        sx={{ fontSize: 36, marginLeft: "10px", marginRight: "10px" }}
      ></PercentIcon>
    );
  } else {
    CodeIcon = (
      <AttachMoneyIcon
        sx={{ fontSize: 36, marginLeft: "10px", marginRight: "10px" }}
      ></AttachMoneyIcon>
    );
  }

  return (
    <Box sx={{ border: 2, marginBottom: "10px", marginRight: "10px" }}>
      <div
        {...props}
        className={styles.promoCardContainer}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={styles.cardContent}>
          <div className={styles.topCard}>
            <div className={styles.cardPromoData}>
              <div className={styles.metaData}>
                <Box
                  className={
                    isHover
                      ? classNames(
                          styles.cardBanner,
                          styles.cardBannerHoverColor,
                          !props.isSmall ? styles.cardBannerHoverWidth : ""
                        )
                      : styles.cardBanner
                  }
                >
                  {CodeIcon}
                  <div className={styles.vertical}></div>
                  <Typography
                    align="left"
                    variant="h1"
                    sx={{
                      fontSize: 16,
                      fontWeight: 700,
                      color: "description_grey",
                      marginLeft: "20px",
                    }}
                  >
                    {data.amount} {data.type === "percent" ? "%" : "zł"}
                  </Typography>
                </Box>
                <div className={styles.PromoText}>
                  <Typography align="left">{data.code}</Typography>
                </div>
              </div>
              {!props.isSmall && <div className={styles.verticalDark}></div>}
              {!props.isSmall && (
                <div className={styles.PromoData}>
                  {data.multiUse ? (
                    <div className={styles.PromoStat}>
                      <Typography
                        align="left"
                        variant="subtitle1"
                        sx={{
                          fontSize: 12,
                          color: "description_grey",
                        }}
                      >
                        użyto:
                      </Typography>
                      <Typography
                        align="left"
                        variant="subtitle1"
                        sx={{
                          fontSize: 14,
                          color: "description_grey",
                        }}
                      >
                        &nbsp;{data.used} razy
                      </Typography>
                    </div>
                  ) : (
                    <div className={styles.PromoStat}>
                      <Typography
                        align="left"
                        variant="subtitle1"
                        sx={{
                          fontSize: 12,
                          color: "description_grey",
                        }}
                      >
                        kod jednorazowy
                      </Typography>
                    </div>
                  )}

                  <div className={styles.PromoStat}>
                    <Typography
                      align="left"
                      noWrap
                      variant="subtitle1"
                      sx={{
                        fontSize: 12,
                        color: "description_grey",
                      }}
                    >
                      ważny do:
                    </Typography>
                    <Typography
                      align="left"
                      variant="subtitle1"
                      sx={{
                        fontSize: 14,
                        color: !data.isExpired ? "description_grey" : "#df0000",
                        fontWeight: 500,
                      }}
                    >
                      &nbsp;
                      {moment(data.expirationDate)
                        .locale("pl")
                        .format(DATE_FORMAT)}
                    </Typography>
                  </div>
                </div>
              )}
            </div>
            <Box className={styles.PromoDelete}>
              <Tooltip placement="top" title="Kopiuj kod">
                <ContentCopyIcon
                  sx={{ fontSize: 36 }}
                  onClick={() => {
                    navigator.clipboard.writeText(data.code);
                  }}
                ></ContentCopyIcon>
              </Tooltip>
              {!props.isSmall && (
                <ConfirmButton
                  cancelStartIcon={<CloseIcon />}
                  confirmStartIcon={<DoneAllIcon />}
                  questionText="Czy na pewno chcesz usunąć kod promocyjny?"
                  onConfirm={handleDelete}
                >
                  <DeleteForeverIcon sx={{ fontSize: 36 }}></DeleteForeverIcon>
                </ConfirmButton>
              )}
            </Box>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default PromoCodeCard;
