import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import {
  fetchHomepageComponent,
  selectPopulatedHomepage,
} from "../../../../redux/slices/utilsSlice";
import styles from "./resources.module.scss";
import classNames from "classnames";
import { TypingText, TitleText } from "../utils/CustomText";
import { fadeIn, staggerContainer, galleryVariants } from "../utils/motion";
import { motion } from "framer-motion";
import stolicaWizualizacja from "../../../../assets/wizualizacja.webp";
import ResourceCardNew from "./ResourceCardNew";
import { useHistory } from "react-router-dom";

function Resources(props) {
  const { data, index } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const populatedHomepage = useSelector(selectPopulatedHomepage);
  useEffect(() => {
    if (populatedHomepage[index] !== undefined) return; //nie fetchujemy jeśli już jest

    dispatch(
      fetchHomepageComponent({
        url: `/resources/`,
        index,
      })
    );
  }, []);

  const resources = populatedHomepage[index];
  const loading =
    populatedHomepage[index] === null || populatedHomepage[index] === undefined;

  if (loading) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }

  const content = (
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className={classNames(
        styles.resourcesSection,
        styles.maxContainer,
        styles.sectionMargin
      )}
    >
      <TitleText title="Nasze laboratoria" />
      <div className={styles.resourcesContainer}>
        {resources.slice(0, 9).map((el, index) => (
          <ResourceCardNew
            history={history}
            data={el}
            key={index}
            index={index}
          ></ResourceCardNew>
        ))}
      </div>
    </motion.div>
  );

  return content;
}

export default connect()(Resources);
