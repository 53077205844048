import React from "react";

import { connect, useSelector } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";
import Empty from "../components/universal/Empty";
import Loading from "../components/universal/Loading";

import { selectType } from "../redux/slices/userSlice";
import { emitInfo } from "./ToastEmmiter";

const ModRoute = ({ component, requiredScope, redirectPath, ...rest }) => {
  let ComponentToRender = component;

  const data = useSelector(selectType);
  const loading = data.authorizing;

  const conditionalRender = (props) => {
    if (loading) return <Loading />;

    if (!data.authorized || (data.type !== "mod" && data.type !== "admin"))
      return (
        <>
          {emitInfo(
            redirectPath
              ? "Przeniesiono na odpowiednią podstronę"
              : "Niewystarczające uprawnienia"
          )}
          <Redirect
            to={{
              pathname: redirectPath ? redirectPath : "/login",
              state: { from: props.location },
            }}
          />
        </>
      );

    if (requiredScope) {
      if (data.scope.includes(requiredScope) || data.type === "admin")
        return <ComponentToRender {...props} />;
      else
        return (
          <Empty
            text="Niewystarczające uprawnienia"
            hint="Jeśli uważasz, że jest to błąd skontaktuj się z administratorem"
          />
        );
    }
    return <ComponentToRender {...props} />;
  };

  return <Route {...rest} render={(props) => conditionalRender(props)} />;
};

export default withRouter(connect()(ModRoute));
