import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../API/APIService";
import { toast } from "react-toastify";
import Cookie from "js-cookie";
import { history } from "../../index.jsx";
import { emmitError, emmitSuccess } from "../../utils/ToastEmmiter";

export const setFeedbacksByEventPage = createAsyncThunk(
  "feedback/setFeedbacksByEventPage",
  async ({ page, id }, { rejectWithValue }) => {
    let response = null;

    await API.get(`/feedback/feedbacksByEvent?eventID=${id}&page=${page}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

export const setFeedbacksByUser = createAsyncThunk(
  "feedback/setFeedbacksByUser",
  async ({ id }, { rejectWithValue }) => {
    let response = null;
    console.log(id, "Dupa");
    await API.get(`/feedback/feedbacksByUser?userID=${id}`)
      .then((r) => {
        response = { data: r.data };
      })
      .catch((error) => {
        console.log(error);
        throw rejectWithValue(error.response);
      });
    return response.data;
  }
);

const initialFeedbacksByEvent = {
  feedbacks: [],
  page: 1,
  totalPages: 1,
  eventID: null,
  averageRating: null,
};

const initialFeedbacksByUser = {
  feedbacks: [],
  userID: null,
  averageRating: null,
};

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState: {
    feedbacksByEvent: initialFeedbacksByEvent,
    feedbacksByEventLoading: false,
    selectedFeedbackEvent: undefined,
    cycleFeedbackSelectedEvent: undefined,
    feedbacksByUser: initialFeedbacksByUser,
    feedbacksByUserLoading: false,
    selectedFeedbackUser: undefined,
  },
  reducers: {
    clearFeedbacksByEvent: (state, action) => {
      state.feedbacksByEvent = initialFeedbacksByEvent;
    },
    setSelectedFeedbackEvent: (state, action) => {
      state.selectedFeedbackEvent = action.payload;
    },
    setCycleFeedbackSelectedEvent: (state, action) => {
      state.cycleFeedbackSelectedEvent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setFeedbacksByEventPage.fulfilled, (state, action) => {
      state.feedbacksByEventLoading = false;
      state.feedbacksByEvent = action.payload;
    });
    builder.addCase(setFeedbacksByEventPage.pending, (state, action) => {
      state.feedbacksByEventLoading = true;
    });
    builder.addCase(setFeedbacksByEventPage.rejected, (state, action) => {
      state.feedbacksByEventLoading = false;
      emmitError(action.payload.data.error);
    });
    builder.addCase(setFeedbacksByUser.fulfilled, (state, action) => {
      state.feedbacksByUserLoading = false;
      state.feedbacksByUser = action.payload;
    });
    builder.addCase(setFeedbacksByUser.pending, (state, action) => {
      state.feedbacksByUserLoading = true;
    });
    builder.addCase(setFeedbacksByUser.rejected, (state, action) => {
      state.feedbacksByUserLoading = false;
      emmitError(action.payload.data.error);
    });
  },
});

const { actions, reducer } = feedbackSlice;

export const {
  clearFeedbacksByEvent,
  setSelectedFeedbackEvent,
  setCycleFeedbackSelectedEvent,
} = actions;

export const selectFeedbacksByEventLoading = (state) =>
  state.feedback.feedbacksByEventLoading;
export const selectFeedbacksByEventPage = (state) => {
  return {
    page: state.feedback.feedbacksByEvent.page,
    pageCount: state.feedback.feedbacksByEvent.totalPages,
  };
};
export const selectFeedbacksByEventData = (state) => {
  return state.feedback.feedbacksByEvent.feedbacks;
};

export const selectAverageRatingForEvent = (state) => {
  return state.feedback.feedbacksByEvent.averageRating;
};

export const getSelectedFeedbackEvent = (state) => {
  return state.feedback.selectedFeedbackEvent;
};

export const getCycleFeedbackSelectedEvent = (state) => {
  return state.feedback.cycleFeedbackSelectedEvent;
};

export const selectAverageRatingForUser = (state, userID) => {
  return state.feedback.feedbacksByUser.averageRating;
};

export default reducer;
