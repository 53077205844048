import { Close } from "@mui/icons-material";
import PreviewIcon from "@mui/icons-material/Preview";
import React, { useRef, useState } from "react";
import styles from "./imageUploader.module.scss";
import classnames from "classnames";
import useClickOutside from "../../utils/useClickOutside";

function ImageModal(props) {
  const popover = useRef();
  useClickOutside(popover, props.closeImageModal);

  return (
    props.visible && (
      <div
        className={
          props.standalone
            ? classnames([styles.imageHolderStandalone, styles.imageHolder])
            : classnames([styles.imageHolderEmbedded, styles.imageHolder])
        }
        style={props.style  ? props.style : {}}
      >
        <div ref={popover} className={styles.imageWrapper}>
          <div onClick={props.closeImageModal} className={styles.closeHandle}>
            <Close sx={{ fontSize: 30 }} />
          </div>
          <img
            className={styles.image}
            src={props.image}
            alt="Trwa ładowanie..."
          />
        </div>
      </div>
    )
  );
}

export default ImageModal;
