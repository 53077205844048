import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Modal from "../../../components/modal/Modal";
import { QrReader } from 'react-qr-reader'
import styles from "./TicketChecker.module.scss"
import API from "../../../API/APIService"
import DoneIcon from '@mui/icons-material/Done';

import * as errorAnimation from "./errorAnim.json";
import * as successAnimation from "./successAnim.json";
import Lottie from "react-lottie";
import PanelButton from "../../../components/panelButton/PanelButton";

import CircularProgress from '@mui/material/CircularProgress';
import { setTicketCheckerSuccessID } from "../../../redux/slices/eventsSlice";

const defaultOptions = {
    loop: false,
    autoplay: true,

    rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
    },
};



function ScanningResult(props) {
    const { status, message, clear } = props
    return (status != "null" &&
        <div className={styles.scanningResultContainer}>
            <div className={styles.statusContainer}>
                <div className={styles.resultBadge}>
                    <Lottie
                        isClickToPauseDisabled={false}
                        options={{
                            ...defaultOptions,
                            animationData: status == "success" ? successAnimation : errorAnimation
                        }}
                    />
                </div>
                <div className={styles.resultMessage}>
                    {message}
                </div>
                <div className={styles.buttons}>
                    <LoadingButton onClick={clear} endIcon={<DoneIcon />} variant="outlined"> OK </LoadingButton>
                </div>
            </div>
        </div>
    )
}

function TicketChecker(props) {

    const [camPermState, setCamPermState] = useState(false);
    const [verifyLoading, setVerifyLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        checkPerms();
    }, [])

    const checkPerms = async () => {
        if(!camPermState) {
            const permission = await navigator.permissions.query({ name: "camera" });
            console.log("TicketButton", permission)
            setCamPermState(permission.state!="denied") }
    }

    const onScan = data => {
        console.log(data)
        if (currentScanningStatus.status == "null")
            verifyTicket(data.text)
    }

    const applyScanningSuccess = message => {
        setCurrentScanningStatus({
            status: "success",
            message
        })
    }

    const applyScanningError = error => {
        setCurrentScanningStatus({
            status: "error",
            message: error
        })
    }

    const resetScanningStatus = () => {
        setCurrentScanningStatus({
            status: "null",
            message: ""
        })
    }

    const closeModal =() => {
        props.closeTicketCheckerModal()
        resetScanningStatus()
    }

    const [currentScanningStatus, setCurrentScanningStatus] = useState({ status: "null", message: "" })

    const verifyTicket = async (userID) => {
        console.log("verifyTicket")
        setVerifyLoading(true)
        await API.get(`/events/verifyTicket?userID=${userID}&cycleIdentifier=${props.cycleID}`)
            .then(r => {
                console.log(r)
                applyScanningSuccess(r.data.message)
                dispatch(setTicketCheckerSuccessID(undefined))
                dispatch(setTicketCheckerSuccessID(userID))
                setVerifyLoading(false)
            })
            .catch(error => {
                console.log(error)
                applyScanningError(error.response.data.error)
                setVerifyLoading(false)
            })
    }

    const renderViewFinder = () => {
        return (
            <div className={styles.viewFinderHolder}>
                {verifyLoading && <CircularProgress/>}
            </div>
        )
    }

    return (
        <>  {console.log("TICKETCHECKER PROPS:", props)}
            <PanelButton
                icon={<QrCode2Icon />}
                width={100}
                height={100}
                onClick={props.openTicketCheckerModal}
                label={"SPRAWDŹ BILET"}
            />
            <Modal
                visible={props.ticketCheckerBool}
                closeModal={closeModal}
                title={"Weryfikacja biletu"}
                isDarkTitle={false}
                noBodyPadding
                fullBody
                height={610}>
                {camPermState ?
                    <QrReader
                        constraints={{facingMode: 'environment'}}
                        ViewFinder={()=>renderViewFinder()}
                        className={styles.qrReader}
                        onResult={(result, error) => {
                            if (result && !verifyLoading) {
                                onScan(result);
                            }
                        }}
                        style={{ width: '100%' }} /> :
                    <div className={styles.noPermContainer}>
                        <div className={styles.lottieContainer}>
                            <Lottie 
                            isClickToPauseDisabled={false}
                            options={{
                                ...defaultOptions,
                                animationData: errorAnimation
                            }}
                            />
                        </div>
                        <div className={styles.messageContainer}>
                            <div className={styles.prompt}>
                                <div className={styles.desc}>
                                    Nie udzielono uprawnień do:
                                </div>
                                <div className={styles.perm}>
                                    KAMERA
                                </div>
                            </div>
                            <div className={styles.table}>
                                
                                    Nie można korzystać z tej funkcjonalności serwisu bez udzielenia uprawnień do korzystania z kamery.
                                
                            </div>
                        </div>
                    </div>
                }
                <ScanningResult
                    status={currentScanningStatus.status}
                    message={currentScanningStatus.message}
                    clear={resetScanningStatus} />
            </Modal>
        </>
    )

}


export default connect()(TicketChecker)