function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

export function parseQuery(queryString) {
  var query = {};
  if (!queryString) return {};
  if (queryString.length === 0) return {};
  const urlParams = new URLSearchParams(queryString);
  const entries = urlParams.entries(); //returns an iterator of decoded [key,value] tuples
  const params = paramsToObject(entries); //{abc:"foo",def:"[asf]",xyz:"5"}
  return params;
}

export const hoursLeft_Handler = (hoursLeft) => {
  switch(hoursLeft) {
    case 0:
      return "ZA MNIEJ NIŻ GODZINĘ"
    case 1:
      return "ZA GODZINĘ"
    case 2:
      return "ZA 2 GODZINY"
    case 3:
      return "ZA 3 GODZINY"
    default:
      return "ZA " + hoursLeft + " GODZIN"
  }
}

export const day_Handler = (daysLeft, hoursLeft) => {
  switch (daysLeft) {
    case 0:
      return hoursLeft_Handler(hoursLeft);
    case 1:
      return "JUTRO";
    default:
      return `ZA ${daysLeft} DNI`;
  }
}

export function getMonthName(month) {
  let months = [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Pażdziernik",
    "Listopad",
    "Grudzień",
  ];

  if (month > 0 && month <= 12) return months[month - 1];
  else return "";
}

export function constructQuery(queryObj) {
  var string = "";
  for (const [key, value] of Object.entries(queryObj)) {
    string += `${key}=${value}&`;
  }
  return string;
}

export function getUploadPath() {
  if (process.env.REACT_APP_TYPE !== "k8s") {
    return "/svc/uploadByFile";
  } else {
    return "/static/uploadByFile";
  }
}

export function getFullPath(str) {
  return process.env.REACT_APP_SERVER_HOST + str;
}

export function getContrastTextColor(color) {
  if (!color) return "black";
  const hex = color.replace("#", "");
  const rgb = hexToRgb(hex);
  if (!rgb) return "black";
  const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
  return brightness >= 128 ? "black" : "white";
}

function hexToRgb(hex) {
  if (hex.length !== 6) return null;
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
}
