import React from "react"
import { connect } from "react-redux"
import styles from "./components.module.scss"
import { motion } from "framer-motion"
import classNames from "classnames"
import { TypingText } from "./utils/CustomText"
import { fadeIn, staggerContainer, slideIn } from "./utils/motion"
import Stats from "./Stats"
import EdjsDisplay from "../../../components/edjsDisplay/EdjsDisplay"

function WhoAreWe(props) {
  const { data } = props

  const stats = [
    {
      id: "1",
      anim: slideIn("left", "tween", 0.1, 1),
      title: data?.text1,
      value: data?.number1,
    },
    {
      id: "2",
      anim: slideIn("up", "tween", 0.1, 1),
      title: data?.text2,
      value: data?.number2,
    },
    {
      id: "3",
      anim: slideIn("right", "tween", 0.1, 1),
      title: data?.text3,
      value: data?.number3,
    },
  ]

  return (
    <div>
      <div
        className={classNames(
          styles.whoAreWeSection,
          styles.maxContainer,
          styles.sectionMargin
        )}
      >
        <div className={styles.gradient02}></div>
        <div className={styles.gradient02v2}></div>
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.25 }}
          className={styles.contentWhoAreWe}
        >
          <TypingText title="| Kim jesteśmy?" />
          <motion.p
            variants={fadeIn("up", "tween", 0.2, 1)}
            className={styles.description}
          >
            <EdjsDisplay data={data?.content} />
          </motion.p>
        </motion.div>
        <Stats data={stats}></Stats>
      </div>
    </div>
  )
}

export default connect()(WhoAreWe)
