import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import sowaLogo from "../../assets/sowa-black.png";
import stolicaLogo from "../../assets/stolica-black.png";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import GroupsIcon from "@mui/icons-material/Groups";
import {
  selectCycles,
  selectFetchCyclesLoading,
  selectPages,
  setPage,
  setSingleCycle,
} from "../../redux/slices/eventsSlice";
import styles from "./cycles.module.scss";

import { Helmet } from "react-helmet";
import Pagination from "../../components/Pagination/Pagination";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { selectType } from "../../redux/slices/userSlice";
import { parseQuery } from "../../svc/svc";
import { getFullPath } from "../../svc/svc";
import CycleCard from "../../components/buyCycleFlowComponents/CycleCard";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/pl";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import TextField from "@mui/material/TextField";
import AutoCompleteSearch from "../../components/autoCompleteSearch/AutoCompleteSearch";

const description =
  "Zapoznaj się z ofertą edukacyjną Stolicy eXperymentu. Wybierz najbardziej interesujące zajęcia i zapisz się już dziś!";

const SmartCycleCard = ({ data, key, onClick }) => (
  <CycleCard forAdmin cycle={data} key={key} onClick={onClick} />
);

function Cycles(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [type, setType] = useState("both");
  const [operator, setOperator] = useState("both");

  const initial_dateNow = moment();
  const initial_dateMonthInAdvance = moment().add(1, "months");

  const [dateFrom, setDateFrom] = useState(initial_dateNow);
  const [dateTo, setDateTo] = useState(initial_dateMonthInAdvance);
  const [product, setProduct] = useState(null);

  const [initial, setInitial] = useState(false);

  const cycles = useSelector(selectCycles);
  const user = useSelector(selectType);
  const pages = useSelector(selectPages);
  const page = pages.page;

  const handleChangeDateFrom = (newValue) => {
    setDateFrom(newValue);
  };

  const handleChangeDateTo = (newValue) => {
    setDateTo(newValue);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    // var allegedType = searchParams.get("type");
    // var allegedOperator = searchParams.get("operator");

    // if (allegedType) {
    //   if (allegedType === "individual" || allegedType === "group")
    //     setType(allegedType);
    // }

    // if (allegedOperator) {
    //   if (
    //     allegedOperator === "Sowa" ||
    //     allegedOperator === "Stolica eXperymentu"
    //   )
    //     setOperator(allegedOperator);
    // }

    //dateFormQueryHandler()

    if (!searchParams.get('date_from') && !searchParams.get('date_to')) {
      dateFormQueryHandler()
      return
    }

    var allegedDateFrom = moment(searchParams.get("date_from"));
    var allegedDateTo = moment(searchParams.get("date_to"));

    if (allegedDateFrom.isValid() && !moment(allegedDateFrom).isSame(dateFrom)) {
      // console.log('allegedDateFrom', !moment(allegedDateFrom).isSame(dateFrom))
      setDateFrom(allegedDateFrom);
    }

    if (allegedDateTo.isValid() && !moment(allegedDateTo).isSame(dateTo)) {
      // console.log('allegedDateTo', !moment(allegedDateTo).isSame(dateTo))
      setDateTo(allegedDateTo);
    }

  }, []);

  const dateFormQueryHandler = () => {
    if (dateFrom && dateTo) {
      // console.log('dateFormQueryHandler', dateFrom, dateTo)
      const searchParams = new URLSearchParams(history.location.search);
      searchParams.set("date_from", dateFrom.format("YYYY-MM-DD"));
      searchParams.set("date_to", dateTo.format("YYYY-MM-DD"));
      history.replace({
        search: searchParams.toString(),
      });
      setFilters(searchParams.toString());
    }
  }

  useEffect(() => {
    if (initial) {
      dateFormQueryHandler()
    } else {
      setInitial(true)
    }
  }, [dateFrom, dateTo])

  const calendarViews = ["year", "month", "day"];
  const DATE_FORMAT = "dddd, DD-MM-YYYY";

  const renderInput = (params) => {
    const newParams = { ...params }
    const oldDate = params.inputProps.value.split(' ')[0].split('.').reverse().join('-')
    const newDate = moment(oldDate).format(DATE_FORMAT)
    newParams.inputProps.value = newDate
    return <TextField {...newParams} ampm={false} />
  }

  const [curFilters, setFilters] = useState(null); //ażeby wywołać rerendera paginacji na zmianie filtrów

  const handleSetSelectedProduct = (product) => {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.set("product", product._id);
    history.replace({
      search: searchParams.toString(),
    });
    setFilters(searchParams.toString());
  };

  const onProductClear = () => {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.delete("product");
    history.replace({
      search: searchParams.toString(),
    });
    setFilters(searchParams.toString());
  }

  const coursesSchema = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    itemListElement: cycles.map((cycle, index) => {
      return {
        "@type": "ListItem",
        position: index + 1,
        url: getFullPath(`/cycles/${cycle.slug ? cycle.slug : cycle._id}`),
      };
    }),
  };

  return (
    <div className={styles.cyclesWrapper}>
      <Helmet>
        <title>Zajęcia - Stolica eXperymentu</title>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <link rel="canonical" href={window.location.href} />
        <script type="application/ld+json">
          {JSON.stringify(coursesSchema)}
        </script>
      </Helmet>


      <div className={styles.filtersWrapper}>
        <div className={styles.datePickers}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='pl'>
            <DateTimePicker
              className={styles.filterTimePicker}
              label="Od"
              views={calendarViews}
              format={DATE_FORMAT}
              maxDate={dateTo ? dateTo : null}
              value={dateFrom}
              onChange={handleChangeDateFrom}
              renderInput={renderInput}
            />
            <DateTimePicker
              className={styles.filterTimePicker}
              label="Do"
              views={calendarViews}
              value={dateTo}
              format={DATE_FORMAT}
              minDate={dateFrom ? dateFrom : null}
              onChange={handleChangeDateTo}
              renderInput={renderInput}
            />
          </LocalizationProvider>
        </div>
        <div className={styles.productPicker}>
          <AutoCompleteSearch
            searchUrl="/events/searchProducts"
            limit={15}
            setSingle={handleSetSelectedProduct}
            placeholder="Wybierz produkt"
            onClear={onProductClear}
            className={styles.searchBar}
          />
        </div>
      </div>

      <Pagination
        double
        key={curFilters} // to po to żeby filtry działały
        location={props.location}
        dataSelector={selectCycles}
        pagesSelector={selectPages}
        setSingle={setSingleCycle}
        loadingSelector={selectFetchCyclesLoading}
        emptyText={"Brak cykli do wyświetlenia"}
        emptyHint={"Tutaj pojawią się dostępne cykle"}
        customDispatchProps={{}}
        obligatoryQuery={['date_from', 'date_to']}
        singlePath="/cycles/"
        setPage={setPage}
        ElementCard={SmartCycleCard}
        customRedirectHandler={() => { }}
        dataContainerClassName={styles.paginationContainer}
        paginationWrapperStyleOverride={{ marginBottom: 10 }}
      />
    </div>
  );
}

export default connect()(Cycles);
