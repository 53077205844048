import React, { useEffect, useRef, useState } from "react";

import { connect, useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import Modal from "../../../../components/modal/Modal";

import moment from "moment";
import styles from "./feedback.module.scss";

import {
  evaluateEvent,
  getEventsToEvaluate,
  selectEvaluateEventLoading,
  selectEventsToEvaluate,
  selectEventToEvaluateByID,
} from "../../../../redux/slices/eventsSlice";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import DoneIcon from "@mui/icons-material/Done";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Rating from "@mui/material/Rating";
import "moment/locale/pl";

import LoadingButton from "@mui/lab/LoadingButton";
import Floater from "../floater/Floater";
import OwnFeedbackCard from "./OwnFeedbackCard";

import Empty from "../../../../components/universal/Empty";
import Loading from "../../../../components/universal/Loading";
import { selectMaxLengths } from "../../../../redux/slices/utilsSlice";

import {
  getSelectedFeedbackEvent,
  setSelectedFeedbackEvent,
} from "../../../../redux/slices/feedbackSlice";

function Feedback(props) {
  const dispatch = useDispatch();
  const eventsToEvaluateBUNDLE = useSelector(selectEventsToEvaluate);
  const { eventsToEvaluate, eventsLoading } = eventsToEvaluateBUNDLE;
  const evaluateEventLoading = useSelector(selectEvaluateEventLoading);

  const selectedEvent = useSelector(getSelectedFeedbackEvent);

  const [ratingValue, setRatingValue] = useState(null);

  const maxLengths = useSelector(selectMaxLengths);

  const r_commentInput = useRef(null);
  const currentEvent = useSelector((state) =>
    selectEventToEvaluateByID(state, selectedEvent)
  );
  const selectEvent = (id) => {
    dispatch(setSelectedFeedbackEvent(id));
  };

  const deselectEvent = () => {
    dispatch(setSelectedFeedbackEvent(undefined));
  };

  useEffect(() => {
    dispatch(getEventsToEvaluate());
  }, []);

  const DOM_EVENTS = [];

  const submitFeedback = async () => {
    var data = {};
    data.eventID = selectedEvent;
    data.rating = ratingValue;
    data.description = r_commentInput.current.value;

    const result = await dispatch(evaluateEvent(data));
    if (result.meta.requestStatus === "fulfilled") {
      deselectEvent();
      setRatingValue(null);
    }
  };

  eventsToEvaluate.forEach((event) =>
    DOM_EVENTS.push(
      <OwnFeedbackCard
        onClick={selectEvent}
        eventID={event._id}
        cycleName={event.cycle.name}
        eventName={event.title}
        key={event._id}
      />
    )
  );

  const DATE_FORMAT = "DD MMMM YYYY hh:mm";

  const ratingTranslation = {
    1: "Bardzo źle",
    2: "Źle",
    3: "Średnio",
    4: "Dobrze",
    5: "Bardzo dobrze",
  };

  return (
    <div className={styles.ownFeedbackContainer}>
      <Modal
        visible={selectedEvent && currentEvent}
        closeModal={deselectEvent}
        title={"Oceń wydarzenie"}
        height={600}
        isPadding={true}
      >
        {currentEvent && (
          <div className={styles.eventInfo}>
            <div className={styles.basic}>
              <div className={styles.cycleName}>{currentEvent.cycle.name}</div>
              <div className={styles.eventName}>{currentEvent.title}</div>
            </div>

            <div className={styles.timestamp}>
              <div className={styles.iconContainer}>
                <ScheduleIcon style={{ fontSize: 30 }} />
              </div>
              <div className={styles.date}>
                <div className={styles.dateFrom}>
                  {moment(currentEvent.start).locale("pl").format(DATE_FORMAT)}
                </div>

                <ArrowRightAltIcon className={styles.arrowIcon} />

                <div className={styles.dateTo}>
                  {moment(currentEvent.end).locale("pl").format(DATE_FORMAT)}
                </div>
              </div>
            </div>

            <div className={styles.teacher}>
              {currentEvent.teacher.fullName}
            </div>
          </div>
        )}

        <div className={styles.ratingContainer}>
          <Floater text={"Twoja ocena"} left={10} top={-10} />
          <div className={styles.rating}>
            <Rating
              icon={<LightbulbIcon />}
              emptyIcon={<LightbulbOutlinedIcon />}
              value={ratingValue}
              onChange={(event, newValue) => {
                setRatingValue(newValue);
              }}
            />
            <span className={styles.verbal}>
              {ratingValue ? ratingTranslation[ratingValue] : "Brak oceny"}
            </span>
          </div>
          <div className={styles.textField}>
            <TextField
              className={styles.field}
              inputRef={r_commentInput}
              id="outlined-multiline-static"
              label="Komentarz"
              multiline
              inputProps={{ maxlength: maxLengths.description }}
              rows={4}
              maxRows={4}
              fullWidth
            />
          </div>
        </div>

        <div className={styles.buttons}>
          <LoadingButton
            variant="outlined"
            endIcon={<DoneIcon />}
            loading={evaluateEventLoading}
            onClick={submitFeedback}
          >
            {" "}
            Potwierdź{" "}
          </LoadingButton>
        </div>
      </Modal>

      <div className={styles.header}>Oceń ostatnie wydarzenia</div>
      <div className={styles.eventsContainer}>
        <div className={styles.cardsHolder}>
          {eventsLoading ? (
            <Loading />
          ) : eventsToEvaluate.length > 0 ? (
            DOM_EVENTS
          ) : (
            <Empty
              text="Brak wydarzeń do oceny"
              hint="Tu pojawią się wydarzenia, w których wzięto udział"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default connect()(Feedback);
