import React from "react";
import styles from "./CycleReservations.module.scss";

import moment from "moment";
import { Tooltip } from "@mui/material";
import { AttachEmailOutlined } from "@mui/icons-material";
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
const DATE_FORMAT = "dddd, DD-MM-YYYY HH:mm:ss";

function CycleReservationCard(props) {
  const { data } = props;

  var anonym = data.user?.email == 'anonimowy@user.pl' || data.user == null

  var userName = data.participant?.fullName
    ? data.participant?.fullName
    : data.user?.fullName;
  var userEmail = data.participant?.email
    ? data.participant?.email
    : data.user?.email;

  var userPhone = data.participant?.phone ?
    data.participant?.phone :
    null;

  var userAge = data.participant?.age ?
    data.participant?.age :
    undefined;

  var userSupervisor = data.participant?.supervisor ?
    data.participant?.supervisor :
    null

  var note = data.participant?.note ? data.participant.note : null

  return (
    <div className={styles.card} onClick={props.onClick}>
      <div className={styles.user}>
        <div className={styles.username}>
          {userName} {anonym && <NoAccountsIcon />} {userAge && ` (${userAge} lat)`}
        </div>

        <div className={styles.supervisorInfo}>

          {userSupervisor != userName ? <span className={styles.supervisorName}>
            {userSupervisor}
          </span> : null}
          {userPhone &&
            <span className={styles.phone}>
              {userPhone}
            </span>}

        </div>

        {!anonym &&
          <span onClick={(e) => e.stopPropagation()} className={styles.email}>
            {userEmail}
            <a href={`mailto:${userEmail}`}>
              <AttachEmailOutlined style={{ fontSize: 23 }} />{" "}
            </a>
          </span>}
      </div>

      {note && <div className={styles.note}>
        {note}</div>}
      <Tooltip title="Data zapisania na kurs">
        <div className={styles.timestamp}>
          {moment(data.createdAt).locale("pl").format(DATE_FORMAT)}
        </div>
      </Tooltip>
    </div>
  );
}

export default CycleReservationCard;
