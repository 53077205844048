import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import styles from "./home.module.scss";
import { motion } from "framer-motion";
import classNames from "classnames";
import LoadingButton from "@mui/lab/LoadingButton";
import { zoomIn, staggerContainer } from "./components/utils/motion";
import { selectType } from "../../redux/slices/userSlice";
import { useHistory } from "react-router-dom";
import moment from "moment";
import FunkyButton from "../orders/singleOrder/FunkyButton";
import { fetchSlider, selectSlider } from "../../redux/slices/utilsSlice";
import Loading from "../../components/universal/Loading";

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

const AutoplaySlider = withAutoplay(AwesomeSlider);

function Hero(props) {
  const { data } = props;
  const user = useSelector(selectType);
  const history = useHistory();
  const dispatch = useDispatch();

  const { slider, loading } = useSelector(selectSlider);

  useEffect(() => {
    dispatch(fetchSlider());
  }, []);

  const logo = document.querySelectorAll("#logo path");

  const handleOnClick = () => {
    if (user.authorized) return history.push("/panel");
    else return history.push("/login");
  };

  const handleSlideClicked = (i) => {
    console.log("clicked", i);

    const redirect = `/aktualnosci/${slider[i].slug}`;
    console.log(redirect);
    history.push(redirect);
  };

  const s = slider && (
    <AutoplaySlider
      cssModule={styles}
      fillParent={true}
      infinite={true}
      play={true}
      interval={5000}
      cancelOnInteraction={false}
    >
      {slider.map((slide, i) => (
        <div
          key={i}
          onClick={() => handleSlideClicked(i)}
          className={styles.slide}
          data-src={`${slide.thumbnail}`}
        >
          <div className={styles.slideData}>
          <div className={styles.newsPub}>
            {moment(slide.publicatedAt).format("DD.MM.YYYY")}
          </div>
          <div className={styles.newsTitle}>{slide.title}</div>
          </div>
          
        </div>
      ))}
    </AutoplaySlider>
  );

  const hero_dom = (
    <div className={styles.contentHero}>
      <div className={styles.svgLogoContainer}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 1923.92 615.85"
          fill="none"
          id="logo"
          className={styles.svgLogo}
        >
          <path
            className="cls-1"
            d="M318.78,712.68q-20.64,0-36.39-8.79a61.69,61.69,0,0,1-24.54-24.81q-8.79-16-8.79-37.79v-3.35q0-21.75,8.65-37.78A61.11,61.11,0,0,1,282,575.34q15.61-8.79,36.25-8.78,20.36,0,35.42,9.06a62.33,62.33,0,0,1,23.42,25.1q8.37,16,8.36,37.22v12H284.76q.56,14.24,10.59,23.15T319.89,682q14.79,0,21.75-6.41a45.15,45.15,0,0,0,10.6-14.22L381,676.43a89.36,89.36,0,0,1-11.29,15.75A61.09,61.09,0,0,1,350,706.68Q337.74,712.68,318.78,712.68Zm-33.74-89h64.69q-1.11-12-9.62-19.24t-22.17-7.25q-14.22,0-22.59,7.25T285,623.72Z"
            transform="translate(-247.06 -270.92)"
          />
          <path
            className="cls-1"
            d="M719.65,764.55V570.46h34.58v16.73h5Q764,579.1,774,572.83t28.73-6.27a60.78,60.78,0,0,1,31,8.22q14.22,8.24,22.87,24.12t8.64,38.49v4.46q0,22.59-8.64,38.48T833.7,704.45a60.79,60.79,0,0,1-31,8.23q-12.56,0-21.06-2.93A39.63,39.63,0,0,1,768,702.22a46.94,46.94,0,0,1-8.23-9.34h-5v71.67ZM792.15,682q16.46,0,27.19-10.45T830.07,641v-2.79q0-20.07-10.87-30.53t-27.05-10.46q-16.17,0-27,10.46t-10.87,30.53V641q0,20.09,10.87,30.54T792.15,682Z"
            transform="translate(-247.06 -270.92)"
          />
          <path
            className="cls-1"
            d="M960.57,712.68q-20.64,0-36.39-8.79a61.82,61.82,0,0,1-24.54-24.81q-8.77-16-8.78-37.79v-3.35q0-21.75,8.64-37.78a61.13,61.13,0,0,1,24.27-24.82q15.62-8.79,36.25-8.78,20.36,0,35.41,9.06a62.29,62.29,0,0,1,23.43,25.1q8.36,16,8.36,37.22v12H926.55q.56,14.24,10.6,23.15T961.69,682q14.77,0,21.75-6.41A45.15,45.15,0,0,0,994,661.37l28.72,15.06a89.89,89.89,0,0,1-11.29,15.75,61.2,61.2,0,0,1-19.66,14.5Q979.54,712.68,960.57,712.68Zm-33.74-89h64.7q-1.12-12-9.62-19.24t-22.17-7.25q-14.22,0-22.59,7.25T926.83,623.72Z"
            transform="translate(-247.06 -270.92)"
          />
          <path
            className="cls-1"
            d="M1058.45,708.77V570.46H1093v15.62h5a22.06,22.06,0,0,1,10.18-12.27,33.85,33.85,0,0,1,16.59-3.91h16.74v31.23h-17.29q-13.39,0-22,7.11t-8.65,21.9v78.63Z"
            transform="translate(-247.06 -270.92)"
          />
          <path
            className="cls-1"
            d="M1183.94,764.55V733.87h75.29q7.8,0,7.81-8.36V690.65h-5a34.42,34.42,0,0,1-7,9.48,35.92,35.92,0,0,1-12.83,7.81q-8.09,3.06-20.64,3.06-16.17,0-28.3-7.39a50.06,50.06,0,0,1-18.82-20.49q-6.69-13.11-6.69-30.12V570.46h35.13v79.75q0,15.63,7.67,23.43t21.89,7.81q16.17,0,25.1-10.74t8.92-30V570.46h35.14V733.31q0,14.22-8.37,22.73t-22.31,8.51Z"
            transform="translate(-247.06 -270.92)"
          />
          <path
            className="cls-1"
            d="M1340.66,708.77V570.46h34.58v15.06h5q3.62-7,12-12.13t22-5.16q14.78,0,23.7,5.72a38.28,38.28,0,0,1,13.66,14.91h5a40.71,40.71,0,0,1,13.38-14.78q8.64-5.85,24.54-5.85a49.63,49.63,0,0,1,23.28,5.44,40.75,40.75,0,0,1,16.74,16.45q6.27,11,6.27,27.75v90.9h-35.14V620.38q0-11.45-5.85-17.15t-16.46-5.72q-12,0-18.54,7.67t-6.55,21.89v81.7H1423.2V620.38q0-11.45-5.85-17.15t-16.46-5.72q-12,0-18.54,7.67t-6.55,21.89v81.7Z"
            transform="translate(-247.06 -270.92)"
          />
          <path
            className="cls-1"
            d="M1641.82,712.68q-20.64,0-36.39-8.79a61.82,61.82,0,0,1-24.54-24.81q-8.78-16-8.78-37.79v-3.35q0-21.75,8.64-37.78A61.13,61.13,0,0,1,1605,575.34q15.62-8.79,36.25-8.78,20.36,0,35.41,9.06a62.29,62.29,0,0,1,23.43,25.1q8.36,16,8.36,37.22v12H1607.8q.56,14.24,10.6,23.15t24.54,8.92q14.77,0,21.75-6.41a45.15,45.15,0,0,0,10.6-14.22L1704,676.43a89.89,89.89,0,0,1-11.29,15.75,61.2,61.2,0,0,1-19.66,14.5Q1660.79,712.68,1641.82,712.68Zm-33.74-89h64.7q-1.12-12-9.62-19.24T1641,597.23q-14.22,0-22.59,7.25T1608.08,623.72Z"
            transform="translate(-247.06 -270.92)"
          />
          <path
            className="cls-1"
            d="M1739.7,708.77V570.46h34.58v18.13h5q3.35-7.26,12.55-13.81t27.89-6.55q16.15,0,28.3,7.39A50.34,50.34,0,0,1,1866.86,596q6.69,13,6.69,30.25v82.54h-35.13V629q0-15.62-7.67-23.42t-21.89-7.81q-16.18,0-25.1,10.73t-8.92,30v70.27Z"
            transform="translate(-247.06 -270.92)"
          />
          <path
            className="cls-1"
            d="M1960.56,708.77q-13.66,0-22.17-8.5t-8.5-22.73V599.46h-34.58v-29h34.58V527.52H1965v42.94H2003v29H1965v71.95q0,8.36,7.81,8.36h26.77v29Z"
            transform="translate(-247.06 -270.92)"
          />
          <path
            className="cls-1"
            d="M2087.44,711q-16.17,0-28.3-7.39a50.08,50.08,0,0,1-18.83-20.49q-6.69-13.11-6.69-30.12V570.46h35.14v79.75q0,15.63,7.66,23.43t21.9,7.81q16.17,0,25.09-10.74t8.93-30V570.46h35.13V708.77h-34.58V690.65h-5q-3.36,7-12.55,13.66T2087.44,711Z"
            transform="translate(-247.06 -270.92)"
          />
          <path
            className="cls-2"
            d="M295,356.73c42.14,54.33,124-7,83.75-62.69-42.14-54.34-124,6.95-83.75,62.69ZM622.28,495.42c42.18,54.4,124.14-6.95,83.84-62.74-42.18-54.4-124.14,6.95-83.84,62.74Zm-1.76,368.45c41.7,53.78,122.73-6.87,82.88-62-41.69-53.78-122.73,6.88-82.88,62ZM592,804.73,534,705c-3,14.79-54.76,96-62.4,110.86-7,9.49-15,19.32-28.59,18.83H346.81L468.13,647.82l-157-251.17c35.62,14.13,81.3-5.53,95.47-41.66L546.57,593.08c6.14-18.08,41.84-75,51.1-92.81,16.54,30.79,53,46,85.83,37.05L611.07,642.94l75,118.51c-35.6-13.31-80.69,7.19-94,43.28Z"
            transform="translate(-247.06 -270.92)"
          />
          <path
            className="cls-1"
            d="M1603,492.6a47,47,0,0,1-20.12-4.08,32,32,0,0,1-13.64-11.67q-4.93-7.61-4.93-18.29v-3.94h18.29v3.94q0,8.85,5.49,13.29t14.91,4.43q9.57,0,14.28-3.8a11.93,11.93,0,0,0,4.71-9.71,9.39,9.39,0,0,0-2.32-6.61,16.83,16.83,0,0,0-6.75-4.15,86.84,86.84,0,0,0-10.77-3l-3.23-.7a82.2,82.2,0,0,1-17.38-5.7,27.38,27.38,0,0,1-11.11-9.08q-3.87-5.62-3.87-14.63a27,27,0,0,1,4.3-15.41,27.5,27.5,0,0,1,12.09-9.84,45.16,45.16,0,0,1,18.36-3.45,46.68,46.68,0,0,1,18.79,3.58,29.11,29.11,0,0,1,12.93,10.7q4.73,7.1,4.72,17.8v4.22h-18.29v-4.22a16.83,16.83,0,0,0-2.18-9.08,12.67,12.67,0,0,0-6.26-5.06,26.25,26.25,0,0,0-9.71-1.62c-5.63,0-9.77,1.06-12.45,3.16a10.45,10.45,0,0,0-4,8.66,9.94,9.94,0,0,0,1.91,6.19,14,14,0,0,0,5.68,4.22,50.78,50.78,0,0,0,9.71,2.95l3.24.71a91.42,91.42,0,0,1,18.36,5.76,30.69,30.69,0,0,1,12.17,9.29q4.35,5.78,4.36,14.77a27.84,27.84,0,0,1-4.57,15.83,30.59,30.59,0,0,1-13,10.69Q1614.38,492.6,1603,492.6Z"
            transform="translate(-247.06 -270.92)"
          />
          <path
            className="cls-1"
            d="M1684.29,490.63V409h-28.7V392.15h76V409h-28.7v81.6Z"
            transform="translate(-247.06 -270.92)"
          />
          <path
            className="cls-1"
            d="M1789.1,492.6q-18.57,0-29.55-10.2t-11-29.19V429.57q0-19,11-29.19t29.55-10.2q18.56,0,29.54,10.2t11,29.19v23.64q0,19-11,29.19T1789.1,492.6Zm0-16.6q10.41,0,16.18-6.05t5.76-16.18V429q0-10.12-5.76-16.18t-16.18-6.05q-10.27,0-16.11,6.05T1767.16,429v24.76q0,10.13,5.83,16.18T1789.1,476Z"
            transform="translate(-247.06 -270.92)"
          />
          <path
            className="cls-1"
            d="M1856.5,490.63V392.15h18.56v81.6h45v16.88Z"
            transform="translate(-247.06 -270.92)"
          />
          <path
            className="cls-1"
            d="M1943.29,490.63V392.15h18.58v98.48Z"
            transform="translate(-247.06 -270.92)"
          />
          <path
            className="cls-1"
            d="M2028.41,492.6q-18.3,0-29-10.2t-10.69-29.19V429.57q0-19,10.69-29.19t29-10.2q18.15,0,28.07,9.92t9.91,27.22v.85H2048.1v-1.41q0-8.73-4.85-14.35t-14.84-5.63q-9.86,0-15.48,6.05t-5.62,16.46v24.2q0,10.27,5.62,16.39t15.48,6.12q10,0,14.84-5.7T2048.1,456v-2.53h18.29v2q0,17.3-9.91,27.22T2028.41,492.6Z"
            transform="translate(-247.06 -270.92)"
          />
          <path
            className="cls-1"
            d="M2084.26,490.63l25.89-98.48h32.36l25.88,98.48h-19.13L2143.91,469h-35.17l-5.34,21.66Zm28.85-38.83h26.45l-12-48h-2.54Z"
            transform="translate(-247.06 -270.92)"
          />
        </svg>
      </div>
      <div className={styles.buttons}>
        <FunkyButton
          style={{ fontSize: "1.5rem" }}
          text={user.authorized ? "PANEL" : "START"}
          onClick={handleOnClick}
        />
      </div>
      <div className={styles.mottoContainer}>
        <div className={styles.motto}>
          <div className={styles.mottoStart}>
            <div className={styles.block1}></div>
            <div className={styles.text1}>
              Jeden eksperyment wart jest więcej
              <span></span>
            </div>
          </div>
          <div className={styles.mottoEnd}>
            <div className={styles.block2}></div>
            <div className={styles.text2}>niż tysiąc eksperckich założeń</div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.heroContainer}>
      {/* <video
        className={styles.videoHero}
        src={stolicavideo}
        muted
        loop
        autoPlay
      ></video> */}
      <div className={styles.overlay}></div>
      {loading ? <Loading /> : slider && slider.length > 0 ? s : hero_dom}
    </div>
  );
}

export default connect()(Hero);
