import React, { useState, useEffect } from 'react';
import { LinearProgress } from '@mui/material';
import moment from 'moment';

const EventBar = ({ startDate, endDate }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const updateProgress = () => {
      const now = moment();
      const start = moment(startDate);
      const end = moment(endDate);
      const totalDuration = end.diff(start);
      const elapsedDuration = now.diff(start);
      const newProgress = (elapsedDuration / totalDuration) * 100;

      setProgress(newProgress);
    };

    // Update progress initially
    updateProgress();

    // Set up interval to update progress every minute
    const intervalId = setInterval(updateProgress, 60000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [startDate, endDate]);

  return <LinearProgress color='secondary' variant="determinate" value={progress} />;
};

export default EventBar;
