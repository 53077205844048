import React, { useState } from "react";

import { connect, useDispatch, useSelector } from "react-redux";

import AddIcon from "@mui/icons-material/Add";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import CustomCheckbox from "../../../../components/universal/CustomCheckbox";

import {
  addPromoCodes,
  selectLoadingAdd,
} from "../../../../redux/slices/promoCodesSlice";
import styles from "./promoCodes.module.scss";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { emmitError } from "../../../../utils/ToastEmmiter";
import { selectMaxLengths } from "../../../../redux/slices/utilsSlice";

function AddPromoCodes(props) {
  const dispatch = useDispatch();
  const [type, setType] = useState("balance");
  const [amount, setAmount] = useState(15);
  const [count, setCount] = useState(3);
  const [multiUse, setMultiUse] = useState(false);
  const maxLengths = useSelector(selectMaxLengths);

  const loading = useSelector(selectLoadingAdd);

  const [expirationDate, setExpirationDate] = useState(moment());

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (
      type === "percent"
        ? value >= 0 && value <= 100 && `${value}`.length <= maxLengths.price
        : value >= 0 &&
          value <= Math.pow(10, maxLengths.price) &&
          `${value}`.length <= maxLengths.price
    )
      setAmount(value);
  };

  const handleCountChange = (e) => {
    const value = e.target.value;
    if (
      value >= 1 &&
      value <= Math.pow(10, maxLengths.count) &&
      `${value}`.length <= maxLengths.count
    ) {
      setCount(value);
    }
  };

  const handleAddCodes = () => {
    dispatch(
      addPromoCodes({
        type,
        amount,
        count,
        multiUse,
        expirationDate: expirationDate.toISOString(),
      })
    );
  };

  const handleDateChange = (value) => {
    if (value < moment()) return emmitError("Niepoprawna data wygaśnięcia");
    setExpirationDate(value);
  };

  return (
    <>
      <div className={styles.inputSection}>
        <div>
          <FormControl className={styles.input}>
            <InputLabel id="demo-simple-select-label">Typ</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              label="Typ"
              onChange={(e) => {
                if (e.target.value === "percent" && amount > 100) {
                  setAmount(100);
                }
                setType(e.target.value);
              }}
            >
              <MenuItem value={"balance"}>Kwota</MenuItem>
              <MenuItem value={"percent"}>Procent</MenuItem>
            </Select>
          </FormControl>
        </div>

        <LocalizationProvider locale="pl" dateAdapter={AdapterMoment}>
          <DateTimePicker
            className={styles.input}
            //inputFormat={DATE_FORMAT}
            label="Data wygaśnięcia"
            value={expirationDate}
            ampm={false}
            locale="pl"
            disablePast
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} ampm={false} />}
          />
        </LocalizationProvider>

        <div>
          <TextField
            className={styles.input}
            value={amount}
            id="amount"
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {type === "percent" ? "%" : "PLN"}
                </InputAdornment>
              ),
            }}
            onChange={handleAmountChange}
            label="Wartość"
          />
        </div>
        <div>
          <TextField
            className={styles.input}
            value={count}
            id="count"
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">sztuk</InputAdornment>
              ),
              inputProps: {
                min: 1,
                max: 99,
              },
            }}
            onChange={handleCountChange}
            label="Ilość kodów"
          />
        </div>
        <div>
          <CustomCheckbox
            size={40}
            onClick={() => {
              setMultiUse(!multiUse);
            }}
            checked={multiUse}
            label="Kod wielokrotnego użytku"
            borderColor="#222222"
          ></CustomCheckbox>
        </div>

        <div className={styles.buttons}>
          <LoadingButton
            variant="outlined"
            onClick={handleAddCodes}
            endIcon={<AddIcon />}
            loading={loading}
          >
            Dodaj
          </LoadingButton>
        </div>
      </div>
    </>
  );
}

export default connect()(AddPromoCodes);
