import React from "react";
import styles from "./MyCycles.module.scss";

function CycleCard(props) {
  const { data } = props;

  return (
    <div onClick={props.onClick} className={styles.cycleCard}>
      <div
        style={{ backgroundImage: `url(${data.thumbnail})` }}
        className={styles.image}
      ></div>
      <div className={styles.name}>{data.name}</div>
    </div>
  );
}

export default CycleCard;
