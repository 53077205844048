import React, { useState, useCallback, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styles from "./Resources.module.scss";
import { useSelector } from "react-redux";

import {
  selectResources,
  selectResourceByID,
  fetchResources,
  setAddResourceModalVisible,
  setResourceViewModalID,
  selectResourceViewModalID,
} from "../../../../redux/slices/resourcesSlice";

import AddIcon from "@mui/icons-material/Add";

import ResourceCard from "./ResourceCard";
import ResourceView from "./ResourceView";
import ResourceAdd from "./ResourceAdd";

import Empty from "../../../../components/universal/Empty";

import Loading from "../../../../components/universal/Loading";
import Modal from "../../../../components/modal/Modal";
import PanelButton from "../../../../components/panelButton/PanelButton";
import PlaceIcon from '@mui/icons-material/Place';

function Resources(props) {
  const { resources, loading } = useSelector(selectResources);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);

  const selectedResource = useSelector(selectResourceViewModalID);
  const resource = useSelector((state) =>
    selectResourceByID(state, selectedResource)
  );

  const handleModalOpen = () => {
    dispatch(setAddResourceModalVisible());
  };

  useEffect(() => {
    dispatch(fetchResources());
  }, []);

  const selectResource = (id) => {
    dispatch(setResourceViewModalID(id));
  };

  const RESOURCECARDS_DOM = [];

  resources.forEach((resource) => {
    RESOURCECARDS_DOM.push(
      <ResourceCard
        key={resource._id}
        name={resource.name}
        building={resource.building}
        id={resource._id}
        onClick={selectResource}
      />
    );
  });

  const setVisible = () => {
    setModal(true);
  };

  const setInvisible = () => {
    setModal(false);
  };

  return (
    <>
      <PanelButton
        label={"Laboratoria"}
        icon={<PlaceIcon style={{ fontSize: 40 }} />}
        onClick={setVisible}
        width={100}
        height={100}
      />

      

      <Modal
        visible={modal}
        closeModal={setInvisible}
        title={"Laboratoria"}
        isDarkTitle={false}
        isPadding={true}
        height={600}
      >
        <div className={styles.mainResourcesContainer}>

          <div className={styles.header}>Zasoby</div>
          <div
            onClick={!loading ? handleModalOpen : () => { }}
            className={styles.addNew}
          >
            Dodaj nowy <AddIcon style={{ marginLeft: 5 }} />
          </div>
          <div className={styles.resourcesContainer}>
            <div className={styles.cardsContainer}>
              {!loading ? (
                RESOURCECARDS_DOM.length === 0 ? (
                  <Empty
                    text={"Brak zasobw"}
                    hint={"Tutaj pojawią się istniejące zasoby"}
                  />
                ) : (
                  RESOURCECARDS_DOM
                )
              ) : (
                <Loading />
              )}{" "}
            </div>
          </div>
        </div>
      </Modal>
      {resource && <ResourceView />}
      <ResourceAdd />
    </>

  );
}

export default connect()(Resources);
