import { Button } from "@mui/material";
import styles from "./BuyCycleFlow.module.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";

function ReturnHeader(props) {
  const history = useHistory()
  const handleReturn = () => {
    history.push('/offer')
  }
  return (
    <div className={styles.returnHeaderContainer}>
      <Button onClick={handleReturn} variant="outlined" startIcon={<ArrowBackIcon />}>
        WSTECZ
      </Button>
      <div className={styles.productName}>{props.currentProduct}</div>
    </div>
  );
}

export default ReturnHeader;
