import React, { useRef, useState } from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";

import Modal from "../modal/Modal";

import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { selectMaxLengths } from "../../redux/slices/utilsSlice";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/pl";
import styles from "./calendarComponents.module.scss";
import { emmitError } from "../../utils/ToastEmmiter";
import AutoCompleteSearch from "../autoCompleteSearch/AutoCompleteSearch";
import { de } from "date-fns/locale";
import { useEffect } from "react";

moment.locale("pl");

function PasteEvent(props) {
  const { teachers, buildings, calendar, pasteMode } = props;
  const [selectedItem, setSelectedItem] = useState(undefined);

  useEffect(() => {
    setSelectedItem(undefined);
  }, [props.open]);
  const handleAddRepetetive = async () => {
    if (pasteMode === "event") {
      let event = selectedItem;
      event.cycle = event.cycle.id;
      event.start = moment(event.start).format("YYYY-MM-DD HH:mm");
      event.end = moment(event.end).format("YYYY-MM-DD HH:mm");
      event.durationEditable = true;
      event.startEditable = true;
      event.resources = [event.resource, event.teacher];
      event.extendedProps = {
        teacher: event.teacher,
        preMail: { ...event.preMail, sent: false },
        postMail: { ...event.postMail, sent: false },
        description: event.description,
      };
      delete event.id;
      delete event._id;

      calendar.current.getApi().addEvent(event);
    } else {
      let events = selectedItem.events;
      for (var i = 0; i < events.length; i++) {
        let event = events[i];
        event.cycle = event.cycle.id;
        event.start = moment(event.start).format("YYYY-MM-DD HH:mm");
        event.end = moment(event.end).format("YYYY-MM-DD HH:mm");
        event.durationEditable = true;
        event.startEditable = true;
        event.resources = [event.resource, event.teacher];
        event.extendedProps = {
          teacher: event.teacher,
          preMail: { ...event.preMail, sent: false },
          postMail: { ...event.postMail, sent: false },
          description: event.description,
        };
        delete event.id;
        delete event._id;
        calendar.current.getApi().addEvent(event);
      }
    }
    handleCloseModal();
  };

  const handleCloseModal = async () => {
    props.setOpen(false);
  };

  return (
    <>
      <Modal
        title={
          pasteMode === "event"
            ? "Skopiuj istniejące wydarzenie"
            : "Skopiuj wydarzenia z istniejącego cyklu zajęć"
        }
        visible={props.open}
        closeModal={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        height={500}
        isPadding={true}
      >
        <div className={styles.repetetiveEventContainer}>
          <div className={styles.info}>
            <AutoCompleteSearch
              style={{ width: "100%", marginBottom: 30 }}
              searchUrl={
                pasteMode === "event"
                  ? "/events/searchEvents"
                  : "/events/searchCycles"
              }
              limit={15}
              hardCodedQuery={
                pasteMode === "cycle" ? "&populateEvents=true" : ""
              }
              setSingle={(el) => setSelectedItem(el)}
              placeholder={pasteMode === "event" ? "Wydarzenie" : "Cykl"}
              sx={{ width: 500 }}
              className={styles.searchBar}
            />
          </div>
          {selectedItem ? (
            pasteMode === "event" ? (
              <>
                <p>
                  <i>
                    Poniższe informacje będzie można edytować po skopiowaniu
                  </i>
                </p>
                <p>
                  Wydarzenie {selectedItem?.title} z cyklu{" "}
                  {selectedItem.cycle.name}
                  <br />
                  Start: {moment(selectedItem.start).format("DD.MM.YYYY HH:mm")}
                  <br />
                  Koniec: {moment(selectedItem.end).format("DD.MM.YYYY HH:mm")}
                </p>
              </>
            ) : (
              <>
                Wszystkie wydarzenia z cyklu {selectedItem.name} (łącznie{" "}
                {selectedItem.events.length} wydarzeń)
              </>
            )
          ) : (
            <></>
          )}

          <div className={styles.buttons}>
            <LoadingButton
              color="secondary"
              variant="outlined"
              onClick={handleCloseModal}
            >
              Anuluj
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              onClick={() => {
                handleAddRepetetive();
              }}
            >
              Dodaj
            </LoadingButton>
          </div>
        </div>
      </Modal>

      <div className={styles.calendar}></div>

      {/* <Button onClick={() => handleSubmit()}>zatwierdź</Button> */}
    </>
  );
}

export default PasteEvent;
