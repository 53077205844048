import React from "react";

import { connect, useSelector } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";
import Loading from "../components/universal/Loading";

import { selectType } from "../redux/slices/userSlice";
import { emitInfo } from "./ToastEmmiter";

const AdminRoute = ({ component, ...rest }) => {
  let ComponentToRender = component;

  const data = useSelector(selectType);
  const loading = data.authorizing;
  return (
    <Route
      {...rest}
      render={(props) =>
        loading ? (
          <Loading />
        ) : data.authorized && data.type === "admin" ? (
          <ComponentToRender {...props} />
        ) : (
          <>
            {emitInfo("Zaloguj się, aby przejść dalej")}
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          </>
        )
      }
    />
  );
};

export default withRouter(connect()(AdminRoute));
