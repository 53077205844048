import { TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import styles from "./components.module.scss";

const FormTextField = (props) => {
  const { name, control, label, maxLen, type, ...rest } = props;

  const validateLength = (event, lateValue) => {
    if (event.target.value.length > maxLen) event.target.value = lateValue;

    return event;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          helperText={error ? error.message : null}
          error={!!error}
          onChange={(e) => onChange(validateLength(e, value))}
          value={value}
          className={styles.input}
          //fullWidth
          type={type && type}
          label={label}
          variant="outlined"
          onKeyDown={(e) => {
            if (props.enterClick)
              if (e.key === "Enter") {
                props.enterClick();
              }
          }}
          {...rest}
        />
      )}
    />
  );
};

export default FormTextField;
