import { connect, useDispatch, useSelector } from "react-redux";
import styles from "./Products.module.scss";
import { useEffect, useState } from "react";
import {
  getAvailableProducts,
  selectAvailableProducts,
} from "../../../redux/slices/eventsSlice";
import Loading from "../../../components/universal/Loading";
import ProductCard from "../../../components/buyCycleFlowComponents/ProductCard";
import Empty from "../../../components/universal/Empty";
import ProductDetailsModal from "./ProductDetailsModal";
import CartFooter from "../../../components/buyCycleFlowComponents/CartFooter";
import AttentionCard from "./attentionCard";
import HelpCard from "./helpCard";
import { getFullPath } from "../../../svc/svc";
import { Helmet } from "react-helmet";

const description =
  "Zapoznaj się z ofertą edukacyjną Stolicy eXperymentu. Wybierz najbardziej interesujące zajęcia i zapisz się już dziś!";


function Products(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAvailableProducts());
  }, []);

  const { products, loading } = useSelector(selectAvailableProducts);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const closeModal = () => setSelectedProduct(null);

  const productsSchema = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    itemListElement: products?.map((product, index) => {
      return {
        "@type": "ListItem",
        position: index + 1,
        url: getFullPath(`/oferta/${product.slug ? product.slug : product._id}`),
      };
    }),
  };

  const breadcrumbListStructuredData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Stolica eXperymentu",
        item: getFullPath(""),
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Oferta",
        item: getFullPath("/oferta"),
      },
    ],
  };

  return (
    <>
    <Helmet>
        <title>Oferta | Stolica eXperymentu</title>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <link rel="canonical" href={window.location.href} />
      <script type="application/ld+json">{JSON.stringify(productsSchema)}</script>
      <script type="application/ld+json">{JSON.stringify(breadcrumbListStructuredData)}</script>
    </Helmet>
    <div className={styles.productsWrapper}>
      {/* <AttentionCard /> */}
      <HelpCard/>
      <CartFooter />
      <div className={styles.productCards}>
        {loading ? (
          <Loading />
        ) : products.length > 0 ? (
          products.map((product, index) => (
            <ProductCard
              setSelectedProduct={setSelectedProduct}
              key={index}
              product={product}
            />
          ))
        ) : (
          <Empty text="Brak produktów do wyświetlenia" />
        )}
        <ProductDetailsModal
          visible={selectedProduct}
          closeModal={closeModal}
          product={selectedProduct}
        />
      </div>
    </div>
    </>
  );
}

export default connect()(Products);
