import React from "react";
import styles from "./Resources.module.scss";

function ResourceCard(props) {
  return (
    <div
      onClick={() => props.onClick(props.id)}
      className={styles.resourceCard}
    >
      <div className={styles.name}>{props.name}</div>
      <div className={styles.building}>{props.building}</div>
    </div>
  );
}

export default ResourceCard;
