import React, { useState, useEffect, useRef } from "react";
import Modal from "../../../../components/modal/Modal";
import PanelButton from "../../../../components/panelButton/PanelButton";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  selectMyCycles,
  sendMSGtoCycleAttendees,
  selectMsgCycleAttendeesLoading,
} from "../../../../redux/slices/eventsSlice";
import CycleCard from "./CycleCard";
import styles from "./Communication.module.scss";
import TextEditor from "../../../../components/textEditor/TextEditor";
import Floater from "../floater/Floater";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { useScrollContainer } from "react-indiana-drag-scroll";
import Empty from "../../../../components/universal/Empty";

import { emmitError } from "../../../../utils/ToastEmmiter";
import TextField from "@mui/material/TextField";

import { selectMaxLengths } from "../../../../redux/slices/utilsSlice";

function Communication(props) {
  const scrollContainer = useScrollContainer();
  const [isVisible, setIsVisible] = useState(false);
  const [selectedCycle, setSelectedCycle] = useState(null);
  const [customSubject, setCustomSubject] = useState("");
  const editorCoreMain = useRef();
  const dispatch = useDispatch();
  const maxLengths = useSelector(selectMaxLengths);

  const setVisible = () => {
    setIsVisible(true);
  };

  const setInvisible = () => {
    setIsVisible(false);
  };

  const selectCycle = (id) => {
    setSelectedCycle(id);
  };

  const handleChangeSubject = (e) => {
    setCustomSubject(e.target.value);
  };

  const myCycles = useSelector(selectMyCycles);
  const loading = useSelector(selectMsgCycleAttendeesLoading);

  const getCurrentName = () => {
    if (props.cycleName) return props.cycleName;
    const cycle = myCycles.find((e) => e._id === selectedCycle);
    if (cycle) return cycle.name;
    else return undefined;
  };

  const constructData = async () => {
    let data = {};
    if (props.cycleID) data.cycleID = props.cycleID;
    else {
      data.cycleID = selectedCycle;
      if (!selectedCycle) {
        emmitError("Nie wybrano cyklu");
        return;
      }
    }
    const content = await editorCoreMain.current.save();
    data.content = content;
    data.subject = customSubject;
    const result = await dispatch(sendMSGtoCycleAttendees(data));

    if (result.meta.requestStatus === "fulfilled") {
      setInvisible();
      setSelectedCycle(null);
    }
  };

  return (
    <>
      {props.customButton ? (
        React.cloneElement(props.customButton, {
          onClick: setVisible,
        })
      ) : (
        <PanelButton
          label={"Komunikacja"}
          icon={<AttachEmailIcon style={{ fontSize: 40 }} />}
          onClick={setVisible}
          width={100}
          height={100}
        />
      )}
      <Modal
        visible={isVisible}
        closeModal={setInvisible}
        height={825}
        title={"Panel komunikacji"}
        isDarkTitle={false}
        isPadding={true}
      >
        {!props.cycleID ? (
          <div className={styles.cycles}>
            <Floater text="Dostępne cykle" top={-10} left={0} />
            <div ref={scrollContainer.ref} className={styles.cardHolder}>
              {myCycles.length > 0 ? (
                myCycles.map((cycle) => (
                  <CycleCard
                    key={cycle._id}
                    capacity={cycle.capacity}
                    thumbnail={cycle.thumbnail}
                    name={cycle.name}
                    selected={cycle._id === selectedCycle}
                    onClick={selectCycle}
                    id={cycle._id}
                  />
                ))
              ) : (
                <Empty
                  text="Brak cykli"
                  hint="Tutaj pojawią się cykle, w ramach których prowadzono wydarzenia"
                />
              )}
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className={styles.helperMessage}>
          {selectedCycle !== null || props.cycleID !== null ? (
            <>
              Napisz wiadomość do uczestników cyklu:{" "}
              <span className={styles.name}>{getCurrentName()}</span>{" "}
            </>
          ) : (
            "Wybierz cykl, do którego uczestników chcesz wysłać wiadomość"
          )}
        </div>

        <div className={styles.subjectSection}>
          <TextField
            className={styles.input}
            value={customSubject}
            id="emailTitle"
            inputProps={{ maxLength: maxLengths.name }}
            variant="outlined"
            onChange={handleChangeSubject}
            label="Temat wiadomości"
          />
        </div>

        <div className={styles.textArea}>
          <TextEditor
            useFiles={true}
            editorCore={editorCoreMain}
            readOnly={false}
          />
        </div>

        <div className={styles.buttons}>
          <LoadingButton
            variant="outlined"
            endIcon={<SendIcon />}
            loading={loading}
            disabled={myCycles.length === 0}
            onClick={constructData}
          >
            {" "}
            Wyślij{" "}
          </LoadingButton>
        </div>
      </Modal>
    </>
  );
}

export default connect()(Communication);
