import React from "react";
import { connect } from "react-redux";
import styles from "./components.module.scss";
import Marquee from "react-fast-marquee";
import classNames from "classnames";
import Typography from "@mui/material/Typography";

function Partners(props) {
  const { data } = props;

  return (
    <div
      className={classNames(
        styles.partnersSection,
        styles.maxContainerPartners
      )}
    >
      <div
        style={{ height: data.height ? `${data.height}px` : "160px" }}
        className={classNames(styles.partnersContainer, styles.noSelect)}
      >
        <Typography className={styles.title}>{data.header}</Typography>
        <div className={styles.marqueeContainer}>
          <Marquee
            style={{
              maxWidth: 860,
              overflowY: "hidden",
            }}
          >
            <div className={styles.slider}>
              {data.partners.map((partner, index) => (
                <a className={styles.imglink} href={partner.link}>
                <img
                  className={styles.slide}
                  src={partner.url}
                  key={index}
                  alt="Partner"
                />
                </a>
              ))}
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
}

export default connect()(Partners);
