// ScrollToTop.jsx
import { useEffect, createContext } from "react";
import { useHistory, useLocation } from "react-router";

const ScrollToTop = (props) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    return () => {
      if (history.action !== "POP") {
        document.getElementById("SXWrapper")?.scrollTo(0, 0);
      }
    };
  }, [location]);

  return <>{props.children}</>;
};
export default ScrollToTop;
