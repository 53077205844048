import React from "react"
import { connect } from "react-redux"
import styles from "./components.module.scss"
import { motion } from "framer-motion"
import classNames from "classnames"
import { TypingText, TitleText } from "./utils/CustomText"
import { fadeIn, staggerContainer } from "./utils/motion"
import stolicaRender from "../../../assets/stolicarender.jpg"

const Contact = React.forwardRef((props, ref) => {
  const { data } = props

  return (
    <div ref={ref} id='contact'>
      <div
        className={classNames(
          styles.contactSection,
          styles.maxContainer,
          styles.sectionMargin
        )}
      >
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.25 }}
          className={styles.content}
        >
          <TypingText title="| Napisz do nas" />
          <TitleText title="Kontakt" />
          <motion.div className={styles.info}>
            <motion.div
              variants={fadeIn("left", "tween", 1.5, 1)}
              className={styles.infoLeft}
            >
              <TitleText title="Adres" />
              <div className={styles.divider}></div>
              <motion.p
                variants={fadeIn("left", "tween", 3, 1)}
                className={styles.description}
              >
                ul.&nbsp;<span className={styles.bold}>Słowackiego 45/47</span>
              </motion.p>
              <motion.p
                variants={fadeIn("left", "tween", 3, 1)}
                className={styles.description}
              >
                62-200 Gniezno
              </motion.p>
            </motion.div>
            <motion.div
              variants={fadeIn("up", "tween", 0.3, 1)}
              className={styles.imageContainer}
            >
              <img
                src={stolicaRender}
                alt="Wygląd budynku"
                className={styles.mainImage}
              />
            </motion.div>
            <motion.div
              variants={fadeIn("right", "tween", 1.5, 1)}
              className={styles.infoRight}
            >
              <TitleText title="Dane kontaktowe" />
              <div className={styles.divider}></div>
              <motion.p
                variants={fadeIn("right", "tween", 3, 1)}
                className={styles.description}
              >
                <span className={styles.bold}>786 815 155</span>
              </motion.p>
              <motion.p
                variants={fadeIn("right", "tween", 3, 1)}
                className={styles.description}
              >
                biuro@stolicaexperymentu.edu.pl
              </motion.p>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  )
})

export default (Contact)
