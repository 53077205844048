import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import * as React from "react";
import { useRef, useState } from "react";
import { connect } from "react-redux";
import API from "../../API/APIService";
import styles from "./search.module.scss";
import { get } from "js-cookie";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment'
import { Person } from "@mui/icons-material";
import classnames from 'classnames'

const LIGHT_FONT_WEIGHT = 400;
const BOLD_FONT_WEIGHT = 700;

function AutoCompleteSearch(props) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const {
    limit,
    searchUrl,
    setSingle,
    placeholder,
    className,
    label,
    variant,
    renderPhone,
    renderEmail,
    renderCycleMeta,
    inputProps,
    getOptionLabel,
    onChange,
    ...rest
  } = props;

  const [options, setOptions] = useState([]);
  const inputTimer = useRef(null);
  const [init, setInit] = useState(true);

  const minLength = props.minLength || 3;

  const handleSearch = async (value) => {
    setLoading(true);
    if (value === undefined) value = "";
    const query = `?count=${limit}&search=${value}${props.hardCodedQuery ? props.hardCodedQuery : ""
      }`;
    try {
      const response = await API.get(searchUrl + query);

      setOptions(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleInputChange = async (e, value) => {

    clearTimeout(inputTimer.current);
    if (value.length === 0) setInit(true);
    else setInit(false);

    if (value.length < minLength) return;

    const newTimer = setTimeout(async () => {
      await handleSearch(value);
    }, 500);

    inputTimer.current = newTimer;
    if (onChange) props.onChange(e)
  };

  const handleSelect = (e, val, reason) => {
    if(reason === 'clear') {
      props.onClear && props.onClear()
      return
    } 
    if (val !== null) setSingle(val);
    else setOptions([]);
  };

  const handleSetOpen = () => {
    setOpen(true);

    if (init && props.minLength === 0) handleSearch("");
  };

  const handleSetClose = () => {
    setOpen(false);
  };

  const renderValues = (props, option, { inputValue }) => {
    const matchesMain = match(
      option.fullName || option.name || option.title,
      inputValue
    );
    console.log(option)
    let partsMain;
    if (matchesMain)
      partsMain = parse(
        option.fullName || option.name || option.title,
        matchesMain
      );
    let partsPhone;
    let matchesPhone = false;
    if (option?.phone?.number) {
      matchesPhone = match(option?.phone?.number, inputValue);
      partsPhone = parse(option?.phone?.number, matchesPhone);
    }

    let matchesEmail
    let partsEmail;
    if (option.email) {
      matchesEmail = match(option.email, inputValue);
      partsEmail = parse(option.email, matchesEmail);
    }

    return (
      <li {...props} key={option._id}>
        <div className={styles.option}>
          <div className={styles.mainOption}>
            {partsMain?.map((part, index) => (
              <span
                key={index}
                style={{
                  fontWeight: part.highlight ? BOLD_FONT_WEIGHT : LIGHT_FONT_WEIGHT,
                }}
              >
                {part.text}
              </span>
            ))}
          </div>
          <div className={styles.subOptions}>
            {renderPhone && option.phone ? (
              <div className={styles.subOption}>
                <span
                  style={{ fontWeight: LIGHT_FONT_WEIGHT }}>
                  {option.phone?.prefix}{" "}
                </span>
                {partsPhone?.length > 0 && partsPhone?.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? BOLD_FONT_WEIGHT : LIGHT_FONT_WEIGHT,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            ) : null}

            {renderEmail && option.email ?
              <div className={styles.subOption}>
                {partsEmail.map((part, index) => (
                  <span

                    key={index}
                    style={{
                      fontWeight: part.highlight ? BOLD_FONT_WEIGHT : LIGHT_FONT_WEIGHT,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div> : null}

            {renderCycleMeta ?
              <div className={styles.subOption}>
                <div className={styles.cycleMeta}>
                  <span>
                    <CalendarMonthIcon /> {moment(option?.firstEvent?.start).format('DD MMMM YYYY, HH:mm')}
                  </span>
                  <span>
                    <Person /> {`${option.reservationsCount}/${option.capacity}`}
                  </span>
                </div>
              </div> : null}

          </div>
        </div>
      </li>
    );
  };

  return (
    <Autocomplete
    multiple={props.multiple}
      className={classnames(className, styles.searchBar)}
      id="highlights-demo"
      open={open}
      key={props.key}
      onOpen={handleSetOpen}
      onClose={handleSetClose}
      style={props.style}
      freeSolo={props.freeSolo || false}
      options={options}
      getOptionLabel={(option) => {
        if (getOptionLabel) return getOptionLabel(option)
        else return option.fullName || option.name || option.title || option
      }
      }
      onChange={handleSelect}
      noOptionsText={"Brak wyników"}
      onInputChange={handleInputChange}
      isOptionEqualToValue={(option, value) => option?._id === value?._id}
      renderInput={(params) => (
        <TextField
          {...params}
          className={styles.searchBar}
          label={label}
          variant={variant}
          placeholder={placeholder}
          margin="normal"
          name={props.name}

          InputProps={{
            ...inputProps,
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      filterOptions={(x) => x}
      renderOption={renderValues}
    />
  );
}

export default connect()(AutoCompleteSearch);
