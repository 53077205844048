import { useSelector } from "react-redux";
import styles from "./BuyCycleFlow.module.scss";
import { selectMaxLengths } from "../../redux/slices/utilsSlice";
import InfoIcon from "@mui/icons-material/Info";
import { Link, useHistory } from "react-router-dom";

function ProductCard(props) {
  const product = props.product || props.data;
  const maxLengths = useSelector(selectMaxLengths);
  const setSelectedProduct = () => props.setSelectedProduct(product);
  const forAdmin = props.forAdmin;
  const linkhref = forAdmin ? `editProduct` : "oferta";

  const metadata = product.cheapestPrice ? (
    <div className={styles.priceInfo}>
      <span>od</span>
      <span className={styles.price}>
        {product.cheapestPrice} {maxLengths.currencySign}
      </span>
      <span>za zajęcia</span>
    </div>
  ) : (
    <div className={styles.priceInfo}>
      <span className={styles.price}> {product.cycles?.length} </span> <span>{product.cycles?.length < 5 ? 'zajęcia' : 'zajęć'}</span>
    </div>
  );

  return (
    <div className={styles.productCardWrapper}>
      <Link to={`/${linkhref}/${product?.slug ? product?.slug : product?._id}`}>
        <div className={styles.productCard}>
          <div
            style={{ backgroundImage: `url(${product.thumbnail})` }}
            className={styles.thumbnail}
          />
          <div className={styles.productInfo}>
            <div className={styles.name}>{product.name}</div>
            {metadata}
          </div>
        </div>
      </Link>

      {!props.forAdmin && (
      <div onClick={setSelectedProduct} className={styles.detailsButton}>
        <InfoIcon /> <span>SZCZEGÓŁY</span>
      </div>) }
    </div> 
  );
}

export default ProductCard;
