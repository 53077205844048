import React from "react";

import PanelButton from "../../../../components/panelButton/PanelButton";
import WebIcon from "@mui/icons-material/Web";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
function ManageHomePage(props) {
  const history = useHistory();
  return (
    <div>
      <PanelButton
        onClick={() => history.push("/manageCustomPages")}
        label={"Zarządzaj podstronami"}
        icon={<WebIcon style={{ fontSize: 40 }} />}
        width={100}
        height={100}
      />
    </div>
  );
}

export default ManageHomePage;
