import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import {
  selectNews,
  selectNewsLoading,
  selectPages,
  setPage,
  setSingleNews,
} from "../../redux/slices/newsSlice";
import styles from "./news.module.scss";
import Pagination from "../../components/Pagination/Pagination";
import NewNewsCard from "./NewNewsCard/NewNewsCard";
import classNames from "classnames";
import Archive from "./Archive";
import { useHistory } from "react-router-dom";
import { getFullPath, getMonthName } from "../../svc/svc";
import UpcomingEvents from "./UpcomingEvents";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
import AddPostButton from "../panel/components/addPostButton/AddPostButton";

const description =
  "W Stolicy eXperymentu nieprzerwanie coś się dzieje. Zaglądaj tu a z pewnością będziesz na bieżąco! Aktualności, ciekawostki, zdjęcia z kolejnych etapów budowy i wiele innych";

  const breadcrumbListStructuredData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Stolica eXperymentu",
        item: getFullPath(""),
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Aktualności",
        item: getFullPath("/aktualnosci"),
      },
  
    ],
  };

function News(props) {
  const [date, setDate] = useState({});
  const history = useHistory();

  const user = useSelector(selectUser);

  const canAddPost = user.scope.includes("news") || user.type === "admin";

  const selectDate = (year, month) => {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.set("page", 1);
    if (year) searchParams.set("year", year);
    else searchParams.delete("year");
    if (month) searchParams.set("month", month);
    else searchParams.delete("month");
    history.replace({
      search: searchParams.toString(),
    });
    if (year || month) setDate({ year, month });
    else setDate({});
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    let allegedDate = {};
    if (searchParams.has("year")) allegedDate.year = searchParams.get("year");
    if (searchParams.has("month"))
      allegedDate.month = searchParams.get("month");

    setDate(allegedDate);
  }, []);

  return (
    <>
      <Helmet>
        <title>Aktualności | Stolica eXperymentu</title>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <link rel="canonical" href={window.location.href} />

        <script type="application/ld+json">
          {JSON.stringify(breadcrumbListStructuredData)}
        </script>
        
      </Helmet>
      <div className={styles.newsMainContainer}>
        <div className={classNames(styles.header)}>
          <h1>
            Aktualności w Stolicy e<span className={styles.orange}>X</span>
            perymentu{" "}
            {Object.keys(date).length > 0
              ? ` - ${getMonthName(date?.month)} ${date?.year}`
              : ""}
          </h1>
        </div>

        <div className={styles.mainContent}>
          <Pagination
            key={JSON.stringify(date)}
            location={props.location}
            dataSelector={selectNews}
            pagesSelector={selectPages}
            loadingSelector={selectNewsLoading}
            setSingle={setSingleNews}
            singlePath="/aktualnosci/"
            setPage={setPage}
            emptyText="Brak aktualności"
            ElementCard={NewNewsCard}
            dataContainerClassName={styles.newsWrapper}
          />

          <div className={styles.sidebar}>
            {canAddPost ? <AddPostButton inheritSize /> : <></>}
            {/* <UpcomingEvents /> */}
            <Archive selectDate={selectDate} />
          </div>
        </div>
      </div>
    </>
  );
}

export default connect()(News);
