import PersonIcon from "@mui/icons-material/Person";
import styles from "./Resources.module.scss";

function ResourceCard(props) {
  const { name, building, description, thumbnail, capacity } = props.resource;

  return (
    <div className={styles.cardWrapper}>
      <div
        style={{ backgroundImage: `url(${thumbnail})` }}
        className={styles.imgBox}
      >
        <div className={styles.capacityDiv}>
          <PersonIcon /> <div className={styles.capacityText}>{capacity}</div>
        </div>
      </div>
      <div className={styles.nameBox}>{name}</div>
      <div className={styles.buildingBox}>{building}</div>
      <div className={styles.descriptionBox}>{description}</div>
    </div>
  );
}

export default ResourceCard
